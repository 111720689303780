import React from "react";
import { connect } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
//@material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
//@material-ui/icons
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
//custom components
import GenericDialog from "components/Dialogs/GenericDialogV2";
import GenericButton from "components/Buttons/GenericButton";
import ApproveSection from "views/admin/Loans/Sections/ApproveSection";
import { updateLoanAction } from "redux/actions/loansAction";
import { updateLoanMap } from "mappers/loansMap";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ApproveDialogContent(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = methods;

  const onSubmitApprove = (data) => {
    data["loanId"] = parseInt(props.loanNumber);
    data["isMissingPayments"] = null;
    if (isValid) {
      props.updateLoan(
        updateLoanMap(data),
        function () {
          props.onClose();
        },
        function () {
          props.history.goBack();
        }
      );
    }
  };

  return (
    <GenericDialog maxWidth={props.maxWidth} name={props.name} open={props.open} onClose={props.onClose}>
      <Box className={classes.modalHelpIconBox}>
        <HelpOutlineIcon className={classes.modalHelpIconRoot} />
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitApprove)}>
          <ApproveSection
            getValues={getValues}
            isEditable={true}
            errors={errors}
            classes={classes}
            setValue={setValue}
            theme={theme}
            trigger={trigger}
          ></ApproveSection>
          <Box textAlign="center" marginBottom="30px" marginTop="30px">
            <GenericButton 
              typeSubmit={true} 
              color="primary"
              disabled={props.loansState?.update_loan_loading}
              loading={props.loansState?.update_loan_loading}
            >
              Submeter
            </GenericButton>
          </Box>
        </form>
      </FormProvider>
    </GenericDialog>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  updateLoan: (data, onSuccess, okAction) => dispatch(updateLoanAction(data, onSuccess, okAction)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApproveDialogContent);
