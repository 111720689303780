import { React } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";
import "moment/locale/pt";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons components
import ErrorIcon from "@material-ui/icons/Error";
//import custom components
import FormSelect from "components/FormFields/FormSelect";
import GenericButton from "components/Buttons/GenericButton";
import { getCategoriesAction } from "redux/actions/datasetsAction";
import { updateProfessionalKitStateAction } from "redux/actions/stockAction";
import { showGenericTwoButtonDialogAction } from "redux/actions/dialogsAction";
import { messages, labels } from "resources/resources";
import { DATASET, REGEX_PATTERNS, UNITS, MONETARYUNITPLACEHOLDER } from "utils/consts";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
const useStylesGeneric = makeStyles(componentStylesGeneric);

function GeneralDataSection(props) {
  const intl = useIntl();
  const classes = useStylesGeneric();
  const theme = useTheme();
  const { control } = useFormContext();

  const updateKitStatus = () => {
    if (props.getValues("active")) {
      props.showDialog(
        "Pretende continuar?",
        "A activação deste kit profissional irá inactivar a caracterização activa para esta especialidade.",
        function () {
          props.setIsActive(props.getValues("active"));
          return props.updateProfessionalKitState(props.professionalKitId);
        }
      );
    } else {
      props.setIsActive(props.getValues("active"));
      return props.updateProfessionalKitState(props.professionalKitId);
    }
  };
  const onUnitPriceChange = (value, onChange) => {
    value = value?.replace(/\./g, "");
    //TODO - Check better way to format currency on same input
    if (value && value.charAt(value.length - 1) === ",") props.setFormattedUnitPrice(value);
    else {
      if (value) {
        value = value?.replace(/,/g, ".");
        if (intl.formatNumber(value) !== "NaN") props.setFormattedUnitPrice(intl.formatNumber(value));
        else props.setFormattedUnitPrice(value);
      } else {
        props.setFormattedUnitPrice("");
      }
    }
    value = value?.replace(/\./g, ",");
    onChange(value);
  };

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          classes={{ title: classes.cardHeader, action: classes.cardHeaderAction }}
          title="Dados Gerais"
          action={
            !props.isCreate && (
              <Grid container>
                <Grid
                  item
                  className={
                    !props.isActive
                      ? classes.cardHeaderActionSwitchActiveLabel
                      : classes.cardHeaderActionSwitchInactiveLabel
                  }
                >
                  Inactivo
                </Grid>
                <Grid item className={classes.cardHeaderActionSwitchGrid}>
                  <Controller
                    name="active"
                    control={control}
                    component={Box}
                    render={({ field: { onChange, name } }) => (
                      <Switch
                        name={name}
                        onChange={(_, val) => {
                          onChange(val);
                          updateKitStatus();
                        }}
                        checked={props.isActive}
                        disabled={props.isEditable}
                        classes={{
                          thumb: !props.isEditable ? classes.warningSwitchThumb : classes.disabledSwitchThumb,
                          track: !props.isEditable ? classes.warningSwitchTrack : classes.disabledSwitchTrack,
                          checked: classes.checkedSwitch,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  className={
                    props.isActive
                      ? classes.cardHeaderActionSwitchActiveLabel
                      : classes.cardHeaderActionSwitchInactiveLabel
                  }
                >
                  Activo
                </Grid>
                <Grid item>
                  <GenericButton
                    typeSubmit={false}
                    size="small"
                    color="tertiary"
                    onClick={() => {
                      props.handleEditSection();
                    }}
                  >
                    {intl.formatMessage(labels.Label_Generic_Edit)}
                  </GenericButton>
                </Grid>
              </Grid>
            )
          }
        ></CardHeader>
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={6} md={3}>
              <FormGroup>
                <FormLabel>Especialidade</FormLabel>
                <FormSelect
                  control={control}
                  fieldName="categoryId"
                  selectOptions={
                    props.dataSetState[DATASET.INACTIVE_CATEGORIES]
                      ? props.dataSetState[DATASET.INACTIVE_CATEGORIES]
                      : []
                  }
                  getValues={props.getValues}
                  required={props.isEditable}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  errors={props.errors}
                  classes={classes}
                  disabled={!props.isEditable}
                />
                {props.errors["categoryId"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["categoryId"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormGroup>
                <FormLabel>Valor Total</FormLabel>
                <Controller
                  name="unitPrice"
                  control={control}
                  component={Box}
                  defaultValue=""
                  rules={{
                    required: {
                      value: props.isEditable,
                      message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                    },
                    pattern: {
                      value: REGEX_PATTERNS.MONETARY,
                      message: intl.formatMessage(messages.Message_Generic_InvalidField),
                    },
                    validate: {
                      minValue: (value) => {
                        if (value === "0" || value === "0,00" || value === "0,0")
                          return intl.formatMessage(messages.Message_Generic_ValueGreaterThanZeroField);
                        return true;
                      },
                      //  EmailMatch: value => { return value === getValues("email") || "O e-mail inserido deve ser igual ao campo 'E-mail'"; }
                    },
                  }}
                  render={({ field: { onChange, name } }) => (
                    <OutlinedInput
                      name={name}
                      value={props.formattedUnitPrice ?? ""}
                      onChange={(e) => {
                        onUnitPriceChange(e.target.value, onChange);
                      }}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      disabled={!props.isEditable}
                      placeholder={MONETARYUNITPLACEHOLDER}
                      endAdornment={
                        <InputAdornment disableTypography={true} position="end">
                          {UNITS.MONETARY}
                        </InputAdornment>
                      }
                      classes={{
                        input: classes.monetaryFields,
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["unitPrice"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {props.errors["unitPrice"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["unitPrice"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormGroup>
                <FormLabel>
                  Período de carência
                  <Tooltip title="Período em meses">
                    <ErrorIcon className={classes.tooltipInfoIcon} />
                  </Tooltip>
                </FormLabel>
                <Controller
                  name="paymentDate"
                  control={control}
                  component={Box}
                  defaultValue=""
                  rules={{
                    required: {
                      value: props.isEditable,
                      message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                    },
                    validate: {
                      minValue: (value) => {
                        return value >= 0 || intl.formatMessage(messages.Message_Generic_ValueGreaterThanZeroField);
                      },
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      disabled={!props.isEditable}
                      autoComplete="off"
                      type="number"
                      placeholder={"00"}
                      endAdornment={
                        <InputAdornment disableTypography={true} position="end">
                          {UNITS.MONTHS}
                        </InputAdornment>
                      }
                      classes={{
                        input: classes.monetaryFields,
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["paymentDate"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {props.errors["paymentDate"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["paymentDate"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormGroup>
                <FormLabel>
                  Período de reembolso
                  <Tooltip title="Período em meses">
                    <ErrorIcon className={classes.tooltipInfoIcon} />
                  </Tooltip>
                </FormLabel>
                <Controller
                  name="refundDate"
                  control={control}
                  component={Box}
                  defaultValue=""
                  rules={{
                    required: {
                      value: props.isEditable,
                      message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                    },
                    validate: {
                      minValue: (value) => {
                        return value >= 0 || intl.formatMessage(messages.Message_Generic_ValueGreaterThanZeroField);
                      },
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      disabled={!props.isEditable}
                      placeholder={"00"}
                      autoComplete="off"
                      type="number"
                      endAdornment={
                        <InputAdornment disableTypography={true} position="end">
                          {UNITS.MONTHS}
                        </InputAdornment>
                      }
                      classes={{
                        input: classes.monetaryFields,
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["refundDate"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {props.errors["refundDate"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["refundDate"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <FormGroup>
                <FormLabel>Notas</FormLabel>
                <Controller
                  name="notes"
                  control={control}
                  component={Box}
                  defaultValue=""
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      disabled={!props.isEditable}
                      placeholder={"Escreva aqui as suas notas"}
                      autoComplete="off"
                      type="text"
                      multiline={true}
                      rows={3}
                    />
                  )}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormGroup>
                <FormLabel>Composição do Kit</FormLabel>
                <Controller
                  name="kitDescription"
                  control={control}
                  component={Box}
                  defaultValue=""
                  rules={{
                    required: {
                      value: props.isEditable,
                      message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      disabled={!props.isEditable}
                      autoComplete="off"
                      type="text"
                      placeholder={"Escreva aqui a composição do kit"}
                      multiline={true}
                      rows={3}
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["kitDescription"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {props.errors["kitDescription"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["kitDescription"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getCategories: (name) => dispatch(getCategoriesAction(name)),
  updateProfessionalKitState: (professionalKitId) => dispatch(updateProfessionalKitStateAction(professionalKitId)),
  showDialog: (message, yesAction, noAction) =>
    dispatch(showGenericTwoButtonDialogAction(message, yesAction, noAction)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralDataSection);
