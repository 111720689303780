import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactDatetime from "react-datetime";
import "moment/locale/pt";
import moment from "moment";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import { Card, CardHeader, CardContent } from "@material-ui/core";
// @material-ui/icons components
import SearchIcon from "@material-ui/icons/Search";
import DateRange from "@material-ui/icons/DateRange";
// components
import FormSelect from "components/FormFields/FormSelect";
import GenericFormSelect from "components/FormFields/GenericFormSelect";
import GenericButton from "components/Buttons/GenericButton";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import { getDataSetByNameAction, getGovernmentProgramsAction } from "redux/actions/datasetsAction";
import { searchSchedulingsAction } from "redux/actions/schedulingAction";
import { getReportAction } from "redux/actions/reportsAction";
import { DATASET, PAGINATION, REPORT_FILE_NAME, OPERATIONS, REPORT_CONSTS } from "utils/consts";
import { getSelectOptionsElementByCode } from "utils/formSelect";
import { labels, messages, errorMessages, titles } from "resources/resources";
import { schedulingsMap, schedulesReportMap } from "mappers/schedulesMap";
import { checkOperationPermission } from "utils/permission";
import { REGEX_PATTERNS } from "utils/consts"

const useStylesGeneric = makeStyles(componentStylesGeneric);

function FilterSection(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const theme = useTheme();

  const [filter, setFilter] = useState({
    numApplication: "",
    numBI: "",
    userCode: "",
    nameOfUser: "",
    governmentProgramId: null,
    motiveId: null,
    startDate: null,
    endDate: null,
    provinceId: props.authState?.province ?? null,
  });
  const [filterCounter, setFilterCounter] = useState(0);

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const handleInputValidDate = (date, onChange) => {
    if (date === "" || REGEX_PATTERNS.DATE.test(date)) {
      onChange(date);
    }
  };

  useEffect(() => {
    let count = 0;

    Object.keys(filter).map((key) => {
      if (filter[key] && key !== "pageIndex" && key !== "pageSize") {
        count += 1;
      }

      return count;
    });

    setFilterCounter(count);
  }, [filter]);

  const cleanFilter = () => {
    setValue("applicationNumber", "");
    setValue("numBI", "");
    setValue("userCode", "");
    setValue("nameOfUser", "");
    setValue("statusId", undefined);
    setValue("governmentProgramId", undefined);
    setValue("motiveId", undefined);
    setValue("startDate", undefined);
    setValue("endDate", undefined);
    if (!props.authState?.province) setValue("province", undefined);

    setFilterCounter(0);
    setFilter(0);
  };

  const onSubmit = (data) => {
    let filter = schedulingsMap(data);
    filter["pageIndex"] = PAGINATION.FIRST_PAGE;
    filter["pageSize"] = PAGINATION.PAGE_SIZE;
    setFilter(filter);
    
    if (props.page === PAGINATION.FIRST_PAGE) props.getSchedulings(filter);
    else props.handleChangePage(1);
  };

  const handleSetFilter = (filterKey, value, clearChildKeyList) => {
    const newFilter = { ...filter, [filterKey]: value };

    clearChildKeyList?.forEach((child) => {
      newFilter[child] = null;
    });

    setFilter(newFilter);
  };

  useEffect(() => {
    if (
      props.dataSetState[DATASET.APPLICATION_SCHEDULING_MOTIVES] === undefined ||
      props.dataSetState[DATASET.APPLICATION_SCHEDULING_MOTIVES]?.length === 0
    ) {
      props.getDataSet(DATASET.APPLICATION_SCHEDULING_MOTIVES);
    }

    if (props.dataSetState[DATASET.PROVINCES] === undefined || props.dataSetState[DATASET.PROVINCES] === []) {
      props.getDataSet(DATASET.PROVINCES);
    }

    if (
      props.dataSetState[DATASET.GOVERNMENT_PROGRAMS] === undefined ||
      props.dataSetState[DATASET.GOVERNMENT_PROGRAMS]?.length === 0
    ) {
      props.getGovernmentPrograms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      props.authState?.province &&
      props.dataSetState[DATASET.PROVINCES] !== undefined &&
      props.dataSetState[DATASET.PROVINCES] !== []
    ) {
      var selectedProvince = getSelectOptionsElementByCode(
        props.dataSetState[DATASET.PROVINCES],
        props.authState.province
      );
      setValue("province", selectedProvince);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.PROVINCES]]);

  useEffect(() => {
    setValue("nameOfUser", props.nameOfUser !== undefined ? props.nameOfUser : "");
    if (props.nameOfUser !== undefined) {
      handleSubmit(onSubmit)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nameOfUser]);

  useEffect(() => {
    if (props.match?.params?.applicationNumber !== undefined) {
      setValue(
        "applicationNumber",
        props.match?.params?.applicationNumber !== undefined ? props.match.params.applicationNumber : ""
      );
      handleSubmit(onSubmit)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match?.params?.applicationNumber]);

  useEffect(() => {
    let temp_filter = filter;
    Object.assign(temp_filter, filter);
    temp_filter["pageIndex"] = props.page;
    temp_filter["pageSize"] = PAGINATION.PAGE_SIZE;
    props.getSchedulings(temp_filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page]);

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={
          <div>
            <div className={classes.sideBySideDiv}>{intl.formatMessage(titles.Title_Admin_SchedulesManagement)}</div>
            <div className={classes.sideBySideDiv + " " + classes.cardElementsCount}>
              Filtros {"("}
              {filterCounter}
              {")"}
            </div>
          </div>
        }
      ></CardHeader>
      <CardContent>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} sm={12} md={4}>
                <FormGroup>
                  <FormLabel>Nº Candidatura</FormLabel>
                  <Controller
                    name="applicationNumber"
                    control={control}
                    defaultValue=""
                    rules={{ required: { value: false } }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("numApplication", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["applicationNumber"] !== undefined,
                          }),
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>Nº BI</FormLabel>
                  <Controller
                    name="numBI"
                    control={control}
                    defaultValue=""
                    rules={{ required: { value: false } }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("NumBI", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["numBI"] !== undefined,
                          }),
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel classes={{ root: classes.filterLabelLong }}>Código do utilizador</FormLabel>
                  <Controller
                    name="userCode"
                    control={control}
                    defaultValue=""
                    rules={{ required: { value: false } }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("userCode", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["userCode"] !== undefined,
                          }),
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>Nome</FormLabel>
                  <Controller
                    name="nameOfUser"
                    control={control}
                    defaultValue=""
                    rules={{ required: { value: false } }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("nameOfUser", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["nameOfUser"] !== undefined,
                          }),
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_SchedulingManagementFilter_SchedulingDateFrom)}
                  </FormLabel>
                  <Controller
                    name="startDate"
                    control={control}
                    defaultValue=""
                    rules={{
                      validate: {
                        isValidDate: (value) => {
                          return (
                            !value ||
                            value._isAMomentObject ||
                            intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat)
                          );
                        },
                        maxDate: (value) => {
                          return (
                            !value ||
                            value <= moment().startOf("day") ||
                            intl.formatMessage(messages.Message_Generic_DateLessThanOrEqualToTodayField)
                          );
                        },
                        isLessThanEndDate: (value) => {
                          if (!getValues("endDate") || errors["endDate"] !== undefined) return true;
                          return (
                            !value ||
                            (value !== "" &&
                              value._isAMomentObject &&
                              getValues("endDate")._isAMomentObject &&
                              value <= getValues("endDate")) ||
                            intl.formatMessage(messages.Message_Generic_DateFromLessThanOrEqualToDateToField)
                          );
                        },
                      },
                    }}
                    render={({ field: { onChange, name, value } }) => (
                      <ReactDatetime
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          if (getValues("endDate")) trigger("endDate");
                          trigger("startDate");
                        }}
                        locale="pt"
                        dateFormat={"DD/MM/YYYY"}
                        timeFormat={false}
                        isValidDate={(currentDate, _) => {
                          return (
                            currentDate <= moment().startOf("day") &&
                            (!getValues("endDate") ||
                              (getValues("endDate")._isAMomentObject && currentDate <= getValues("endDate")))
                          );
                        }}
                        closeOnSelect={true}
                        closeOnTab={true}
                        closeOnClickOutside={true}
                        inputProps={{
                          placeholder: "DD/MM/AAAA",
                          className: "",
                          value: value && value._isAMomentObject ? value.format("DD/MM/YYYY") : value ?? "",
                        }}
                        renderInput={(dateInputProps, open) => (
                          <OutlinedInput
                            style={{ display: "flex" }}
                            onFocus={open}
                            {...dateInputProps}
                            onChange={(e) => {
                              handleInputValidDate(e.target.value, dateInputProps.onChange);
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                <Box component={DateRange} marginRight=".5rem" color={theme.palette.primary.main} />
                              </InputAdornment>
                            }
                            classes={{
                              notchedOutline: clsx({
                                [classes.borderWarning]: errors["startDate"] !== undefined,
                              }),
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  {errors["startDate"] !== undefined && (
                    <FormHelperText
                      component={Box}
                      color={theme.palette.warning.badge + "!important"}
                      bgcolor={theme.palette.warning.badgeBg + "!important"}
                      borderRadius="25px"
                      padding={"0px 10px"}
                    >
                      {errors["startDate"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_SchedulingManagementFilter_SchedulingDateTo)}
                  </FormLabel>
                  <Controller
                    name="endDate"
                    control={control}
                    defaultValue=""
                    rules={{
                      validate: {
                        isValidDate: (value) => {
                          return (
                            !value ||
                            value._isAMomentObject ||
                            intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat)
                          );
                        },
                        maxDate: (value) => {
                          return (
                            !value ||
                            value <= moment() ||
                            intl.formatMessage(messages.Message_Generic_DateLessThanOrEqualToTodayField)
                          );
                        },
                        isGreaterThanStartDate: (value) => {
                          if (!getValues("startDate") || errors["startDate"] !== undefined) return true;
                          return (
                            !value ||
                            (value !== "" &&
                              value._isAMomentObject &&
                              getValues("startDate")._isAMomentObject &&
                              value >= getValues("startDate")) ||
                            intl.formatMessage(messages.Message_Generic_DateToGreaterThanOrEqualToDateFromField)
                          );
                        },
                      },
                    }}
                    render={({ field: { onChange, name, value } }) => (
                      <ReactDatetime
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          if (getValues("startDate")) trigger("startDate");
                          trigger("endDate");
                        }}
                        locale="pt"
                        dateFormat={"DD/MM/YYYY"}
                        timeFormat={false}
                        isValidDate={(currentDate, _) => {
                          return (
                            currentDate <= moment() &&
                            (!getValues("startDate") ||
                              (getValues("startDate")._isAMomentObject && currentDate >= getValues("startDate")))
                          );
                        }}
                        closeOnSelect={true}
                        closeOnTab={true}
                        closeOnClickOutside={true}
                        inputProps={{
                          placeholder: "DD/MM/AAAA",
                          className: "",
                          value: value && value._isAMomentObject ? value.format("DD/MM/YYYY") : value ?? "",
                        }}
                        renderInput={(dateInputProps, open) => (
                          <OutlinedInput
                            style={{ display: "flex" }}
                            onFocus={open}
                            {...dateInputProps}
                            onChange={(e) => {
                              handleInputValidDate(e.target.value, dateInputProps.onChange);
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                <Box component={DateRange} marginRight=".5rem" color={theme.palette.primary.main} />
                              </InputAdornment>
                            }
                            classes={{
                              notchedOutline: clsx({
                                [classes.borderWarning]: errors["endDate"] !== undefined,
                              }),
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  {errors["endDate"] !== undefined && (
                    <FormHelperText
                      component={Box}
                      color={theme.palette.warning.badge + "!important"}
                      bgcolor={theme.palette.warning.badgeBg + "!important"}
                      borderRadius="25px"
                      padding={"0px 10px"}
                    >
                      {errors["endDate"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_Subprogram_GovernmentProgramField)}
                  </FormLabel>
                  <GenericFormSelect
                    control={control}
                    fieldName="governmentProgramId"
                    selectOptions={
                      props.dataSetState[DATASET.GOVERNMENT_PROGRAMS] !== undefined
                        ? props.dataSetState[DATASET.GOVERNMENT_PROGRAMS]
                        : []
                    }
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"governmentProgramId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>Motivo</FormLabel>
                  <GenericFormSelect
                    control={control}
                    fieldName="motiveId"
                    selectOptions={
                      props.dataSetState[DATASET.APPLICATION_SCHEDULING_MOTIVES] !== undefined
                        ? props.dataSetState[DATASET.APPLICATION_SCHEDULING_MOTIVES]
                        : []
                    }
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"motiveId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>Província</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="province"
                    selectOptions={props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : []}
                    disabled={props.authState?.province !== null}
                    setValue={setValue}
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"provinceId"}
                  />
                </FormGroup>
              </Grid>
            </Grid>

            {/* Butões de Pesquisa */}
            <Grid container>
              <Grid item xs={12} md={12} classes={{ root: classes.alignRight }}>
                <GenericButton
                  color="ghost"
                  onClick={() => {
                    cleanFilter();
                  }}
                >
                  {intl.formatMessage(labels.Label_ClearFilters)}
                </GenericButton>
                {checkOperationPermission(props.authState.operationsList, OPERATIONS.EXPORT_REPORTS) && (
                  <Tooltip
                    title={
                      props.schedulingState?.total_records > REPORT_CONSTS.MAX_RESULTS ||
                      props.schedulingState?.total_records === 0
                        ? `Limite mínimo de 1 e máximo de ${REPORT_CONSTS.MAX_RESULTS} resultados para exportar relatório`
                        : ""
                    }
                  >
                    <span>
                      <GenericButton
                        color="secondary"
                        loading={props.reportsState.get_report_loading}
                        disabled={
                          props.schedulingState?.total_records > REPORT_CONSTS.MAX_RESULTS ||
                          props.schedulingState?.total_records === 0
                        }
                        style={{ marginRight: "20px" }}
                        onClick={() => {
                          props.getReport(
                            schedulesReportMap(filter),
                            REPORT_FILE_NAME.SCHEDULES_SEARCH_RESULTS +
                              moment().format("YYYYMMDDHHmm") +
                              REPORT_FILE_NAME.EXCEL_EXTENSION
                          );
                        }}
                      >
                        {intl.formatMessage(labels.Label_ExportButton)}
                      </GenericButton>
                    </span>
                  </Tooltip>
                )}
                <GenericButton
                  color="primary"
                  variant="contained"
                  typeSubmit={true}
                  loading={props.schedulingState.search_loading}
                >
                  {intl.formatMessage(labels.Label_FilterSection_SearchButton)}
                </GenericButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getSchedulings: (filterData) => dispatch(searchSchedulingsAction(filterData)),
  getReport: (data, reportFileName) => dispatch(getReportAction(data, reportFileName)),
  getGovernmentPrograms: () => dispatch(getGovernmentProgramsAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FilterSection));
