import React from "react";
import { useIntl } from "react-intl";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
//MaterialUI
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import LinkMaterialUi from "@material-ui/core/Link";

import componentStylesGeneric from "assets/theme/views/admin/generic";
import GenericButton from "components/Buttons/GenericButton";
import { labels } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

export default function CardTableContainer(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();

  return (
    <Card classes={{ root: classes.tableCardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={
          <div>
            <div className={classes.sideBySideDiv}>{props.title}</div>
            <div className={classes.sideBySideDiv + " " + classes.cardElementsCount}>
              {"("}
              {props.count}
              {")"}
            </div>
          </div>
        }
        action={
          props.viewMoreLink ? (
            <LinkMaterialUi component={Link} variant="h5" to={props.viewMoreLink} className={classes.CardHeaderLink}>
              {intl.formatMessage(labels.Label_See_More)}
            </LinkMaterialUi>
          ) : (
            props.actionButtonOnClick && (
              <GenericButton
                typeSubmit={false}
                color="tertiary"
                size="large"
                onClick={props.actionButtonOnClick}
                classes={classes.TableCardHeaderButton}
              >
                {props.actionButtonLabel}
              </GenericButton>
            )
          )
        }
      />
      {props.children}
    </Card>
  );
}
