import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
// @material-ui/icons components
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
// core components

import componentStyles from "assets/theme/components/headers/page-title-header.js";

const useStyles = makeStyles(componentStyles);

const PageTitleHeader = ({ title, subsectionLink }) => {
    const classes = useStyles();
    const history = useHistory();
    return (
        <Grid container classes={{ root: classes.containerPadding }}>
            <Grid item xs={12} md={12} className={classes.titleGrid}>
                <Box component={Link} onClick={() => { !subsectionLink ? history.goBack() : history.push(subsectionLink); }} className={classes.navigateBeforeBox}>
                    <NavigateBeforeIcon className={classes.navigateBefore} />
                </Box>
                <Box className={classes.pageTitle} data-testid="page-header-title">
                    {title}
                </Box>
            </Grid>
        </Grid>

    );
};

PageTitleHeader.propTypes = {
    title: PropTypes.string,
};

export default PageTitleHeader;
