import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Pagination from "@material-ui/lab/Pagination";
import { ArrayUtils } from "utils/array_utils";
import { DATASET, USER_TYPE } from "utils/consts";

import getFilteredFrontOfficeUsersActions from "redux/actions/usersAction";

// @material-ui/icons components
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// core componentssrc
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const tableHead = [
  "Nome",
  "Nº BI / NIF",
  "Tipo de Utilizador",
  "Estado",
  "Província",
  "Munícipio",
  "Email",
  "Telemóvel",
  "",
];

function ResultSectionFo(props) {
  const theme = useTheme();
  const classes = useStylesGeneric();
  const [foUserList, setFoUserList] = useState([]);

  useEffect(() => {
    if (!ArrayUtils.NullOrEmpty(props.usersState.result)) {
      setFoUserList(props.usersState.result);
    } else {
      setFoUserList([]);
    }
  }, [props.usersState.result]);

  useEffect(() => {
    if (ArrayUtils.NullOrEmpty(props.dataSetState[DATASET.PROVINCES])) {
      props.getDataSetByName(DATASET.PROVINCES);
    }
    if (ArrayUtils.NullOrEmpty(props.dataSetState[DATASET.MUNICIPALITIES])) {
      props.getDataSetByName(DATASET.MUNICIPALITIES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          title: classes.cardHeader,
          action: classes.cardHeaderAction,
        }}
        title={
          <div>
            <div className={classes.sideBySideDiv}>Lista de Utilizadores Frontoffice</div>
            <div className={classes.sideBySideDiv + " " + classes.cardElementsCount}>
              {"("}
              {props.usersState?.total_records}
              {")"}
            </div>
          </div>
        }
      ></CardHeader>
      <TableContainer>
        <Box component={Table} alignItems="center" marginBottom="0!important">
          <TableHead>
            <TableRow>
              {tableHead.map((prop, key) => (
                <TableCell
                  key={key}
                  classes={{
                    root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                  }}
                  data-testid={"result-section-fo-table-cell-" + prop}
                >
                  {prop}
                </TableCell>
              ))}
              <TableCell
                key="detailsCol"
                classes={{
                  root:
                    classes.tableCellRoot + " " + classes.tableCellRootHead + " " + classes.tableCellHeadStickyRight,
                }}
                data-testid="result-section-fo-table-cell-details"
              >
                Detalhes
              </TableCell>
            </TableRow>
          </TableHead>

          {foUserList !== undefined && foUserList.length > 0 && (
            <TableBody>
              {foUserList?.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell
                    classes={{ root: classes.tableRowEllipsis + " " + classes.tableCellRoot }}
                    title={prop.nameOfUser}
                  >
                    {prop.nameOfuser}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.numBI}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.userType === USER_TYPE.INDIVIDUAL ? "Cidadão" : "Empresa"}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    {!!prop.isActive ? "Activo" : "Inactivo"}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.province ? prop.province : ""}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    {prop.municipality ? prop.municipality : ""}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableRowEllipsis + " " + classes.tableCellRoot }}>
                    {prop.email}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.phone}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}></TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight,
                    }}
                  >
                    {" "}
                    {prop.userType === USER_TYPE.COLETIVO ? (
                      <Tooltip title="Detalhe">
                        <IconButton
                          aria-label="details"
                          component={Link}
                          to={props.userColectiveDetailsPagePath + prop.id}
                        >
                          <Box
                            component={ArrowForwardIosIcon}
                            width="12px!important"
                            height="12px!important"
                            color={theme.palette.primary.main}
                          />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Detalhe">
                        <IconButton
                          aria-label="details"
                          component={Link}
                          to={props.userSingularDetailsPagePath + prop.id}
                        >
                          <Box
                            component={ArrowForwardIosIcon}
                            width="12px!important"
                            height="12px!important"
                            color={theme.palette.primary.main}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Box>

        {(foUserList == null || foUserList.length === 0) && !props.usersState.frontoffice_users_search_loading && (
          <Box className={classes.noResultsInfoInTable}>Não existem resultados disponíveis.</Box>
        )}
      </TableContainer>

      <TableBackdrop open={props.usersState.frontoffice_users_search_loading} />

      <Pagination
        className={classes.alignPagination}
        count={props.usersState?.total_pages}
        page={props.page}
        onChange={props.handleChangePage}
        color="primary"
      ></Pagination>
    </Card>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getUsers: (name) => dispatch(getFilteredFrontOfficeUsersActions(name)),
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultSectionFo);
