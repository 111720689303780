import { MONETARY_DECIMAL_SEPARATOR } from "utils/consts";
export function updateProfessionalKitMap(id, formData) {
    if (formData !== null && formData !== undefined)
        return {
            Id: id,
            CategoryId: formData.categoryId.code ? formData.categoryId.code : "",
            UnitPrice: formData.unitPrice ? (formData.unitPrice + "").replace(MONETARY_DECIMAL_SEPARATOR.WEB, MONETARY_DECIMAL_SEPARATOR.API) : 0,
            PaymentDate: formData.paymentDate ? formData.paymentDate : 0,
            RefundDate: formData.refundDate ? formData.refundDate : 0,
            Notes: formData.notes ? formData.notes : "",
            KitDescription: formData.kitDescription ? formData.kitDescription : "",

        };
    return formData;
}

export default updateProfessionalKitMap