import { React } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
// @material-ui/icons components
import HelpIcon from "@material-ui/icons/Help";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
//import custom components

import componentStyles from "assets/theme/views/admin/static-page.js";
import { labels, titles } from "resources/resources";

const useStyles = makeStyles(componentStyles);

function QuickGuidesPage() {
  const classes = useStyles();
  const intl = useIntl();

  const areas = [
    {
      name: intl.formatMessage(labels.Label_QuickGuides_360Vision),
      quickGuides: [
        {
          title: intl.formatMessage(labels.Label_QuickGuides_CheckUserData),
          path: "INEFOP_360.pdf",
        },
      ],
    },
    {
      name: intl.formatMessage(labels.Label_QuickGuides_InternshipsFollowup),
      quickGuides: [
        {
          title: intl.formatMessage(labels.Label_QuickGuides_InternshipsFollowup_Citizen),
          path: "Followup_citizen.pdf",
        },
        {
          title: intl.formatMessage(labels.Label_QuickGuides_InternshipsFollowup_Company),
          path: "Followup_company.pdf",
        },
      ],
    },
    // {
    //   name: "Manuais",
    //   quickGuides: [
    //     {
    //       title: "Manual BackOffice",
    //       path: "Manual_BackOffice.pdf",
    //     },
    //   ],
    // },
    // {
    //   name: "Utilizadores Backoffice",
    //   quickGuides: [
    //     {
    //       title: "Como criar utilizador de BackOffice",
    //       path: "Como_Criar_Utilizador_BO.pdf",
    //     },
    //     {
    //       title: "O Processo de criação de utilizador de BackOffice",
    //       path: "O_Processo_Criacao_Utilizador_BO.pdf",
    //     },
    //   ],
    // },
    // {
    //   name: "Kits profissionais",
    //   quickGuides: [
    //     {
    //       title: "Como criar caracterizar e gerir kits profissionais",
    //       path: "Como_Caracterizar_Gerir_Kits_Profissionais.pdf",
    //     },
    //     {
    //       title: "O Processo de caracterização e gestão de kits profissionais",
    //       path: "O_Processo_Caracterizacao_Gestao_Kits_Profissionais.pdf",
    //     },
    //   ],
    // },
    // {
    //   name: "Subprograma PAPE",
    //   subAreas: [
    //     {
    //       name: "Caracterizar subprograma PAPE",
    //       quickGuides: [
    //         {
    //           title: "Como caracterizar um subprograma",
    //           path: "Como_Caracterizar_SubProgramas.pdf",
    //         },
    //         {
    //           title: "O Processo de caracterização de um subprograma",
    //           path: "O_Processo_Caracterizacao_SubProgramas.pdf",
    //         },
    //       ],
    //     },
    //     {
    //       name: "Avaliar candidatura PAPE",
    //       quickGuides: [
    //         {
    //           title: "Como avaliar uma candidatura a um subprograma",
    //           path: "Como_Avaliar_Candidaturas.pdf",
    //         },
    //         {
    //           title: "O Processo de avaliação de uma candidatura a um subprograma",
    //           path: "O_Processo_Avaliacao_Candidaturas.pdf",
    //         },
    //       ],
    //     },
    //     {
    //       name: "Associar candidato a Estágio PAPE",
    //       quickGuides: [
    //         {
    //           title: "Como associar candidato a estágio PAPE",
    //           path: "Como_Associar_Candidatos_Estagios.pdf",
    //         },
    //         {
    //           title: "O Processo de associação de candidato a Estágio PAPE",
    //           path: "O_Processo_Associar_Candidatos_Estagios.pdf",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   name: "Backoffice do Bancos",
    //   quickGuides: [
    //     {
    //       title: "Como gerir o microcrédito no BackOffice dos Bancos",
    //       path: "Como_Gerir_Microcredito_BackOffice_Banco.pdf",
    //     },
    //     {
    //       title: "O Processo de gestão de microcrédito no BackOffice dos Bancos",
    //       path: "O_Processo_Gestao_Microcredito_BackOffice_Bancos.pdf",
    //     },
    //   ],
    // },
    // {
    //   name: "Ofertas de emprego/estágio profissional",
    //   quickGuides: [
    //     {
    //       title: "Como monitorizar ofertas de emprego",
    //       path: "Como_Monitorizar_Ofertas_Emprego.pdf",
    //     },
    //     {
    //       title: "O Processo de monitorização ofertas de emprego",
    //       path: "O_Processo_Monitorizacao_Ofertas_Emprego.pdf",
    //     },
    //   ],
    // },
    // {
    //   name: "Disseminação de Informação",
    //   quickGuides: [
    //     {
    //       title: "Como gerir a disseminação de informação",
    //       path: "Como Gerir_Disseminacao_Informacao.pdf",
    //     },
    //     {
    //       title: "O Processo de gestão da disseminação de informação",
    //       path: "O_Processo_Gestao_Disseminacao_Informacao.pdf",
    //     },
    //   ],
    // },
  ];

  return (
    <>
      <Container maxWidth={false} classes={{ root: classes.containerRoot }}>
        <Grid container>
          <Grid item xs={12} md={9} xl={6}>
            <Card className={classes.cardRoot}>
              <CardContent>
                <Grid item xs={12} md={12}>
                  <Box classes={{ root: classes.pageTitle }}>{intl.formatMessage(titles.Title_QuickGuides)}</Box>
                </Grid>

                <Grid item xs={12} md={12}>
                  <List dense={true}>
                    {areas.map((item, key) => (
                      <Accordion key={key}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={key + "-content"}
                          id={key + "-header"}
                        >
                          <ListSubheader key={key} className={classes.fontWeight700}>
                            {item.name}
                          </ListSubheader>
                        </AccordionSummary>
                        <AccordionDetails className={classes.displayBlock}>
                          {item.quickGuides?.map((item, key) => (
                            <ListItem key={key}>
                              <Grid container>
                                <Grid item xs={1} md={1}>
                                  <HelpIcon className={classes.helpIcon}> </HelpIcon>
                                </Grid>
                                <Grid item xs={11} md={11} style={{ alignSelf: "center" }}>
                                  <Link
                                    to={"/quick-guides/" + item.path}
                                    target="_blank"
                                    className={classes.linkStandard}
                                  >
                                    {item.title}
                                  </Link>
                                </Grid>
                              </Grid>
                            </ListItem>
                          ))}
                          {item.subAreas?.map((item, key) => (
                            <Accordion key={key}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={key + "-content"}
                                id={key + "-header"}
                              >
                                <ListSubheader key={key} className={classes.fontWeight600}>
                                  {item.name}
                                </ListSubheader>
                              </AccordionSummary>
                              <AccordionDetails className={classes.displayBlock}>
                                {item.quickGuides?.map((item, key) => (
                                  <ListItem key={key}>
                                    <Grid container>
                                      <Grid item xs={1} md={1}>
                                        <HelpIcon className={classes.helpIcon}> </HelpIcon>
                                      </Grid>
                                      <Grid item xs={11} md={11} style={{ alignSelf: "center" }}>
                                        <Link
                                          to={"/quick-guides/" + item.path}
                                          target="_blank"
                                          className={classes.linkStandard}
                                        >
                                          {item.title}
                                        </Link>
                                      </Grid>
                                    </Grid>
                                  </ListItem>
                                ))}
                              </AccordionDetails>
                            </Accordion>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </List>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(QuickGuidesPage);
