export function userBOFilterMap(formData) {
  if (formData !== null && formData !== undefined) {
    return {
      name: formData.name ? formData.name : "",
      email: formData.email ? formData.email :"",
      province: formData.province ? formData.province.code : undefined,
      municipality: formData.municipality ? parseInt(formData.municipality.code) : undefined,
      userStatus: formData.userStatus ? formData.userStatus.code.toString() : "",
      profile: formData.profile ? formData.profile.label : undefined,
    };
  }
}
  
export default userBOFilterMap;
