import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
//import custom components
import PasswordForm from "./PasswordForm";
import PersonalForm from "./PersonalForm";
import UserPicture from "./UserPicture";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import { updateProfileAction } from "redux/actions/usersAction";
import updateProfileMap from "mappers/updateProfileMap";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import componentStylesProfile from "assets/theme/views/admin/user-profile.js";
const useStylesGeneric = makeStyles(componentStylesGeneric);
const useStylesProfile = makeStyles(componentStylesProfile);

function UserProfile(props) {
  const classes = { ...useStylesGeneric(), ...useStylesProfile() };
  const [file, setFile] = React.useState();
  const [changePassword, setChangePassword] = React.useState(false);

  const methods = useForm({
    mode: "onChange",
  });

  function handleChangePassword() {
    setChangePassword(true);
  }
  function handleCloseModal() {
    setChangePassword(false);
  }
  const handleRegister = (data) => {
    console.log(updateProfileMap(data, file));
    props.updateProfile(updateProfileMap(data, file));
  };

  return (
    <>
      <GenericBackdrop open={props.usersInfo.getUserInfoBO_loading} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleRegister)}>
          <Container maxWidth={false} component={Box} classes={{ root: classes.containerRoot }}>
            <Grid container>
              <Grid item xs={12} md={3} className={classes.userProfileTitle}>
                Meu perfil
              </Grid>
              <Grid item xs={12} md={9}></Grid>

              <Grid item xs={12} md={3}>
                <UserPicture file={file} setFile={setFile} />
              </Grid>
              <Grid item xs={12} md={9}>
                <PersonalForm handleChangePassword={handleChangePassword} />
              </Grid>
            </Grid>
          </Container>
        </form>
      </FormProvider>

      <GenericDialog maxWidth={"sm"} open={changePassword} onClose={handleCloseModal}>
        <PasswordForm handleCloseModal={handleCloseModal} />
      </GenericDialog>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  updateProfile: (data) => dispatch(updateProfileAction(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
