import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useFormContext } from "react-hook-form";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/icons components
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
//import custom components
import FormInput from "components/FormFields/FormInput";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function CandidateWithdrawObservations(props) {
  const classes = useStylesGeneric();
  const methods = useFormContext();

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (props.opportunitiesState?.candidateInfo) {
      methods.setValue(
        "observations",
        props.opportunitiesState?.candidateInfo.opportunityCandidateHistory?.observations
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.opportunitiesState?.candidateInfo]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Container maxWidth={false}>
        <Card className={classes.cardRoot}>
          <CardHeader
            classes={{ title: classes.cardHeader, action: classes.cardHeaderAction }}
            title={"Motivo de desistência"}
            action={
              <IconButton
                className={clsx(classes.cardExpand, {
                  [classes.cardExpandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show-more"
              >
                <ExpandMoreIcon />
              </IconButton>
            }
          />
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <FormInput
                    name="observations"
                    label="Comentários"
                    disabled={true}
                    multiline={true}
                    rows={4}
                    control={methods.control}
                    errors={methods.formState.errors}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Collapse>
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CandidateWithdrawObservations);
