import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import { connect } from "react-redux";
import moment from "moment";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic";
import UserSingularGeneralDataSection from "../Sections/UserSingularGeneralDataSection";
import UserColectiveGeneralDataSection from "../Sections/UserColectiveGeneralDataSection";
import ProgramSubprogramDataSection from "../Sections/ProgramSubprogramDataSection";
import ComplementaryDataSection from "../Sections/ComplementaryDataSection";
import DocumentsSection from "../Sections/DocumentsSection";
import GenericFormSelect from "components/FormFields/GenericFormSelect";
import { labels } from "resources/resources";
//redux actions
import { getNextApplicationStatesAction } from "redux/actions/datasetsAction";
import {
  updateApplicationDetailGeneralDataAction,
  getApplicationDetailsGeneralDataActions,
} from "redux/actions/applicationsAction";
import { showGenericTwoButtonDialogAction } from "redux/actions/dialogsAction";
import { getReportAction } from "redux/actions/reportsAction";
import { DATASET, APPLICATION_STATE_KEY, REPORT_FILE_NAME } from "utils/consts";
import { updateGeneralDataMap, applicationExportReportMap } from "../../../../mappers/applicationsDetailsMap";
import { getSelectOptionsElementByCode } from "utils/formSelect";
import RejectDialog from "views/admin/Applications/Dialogs/RejectDialog";
import GenericButton from "components/Buttons/GenericButton";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function GeneralDataTab(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const history = useHistory();

  const methods = useForm({ mode: "onChange" });

  const [applicationData, setApplicationData] = useState();
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [nextApplicationState, setNextApplicationState] = useState(0);

  const handleOpenRejectModal = () => {
    setShowRejectModal(true);
  };

  const handleCloseRejectModal = () => {
    setShowRejectModal(false);
  };

  const onSubmit = (data) => {
    data["applicationNumber"] = props.applicationNumber;
    data["identityCardOrTaxIdNum"] = props.generalData.identityCardOrTaxIdNum;
    if (data.applicationStateId?.code === APPLICATION_STATE_KEY.REJECTED) {
      //rejeitada
      setApplicationData(data);
      handleOpenRejectModal();
    } else {
      props.showDialog(
        "Pretende continuar?",
        "Irá guardar as alterações realizadas e alterar o Estado de Candidatura.",
        function () {
          return props.updateApplicationDetails(updateGeneralDataMap(data, props.generalData?.documents), () => {
            props.getApplicationDetailsGeneralData(props.applicationNumber);
          });
        }
      );
    }
  };

  useEffect(() => {
    props.getNextApplicationStates(DATASET.NEXT_APPLICATION_STATES, {
      applicationStateId: props.generalData !== undefined ? props.generalData?.applicationStateId : null,
      benefitId: props.generalData !== undefined ? props.generalData?.benefitId : null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.generalData?.applicationStateId]);

  useEffect(() => {
    methods.setValue(
      "applicationStateId",
      getSelectOptionsElementByCode(
        props.dataSetState[DATASET.NEXT_APPLICATION_STATES],
        props.generalData !== undefined ? props.generalData?.applicationStateId : ""
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.NEXT_APPLICATION_STATES]]);

  return (
    <>
      <Container maxWidth={false}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {props.generalData?.userSingularGeneralDataSection != null && (
              <UserSingularGeneralDataSection generalData={props.generalData.userSingularGeneralDataSection} setValue={methods.setValue} />
            )}

            {props.generalData?.userColectiveGeneralDataSection != null && (
              <UserColectiveGeneralDataSection generalData={props.generalData.userColectiveGeneralDataSection} setValue={methods.setValue} />
            )}

            <ProgramSubprogramDataSection
              generalData={props.generalData}
              errors={methods.formState.errors}
              getValues={methods.getValues}
              setValue={methods.setValue}
              trigger={methods.trigger}
              nextApplicationState={nextApplicationState}
            />

            <ComplementaryDataSection
              generalData={props.generalData}
              errors={methods.formState.errors}
              getValues={methods.getValues}
              setValue={methods.setValue}
              subprogramId={props.generalData?.subprogramId}
              complementaryDataAnswers={props.generalData?.complementaryData}
              unregister={methods.unregister}
              applicationNumber={props.applicationNumber}
            />

            <DocumentsSection generalData={props.generalData} />

            <Grid container>
              <Grid item xs={12} sm={6} lg={3} xl={2}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_ApplicationDetail_ApplicationStateField)}
                  </FormLabel>
                  <GenericFormSelect
                    control={methods.control}
                    fieldName="applicationStateId"
                    selectOptions={
                      props.dataSetState[DATASET.NEXT_APPLICATION_STATES] !== undefined
                        ? props.dataSetState[DATASET.NEXT_APPLICATION_STATES]
                        : []
                    }
                    getValues={methods.getValues}
                    required={true}
                    onOptionChange={(option) => setNextApplicationState(option?.code)}
                    errors={methods.formState.errors}
                    classes={classes}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={1} xl={4}></Grid>
              <Grid item xs={12} sm={6} lg={4} xl={3}>
                <FormGroup>
                  <GenericButton
                    typeSubmit={false}
                    color="secondary"
                    disabled={props.reportsState?.get_report_loading}
                    loading={props.reportsState?.get_report_loading}
                    style={{ marginTop: "22px" }}
                    onClick={() => {
                      props.getReport(
                        applicationExportReportMap(props.applicationNumber),
                        REPORT_FILE_NAME.APPLICATION_DETAILS +
                          moment().format("YYYYMMDDHHmm") +
                          REPORT_FILE_NAME.PDF_EXTENSION
                      );
                    }}
                  >
                    Exportar Relatório
                  </GenericButton>
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={3}>
                <FormGroup>
                  <GenericButton
                    typeSubmit={true}
                    color="primary"
                    disabled={props.applicationsState?.application_update_loading}
                    loading={props.applicationsState?.application_update_loading}
                    style={{ marginTop: "22px" }}
                  >
                    {intl.formatMessage(labels.Label_Generic_Save)}
                  </GenericButton>
                </FormGroup>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Container>

      {/* RejectDialog */}
      <RejectDialog
        maxWidth={"sm"}
        open={showRejectModal}
        onClose={handleCloseRejectModal}
        history={history}
        data={applicationData}
      />
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getNextApplicationStates: (name, data) => dispatch(getNextApplicationStatesAction(name, data)),
  updateApplicationDetails: (data, onCloseMethod) =>
    dispatch(updateApplicationDetailGeneralDataAction(data, onCloseMethod)),
  showDialog: (message, yesAction, noAction) =>
    dispatch(showGenericTwoButtonDialogAction(message, yesAction, noAction)),
  getApplicationDetailsGeneralData: (applicationNum) =>
    dispatch(getApplicationDetailsGeneralDataActions(applicationNum)),
  getReport: (data, reportFileName) => dispatch(getReportAction(data, reportFileName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralDataTab);
