import axios from "axios";
import { INTERNAL_API_URL } from "../index";

export const UPDATE_LOAN_URL = "Loans/update";
export const GET_FILTERED_LOANS_URL = "Loans/get-filtered-loans";
export const GET_LOANS_BY_ID_URL = "Loans/get-loan-details";
export const REJECT_LOAN_URL = "Loans/Reject";

export function updateLoan(data) {
    return axios.post(INTERNAL_API_URL + UPDATE_LOAN_URL, data);
}

export function getFilteredLoans(data) {
    return axios.post(INTERNAL_API_URL + GET_FILTERED_LOANS_URL, data);
}

export function getLoan(loanNumber) {
    return axios.get(INTERNAL_API_URL + GET_LOANS_BY_ID_URL, {
        params: {
            id: loanNumber,
        },
    });
}

export function rejectLoan(loan) {
    return axios.post(INTERNAL_API_URL + REJECT_LOAN_URL, loan);
}
