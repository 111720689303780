import {
  createNotification,
  getNotifications,
  updateNotification,
  getUnreadNotifications,
  getNumberOfUnreadNotifications,
} from "crud/notifications.crud";
import { NOTIFICATION, DIALOG } from "utils/actionsConsts";

export const createNotificationAction = (formData, successFunction) => async (dispatch) => {
  dispatch({
    type: NOTIFICATION.CREATE_LOADING,
    payload: true,
  });
  createNotification(formData)
    .then((res) => {
      dispatch({
        type: NOTIFICATION.CREATE,
        payload: res.data,
      });

      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            goBack: false,
            isSuccess: res.data,
            message: res.data ? "Notificação criada com sucesso" : "Ocorreu um erro a criar a notificação",
            okAction: res.data ? successFunction : null,
          },
        },
      });
      dispatch({
        type: NOTIFICATION.CREATE_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: NOTIFICATION.CREATE_LOADING,
        payload: false,
      });
      dispatch({
        type: NOTIFICATION.CREATE,
        payload: false,
      });

      console.log("error", error);
    });
};

export const getNotificationsAction = (formData) => async (dispatch) => {
  dispatch({
    type: NOTIFICATION.GET_NOTIFICATIONS_LOADING,
    payload: true,
  });
  getNotifications(formData)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          id: record.id,
          isRead: record.isRead,
          message: record.message,
          date: record.notificationDate,
          sentBy: record.sentByUserName,
          origin: record.origin,
          title: record.subject,
          subtitle: record.motive,
        };
      });
      dispatch({
        type: NOTIFICATION.GET_NOTIFICATIONS,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: NOTIFICATION.GET_NOTIFICATIONS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: NOTIFICATION.GET_NOTIFICATIONS,
        payload: [],
      });
      dispatch({
        type: NOTIFICATION.GET_NOTIFICATIONS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getUnreadNotificationAction = () => async (dispatch) => {
  dispatch({
    type: NOTIFICATION.GET_UNREAD_NOTIFICATIONS_LOADING,
    payload: true,
  });
  getUnreadNotifications(null, null, true, 5)
    .then((res) => {
      console.log(res);
      const mappedList = [];
      res.data.result.forEach((child) => {
        mappedList.push({
          id: child.id,
          isRead: child.isRead,
          message: child.message,
          date: child.notificationDate,
          sentBy: child.sentByUserName,
          origin: child.origin,
          title: child.subject,
          subtitle: child.motive,
        });
      });
      dispatch({
        type: NOTIFICATION.GET_UNREAD_NOTIFICATIONS,
        result: true,
        payload: { notifications: mappedList, count: res.data.count },
      });
      dispatch({
        type: NOTIFICATION.GET_UNREAD_NOTIFICATIONS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: NOTIFICATION.GET_UNREAD_NOTIFICATIONS,
        payload: [],
      });
      dispatch({
        type: NOTIFICATION.GET_UNREAD_NOTIFICATIONS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const updateNotificationAction = (notificationId) => async (dispatch) => {
  updateNotification(notificationId)
    .then(() => {
      dispatch({
        type: NOTIFICATION.UPDATE_NOTIFICATION,
        payload: { id: notificationId },
      });
    })
    .catch((error) => {
      dispatch({
        type: NOTIFICATION.UPDATE_NOTIFICATION,
        payload: { id: null },
      });
      console.log("error", error);
    });
};

export const getNumberOfUnreadNotificationsAction = () => async (dispatch) => {
  getNumberOfUnreadNotifications()
    .then((res) => {
      dispatch({
        type: NOTIFICATION.GET_TOTAL_UNREAD_NOTIFICATIONS,
        payload: res.data.unreadCount,
      });
    })
    .catch((error) => {
      dispatch({
        type: NOTIFICATION.GET_TOTAL_UNREAD_NOTIFICATIONS,
        payload: 0,
      });
      console.log("error", error);
    });
};

export default createNotificationAction;
