export function updateProfileMap(formData, file) {
    if (formData !== null && formData !== undefined) {
        return {
            name: formData.name,
            surname: formData.surname,
            photo: file
        };
    }
}

export default updateProfileMap;

