import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import routes from "routes.js";

const Auth = (props) => {
  const mainContent = React.useRef(null);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  if (props.authState.loggedIn !== undefined && props.authState.loggedIn && !props.authState.mustConfirmEmail) {
    return <Redirect to="/admin/dashboard" />;
  } else {
    return (
      <>
        <div className="main-content" ref={mainContent}>
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/auth/login" />
          </Switch>
        </div>
      </>
    );
  }
};

// export default Auth;
const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps, {})(Auth);
