export const DATASET = {
  DATASET_TYPES: "ReferenceDataSets",

  APPLICATION_REJECT_MOTIVES: "ApplicationsRejectMotives",
  APPLICATION_NOTIFICATION_MOTIVES: "NotificationMotives",
  APPLICATION_NOTIFICATION_RECIPIENT_TYPES: "NotificationRecipientTypes",
  APPLICATION_NOTIFICATION_SUBJECTS: "NotificationSubjects",
  APPLICATION_SCHEDULING_MOTIVES: "SchedulingMotives",
  APPLICATION_STATES: "ApplicationStates",
  NEXT_APPLICATION_STATES: "NextApplicationStates",

  HISTORY_ENTRY_TYPES: "HistoryEntryTypes",

  ECONOMIC_ACTIVITIES_CLASSIFICATION: "EconomicActivitiesClassification",

  BANK: "Bank",
  LOAN_TYPE: "LoanType",
  LOAN_STATES: "LoanStates",
  LOAN_REJECT_MOTIVES: "LoansRejectMotives",

  MUNICIPALITIES: "Municipalities",
  NATIONALITIES: "Nationalities",
  PROVINCES: "Provinces",

  ORIGIN: "Origin",

  BUSINESS_PURPOSE: "BusinessPurpose",
  ACADEMIC_DEGREE: "AcademicDegree",
  ACADEMIC_LEVEL: "AcademicLevel",
  GENDER: "Gender",
  MARITAL_STATUS: "MaritalStatus",
  SPECIAL_NEEDS: "SpecialNeeds",
  SPECIALTY: "Specialty",
  TECHNICAL_CAPABIBILITY: "TechnicalCapability",  // not used
  PROFESSIONAL_TRAINNING: "ProfessionalTraining",
  ENTREPRENEURSHIP_TRAINNING: "EntrepreneurshipTraining",
  SMALL_BUSINESS_MGMT_TRAINNING: "SmallBusinessMgmtTraining",
  PROFESSIONAL_CATEGORY: "ProfessionalCategory",

  DEPARTMENTS: "Departments",
  WAREHOUSES: "Warehouses",
  CATEGORIES: "Categories",
  ACTIVE_CATEGORIES: "ActiveCategories",
  INACTIVE_CATEGORIES: "InactiveCategories",
  NOT_ASSIGNED_ACTIVE_CATEGORIES: "NotAssignedActiveCategories",
  LOSS_BREAK_MOTIVES: "ProfessionalKitBreakLossMotives",

  GOVERNMENT_PROGRAMS: "GovernmentProgram",
  PROGRAMS: "Programs",
  SUBPROGRAMS: "Subprograms",
  SUBPROGRAM_STATES: "SubprogramStates",
  BENEFITS: "Benefits",
  DOCUMENTS: "Documents",
  CONDITION_TYPES: "ConditionTypes",
  PREDEFINED_QUESTIONS: "PredefinedQuestions",

  BACKOFFICE_USERS: "getBackOfficeUsers",
  BACKOFFICE_ROLES: "getBackOfficeRoles",

  YES_OR_NO_DROPDOWN: [
    { code: true, label: "Sim" },
    { code: false, label: "Não" },
  ],

  SHAPE_PROGRAMS: "getShapePrograms",
  SHAPE_SUBSITES: "getShapeSubsites",

  INFORMATION_DISSEMINATION_TYPE: "InformationDisseminationType",
  INFORMATION_DISSEMINATION_ACCESS: "Access",

  OPPORTUNITY_TYPE: "OpportunityType",
  CONTRACT_TYPE: "ContractType",
  INTERNSHIP_TYPE: "InternshipType",
  TIMETABLE_TYPE: "TimetableType",
  OPPORTUNITY_STATE: "OpportunityState",
  OPPORTUNITY_STATE_MOTIVES: "OpportunityStateMotives",
  AVAILABLE_INTERNSHIP_OPPORTUNITIES: "AvailableInternshipOpportunities",
  OPPORTUNITY_CANDIDATE_REJECT_MOTIVES: "OpportunityCandidateRejectionMotives",
  OPPORTUNITY_DOCUMENT_TYPE: "OpportunityDocumentType",
  OPPORTUNITY_CANDIDATE_STATE: "OpportunityCandidateState",
  OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE: "OpportunityCandidateFollowUpState",
  FEEDBACK_REPORT_REJECT_MOTIVES: "FeedbackReportRejectionMotives",
  EMPLOYMENT_CENTERS: "EmploymentCenter",

  SOCIAL_MEDIA_TYPE: "SocialMediaType",
  LANGUAGES: "Languages",
  OCCUPATIONAL_CATEGORIES: "OccupationalCategories",

  INTERVENTION_OTHER_AREA: "InterventionOtherArea",
};

export const OPERATIONS = {
  APPLICATIONS_MENU: "applicationsManagmentMenu",
  APPLICATIONS_LIST_ITEM: "applicationsManagmentItem",

  SCHEDULES_ITEM: "schedulesManagmentItem",

  PROFESSIONAL_KITS_MENU: "professionalKitsMenu",
  PROFESSIONAL_KITS_LIST_ITEM: "professionalKitsListItem",
  PROFESSIONAL_KITS_CHARACTERIZE_KIT_ITEM: "characterizeKitItem",
  PROFESSIONAL_KITS_REGISTER_LOSS_KIT_ITEM: "registerLossKitItem",
  PROFESSIONAL_KITS_REGISTER_IN_WAREHOUSE_ITEM: "registerInWarehouseItem",
  PROFESSIONAL_KITS_STOCKS_ITEM: "stocksItem",
  PROFESSIONAL_KITS_DELIVERY_ITEM: "kitDeliveryItem",

  SUBPROGRAMS_MENU: "subprogramsMenu",
  SUBPROGRAMS_CHARACTERIZE_SUBPROGRAM_ITEM: "characterizeSubprogramItem",
  SUBPROGRAMS_LIST_ITEM: "subprogramsListItem",

  OPPORTUNITIES_MENU: "OpportunitiesMenu",
  OPPORTUNITIES_CREATE_ITEM: "CreateOpportunityItem",
  OPPORTUNITIES_CANDIDATES_LIST_ITEM: "CandidatesListItem",
  OPPORTUNITIES_APPLICATIONS_LIST_ITEM: "BOUserOpportunityApplicationsListItem",
  OPPORTUNITIES_APPLICATIONS_FOLLOWUP_LIST_ITEM: "BOUserOpportunityApplicationsFollowUpListItem",
  OPPORTUNITIES_APPLICATIONS_FOLLOWUP_UPDATE_REPORT_FINANCIAL_INFO: "BO_UpdateInternshipFinancialInformation",

  BANKS_MENU: "BanksMenu",

  USER_MANAGEMENT_MENU: "userManagementMenu",
  USER_MANAGEMENT_BACKOFFICE_ITEM: "backofficeUsersItem",
  USER_MANAGEMENT_FRONTOFFICE_ITEM: "frontofficeUsersItem",

  INFORMATION_MENU: "InformationDisseminationMenu",
  INFORMATION_CREATE_ITEM: "createInformationItem",
  INFORMATION_LIST_ITEM: "informationListItem",

  DASHBOARD_ONGOING_APPLICATIONS: "onGoingApplicationsDashboard",
  DASHBOARD_STOCKS: "stocksDashboard",
  DASHBOARD_NEW_COMPANIES: "validateNewCompaniesDashboard",
  DASHBOARD_OPPORTUNITIES: "opportunitiesDashboard",
  DASHBOARD_NOTIFICATIONS: "notificationsDashboard",

  EXPORT_REPORTS: "ExportReports",
};

export const UNITS = {
  MONETARY: "Kz",
  MONTHS: "Meses",
  INTEREST: "%",
};

export const MONETARY_DECIMAL_SEPARATOR = {
  WEB: ",",
  API: ".",
};
export const MONETARYUNITPLACEHOLDER = "00" + MONETARY_DECIMAL_SEPARATOR.WEB + "00";

export const REGEX_PATTERNS = {
  MONETARY: /^(\d+(?:[,]\d{1,2})?|)$/,
  EMAIL: /\S+@\S+\.\S+/,
  DATE: /^[0-9/]{0,10}$/
};

export const COMPLEMENTARY_DATA_TYPE = {
  DATASET: 1,
  PLAIN_TEXT: 2,
  BOOLEAN: 3,
  DEFAULT_QUESTIONS: 4,
};

//SHAPE
export const SHAPE_PROGRAMS_TARGET = {
  AVANCO: 1,
  CAPACITA: 2,
  PROFESSIONAL_WALLET: 3,
};

export const SCHEDULE_STATES = {
  SIM: "Sim",
  NAO: "Não",
};

export const SUBPROGRAM_STATES = {
  CRIADO: 1,
  APROVADO: 2,
  CONCLUIDO: 3,
  PUBLICADO: 4,
};

export const PAGINATION = {
  PAGE_SIZE: 15,
  PAGE_SIZE_MODAL_RESULT_TABLE: 10,
  PAGE_SIZE_DISSEMINATION: 12,
  PAGE_SIZE_DASHBOARD_NOTIFICATIONS: 6,
  FIRST_PAGE: 1,
};

export const USER_TYPE = {
  INDIVIDUAL: 1,
  COLETIVO: 2,
};

export const USER_TYPE_KEY = {
  INDIVIDUAL: "Singular",
  COLETIVO: "Colective",
};

export const BENEFIT = {
  PROFESSIONAL_KIT: "ProfessionalKit",
  MICROCREDIT: "Microcredit",
  PROFESSIONAL_WALLET: "ProfessionalWallet",
  CAPACITA: "Capacita",
  AVANCO: "Avanco",
  PROFESSIONAL_INTERNSHIP: "ProfessionalInternship",
  PROFESSIONAL_KIT_MICROCREDIT: "ProfessionalKitMicrocredit",
};

export const NOTIFICATION_RECIPIENT_TYPE = {
  CANDIDATE: "notificationCandidate",
  BO_USER: "notificationBackOfficeUser",
  BO_GROUP: "notificationBackOfficeGroup",
};

export const ROLES_ORIGIN_APPLICATION = {
  BACKOFFICE: 1,
  FRONTOFFICE: 2,
};

export const OPPORTUNITY_STATE_KEY = {
  SUBMETIDA: "Submetida",
  REJEITADA: "Rejeitada",
  APROVADA: "Aprovada",
  FECHADA: "Fechada",
};

export const OPPORTUNITY_CANDIDATE_STATE_KEY = {
  EMRECRUTAMENTO: "EmRecrutamento",
  REJEITADA: "Rejeitada",
  APROVADA: "Aprovada",
  DESISTENCIA: "Desistencia",
};

export const OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY = {
  PORINICIAR: "PorIniciar",
  ADECORRER: "ADecorrer",
  TERMINADO: "Terminado",
};

export const OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY = {
  PORSUBMETER: "AwaitingSubmission",
  SUBMETIDO: "Submitted",
  VALIDADO: "Validated",
  REJEITADO: "Rejected",
  PAGO: "Paid",
};

export const OPPORTUNITY_TYPE_KEY = {
  EMPREGO: "Emprego",
  ESTAGIO_PROFISSIONAL: "EstagioProfissional",
};

export const OPPORTUNITY_TYPE_ID = {
  EMPREGO: 1,
  ESTAGIO_PROFISSIONAL: 2,
};

export const FEEDBACK_REPORT_QUESTION_TYPE_KEY = {
  TEXT: "Text",
  STAR_CLASSIFICATION: "StarClassification",
};

export const FEEDBACK_REPORT_TYPE_KEY = {
  OPPORTUNITY_FOLLOWUP_CANDIDATE: "OpportunityApplicationCandidateFollowUp",
  OPPORTUNITY_FOLLOWUP_COMPANY: "OpportunityApplicationCompanyFollowUp",
};

export const FEEDBACK_REPORT_USER_TYPE_KEY = {
  COMPANY: "Company",
  INTERN: "Intern",
};

export const INTERNSHIP_TYPE_KEY = {
  GERAL: "Geral",
  PAPE: "PAPE",
  RETFOP: "RETFOP",
};

export const UPDATE_OPPORTUNITY_STATE_ACTION_TYPE = {
  APPROVE: 1,
  CLOSE: 2,
  REJECT: 3,
};

export const OPPORTUNITY_MOTIVES_TYPE_KEY = {
  CLOSE: "OpportunityCloseMotives",
  REJECT: "OpportunityRejectMotives",
};

export const REGISTRATION_CONSTS = {
  DATE_TIME_FORMAT: "DD/MM/YYYY",
  DATE_TIME_PLACEHOLDER: "DD/MM/AAAA",
  MIN_BIRTHDATE: "31/12/1899",
  MAX_LENGTH_ID_CARD: 14,
  MIN_LENGTH_PHONE_NUMBER: 100000000,
  MAX_LENGTH_PHONE_NUMBER: 999999999,
  MIN_LENGTH_PERCENTAGE: 0,
  MAX_LENGTH_PERCENTAGE: 100,
};

export const SPECIAL_NEEDS_KEY = {
  NAO: "SpecialNeeds_01",
  INTELECTUAL: "SpecialNeeds_02",
  FISICA: "SpecialNeeds_03",
  SENSORIAL_VISUAL: "SpecialNeeds_04",
  SENSORIAL_AUDIBLE: "SpecialNeeds_05",
};

export const APPLICATION_STATE_GROUP_KEY = {
  SUBMITTED: 1,
  TRIAGE: 2,
  ANALYSIS: 3,
  CONCLUDED: 4,
};

export const APPLICATION_STATE_KEY = {
  AWAITING_BANK_INFORMATION: 10,
  REJECTED: 13,
};

export const ROLE_KEY_VALUE = {
  BANCOS: "Bancos",
  EMPLOYMENT_AND_ENTREPRENEURSHIP_TECHNICAL_TEAM: "EquipaTecnicaPelouroEmpregoEmpreendedorismo",
  EMPLOYMENT_AND_ENTREPRENEURSHIP_MANAGEMENT_TEAM: "EquipaGestaoPelouroEmpregoEmpreendedorismo",
};

export const FORM_FIELDS_PREFIX = {
  DOCUMENT: "doc_",
  COMPLEMENTARY_DATA: "cd_",
  FOLLOWUP_FEEDBACK_REPORT_DATA: "ffd_",
};

export const COMPLEMENTARY_DATA_ANSWER_TYPE = {
  DATASET: 1,
  TEXT: 2,
  BOOLEAN: 3,
};

export const REPORT_TYPES = {
  APPLICATION_DETAILS: "ApplicationDetails",
  APPLICATIONS_SEARCH_RESULTS: "ApplicationsSearchResults",
  LOANS_SEARCH_RESULTS: "LoansSearchResults",
  SCHEDULES_SEARCH_RESULTS: "SchedulesSearchResults",
  STOCKS_SEARCH_RESULTS: "StocksSearchResults",
  OPPORTUNITIES_SEARCH_RESULTS: "OpportunitiesSearchResults",
  OPPORTUNITY_APPLICATIONS_SEARCH_RESULTS: "OpportunityApplicationsSearchResults",
  CURRICULUM: "UserSingularCurriculum",
};

export const REPORT_FILE_NAME = {
  APPLICATION_DETAILS: "Detalhe_da_Candidatura_",
  APPLICATIONS_SEARCH_RESULTS: "Lista_de_Candidaturas_",
  LOANS_SEARCH_RESULTS: "Lista_de_Bancos_",
  SCHEDULES_SEARCH_RESULTS: "Lista_de_Agendamentos_",
  STOCKS_SEARCH_RESULTS: "Lista_de_Stocks_",
  OPPORTUNITIES_SEARCH_RESULTS: "Lista_de_Ofertas_",
  OPPORTUNITY_APPLICATIONS_SEARCH_RESULTS: "Lista_de_Candidaturas_a_Ofertas_",

  PDF_EXTENSION: ".pdf",
  EXCEL_EXTENSION: ".xlsx",
};

export const REPORT_CONSTS = {
  MAX_RESULTS: 1000,
};

export const TABLE_STATE_CHIP_COLOR = {
  GREEN: 1,
  RED: 2,
  YELLOW: 3,
  DARK_ORANGE: 4,
  LIGHT_ORANGE: 5,
  BLUE: 6,
};

export const APPLICATION_STATES_KEY = {
  SUBMETIDA: "1",
  AGUARDA_INFORMACAO: "2",
  AGUARDA_FORMACAO_EM_FALTA: "3",
  AGUARDA_ACEITACAO_FORMACAO: "4",
  AGUARDA_FORMACAO: "5",
  EM_ANALISE: "6",
  AGUARDA_VISITA: "7",
  AGUARDA_TESTE: "8",
  SUSPENSA: "9",
  AGUARDA_INFORMACAO_BANCO: "10",
  AGUARDA_AGENDAMENTO_ENTREGA_KIT: "11",
  ENTREGA_KIT_AGENDADA: "12",
  REJEITADA: "13",
  ACEITE: "14",
  CANCELADA: "15",
  AGUARDA_DECISAO: "16",
  AGUARDA_RESPOSTA_DA_EMPRESA: "17",
};

export const APPLICATION_STATES_IN_PROGRESS = [
  APPLICATION_STATES_KEY.SUBMETIDA,
  APPLICATION_STATES_KEY.AGUARDA_INFORMACAO,
  APPLICATION_STATES_KEY.AGUARDA_FORMACAO_EM_FALTA,
  APPLICATION_STATES_KEY.AGUARDA_ACEITACAO_FORMACAO,
  APPLICATION_STATES_KEY.AGUARDA_FORMACAO,
  APPLICATION_STATES_KEY.EM_ANALISE,
  APPLICATION_STATES_KEY.AGUARDA_VISITA,
  APPLICATION_STATES_KEY.AGUARDA_TESTE,
  APPLICATION_STATES_KEY.SUSPENSA,
  APPLICATION_STATES_KEY.AGUARDA_INFORMACAO_BANCO,
  APPLICATION_STATES_KEY.AGUARDA_AGENDAMENTO_ENTREGA_KIT,
  APPLICATION_STATES_KEY.ENTREGA_KIT_AGENDADA,
  APPLICATION_STATES_KEY.AGUARDA_DECISAO,
  APPLICATION_STATES_KEY.AGUARDA_RESPOSTA_DA_EMPRESA,
];

export const APPLICATION_STATES_CANCELED = [APPLICATION_STATES_KEY.REJEITADA, APPLICATION_STATES_KEY.CANCELADA];

export const APPLICATION_STATES_APPROVED = [APPLICATION_STATES_KEY.ACEITE];

export const FOLLOWUP_STATE_ID_QUERY = {
  NOTSTARTED: "1",
  ONGOING: "2",
  FINISHED: "3",
};

export const HISTORY_ENTRY_TYPES = {
  COMENTARIO: "Comment",
  GERACAO_FORMULARIO_EXTRA: "FeedbackReportAdHocCreated",
  EMPREGO_APROVADO: "FoUserEmploymentApplicationApproved",
  PROGRAMA_GOVERNO_APROVADO: "FoUserGovernmentProgramApplicationAccepted",
  ESTAGIO_APROVADO: "FoUserInternshipApplicationApproved",
  ESTAGIO_INICIO: "FOUserInternshipBegan",
  ESTAGIO_FIM: "FOUserInternshipEnded",
  INSCRICAO: "FOUserRegister",
  ENTREGA_KIT_CONCLUIDA: "KitDeliveryCompleted",
  EMPRESTIMO_APROVADO: "LoanApproved",
  EMPRESTIMO_REJEITADO: "LoanRejected",
  NOTIFICACAO: "Notification",
  CANDIDATURA_ESTAGIO_APROVADA: "OpportunityFollowUpCandidateApproved",
  FORMULARIO_ENTREGUE_CANDIDATO: "OpportunityFollowUpCandidateFeedbackReportDelivered",
  FORMULARIO_ENTREGUE_EMPRESA: "OpportunityFollowUpCompanyFeedbackReportDelivered",
  CANDIDATURA_ESTAGIO_INICIO: "OpportunityFollowUpInternshipBegan",
  CANDIDATURA_ESTAGIO_FIM: "OpportunityFollowUpInternshipEnded",
  PERIODO_ESTAGIO_PAGO: "OpportunityFollowUpInternshipPeriodPayment",
  PERIODO_ESTAGIO_ATUALIZADO: "OpportunityFollowUpInternshipPeriodUpdated",
  AGENDAMENTO: "Scheduling",
  ALTERACAO_ESTADO: "StateChange",
};

export const INFORMATION_DISSEMINATION_TYPE_KEYVALUE = {
  INFORMATION: "information",
  LEGISLATION: "legislation",
  SCHEDULE: "schedule",
  STRUCTURING_PROJECT: "structuringProjects",
};

export const LOAN_STATES = {
  EM_APROVACAO: 1,
  REEMBOLSADO: 2,
  REJEITADO: 3,
  APROVADO: 4,
};

export const IMAGE_TYPE = {
  URL: "url",
  BASE64: "base64",
};
