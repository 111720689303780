import { Box, Container, FormGroup, FormHelperText, Grid, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useForm } from "react-hook-form";
import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FormSelect from "components/FormFields/FormSelect";
import GenericButton from "components/Buttons/GenericButton";
import FormDatePicker from "components/FormFields/FormDatePicker";
import FormInput from "components/FormFields/FormInput";
import {
  createFoUSInterventionOtherAreaAction,
  updateFoUSInterventionOtherAreaAction,
} from "redux/actions/usersAction";
import getDataSetByNameAction from "redux/actions/datasetsAction";
import { DATASET } from "utils/consts";
import formatDate from "utils/date_utils";
import { getDatasetDataByCode, getDatasetStateByName } from "utils/datasets";
import { labels, errorMessages } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

export default function AddInterventionOtherAreaModal({ data, editing = false, onCloseModal, onSuccess }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { userId } = useParams();
  const state = useSelector((state) => state);
  const classes = useStylesGeneric();
  const theme = useTheme();

  useEffect(() => {
    if (getDatasetStateByName(state, DATASET.INTERVENTION_OTHER_AREA).length === 0)
      dispatch(getDataSetByNameAction(DATASET.INTERVENTION_OTHER_AREA));
  }, [dispatch, state]);

  useEffect(() => {
    if (getDatasetStateByName(state, DATASET.INTERVENTION_OTHER_AREA).length !== 0)
      setValue(
        "interventionOtherArea",
        getDatasetDataByCode(state.dataSetState[DATASET.INTERVENTION_OTHER_AREA], data.interventionOtherAreaId)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dataSetState[DATASET.INTERVENTION_OTHER_AREA]]);

  const defaultValues = useMemo(() => {
    const { dataSetState } = state;

    if (editing) {
      return {
        ...data,
        date: moment(data.date),
        interventionOtherArea: getDatasetDataByCode(
          dataSetState[DATASET.INTERVENTION_OTHER_AREA],
          data.interventionOtherAreaId
        ),
      };
    }

    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, editing]);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    mode: "onChange",
  });

  const title = useMemo(() => {
    const titleSufix = intl.formatMessage(labels.Label_InterventionOtherArea);

    let formatedTitle = "";

    if (editing) {
      formatedTitle = `${intl.formatMessage(labels.Label_Generic_Edit)} ${titleSufix}`;
    } else {
      formatedTitle = `${intl.formatMessage(labels.Label_Generic_Add)} ${titleSufix}`;
    }

    return formatedTitle;
  }, [editing, intl]);

  async function onSubmit({ interventionOtherArea, date, reasonForIntervention, results, id }) {
    const dataToSubmit = {
      results,
      reasonForIntervention,
      FO_UserSingularId: userId,
      interventionOtherAreaId: interventionOtherArea.code,
      date: formatDate(date),
    };

    if (!editing) {
      await dispatch(
        createFoUSInterventionOtherAreaAction(dataToSubmit, () => {
          onCloseModal();
          onSuccess();
        })
      );
    } else {
      await dispatch(
        updateFoUSInterventionOtherAreaAction(
          {
            id,
            ...dataToSubmit,
          },
          () => {
            onCloseModal();
            onSuccess();
          }
        )
      );
    }
  }

  return (
    <Container>
      <Box>
        <h2 style={{ textAlign: "center" }}>{title}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container style={{ marginTop: "40px", marginBottom: "30px" }}>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <FormSelect
                  fieldName="interventionOtherArea"
                  control={control}
                  initialLabel={intl.formatMessage(labels.Label_InterventionOtherArea_Area)}
                  selectOptions={getDatasetStateByName(state, DATASET.INTERVENTION_OTHER_AREA)}
                  getValues={getValues}
                  setValue={setValue}
                  classes={classes}
                  required
                  requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                  disabled={isSubmitting}
                />
                <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                  {errors?.interventionOtherArea?.message}
                </FormHelperText>
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormDatePicker
                name="date"
                label={intl.formatMessage(labels.Label_Date)}
                control={control}
                getValues={getValues}
                trigger={trigger}
                maxDateToday
                isRequired
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormInput
                name="reasonForIntervention"
                control={control}
                label={intl.formatMessage(labels.Label_InterventionOtherArea_ReasonForIntervention)}
                isRequired
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormInput
                name="results"
                control={control}
                label={intl.formatMessage(labels.Label_InterventionOtherArea_Results)}
                multiline
                rows={3}
                isRequired
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <GenericButton fullWidth typeSubmit={false} color="secondary" onClick={onCloseModal}>
                  {intl.formatMessage(labels.Label_Button_Cancel)}
                </GenericButton>
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <GenericButton typeSubmit color="primary" loading={isSubmitting}>
                  {intl.formatMessage(labels.Label_Button_Submit)}
                </GenericButton>
              </FormGroup>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
}
