import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { searchFOUsersAction } from "redux/actions/usersAction";
import SearchBar from "components/SearchBar/SearchBar"
import { labels } from "resources/resources";

function SearchBarNavbar(props) {
  const [searchResultList, setSearchResultList] = useState([])
  const intl = useIntl();

  const handleSearchFOUsers = (searchText) => {
    if (searchText){
      props.searchFOUsers({ searchText: searchText })
    }
  }

  useEffect(() => {
    setSearchResultList({
      ...searchResultList,
      options: props?.usersState?.searchFOUsers_result?.data?.usersList?.map((prop) => {
        return {
          text: '<div>'
                + (prop.identityCard ? intl.formatMessage(labels.Label_Admin_SearchBarNavbar_IdemtificationNumber) + ": <b>" + prop.identityCard + "</b> - " : "")
                + (prop.taxIdNumber ? intl.formatMessage(labels.Label_Admin_SearchBarNavbar_NIF) + ": <b>" + prop.taxIdNumber + "</b> - " : "")
                + (prop.mobilePhone ? intl.formatMessage(labels.Label_Admin_SearchBarNavbar_PhoneNumber) + ": <b>" + prop.mobilePhone + "</b> - " : "") 
                + prop.name
                + '</div>',
          searchResultPath: "/admin/user-overview/" + prop?.id,
        };
      }),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.usersState?.searchFOUsers_result?.data?.usersList]);

  return (
    <div> 
        <SearchBar
          handleSearch={handleSearchFOUsers}
          loadingSearch={props?.usersState?.searchFOUsers_loading}
          searchResultList={searchResultList}
          placeholder={intl.formatMessage(labels.Label_Admin_SearchBarNavbar_SearchBarPlaceholder)}
          advancedSearchPath={"/admin/advanced-search"}
        /> 
    </div>    
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  searchFOUsers: (formData) => dispatch(searchFOUsersAction(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBarNavbar);
