import React from "react";
import "moment/locale/pt";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic";
import GenericButton from "components/Buttons/GenericButton";
import { labels } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

export default function SubmitedDocumentsSection(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();

  return (
    <>
      {props.files?.map((file, key) => (
        <Box key={key}>
          <Box className={classes.detailLabels}>{file.documentType}</Box>

          <Box className={classes.documentNameRoot}>{file.name}</Box>
          <Box>
            <GenericButton
              typeSubmit={false}
              key={key}
              onClick={() => {
                props.getDocument(file.documentId, file.documentName);
              }}
              color="tertiary"
            >
              {intl.formatMessage(labels.Label_Download)}
            </GenericButton>
          </Box>
        </Box>
      ))}
    </>
  );
}
