import {
  createSubprogram,
  getFilteredSubprograms,
  getSubprogramDetails,
  updateSubprogram,
  updateSubprogramState,
} from "crud/subprograms.crud";
import { SUBPROGRAM, DIALOG } from "utils/actionsConsts";
import moment from "moment";
const convertIntervalToString = (dateFrom, dateTo) => {
  return `De ${dateFrom} Até ${dateTo}`;
};
export const createSubprogramAction = (formData) => async (dispatch) => {
  dispatch({
    type: SUBPROGRAM.CREATE_SUBPROGRAM_LOADING,
    payload: true,
  });
  createSubprogram(formData)
    .then((res) => {
      dispatch({
        type: SUBPROGRAM.CREATE,
        payload: res.data,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data.succeeded,
            message: res.data.succeeded
              ? "Subprograma caracterizado com sucesso"
              : "Ocorreu um erro a criar o subprograma",
            goBack: res.data.succeeded,
            onClose: "/admin/subprograms-management",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: SUBPROGRAM.CREATE,
        payload: false,
      });

      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
          },
        },
      });

      console.log("error", error);
    });
};

export const getFilteredSubprogramsActions = (formData) => async (dispatch) => {
  dispatch({
    type: SUBPROGRAM.GET_FILTERED_SUBPROGRAMS_LOADING,
    payload: true,
  });
  dispatch({
    type: SUBPROGRAM.REFRESH_RESULTS,
    payload: false,
  });
  getFilteredSubprograms(formData)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          governmentProgramName: record.governmentProgramName,
          programName: record.programName,
          subprogramId: record.id,
          subprogramName: record.subprogramName,
          benefitTypeName: record.benefitName,
          departmentName: record.department,
          subprogramStateName: record.status,
          subprogramStateKeyValue: record.statusKeyValue,
          vigencyDate: convertIntervalToString(
            moment(record.validFrom).format("DD/MM/YYYY"),
            moment(record.validTo).format("DD/MM/YYYY")
          ),
        };
      });
      dispatch({
        type: SUBPROGRAM.GET_FILTERED_SUBPROGRAMS,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: SUBPROGRAM.GET_FILTERED_SUBPROGRAMS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: SUBPROGRAM.GET_FILTERED_SUBPROGRAMS,
        result: false,
        payload: [],
      });
      dispatch({
        type: SUBPROGRAM.GET_FILTERED_SUBPROGRAMS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getSubprogramDetailsActions = (formData) => async (dispatch) => {
  dispatch({
    type: SUBPROGRAM.CLEAR_DETAIL,
  });
  dispatch({
    type: SUBPROGRAM.GET_SUBPROGRAM_DETAILS_LOADING,
    payload: true,
  });
  getSubprogramDetails(formData)
    .then((res) => {
      dispatch({
        type: SUBPROGRAM.GET_SUBPROGRAM_DETAILS,
        result: true,
        payload: {
          subprogramName: res.data.subprogramName,
          governmentProgramId: res.data.governmentProgramId,
          governmentProgramName: res.data.governmentProgramName, 
          programId: res.data.programId,
          programName: res.data.programName,
          stateKey: res.data.stateKey,
          validFrom: res.data.validFrom,
          validTo: res.data.validTo,
          description: res.data.description,
          objectives: res.data.objectives,
          numVacancies: res.data.numVacancies,
          departmentId: res.data.departmentId,
          benefitTypeId: res.data.benefitId,
          benefitKeyValue: res.data.benefitKeyValue,
          benefitName: res.data.benefitName,
          categoryList: res.data.categoriesList,
          numMaxKits: res.data.numMaxKits,
          minMicrocreditValue: res.data.microcreditMinValue,
          maxMicrocreditValue: res.data.microcreditMaxValue,
          interestRate: res.data.microcreditInterestRate,
          gracePeriod: res.data.microcreditGracePeriod,
          repaymentPeriod: res.data.microcreditRepaymentPeriod,

          documents: res.data.documentTypeList,
          complementaryData: res.data.complementaryDataList,

          deliveredProfessionalKits: res.data.deliveredProfessionalKitsList,
        },
      });
      dispatch({
        type: SUBPROGRAM.GET_SUBPROGRAM_DETAILS_LOADING,
        payload: false,
      });
      dispatch({
        type: SUBPROGRAM.REFRESH_RESULTS,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: SUBPROGRAM.GET_SUBPROGRAM_DETAILS,
        result: false,
        payload: [],
      });
      dispatch({
        type: SUBPROGRAM.GET_SUBPROGRAM_DETAILS_LOADING,
        payload: false,
      });
      dispatch({
        type: SUBPROGRAM.REFRESH_RESULTS,
        payload: false,
      });
      console.log("error", error);
    });
};

export const updateSubprogramAction = (formData) => async (dispatch) => {
  dispatch({
    type: SUBPROGRAM.UPDATE_SUBPROGRAM_LOADING,
    payload: true,
  });
  updateSubprogram(formData)
    .then((res) => {
      dispatch({
        type: SUBPROGRAM.REFRESH_RESULTS,
        payload: true,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data.succeeded,
            message: res.data.succeeded
              ? "Subprograma actualizado com sucesso"
              : "Ocorreu um erro a actualizar o subprograma",
          },
        },
      });
      dispatch({
        type: SUBPROGRAM.UPDATE_SUBPROGRAM_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: SUBPROGRAM.REFRESH_RESULTS,
        payload: false,
      });
      dispatch({
        type: SUBPROGRAM.UPDATE_SUBPROGRAM_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
          },
        },
      });
      console.log("error", error);
    });
};

export const updateSubprogramStateAction = (formData) => async (dispatch) => {
  dispatch({
    type: SUBPROGRAM.UPDATE_SUBPROGRAM_STATE_LOADING,
    payload: true,
  });
  updateSubprogramState(formData)
    .then((res) => {
      dispatch({
        type: SUBPROGRAM.REFRESH_RESULTS,
        payload: true,
      });

      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data !== null,
            goBack: true,
            onClose: "/admin/subprograms-management",
            message:
              res.data !== null
                ? "Estado do Subprograma actualizado com sucesso"
                : "Ocorreu um erro a actualizar o estado do subprograma",
          },
        },
      });
      dispatch({
        type: SUBPROGRAM.UPDATE_SUBPROGRAM_STATE_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: SUBPROGRAM.REFRESH_RESULTS,
        payload: true,
      });
      dispatch({
        type: SUBPROGRAM.UPDATE_SUBPROGRAM_STATE_LOADING,
        payload: false,
      });

      console.log("error", error);
    });
};

export default getFilteredSubprogramsActions;
