import React from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Avatar } from "@material-ui/core";
import { getNameInitials } from "utils/converters";

import componentStyles from "assets/theme/views/admin/user-profile.js";
const useStyles = makeStyles(componentStyles);

function UserPicture(props) {
  const classes = useStyles();
  const [file, setFile] = React.useState(props.file ? "data:image/*;base64, " + props.file : props.authState?.photo);
  const [errorMaxSize, setErrorMaxSize] = React.useState(false);
  const [imageTypeError, setImageTypeError] = React.useState(false);
  const [imageUploaded, setImageUploaded] = React.useState(props.file);
  
  const fileMaxSize = 5; // MB

  const getUserFullName = () => {
    return (
      (props.authState?.name ?? "") + " " + (props.authState?.lastName ?? "")
    );
  };

  function clearErrors(){
    setImageUploaded(false);
    setErrorMaxSize(false);
    setImageTypeError(false);
  }

  function handleImageChange(e) {
    e.preventDefault();
    clearErrors();

    let reader = new FileReader();
    let file = e.target.files[0];
    if (file && file.type !== "image/png" && file.type !== "image/jpeg") {
      setImageTypeError(true);
      return;
    }
    if (file && file.size > fileMaxSize * 1048576) {
      setErrorMaxSize(true);
      return;
    } else {
      setErrorMaxSize(false);
    }
    reader.onloadend = () => {
      setFile(reader.result);
      if (reader.result) props.setFile(reader.result.split(",")[1]);
      setImageUploaded(true);
    };
    if (file) reader.readAsDataURL(file);
  }

  return (
    <Box textAlign="center">
      <Box>
        {file ? (
          <Avatar
            alt="..."
            src={file}
            classes={{
              root: classes.avatar,
            }}
          />
        ) : (
          <Box
            component={Avatar}
            alt="..."
            classes={{ root: classes.nameInitialsAvatar }}
          >
            {" "}
            {getNameInitials(getUserFullName())}
          </Box>
        )}
      </Box>
      <Box marginTop="30px">
        <label htmlFor="fileUpload" className={classes.updatePhotoButton}>
          Alterar Imagem
        </label>
        <input
          id="fileUpload"
          type="file"
          hidden
          onChange={handleImageChange}
        />
      </Box>
      {errorMaxSize && (
        <Box marginTop="30px" color="red">
        A imagem não pode ter mais que {fileMaxSize} MB.
        </Box>
      )}
      {imageTypeError && (
        <Box marginTop="30px" color="red">
          A imagem só pode ter formato .jpeg ou .png.
        </Box>
      )}
      {imageUploaded && (
        <Box marginTop="30px" color="green">
          Imagem carregada. Para atualizar, guarde as alterações.
        </Box>
      )}
    </Box>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(UserPicture);
