import {
  createInformation,
  getFilteredInformation,
  getInformationDetails,
  updateInformationPublish,
  updateInformationHighlighted,
  updateInformation,
} from "crud/informationdissemination.crud";
import { INFORMATION_DISSEMINATION, DIALOG } from "utils/actionsConsts";
import moment from "moment";

export const createInformationAction = (formData) => async (dispatch) => {
  dispatch({
    type: "createInformation_loading",
    payload: true,
  });
  createInformation(formData)
    .then((res) => {
      dispatch({
        type: INFORMATION_DISSEMINATION.CREATE,
        payload: res.data,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data !== null,
            message: res.data !== null ? "Informação criada com sucesso" : "Ocorreu um erro a criar o Informação",
            goBack: true,
            onClose: `/admin/informationdissemination-details/${res.data}`,
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: INFORMATION_DISSEMINATION.CREATE,
        payload: false,
      });

      console.log("error", error);
    });
};

export const getFilteredInformationAction = (formData) => async (dispatch) => {
  dispatch({
    type: INFORMATION_DISSEMINATION.GET_FILTERED_INFORMATION_DISSEMINATION_LOADING,
    payload: true,
  });
  dispatch({
    type: INFORMATION_DISSEMINATION.REFRESH_RESULTS,
    payload: false,
  });
  getFilteredInformation(formData)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          alternateKey: record.alternateKey,
          informationDisseminationTypeId: record.informationDisseminationTypeId,
          informationDisseminationTypeKeyValue: record.informationDisseminationTypeKeyValue,
          informationDisseminationTypeDescription: record.informationDisseminationTypeDescription,
          title: record.title,
          description: record.description,
          isPublic: record.isPublic,
          published: record.published,
          publishedOn: record.publishedOn !== null ? moment(record.publishedOn).format("DD/MM/YYYY") : null,
          isHighlighted: record.isHighlighted,
          photo: record.thumbnail,
          socialMediaList: record.socialMediaList,
        };
      });
      dispatch({
        type: INFORMATION_DISSEMINATION.GET_FILTERED_INFORMATION_DISSEMINATION,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: INFORMATION_DISSEMINATION.GET_FILTERED_INFORMATION_DISSEMINATION_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: INFORMATION_DISSEMINATION.GET_FILTERED_INFORMATION_DISSEMINATION,
        result: false,
        payload: [],
      });
      dispatch({
        type: INFORMATION_DISSEMINATION.GET_FILTERED_INFORMATION_DISSEMINATION_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getInformationDetailsActions = (formData, history) => async (dispatch) => {
  dispatch({
    type: INFORMATION_DISSEMINATION.CLEAR_DETAIL,
  });
  dispatch({
    type: INFORMATION_DISSEMINATION.GET_INFORMATION_DISSEMINATION_DETAILS_LOADING,
    payload: true,
  });
  dispatch({
    type: INFORMATION_DISSEMINATION.REFRESH_RESULTS,
    payload: false,
  });
  getInformationDetails(formData)
    .then((res) => {
      dispatch({
        type: INFORMATION_DISSEMINATION.GET_INFORMATION_DISSEMINATION_DETAILS_LOADING,
        payload: false,
      });

      if(!res.data.success){
        return history.push('/admin/informationdissemination-management');
      }

      let infoData = res.data.infoData;
      dispatch({
        type: INFORMATION_DISSEMINATION.GET_INFORMATION_DISSEMINATION_DETAILS,
        result: true,
        payload: {
          alternateKey: infoData.alternateKey,
          informationDisseminationTypeId: infoData.informationDisseminationTypeId,
          informationDisseminationTypeKeyValue: infoData.informationDisseminationTypeKeyValue,
          informationDisseminationTypeDescription: infoData.informationDisseminationTypeDescription,
          title: infoData.title,
          description: infoData.description,
          isPublic: infoData.isPublic,
          published: infoData.published,
          publishedOn: infoData.publishedOn !== null ? moment(infoData.publishedOn).format("DD/MM/YYYY") : null,
          publishedBy: infoData.publishedBy,
          createdBy: infoData.createdBy,
          isHighlighted: infoData.isHighlighted,
          mediaList: infoData.mediaList,
          socialMediaList: infoData.socialMediaList,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: INFORMATION_DISSEMINATION.GET_INFORMATION_DISSEMINATION_DETAILS,
        result: false,
        payload: [],
      });
      dispatch({
        type: INFORMATION_DISSEMINATION.GET_INFORMATION_DISSEMINATION_DETAILS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const updateInformationAction = (formData) => async (dispatch) => {
  dispatch({
    type: INFORMATION_DISSEMINATION.UPDATE_INFORMATION_DISSEMINATION_LOADING,
    payload: true,
  });
  updateInformation(formData)
    .then((res) => {
      dispatch({
        type: INFORMATION_DISSEMINATION.UPDATE_INFORMATION_DISSEMINATION_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data !== "" && res.data !== 0,
            goBack: res.data !== "" && res.data !== 0,
            onClose: `/admin/informationdissemination-details/${res.data}`,
            message:
              res.data !== ""
                ? res.data !== 0
                  ? "Informação actualizada com sucesso"
                  : "Não é possível publicar a sua própria informação"
                : "Ocorreu um erro a actualizar a informação",
          },
        },
      });
      dispatch({
        type: INFORMATION_DISSEMINATION.REFRESH_RESULTS,
        payload: true,
      });
      dispatch({
        type: INFORMATION_DISSEMINATION.UPDATE_INFORMATION_DISSEMINATION_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: INFORMATION_DISSEMINATION.REFRESH_RESULTS,
        payload: false,
      });
      console.log("error", error);
    });
};

export const updateInformationPublishAction = (formData, errorFunction) => async (dispatch) => {
  updateInformationPublish(formData)
    .then((res) => {
      dispatch({
        type: INFORMATION_DISSEMINATION.UPDATE_INFORMATION_DISSEMINATION_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data.success,
            goBack: true,
            onClose: "/admin/informationdissemination-management",
            message:
              res.data !== ""
                ? res.data.success
                  ? formData.published
                    ? "Informação publicada com sucesso"
                    : "Informação retirada do Frontoffice com sucesso"
                  : "Não é possível publicar a sua própria informação"
                : formData.published
                ? "Ocorreu um erro a publicar a informação"
                : "Ocorreu um erro a ocultar a informação",
          },
        },
      });
      dispatch({
        type: INFORMATION_DISSEMINATION.PUBLISH,
        payload: res.data !== "" ? res.data : null,
      });

      if (errorFunction && (res.data === "" || res.data === null)) errorFunction();

      dispatch({
        type: INFORMATION_DISSEMINATION.REFRESH_RESULTS,
        payload: true,
      });
    })
    .catch((error) => {
      dispatch({
        type: INFORMATION_DISSEMINATION.REFRESH_RESULTS,
        payload: true,
      });

      console.log("error", error);
    });
};

export const updateInformationHighlightedAction = (formData, errorFunction) => async (dispatch) => {
  updateInformationHighlighted(formData)
    .then((res) => {
      dispatch({
        type: INFORMATION_DISSEMINATION.UPDATE_INFORMATION_DISSEMINATION_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data.success,
            goBack: true,
            onClose: "/admin/informationdissemination-management",
            message:
              res.data !== ""
                ? res.data.success
                  ? formData.highlighted
                    ? "Informação colocada em destaque com sucesso"
                    : "Informação retirada de destaque do Frontoffice com sucesso"
                  : "Não é possível colocar em destaque a sua própria informação"
                : formData.highlighted
                ? "Ocorreu um erro a colocar em destaque a informação"
                : "Ocorreu um erro a retirar de destaque a informação",
          },
        },
      });

      if (errorFunction && (res.data === "" || res.data === null || !res.data.success)) errorFunction();

      dispatch({
        type: INFORMATION_DISSEMINATION.REFRESH_RESULTS,
        payload: true,
      });
    })
    .catch((error) => {
      dispatch({
        type: INFORMATION_DISSEMINATION.REFRESH_RESULTS,
        payload: true,
      });

      console.log("error", error);
    });
};
