const componentStyles = (theme) => ({
  buttonLabel: {
    fontSize: ".875rem",
    fontWeight: "600",
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: "0!important",
    },
  },
  gridItemRoot: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    textAlign: "center",
  },
  gridContainerRoot: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
  },
  menuPaper: {
    background: theme.palette.dark.main,
    minWidth: "320px",
  },
  avatarRoot: {
    height: "48px",
    width: "48px",
  },
  bgGradientError: {
    background:
      "linear-gradient(87deg," +
      theme.palette.error.main +
      ", " + theme.palette.primary.main + ")!important",
  },
  bgGradientWarning: {
    background:
      "linear-gradient(87deg," +
      theme.palette.warning.main +
      "," + theme.palette.primary.mediumBlue + ")!important",
  },
  bgGradientInfo: {
    background:
      "linear-gradient(87deg," +
      theme.palette.info.main +
      ", " + theme.palette.primary.mediumBlue + " )!important",
  },
  bgGradientSuccess: {
    background:
      "linear-gradient(87deg," +
      theme.palette.success.main +
      ", " + theme.palette.primary.mediumBlue + " )!important",
  },
  bgGradientPurple: {
    background: "linear-gradient(87deg, " + theme.warning.primary.greyBlue + " , " + theme.warning.primary.mediumBlue + " )!important",
  },
  bgGradientYellow: {
    background:
      "linear-gradient(87deg," +
      theme.palette.warning.light +
      ", " + theme.warning.main + " )!important",
  },
});

export default componentStyles;
