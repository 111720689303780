import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getDashboardNotificationsInfoAction,
  getDashboardUnreadNotificationsCountInfoAction,
} from "redux/actions/dashboardAction";
import { getNumberOfUnreadNotificationsAction } from "redux/actions/notificationsAction";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import LinkMaterialUi from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { PAGINATION } from "utils/consts";

// @material-ui/lab components
// core components
import componentStyles from "assets/theme/components/cards/dashboard/card-dashboard-generic.js";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStyles = makeStyles(componentStyles);
const useStylesGeneric = makeStyles(componentStylesGeneric);

function CardNotifications(props) {
  const classes = { ...useStylesGeneric(), ...useStyles() };

  const getHours = (date) => {
    if (date) return moment(date).format("HH:mm");
  };

  const getDate = (date) => {
    if (date) return moment(date).format("DD/MM/YYYY");
  };

  const getUnreadNotificationsCount = () => {
    return props.notificationState?.unreadNotificationsCount >= 0 ? props.notificationState?.unreadNotificationsCount : 0;
  };

  useEffect(() => {
    let filter = {};
    filter["lastXNotifications"] = 3;
    filter["message"] = null;
    filter["originId"] = null;
    filter["origin"] = undefined;
    filter["onlyUnread"] = true;
    filter["search"] = "";
    filter["pageIndex"] = 1;
    filter["pageSize"] = PAGINATION.PAGE_SIZE_DASHBOARD_NOTIFICATIONS;

    props.getDashboardNotificationsInfo(filter);
    props.getNumberOfUnreadNotifications();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <div style={{position:"relative"}}>
        <img
            src={"/images/empregoAngolaBackground1.png"} //Designed by 0melapics / Freepik: http://www.freepik.com
            className={classes.backgroundImage}
            alt={""}
        />
      </div>
      <CardHeader
        classes={{
          root: classes.cardHeader + " " + classes.cardHeaderDashboard,
          action: classes.cardHeaderAction,
        }}
        subheader={
          <Grid container component={Box} alignItems="center" justifyContent="space-between">
            <Box className={classes.lineHeader}> {""} </Box>
            <Grid item xs={12}>
              <Box component={Typography} variant="h3" marginBottom="0!important" classes={{ root: classes.cardTitle }}>
                <Box component="span">Notificações por Ler</Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                component={Typography}
                variant="h1"
                classes={{ root: classes.cardHeaderCounter }}
                marginBottom="0!important"
              >
                <Box component="span">{getUnreadNotificationsCount()}</Box>
              </Box>
            </Grid>
          </Grid>
        }
        action={
          <LinkMaterialUi component={Link} variant="h5" to="/admin/notifications" className={classes.link}>
            Ver mais
          </LinkMaterialUi>
        }
      ></CardHeader>
      {props.dashboardState?.dashboardNotificationsInfo !== undefined &&
        props.dashboardState?.dashboardNotificationsInfo?.length > 0 && (
          <TableContainer>
            <Box component={Table} alignItems="center" marginBottom="0!important">
              <TableBody>
                {props.dashboardState?.dashboardNotificationsInfo?.map((prop, key) => (
                  <TableRow key={key}>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      <Grid container component={Box} alignItems="center" justifyContent="space-between">
                        <Grid item xs={8} xl={8}>
                          <Box display="flex">
                            <Divider
                              flexItem={true}
                              orientation="vertical"
                              classes={{
                                root:
                                  classes.dividerRoot + " " + (!prop.isRead ? classes.readNotificationDividerRoot : ""),
                              }}
                            />
                            <Box marginLeft="10px">
                              <Box
                                component={Typography}
                                classes={{
                                  root: classes.cellTextRoot + " " + classes.cellTitleTextRoot,
                                }}
                                marginBottom="0!important"
                              >
                                <Box component="span">{prop.title}</Box>
                              </Box>
                              <Box
                                component={Typography}
                                classes={{ root: classes.cellTextRoot }}
                                marginBottom="0!important"
                              >
                                <Box component="span"> {prop.subtitle} </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={4} xl={4}>
                          <Box>
                            <Box
                              component={Typography}
                              classes={{ root: classes.cellTextRoot }}
                              marginBottom="0!important"
                              textAlign="end"
                            >
                              <Box component="span">{getHours(prop.date)}</Box>
                            </Box>
                            <Box
                              component={Typography}
                              classes={{ root: classes.cellTextRoot }}
                              marginBottom="0!important"
                              textAlign="end"
                            >
                              <Box component="span"> {getDate(prop.date)} </Box>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Box>
          </TableContainer>
        )}
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDashboardNotificationsInfo: (formData) => dispatch(getDashboardNotificationsInfoAction(formData)),
  getDashboardUnreadNotificationsCountInfo: () => dispatch(getDashboardUnreadNotificationsCountInfoAction()),
  getNumberOfUnreadNotifications: () => dispatch(getNumberOfUnreadNotificationsAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardNotifications);
