import { React, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
//import custom components
import GeneralData from "./GeneralDataSection";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import GenericButton from "components/Buttons/GenericButton";
import updateProfessionalKitMap from "mappers/updateProfessionalKitMap";
import { DATASET, MONETARY_DECIMAL_SEPARATOR } from "utils/consts";
import { getSelectOptionsElementByCode } from "utils/formSelect";
import { labels } from "resources/resources";
import useScrollDirection from "hooks/useScrollDirection";
import { getInactiveCategoriesAction } from "redux/actions/datasetsAction";
import { updateProfessionalKitAction, getProfessionalKitDetailsAction } from "redux/actions/stockAction";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function KitDetails(props) {
  const classes = useStylesGeneric();
  const history = useHistory();
  const intl = useIntl();
  const scrollDirection = useScrollDirection();
  const methods = useForm({
    mode: "onChange",
  });
  const [formattedUnitPrice, setFormattedUnitPrice] = useState();
  const [isEditable, setIsEditable] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handleEditSection = () => {
    setIsEditable(!isEditable);
    if (isEditable) {
      methods.clearErrors();
      setFormFields();
    }
  };
  useEffect(() => {
    props.getInactiveCategories(DATASET.INACTIVE_CATEGORIES);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      props.stockState.professionalKitDetails === undefined ||
      props.stockState.professionalKitDetails.Id !== props.match.params.professionalKitId
    ) {
      props.getProfessionalKitDetails(props.match.params.professionalKitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.professionalKitId]);

  useEffect(() => {
    if (props.stockState.professionalKitDetails !== undefined) {
      setFormFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.stockState.professionalKitDetails]);

  const setFormFields = () => {
    methods.setValue(
      "unitPrice",
      (props.stockState.professionalKitDetails.unitPrice + "")?.replace(
        MONETARY_DECIMAL_SEPARATOR.API,
        MONETARY_DECIMAL_SEPARATOR.WEB
      )
    );
    setFormattedUnitPrice(intl.formatNumber(props.stockState.professionalKitDetails.unitPrice + ""));
    methods.setValue("paymentDate", props.stockState.professionalKitDetails.paymentDate);
    methods.setValue("refundDate", props.stockState.professionalKitDetails.refundDate);
    methods.setValue("notes", props.stockState.professionalKitDetails.notes);
    methods.setValue("kitDescription", props.stockState.professionalKitDetails.kitDescription);
    methods.setValue("active", props.stockState.professionalKitDetails.active);
    setIsActive(props.stockState.professionalKitDetails.active);
    setCategoryField();
  };

  const setCategoryField = () => {
    if (props.stockState.professionalKitDetails !== undefined) {
      var categoryList = props.dataSetState[DATASET.CATEGORIES] ? props.dataSetState[DATASET.CATEGORIES] : [];
      const category =
        props.stockState.professionalKitDetails.categoryId !== undefined
          ? props.stockState.professionalKitDetails.categoryId
          : null;
      methods.setValue("categoryId", getSelectOptionsElementByCode(categoryList, category));
    }
  };

  useEffect(() => {
    if (props.dataSetState) setCategoryField();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, props.dataSetState[DATASET.CATEGORIES]);

  const cancelOnClick = () => {
    history.goBack();
  };

  const onSubmit = (data) => {
    props.updateProfessionalKit(
      updateProfessionalKitMap(props.match.params.professionalKitId, data), 
      () => {
        history.push("/admin/kit-management");
      }
    );
  };

  return (
    <>
      {scrollDirection === "up" && (
        <div className={classes.breadcrumbs}>
          <LocationHeader
            section={"Detalhes do Kit"}
            subsection={"Gestão de Kits Profissionais"}
            subsectionLink={"/admin/kit-management"}
          />
        </div>
      )}

      <GenericBackdrop open={props.stockState.getProfessionalKitDetails_loading} />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Container maxWidth={false} component={Box} classes={{ root: classes.containerRootAfterBreadcrumbs }}>
            <PageTitleHeader title={"Detalhes do Kit"} />

            <Container maxWidth={false} component={Box}>
              <GeneralData
                getValues={methods.getValues}
                errors={methods.formState.errors}
                isCreate={false}
                isEditable={isEditable}
                handleEditSection={handleEditSection}
                professionalKitId={props.match.params.professionalKitId}
                isActive={isActive}
                setIsActive={setIsActive}
                formattedUnitPrice={formattedUnitPrice}
                setFormattedUnitPrice={setFormattedUnitPrice}
              />

              {isEditable && (
                <Grid container>
                  <Grid item xs={12} lg={6} xl={8}></Grid>
                  <Grid item xs={12} lg={3} xl={2}>
                    <FormGroup>
                      <GenericButton typeSubmit={false} color="secondary" onClick={() => cancelOnClick()}>
                        {intl.formatMessage(labels.Label_Generic_Cancel)}
                      </GenericButton>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} lg={3} xl={2}>
                    <FormGroup>
                      <GenericButton
                        typeSubmit={true}
                        color="primary"
                        disabled={props.stockState.updateProfessionalKit_loading}
                        loading={props.stockState.updateProfessionalKit_loading}
                      >
                        {intl.formatMessage(labels.Label_Generic_Save)}
                      </GenericButton>
                    </FormGroup>
                  </Grid>
                </Grid>
              )}
            </Container>
          </Container>
        </form>
      </FormProvider>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  updateProfessionalKit: (formData, onCloseAction) => dispatch(updateProfessionalKitAction(formData, onCloseAction)),
  getProfessionalKitDetails: (kitId) => dispatch(getProfessionalKitDetailsAction(kitId)),
  getInactiveCategories: (name) => dispatch(getInactiveCategoriesAction(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KitDetails);
