import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";

import FilterSection from "./Sections/OpportunitiesFilterSection";
import ResultSection from "./Sections/OpportunitiesResultSection";
import { getIsOpportunityApplicationAction } from "redux/actions/opportunitiesAction";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function OpportunitiesList(props) {
  const classes = useStylesGeneric();

  const [page, setPage] = useState(1);
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const setIsOpportunityApplication = () => {
    props.getIsOpportunityApplication(false);
  };

  useEffect(() => {
    setIsOpportunityApplication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container classes={{ root: classes.containerRoot }} style={{ marginTop: "11rem" }}>
      <Grid item xs={12} md={12}>
        <FilterSection
          page={page}
          handleChangePage={(value) => {
            handleChangePage(undefined, value);
          }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <ResultSection page={page} handleChangePage={handleChangePage} />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getIsOpportunityApplication: (isOpportunityApplication) =>
    dispatch(getIsOpportunityApplicationAction(isOpportunityApplication)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpportunitiesList);
