import 'moment/locale/pt';
import moment from "moment";

export function registerLossBreakInWarehouse(formData) {
    if (formData !== null && formData !== undefined)
        return {
            WarehouseId: formData.warehouseId && formData.warehouseId.code ? formData.warehouseId.code : "",
            CategoryId: formData.categoryId && formData.categoryId.code ? formData.categoryId.code : "",
            Quantity: formData.quantity ? formData.quantity : 0,
            BatchNumber: formData.batchNumber ? formData.batchNumber : "",
            Motive: formData.motive && formData.motive.code ? formData.motive.code : "",
            EntryDate: moment().startOf('day').format("YYYY-MM-DD")

        };
    return formData;
}

export default registerLossBreakInWarehouse