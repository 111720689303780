import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

// core components
import componentStyles from "assets/theme/components/headers/location-header.js";

const useStyles = makeStyles(componentStyles);

const LocationHeader = ({ section, subsection, subsectionLink }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid container classes={{ root: classes.container }}>
      <Grid item xs={12} md={12} className={classes.titleGrid}>
        <Box
          component={Link}
          onClick={() => { history.push(subsectionLink); }}
          className={classes.subsection}
        >
          {subsection}
        </Box>
        <Box className={classes.separator}>
          <ArrowForwardIosIcon/>
        </Box>
        <Box className={classes.section}>
          {section}
        </Box>
      </Grid>
    </Grid>

  );
};

LocationHeader.propTypes = {
  section: PropTypes.string,
  subsection: PropTypes.string,
  subsectionLink: PropTypes.string,
};

export default LocationHeader;
