const componentStyles = (theme) => ({
  stepperBox: {
    marginTop: "20px",
    width: "100%",
    background: theme.palette.primary.greyBlue,
  },
  stepperText: {
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "13px",
      lineHeight: "18px",
      color: theme.palette.white.main,
    },
    "& .MuiStepLabel-label": {
      
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "18px",
      color: theme.palette.white.main,
    },
  },
  circle: {
    fill: theme.palette.white.main + "solid",
  },
  check: {
    fill: theme.palette.primary.greyBlue,
    width: "30px",
    height: "30px",
  },
  filledCircle: {
    fill: theme.palette.primary.greyBlue,
  },
  alertGrid: {
    marginBottom: "30px",
    maxWidth: "fit-content",
  },
  alertIcon: {
    padding: "12px 0",
  },
  alertFilledWarning: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.black.main,
    
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    alignItems: "center",
  },
  alertLink: {
    color: theme.palette.black.main,
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.black.main,
    },
  }
});

export default componentStyles;
