import { React, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
// MUI Core
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
// MUI Icons
import ClearIcon from "@material-ui/icons/Clear";
// custom
import componentStylesGeneric from "assets/theme/views/admin/generic";
import { convertBase64ToFile } from "utils/converters";
import { messages } from "resources/resources";
import { IMAGE_TYPE } from "utils/consts";

const useStylesGeneric = makeStyles(componentStylesGeneric);
export const DropzoneField = ({
  name,
  label,
  register,
  setValue,
  getValues,
  required,
  requiredMessage,
  errors,
  trigger,
  maxNumberOfFiles,
  accept,
  maxFileSize,
  hasPreview = false,
  showFileNameOnPreview = true,
  photosPreviewName = null,
  photosPreviewBase64 = null,
  photosPreviewURL = null,
  handleMediaDelete,
}) => {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const intl = useIntl();
  const [filesState, setFiles] = useState([]);
  const [filesMaxNumberError, setFilesMaxNumberError] = useState(false);
  const [imageTypeError, setImageTypeError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);

  const fileValidator = (file) => {
    // Validate file type
    if (accept === ".jpg, .jpeg, .png") {
      let aux = file ? file.name.split(".") : []; // extensão .jfif é interpretado com o type=jpeg. Daí temos de ver pelo nome, pois não é suposto aceitar.
      if ((file && file.type !== "image/png" && file.type !== "image/jpeg") || aux[aux.length - 1] === "jfif") {
        setImageTypeError(true);
        return {
          code: "wrong-image-type",
          message: `Wrong image type`,
        };
      }
    }

    // Validate file size
    if (maxFileSize && file.size > maxFileSize * 1048576) {
      setFileSizeError(true);
      return {
        code: "name-too-large",
        message: `Name is larger than ${maxFileSize} MB`,
      };
    }

    // Validate number of files
    if (filesState.length >= maxNumberOfFiles) {
      setFilesMaxNumberError(true);
      return {
        code: "file-number-exceeded",
        message: `Number of files exceeded, maximum is ${maxNumberOfFiles} files`,
      };
    }

    setImageTypeError(false);
    setFileSizeError(false);
    setFilesMaxNumberError(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    validator: fileValidator,
    maxFiles: maxNumberOfFiles,
    accept: accept,
    onDrop: (files) => {
      if (filesState.length + files.length > maxNumberOfFiles) setFilesMaxNumberError(true);
      else addFiles(files);
    },
  });

  useEffect(() => {
    var files = [];

    if (photosPreviewBase64 !== null) {
      photosPreviewBase64?.forEach((photoPreviewBase64, i) => {
        var file = {
          fileType: IMAGE_TYPE.BASE64, 
          id: photoPreviewBase64.id,
          file: convertBase64ToFile(
            photoPreviewBase64.photo,
            photoPreviewBase64.photo.substring(6, 16),
            photosPreviewName ? photosPreviewName[i] : ""
          ),
        };
        files = [...files, file];
      });
    }
    else if (photosPreviewURL !== null) {
      photosPreviewURL?.forEach((element) => {
        var file = {
          fileType: IMAGE_TYPE.URL, 
          id: element.id,
          file: {
            photo: element?.photo,
            name: element?.name ? element?.name : "",
          }
        };
        files = [...files, file];
      })
    }

    setValue(
      name,
      files.map((f) => {
        return f.file;
      })
    );
    setFiles(files);
    trigger(name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photosPreviewBase64, photosPreviewURL]);

  const remove = (i) => {
    const newFiles = [...filesState];

    if (newFiles[i].id !== null) handleMediaDelete(newFiles[i].id);

    newFiles.splice(i, 1);

    setValue(
      name,
      newFiles.map((f) => {
        return f.file;
      })
    );
    setFiles(newFiles);
    trigger(name);

    setFilesMaxNumberError(false);
  };

  function addFiles(files) {
    const currentFiles = getValues(name) ?? [];
    const newFiles = [...currentFiles, ...files];

    setValue(name, newFiles);
    setFiles((prevState) => {
      return [
        ...prevState,
        ...files.map((f) => {
          return { id: null, file: f };
        }),
      ];
    });
    trigger(name);
  }

  const files = filesState.map((fileItem, i) => (
    <Box key={`key${i}`} component="span" display="flex" className={classes.documentBadge}>
      <Box className={classes.documentNameRoot}>
        {hasPreview && (
          <Avatar
            src={fileItem.fileType === IMAGE_TYPE.URL ? fileItem.file?.photo : URL.createObjectURL(fileItem.file)}
            classes={{
              root: classes.documentAvatar,
            }}
          />
        )}
      </Box>
      {showFileNameOnPreview && fileItem.file.name && (
        <Box className={classes.documentNameRoot}>{fileItem.file.name}</Box>
      )}
      <Box>
        <IconButton
          onClick={() => {
            remove(i);
          }}
          classes={{ root: classes.documentDeleteButtonRoot }}
        >
          <ClearIcon classes={{ root: classes.documentDeleteButtonIconRoot }} />
        </IconButton>
      </Box>
    </Box>
  ));

  return (
    <>
      <div {...getRootProps({ className: classes.documentDropzone })}>
        <input
          {...register(name, {
            required: {
              value: required,
              message: requiredMessage,
            },
          })}
          {...getInputProps()}
        />
        <p>{label}</p>
      </div>
      <Grid container component={Box} marginTop="1rem" className={classes.documentBadgeContainer}>
        {files}

        {imageTypeError && (
          <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
            {intl.formatMessage(messages.Message_DropzoneField_InvalidFileFormat)}
          </FormHelperText>
        )}

        {fileSizeError && (
          <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
            {intl.formatMessage(messages.Message_DropzoneField_MaxFileSizeExceeded)} {maxFileSize} MB.
          </FormHelperText>
        )}

        {filesMaxNumberError && (
          <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
            {intl.formatMessage(messages.Message_DropzoneField_MaxNumberOfFilesExceeded)} {maxNumberOfFiles}.
          </FormHelperText>
        )}
      </Grid>
      <Grid container component={Box} marginTop="1rem" className={classes.documentBadgeContainer}>
        {errors[name] !== undefined && (
          <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
            {errors[name].message}
          </FormHelperText>
        )}
      </Grid>
    </>
  );
};

export default DropzoneField;
