import { React, useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { useFormContext, Controller } from "react-hook-form";
import { usePasswordValidation } from "hooks/usePasswordValidation";
import "moment/locale/pt";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { green, red } from "@material-ui/core/colors";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// @material-ui/icons components
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
//import custom components
import { messages } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
const useStylesGeneric = makeStyles(componentStylesGeneric);

function PasswordCard() {
  const classes = { ...useStylesGeneric() };
  const theme = useTheme();
  const intl = useIntl();
  const methods = useFormContext();
  const [password, setPassword] = useState({
    firstPassword: "",
    secondPassword: "",
  });
  const [passwordValidation] = usePasswordValidation({
    firstPassword: methods.getValues("password"),
    secondPassword: methods.getValues("passwordConfirmation"),
  });

  useEffect(() => {
    if (passwordValidation?.isValid != null) methods.trigger("password");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordValidation?.isValid]);

  const setFirst = (event) => {
    setPassword({ ...password, firstPassword: event.target.value });
  };

  const setSecond = (event) => {
    setPassword({ ...password, secondPassword: event.target.value });
  };

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader title={"Palavra-passe"} classes={{ title: classes.cardHeader }}></CardHeader>
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={6}>
            <FormGroup>
              <FormLabel>Palavra-passe</FormLabel>
              <Controller
                name="password"
                control={methods.control}
                component={Box}
                defaultValue=""
                value={methods.getValues("password")}
                rules={{
                  required: {
                    value: true,
                    message: "O campo é obrigatório",
                  },
                  validate: {
                    validPassword: () => {
                      if (!passwordValidation.isValid) return "Palavra-passe inválida";
                      return true;
                    },
                  },
                }}
                render={({ field: { onChange, name, value } }) => (
                  <OutlinedInput
                    name={name}
                    value={value}
                    fullWidth
                    autoComplete="off"
                    type={"password"}
                    onChange={(e) => {
                      onChange(e);
                      setFirst(e);
                    }}
                    classes={{
                      notchedOutline: clsx({
                        [classes.borderWarning]: methods.formState.errors["password"] !== undefined,
                      }),
                    }}
                  />
                )}
              />
              {methods.formState.errors["password"] !== undefined && !passwordValidation.isValid && (
                <FormHelperText
                  component={Box}
                  color={theme.palette.warning.main + "!important"}
                >
                  {methods.formState.errors["password"].message}
                </FormHelperText>
              )}
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormGroup>
              <FormLabel>Confirmação da palavra-passe</FormLabel>
              <Controller
                name="passwordConfirmation"
                defaultValue=""
                value={methods.getValues("passwordConfirmation")}
                control={methods.control}
                component={Box}
                rules={{
                  required: {
                    value: true,
                    message: "O campo é obrigatório",
                  },
                  validate: {
                    PasswordMatch: (value) => {
                      return (
                        value === methods.getValues("password") ||
                        "O valor inserido deve ser igual ao campo 'Palavra-passe'"
                      );
                    },
                  },
                }}
                render={({ field: { onChange, name, value } }) => (
                  <OutlinedInput
                    name={name}
                    value={value}
                    fullWidth
                    autoComplete="off"
                    type={"password"}
                    onChange={(e) => {
                      onChange(e);
                      setSecond(e);
                    }}
                    classes={{
                      notchedOutline: clsx({
                        [classes.borderWarning]: methods.formState.errors["passwordConfirmation"] !== undefined,
                      }),
                    }}
                  />
                )}
              />
              {methods.formState.errors["passwordConfirmation"] !== undefined && (
                <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                  {methods.formState.errors["passwordConfirmation"].message}
                </FormHelperText>
              )}
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <FormLabel>{intl.formatMessage(messages.Message_Auth_Password_Rules_1)}</FormLabel>
            <List dense={true}>
              <ListItem>
                <Fragment>
                  {passwordValidation.validLength ? (
                    <CheckCircleOutlinedIcon
                      style={{
                        marginRight: 8,
                        color: green[500],
                      }}
                    />
                  ) : (
                    <CancelOutlinedIcon style={{ marginRight: 8, color: red[500] }} />
                  )}
                  {intl.formatMessage(messages.Message_Auth_Password_Rules_2)}
                </Fragment>
              </ListItem>
              <ListItem>
                <Fragment>
                  {passwordValidation.upperCase ? (
                    <CheckCircleOutlinedIcon
                      style={{
                        marginRight: 8,
                        color: green[500],
                      }}
                    />
                  ) : (
                    <CancelOutlinedIcon style={{ marginRight: 8, color: red[500] }} />
                  )}
                  {intl.formatMessage(messages.Message_Auth_Password_Rules_3)}
                </Fragment>
              </ListItem>
              <ListItem>
                <Fragment>
                  {passwordValidation.lowerCase ? (
                    <CheckCircleOutlinedIcon
                      style={{
                        marginRight: 8,
                        color: green[500],
                      }}
                    />
                  ) : (
                    <CancelOutlinedIcon style={{ marginRight: 8, color: red[500] }} />
                  )}
                  {intl.formatMessage(messages.Message_Auth_Password_Rules_4)}
                </Fragment>
              </ListItem>
              <ListItem>
                <Fragment>
                  {passwordValidation.hasNumber ? (
                    <CheckCircleOutlinedIcon
                      style={{
                        marginRight: 8,
                        color: green[500],
                      }}
                    />
                  ) : (
                    <CancelOutlinedIcon style={{ marginRight: 8, color: red[500] }} />
                  )}
                  {intl.formatMessage(messages.Message_Auth_Password_Rules_5)}
                </Fragment>
              </ListItem>
              <ListItem>
                <Fragment>
                  {passwordValidation.specialChar ? (
                    <CheckCircleOutlinedIcon
                      style={{
                        marginRight: 8,
                        color: green[500],
                      }}
                    />
                  ) : (
                    <CancelOutlinedIcon style={{ marginRight: 8, color: red[500] }} />
                  )}
                  {intl.formatMessage(messages.Message_Auth_Password_Rules_6)}
                </Fragment>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(PasswordCard);
