import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
    border: "0!important",
  },
  listItemRoot: {
    borderBottom: "1px solid " + theme.palette.gray[200],
  },

  cardButtons: {
    padding: "6px 4px 6px 4px",
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgb(50 50 93 / 11%), 0px 1px 3px rgb(0 0 0 / 8%)",
    borderRadius: "4px",
    borderColor: theme.palette.primary.main,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: theme.palette.white.main,
      borderColor: theme.palette.primary.main,
    },
  },

  cardButtonsDisable: {
    padding: "6px 4px 6px 4px",
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgb(50 50 93 / 11%), 0px 1px 3px rgb(0 0 0 / 8%)",
    borderRadius: "4px",
    borderColor: theme.palette.info.grey,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },

  editButton: {
    background: theme.palette.white.main,
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08) !important",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    width: "50%",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  switchText: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },

  switchNotPublished: {
    color: theme.palette.error.badge,
  },

  switchPublished: {
    color: theme.palette.success.snackbar,
  },

  alignEnd: {
    textAlign: "end",
  },

  cardImgTop: {
    borderTopLeftRadius: "calc(.375rem - 1px)",
    borderTopRightRadius: "calc(.375rem - 1px)",
    flexShrink: 0,
    width: "100%",
    verticalAlign: "middle",
    borderStyle: "none",
    height: "175px",
    objectFit: "contain",
    backgroundColor: theme.palette.white.smoke,
  },
  cardImgOverlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: "1.25rem",
    borderRadius: "calc(.375rem - 1px)",
  },
  cardLabelTitle: {
    fontStyle: "normal",
    fontWeight: "bold",
  },
  doubleLineTitleEllipsis: {
    display: "-webkit-box",
    "-webkitBoxOrient": "vertical",
    "-webkitLineClamp": "2",
    lineHeight: "1.5rem",
    height: "3rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  descriptionSize: {
    height: "6.5rem",
    marginBottom: "1rem",
  },
  tooltip: {
    maxWidth: "280px",
    marginTop: "1px",
    marginBottom: "1px",
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.black.light,
    textAlign: "justify",
  },
  tooltipArrow: {
    color: theme.palette.grey[300],
  },
  topLeftIcon: {
    position: "absolute",
    left: "5px",
    top: "5px",
    width: "25px",
    height: "25px",
    color: theme.palette.warning.light,
    backgroundColor: theme.palette.white.main,
    borderRadius: "50%",
  },
  quillOverflowHidden: {
    "& .ql-container .ql-editor": {
      overflowY: "hidden",
    },
  },
});

export default componentStyles;
