import { Box, Card, CardContent, CardHeader, Container, Grid, makeStyles, Typography } from "@material-ui/core";
import { Notifications } from "@material-ui/icons";
import {
  Alert,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import { getOpportunityHistory } from "crud/opportunities.crud";
import moment from "moment";
import { useMemo } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const useStylesGeneric = makeStyles(componentStylesGeneric);

export default function OpportunityHistory() {
  const classes = useStylesGeneric();

  const { opportunityId } = useParams();

  const id = useMemo(() => opportunityId, [opportunityId]);

  const { data, error, isLoading } = useQuery({
    queryKey: ["opportunityHistory", id],
    queryFn: () => getOpportunityHistory(id),
    select: (data) => data || [],
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  if (error) {
    return (
      <Container maxWidth={false}>
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader classes={{ title: classes.cardHeader }} title="Linha do Tempo" />
          <CardContent>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Alert severity="error">
                    Um erro ocorreu ao carregar a linha do tempo. Por favor, tente novamente.
                  </Alert>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    );
  }

  if (isLoading) {
    return (
      <Container maxWidth={false}>
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader classes={{ title: classes.cardHeader }} title="Linha do Tempo" />
          <CardContent>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <GenericBackdrop open />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    );
  }

  if (!data || data.length === 0) {
    return (
      <Container maxWidth={false}>
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader classes={{ title: classes.cardHeader }} title="Linha do Tempo" />
          <CardContent>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Alert severity="info">Não existem registos de alterações de estado para esta oportunidade.</Alert>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    );
  }

  return (
    <Container maxWidth={false}>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader classes={{ title: classes.cardHeader }} title="Linha do Tempo" />
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Timeline
                style={{
                  height: "400px",
                  overflow: "auto",
                }}
              >
                {data.map((historyEntry) => {
                  return (
                    <TimelineItem key={historyEntry.id} className={classes.minHeight0PX}>
                      <TimelineOppositeContent style={{ flex: 0 }}></TimelineOppositeContent>
                      <TimelineSeparator>
                        <Box className={classes.timelineIconsBox}>
                          <Notifications className={classes.timelineIcons} />
                        </Box>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        {historyEntry.opportunityStateKeyValue !== "Submetida" ? (
                          <Typography variant="caption">
                            Atualizado por: <strong>{historyEntry.updatedBy}</strong> -{" "}
                            {moment.utc(historyEntry.updatedAt).format("DD/MM/YYYY HH:mm")}
                          </Typography>
                        ) : (
                          <Typography variant="caption">
                            Criado por: <strong>{historyEntry.createdBy}</strong> -{" "}
                            {moment.utc(historyEntry.createdAt).format("DD/MM/YYYY HH:mm")}
                          </Typography>
                        )}
                        <Typography variant="h5" component="h1">
                          Alteração de Estado - {historyEntry.opportunityStateDescription}
                        </Typography>
                        <Typography variant="caption">{historyEntry.observations}</Typography>
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}
              </Timeline>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}
