import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputBase from "@material-ui/core/InputBase";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic";
import componentStylesApplicationDetails from "assets/theme/views/admin/application-details";
import {
  getShapeProgramsAction,
  getShapeSubsitesAction,
  getDataSetByNameAction,
  getAvailableInternshipOpportunitiesAction,
} from "redux/actions/datasetsAction";
import { getSelectOptionsElementByCode } from "utils/formSelect";
import GenericFormSelect from "components/FormFields/GenericFormSelect";
import { DATASET, SHAPE_PROGRAMS_TARGET, UNITS, REGEX_PATTERNS, MONETARYUNITPLACEHOLDER, BENEFIT, INTERNSHIP_TYPE_KEY, APPLICATION_STATE_GROUP_KEY, APPLICATION_STATE_KEY } from "utils/consts";
import { messages, labels, titles } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);
const useStylesApplicationDetails = makeStyles(componentStylesApplicationDetails);

function ProgramSubprogramDataSection(props) {
  const classes = {...useStylesGeneric(), ...useStylesApplicationDetails()};
  const theme = useTheme();
  const intl = useIntl();
  const { control } = useFormContext();
  const [kitsTotalValue, setKitsTotalValue] = useState(0);
  const [applicationStateGroup, setApplicationStateGroup] = useState(0);
  const [formattedValue, setFormattedValue] = useState();

  const onValChange = (value, onChange) => {
    value = value?.replace(/\./g, "");
    //TODO - Check better way to format currency on same input
    if (value && value.charAt(value.length - 1) === ",") setFormattedValue(value);
    else {
      if (value) {
        value = value?.replace(/,/g, ".");
        if (intl.formatNumber(value) !== "NaN") setFormattedValue(intl.formatNumber(value));
        else setFormattedValue(value);
      } else {
        setFormattedValue("");
      }
    }
    value = value?.replace(/\./g, ",");
    onChange(value);
  };

  useEffect(() => {
    if (
      props.generalData !== undefined &&
      (props.generalData?.benefitKeyValue === BENEFIT.AVANCO ||
        props.generalData?.benefitKeyValue === BENEFIT.CAPACITA ||
        props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_WALLET)
    ) {
      let target = "";
      if (props.generalData?.benefitKeyValue === BENEFIT.AVANCO) target = SHAPE_PROGRAMS_TARGET.AVANCO;
      if (props.generalData?.benefitKeyValue === BENEFIT.CAPACITA) target = SHAPE_PROGRAMS_TARGET.CAPACITA;
      if (props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_WALLET) target = SHAPE_PROGRAMS_TARGET.PROFESSIONAL_WALLET;

      props.getShapePrograms(target, DATASET.SHAPE_PROGRAMS);



      props.getShapeSubsites(
        props.generalData !== undefined ? props.generalData.intendedCourseCode : "",
        props.generalData !== undefined ? props.generalData.provinceId : "",
        DATASET.SHAPE_SUBSITES
      );
      props.setValue(
        "intendedCourseCode",
        getSelectOptionsElementByCode(
          props.dataSetState[DATASET.SHAPE_PROGRAMS],
          props.generalData !== undefined ? props.generalData.intendedCourseCode : ""
        )
      );

      props.setValue(
        "trainingCenterId",
        getSelectOptionsElementByCode(
          props.dataSetState[DATASET.SHAPE_SUBSITES],
          props.generalData !== undefined ? props.generalData.trainingCenterId?.toString() : ""
        )
      );
    }

    if (props.generalData !== undefined && (props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_INTERNSHIP)) {

      const province = props.generalData !== undefined ? props.generalData.provinceId : null;
      const internshipArea = props.generalData !== undefined ? props.generalData.internshipAreaId : ""

      if (!props.dataSetState || props.dataSetState[DATASET.PROFESSIONAL_CATEGORY] === undefined || props.dataSetState[DATASET.PROFESSIONAL_CATEGORY] === []) {
        props.getDataSetByName(DATASET.PROFESSIONAL_CATEGORY)
      } else if (props.dataSetState && props.dataSetState[DATASET.PROFESSIONAL_CATEGORY] && props.generalData.internshipAreaId) {
        props.setValue(
          "internshipAreaId",
          getSelectOptionsElementByCode(
            props.dataSetState[DATASET.PROFESSIONAL_CATEGORY],
            props.generalData.internshipAreaId
          )
        );
      }

      if (props.dataSetState[DATASET.PROVINCES] === undefined || props.dataSetState[DATASET.PROVINCES] === []) {
        props.getDataSetByName(DATASET.PROVINCES);
      } else if (props.dataSetState && props.dataSetState[DATASET.PROVINCES] && props.generalData.provinceId) {
        props.setValue(
          "provinceId",
          getSelectOptionsElementByCode(
            props.dataSetState[DATASET.PROVINCES],
            props.generalData.provinceId
          )
        );
      }

      props.getAvailableInternshipOpportunities(DATASET.AVAILABLE_INTERNSHIP_OPPORTUNITIES, province, internshipArea, INTERNSHIP_TYPE_KEY.PAPE);

      if (props.dataSetState && props.dataSetState[DATASET.AVAILABLE_INTERNSHIP_OPPORTUNITIES])
        props.setValue(
          "internshipOpportunityId",
          getSelectOptionsElementByCode(
            props.dataSetState[DATASET.AVAILABLE_INTERNSHIP_OPPORTUNITIES],
            props.generalData.internshipOpportunityId ?? undefined
          )
        );
    } else {
      props.setValue("internshipAreaId", undefined);
      props.setValue("provinceId", undefined);
      props.setValue("internshipOpportunityId", undefined);
    }

    if (props.generalData?.benefitKeyValue === BENEFIT.MICROCREDIT ||
      props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT ||
      props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT_MICROCREDIT){
      props.setValue(
        "concededMicrocreditAmount",
        props.generalData !== undefined ? props.generalData.concededMicrocreditAmount : ""
      );
      setFormattedValue(props.generalData !== undefined ? intl.formatNumber(props.generalData.concededMicrocreditAmount) : 0);

      if (!props.dataSetState || props.dataSetState[DATASET.BANK] === undefined || props.dataSetState[DATASET.BANK] === [])
        props.getDataSetByName(DATASET.BANK)
      else
        props.setValue("bank",
          props.generalData !== undefined ?
            getSelectOptionsElementByCode(
              props.dataSetState[DATASET.BANK],
              props.generalData.bankPreferenceId
            ) : ""
        );
    }


    props.setValue(
      "concededProfessionalKitsNumber",
      props.generalData !== undefined ? props.generalData.concededProfessionalKitsNumber : ""
    );
    handleKitsChange(props.generalData?.concededProfessionalKitsNumber);

    setApplicationStateGroup(props.generalData?.applicationStateGroupKey);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.generalData]);

  useEffect(() => {
    if (props.dataSetState)
      if (
        props.generalData !== undefined &&
        (props.generalData?.benefitKeyValue === BENEFIT.AVANCO ||
          props.generalData?.benefitKeyValue === BENEFIT.CAPACITA ||
          props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_WALLET)
      ) {
        props.setValue(
          "trainingCenterId",
          getSelectOptionsElementByCode(
            props.dataSetState[DATASET.SHAPE_SUBSITES],
            props.generalData !== undefined ? props.generalData.trainingCenterId?.toString() : ""
          )
        );
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.SHAPE_SUBSITES]])

  useEffect(() => {
    if (props.dataSetState)
      if (
        props.generalData !== undefined &&
        (props.generalData?.benefitKeyValue === BENEFIT.AVANCO ||
          props.generalData?.benefitKeyValue === BENEFIT.CAPACITA ||
          props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_WALLET)
      ) {
        props.setValue(
          "intendedCourseCode",
          getSelectOptionsElementByCode(
            props.dataSetState[DATASET.SHAPE_PROGRAMS],
            props.generalData !== undefined ? props.generalData.intendedCourseCode : ""
          )
        );
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.SHAPE_PROGRAMS]])

  useEffect(() => {
    if (props.dataSetState)
      if (props.generalData !== undefined && (props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_INTERNSHIP)) {
        if (props.dataSetState[DATASET.AVAILABLE_INTERNSHIP_OPPORTUNITIES])
          props.setValue(
            "internshipOpportunityId",
            getSelectOptionsElementByCode(
              props.dataSetState[DATASET.AVAILABLE_INTERNSHIP_OPPORTUNITIES],
              props.generalData.internshipOpportunityId ?? undefined
            )
          );
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.AVAILABLE_INTERNSHIP_OPPORTUNITIES]])

  useEffect(() => {
    if (props.dataSetState)
      if (props.generalData !== undefined && (props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_INTERNSHIP)) {
        if (props.dataSetState && props.dataSetState[DATASET.PROFESSIONAL_CATEGORY] && props.generalData.internshipAreaId) {
          props.setValue(
            "internshipAreaId",
            getSelectOptionsElementByCode(
              props.dataSetState[DATASET.PROFESSIONAL_CATEGORY],
              props.generalData.internshipAreaId
            )
          );
        }
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]])

  useEffect(() => {
    if (props.dataSetState)
      if (props.generalData !== undefined && (props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_INTERNSHIP)) {
        if (props.dataSetState && props.dataSetState[DATASET.PROVINCES] && props.generalData.provinceId) {
          props.setValue(
            "provinceId",
            getSelectOptionsElementByCode(
              props.dataSetState[DATASET.PROVINCES],
              props.generalData.provinceId
            )
          );
        }
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.PROVINCES]])

  useEffect(() => {
    if (props.dataSetState)
      if (
        props.generalData !== undefined &&
        (props.generalData?.benefitKeyValue === BENEFIT.MICROCREDIT ||
          props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT ||
          props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT_MICROCREDIT)
      ) {
        if (props.dataSetState && props.dataSetState[DATASET.BANK] && props.generalData.bankPreferenceId) {
          props.setValue(
            "bank",
            getSelectOptionsElementByCode(props.dataSetState[DATASET.BANK], props.generalData.bankPreferenceId)
          );
        }
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.BANK]]);

  useEffect (() => {
    props.trigger("bank");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nextApplicationState]);

  const handleKitsChange = (kits) => {
    if (!kits) {
      setKitsTotalValue(0);
    } else {
      var value = kits * (props.generalData?.kitUnitPrice ? props.generalData?.kitUnitPrice : 0);
      value = intl.formatNumber(value);
      setKitsTotalValue(value);
    }
  };

  const getAvailableInternshipOpportunitiesOnChange = () => {
    props.setValue("internshipOpportunityId", undefined)
    const province = props.getValues("provinceId")?.code;
    const internshipArea = props.getValues("internshipAreaId")?.code;
    props.getAvailableInternshipOpportunities(DATASET.AVAILABLE_INTERNSHIP_OPPORTUNITIES, province, internshipArea, INTERNSHIP_TYPE_KEY.PAPE);
  }

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader classes={{ title: classes.cardHeader }} title={intl.formatMessage(titles.Title_Admin_ApplicationDetail_ProgramSubprogramDataSection)}></CardHeader>
        <CardContent>
          {props.generalData?.parentApplicationCode && (
            <Grid container className={classes.alertGrid}>
              <Grid item>
                <Alert
                  severity="info"
                  variant="filled"
                  classes={{
                    icon: classes.alertIcon,
                    filledInfo: classes.alertFilledWarning,
                  }}
                >
                  {"Esta candidatura está relacionada com a candidatura nº "}
                  <Box
                    component={Link}
                    className={classes.alertLink}
                    to={"/admin/application-detail/" + props.generalData.parentApplicationCode}
                  >
                    <b>{props.generalData?.parentApplicationCode}</b>
                  </Box>
                </Alert>
              </Grid>
            </Grid>
          )}
          <Grid container style={{ alignItems: "flex-end" }} >
            <Grid item xs={12} md={3}>
              <FormGroup>
                <FormLabel>{intl.formatMessage(labels.Label_Admin_ProgramSubprogramDataSection_WhichProvinceField)}</FormLabel>
                {props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_INTERNSHIP ?
                  <>
                    <GenericFormSelect
                      control={control}
                      fieldName="provinceId"
                      selectOptions={
                        props.dataSetState[DATASET.PROVINCES] !== undefined
                          ? props.dataSetState[DATASET.PROVINCES]
                          : []
                      }
                      getValues={props.getValues}
                      required={true}
                      disabled={applicationStateGroup === APPLICATION_STATE_GROUP_KEY.CONCLUDED}
                      errors={props.errors}
                      classes={classes}
                      onOptionChange={getAvailableInternshipOpportunitiesOnChange}
                    />
                    {props.errors["provinceId"] !== undefined && (
                      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                        {props.errors["provinceId"].message}
                      </FormHelperText>
                    )}
                  </>
                  :
                  <OutlinedInput
                    name="provinceName"
                    value={props.generalData !== undefined ? props.generalData.provinceName : ""}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    disabled={true}
                    classes={{
                      notchedOutline: clsx({
                        [classes.borderWarning]: props.errors["provinceName"] !== undefined,
                      }),
                    }}
                  />
                }
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormGroup>
                <FormLabel>{intl.formatMessage(labels.Label_Admin_ProgramSubprogramDataSection_GovernmentProgramsField)}</FormLabel>
                <OutlinedInput
                  name="governmentProgramName"
                  data-testid="outlined-input-governmentProgramName"
                  value={props.generalData !== undefined ? props.generalData.governmentProgramName : ""}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  disabled={true}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: props.errors["governmentProgramName"] !== undefined,
                    }),
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormGroup>
                <FormLabel>{intl.formatMessage(labels.Label_Admin_ProgramSubprogramDataSection_ProgramsField)}</FormLabel>
                <OutlinedInput
                  name="programName"
                  data-testid="outlined-input-programName"
                  value={props.generalData !== undefined ? props.generalData.programName : ""}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  disabled={true}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: props.errors["programName"] !== undefined,
                    }),
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormGroup>
                <FormLabel>{intl.formatMessage(labels.Label_Admin_ProgramSubprogramDataSection_SubprogramsField)}</FormLabel>
                <OutlinedInput
                  name="subprogramName"
                  data-testid="outlined-input-subprogramName"
                  value={props.generalData !== undefined ? props.generalData.subprogramName : ""}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  disabled={true}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: props.errors["subprogramName"] !== undefined,
                    }),
                  }}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container style={{ alignItems: "flex-end" }} >
            <Grid item xs={12} md={4}>
              <FormGroup>
                <FormLabel>{intl.formatMessage(labels.Label_Admin_ProgramSubprogramDataSection_BenefitTypeField)}</FormLabel>
                <OutlinedInput
                  name="benefitTypeName"
                  data-testid="outlined-input-benefitTypeName"
                  value={props.generalData !== undefined ? props.generalData.benefitName : ""}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  disabled={true}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: props.errors["benefitTypeName"] !== undefined,
                    }),
                  }}
                />
              </FormGroup>
            </Grid>
            {(props.generalData?.benefitKeyValue === BENEFIT.MICROCREDIT || props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT_MICROCREDIT) && (
              <>
                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>{intl.formatMessage(labels.Label_Admin_ProgramSubprogramDataSection_MicrocreditAmountField)}</FormLabel>
                    <OutlinedInput
                      name="microcreditAmount"
                      value={props.generalData !== undefined ? props.generalData.microcreditAmount : 0}
                      inputProps={{ style: { textAlign: "right" } }}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      disabled={true}
                      endAdornment={<InputAdornment position="end">{UNITS.MONETARY}</InputAdornment>}
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["microcreditAmount"] !== undefined,
                        }),
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>{intl.formatMessage(labels.Label_Admin_ProgramSubprogramDataSection_ConcededMicrocreditAmountField)}</FormLabel>
                    <Controller
                      name="concededMicrocreditAmount"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: {
                          value: true,
                          message: "O campo é obrigatório",
                        },
                        pattern: {
                          value: REGEX_PATTERNS.MONETARY,
                          message: "Formato incorrecto",
                        },
                        validate: {
                          minValue: (value) => {
                            if (value === "0" || value === "0,00" || value === "0,0") return "O valor tem de ser maior que 0.";
                            return true;
                          },
                        },
                      }}
                      render={({ field: { onChange, name } }) => (
                        <OutlinedInput
                          name={name}
                          value={formattedValue}
                          onChange={(e) => {
                            onValChange(e.target.value, onChange);
                          }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          disabled={applicationStateGroup === APPLICATION_STATE_GROUP_KEY.CONCLUDED || props.generalData?.applicationStateId === APPLICATION_STATE_KEY.AWAITING_BANK_INFORMATION}
                          placeholder={MONETARYUNITPLACEHOLDER}
                          inputProps={{ style: { textAlign: "right" } }}
                          endAdornment={<InputAdornment position="end">{UNITS.MONETARY}</InputAdornment>}
                          classes={{
                            notchedOutline: clsx({
                              [classes.borderWarning]: props.errors["concededMicrocreditAmount"] !== undefined,
                            }),
                          }}
                        />
                      )}
                    />
                    {props.errors["concededMicrocreditAmount"] !== undefined && (
                      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                        {props.errors["concededMicrocreditAmount"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>
              </>
            )}
            {(props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT || props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT_MICROCREDIT) && (
              <>
                <Grid item xs={12} md={2}>
                  <FormGroup>
                    <FormLabel>{intl.formatMessage(labels.Label_Admin_ProgramSubprogramDataSection_ProfessionalKitsNumberField)}</FormLabel>
                    <OutlinedInput
                      name="professionalKitsNumber"
                      value={props.generalData !== undefined ? props.generalData.professionalKitsNumber : 0}
                      inputProps={{ style: { textAlign: "right" } }}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      disabled={true}
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["professionalKitsNumber"] !== undefined,
                        }),
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormGroup>
                    <FormLabel>{intl.formatMessage(labels.Label_Admin_ProgramSubprogramDataSection_ConcededProfessionalKitsNumberField)}</FormLabel>
                    <Controller
                      name="concededProfessionalKitsNumber"
                      control={control}
                      defaultValue=""
                      textAlign="right"
                      rules={{
                        required: {
                          value: true,
                          message: "O campo é obrigatório",
                        },
                        validate: {
                          minValue: (value) => {
                            return value > 0 || intl.formatMessage(messages.Message_Generic_ValueGreaterThanZeroField);
                          },
                        },
                      }}
                      render={({ field: { onChange, name, value } }) => (
                        <OutlinedInput
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            handleKitsChange(e.target.value);
                          }}
                          fullWidth
                          autoComplete="off"
                          type="number"
                          disabled={applicationStateGroup === APPLICATION_STATE_GROUP_KEY.CONCLUDED}
                          inputProps={{ style: { textAlign: "right" } }}
                          classes={{
                            notchedOutline: clsx({
                              [classes.borderWarning]: props.errors["concededProfessionalKitsNumber"] !== undefined,
                            }),
                          }}
                        />
                      )}
                    />
                    {props.errors["concededProfessionalKitsNumber"] !== undefined && (
                      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                        {props.errors["concededProfessionalKitsNumber"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormGroup>
                    <FormLabel>{intl.formatMessage(labels.Label_Admin_ProgramSubprogramDataSection_ProfessionalKitsValueField)}</FormLabel>
                    <OutlinedInput
                      name="professionalKitValue"
                      value={kitsTotalValue}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      disabled={true}
                      placeholder={MONETARYUNITPLACEHOLDER}
                      inputProps={{ style: { textAlign: "right" } }}
                      endAdornment={<InputAdornment position="end">{UNITS.MONETARY}</InputAdornment>}
                    />
                  </FormGroup>
                </Grid>
              </>
            )}

            {(
              props.generalData?.benefitKeyValue === BENEFIT.MICROCREDIT ||
              props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT ||
              props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT_MICROCREDIT) && (
              <Grid item xs={12} md={3}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(
                      labels.Label_Admin_ProgramSubprogramDataSection_Bank
                    )}
                  </FormLabel>
                  <GenericFormSelect
                    control={control}
                    fieldName="bank"
                    selectOptions={
                      props.dataSetState[DATASET.BANK] !== undefined
                        ? props.dataSetState[DATASET.BANK]
                        : []
                    }
                    disabled={applicationStateGroup === APPLICATION_STATE_GROUP_KEY.CONCLUDED || props.generalData?.applicationStateId === APPLICATION_STATE_KEY.AWAITING_BANK_INFORMATION}
                    getValues={props.getValues}
                    required={props.nextApplicationState === APPLICATION_STATE_KEY.AWAITING_BANK_INFORMATION}
                    requiredMessage={"O campo é obrigatório"}
                    initialLabel={intl.formatMessage(
                      labels.Label_Admin_ProgramSubprogramDataSection_Bank_Placeholder
                    )}
                    errors={props.errors}
                    classes={classes}
                  />
                  {props.errors["bank"] !== undefined && (
                    <FormHelperText
                      component={Box}
                      color={theme.palette.warning.main + "!important"}
                    >
                      {props.errors["bank"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
            )}

            {(props.generalData?.benefitKeyValue === BENEFIT.AVANCO ||
              props.generalData?.benefitKeyValue === BENEFIT.CAPACITA ||
              props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_WALLET) && (
                <>
                  <Grid item xs={12} md={4}>
                    <FormGroup>
                      <FormLabel>
                        {props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_WALLET
                          ? intl.formatMessage(labels.Label_Admin_ProgramSubprogramDataSection_IntendedWalletNameField)
                          : intl.formatMessage(labels.Label_Admin_ProgramSubprogramDataSection_IntendedCourseNameField)}
                      </FormLabel>
                      <OutlinedInput
                        name="intendedCourseName"
                        value={
                          props.generalData !== undefined &&
                            props.generalData !== [] &&
                            props.dataSetState[DATASET.SHAPE_PROGRAMS] !== undefined
                            ? props.dataSetState[DATASET.SHAPE_PROGRAMS].find(
                              (r) => r.code === props.generalData.intendedCourseCode
                            )?.label
                            : ""
                        }
                        fullWidth
                        autoComplete="off"
                        type="text"
                        disabled={true}
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: props.errors["intendedCourseName"] !== undefined,
                          }),
                        }}
                      />
                      <Controller
                        name="intendedCourseCode"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, name, value } }) => (
                          <InputBase name={name} value={value} onChange={onChange} type="hidden" />
                        )}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormGroup>
                      <FormLabel>{intl.formatMessage(labels.Label_Admin_ProgramSubprogramDataSection_TrainingCenterField)}</FormLabel>
                      <GenericFormSelect
                        control={control}
                        fieldName="trainingCenterId"
                        selectOptions={
                          props.dataSetState[DATASET.SHAPE_SUBSITES] !== undefined
                            ? props.dataSetState[DATASET.SHAPE_SUBSITES]
                            : []
                        }
                        getValues={props.getValues}
                        required={true}
                        disabled={applicationStateGroup === APPLICATION_STATE_GROUP_KEY.CONCLUDED}
                        errors={props.errors}
                        classes={classes}
                      />
                      {props.errors["trainingCenterId"] !== undefined && (
                        <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                          {props.errors["trainingCenterId"].message}
                        </FormHelperText>
                      )}
                    </FormGroup>
                  </Grid>
                </>
              )}
            {props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_INTERNSHIP && (
              <>
                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <FormLabel>{intl.formatMessage(labels.Label_Admin_ProgramSubprogramDataSection_InternshipAreaField)}</FormLabel>
                    <GenericFormSelect
                      control={control}
                      fieldName="internshipAreaId"
                      selectOptions={
                        props.dataSetState[DATASET.PROFESSIONAL_CATEGORY] !== undefined
                          ? props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]
                          : []
                      }
                      getValues={props.getValues}
                      required={true}
                      disabled={applicationStateGroup === APPLICATION_STATE_GROUP_KEY.CONCLUDED}
                      errors={props.errors}
                      classes={classes}
                      onOptionChange={getAvailableInternshipOpportunitiesOnChange}
                    />
                    {props.errors["internshipAreaId"] !== undefined && (
                      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                        {props.errors["internshipAreaId"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <FormLabel>{"Estágios Profissionais"}</FormLabel>
                    <GenericFormSelect
                      control={control}
                      fieldName="internshipOpportunityId"
                      selectOptions={
                        props.dataSetState[DATASET.AVAILABLE_INTERNSHIP_OPPORTUNITIES] !== undefined
                          ? props.dataSetState[DATASET.AVAILABLE_INTERNSHIP_OPPORTUNITIES]
                          : []
                      }
                      getValues={props.getValues}
                      required={applicationStateGroup === APPLICATION_STATE_GROUP_KEY.ANALYSIS}
                      disabled={applicationStateGroup === APPLICATION_STATE_GROUP_KEY.CONCLUDED}
                      errors={props.errors}
                      classes={classes}
                    />
                    {props.errors["internshipOpportunityId"] !== undefined && (
                      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                        {props.errors["internshipOpportunityId"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getShapePrograms: (target, name) => dispatch(getShapeProgramsAction(target, name)),
  getShapeSubsites: (offer, province, name) => dispatch(getShapeSubsitesAction(offer, province, name)),
  getAvailableInternshipOpportunities:
    (name, province, professionalCategory, internshipTypeId) => dispatch(getAvailableInternshipOpportunitiesAction(name, province, professionalCategory, internshipTypeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramSubprogramDataSection);
