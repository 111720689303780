import { React, useEffect, useState } from "react";
import clsx from "clsx";
import ReactDatetime from "react-datetime";
import { useIntl } from "react-intl";
import { messages, labels } from "resources/resources";
import { connect } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";
import "moment/locale/pt";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import GenericButton from "components/Buttons/GenericButton";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// @material-ui/icons components
import SearchIcon from "@material-ui/icons/Search";
import DateRange from "@material-ui/icons/DateRange";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic";
import "moment/locale/pt";
import moment from "moment";
import FormSelect from "components/FormFields/FormSelect";
import {
  getDataSetByNameAction,
  getWarehousesByProvinceAction,
  getCategoriesAction,
} from "redux/actions/datasetsAction";
import DropzoneField from "components/FormFields/DropzoneField";
import { getKitDeliveryApplicationDetailsAction } from "redux/actions/stockAction";
import { DATASET, UNITS } from "utils/consts";
import { getSelectOptionsElementByCode } from "utils/formSelect";
import { REGEX_PATTERNS } from "utils/consts"

const useStylesGeneric = makeStyles(componentStylesGeneric);

function GeneralData(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const { control } = useFormContext();
  const [tableState, setTableState] = useState({ tableBody: [] });
  const intl = useIntl();

  const handleValidDate = (date, onChange) => {
    if (date === "" || REGEX_PATTERNS.DATE.test(date)) {
      onChange(date);
    }
  };

  const tableHead = ["Especialidade", "Valor Uni.", "Descrição"];

  useEffect(() => {
    if (props.stockState.kitDeliveryDetails && props.stockState.kitDeliveryDetails?.categories) {
      setTableState({
        ...tableState,
        tableBody: props.stockState.kitDeliveryDetails.categories.map((c) => {
          return {
            category: c.categoryName,
            unitValue: (c.professionalKit?.unitPrice ?? "0") + " " + UNITS.MONETARY,
            description: c.professionalKit?.description,
          };
        }),
      });
    } else {
      props.setValue("refNumber", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.stockState.kitDeliveryDetails]);

  useEffect(() => {
    if (props.dataSetState[DATASET.PROVINCES] === undefined || props.dataSetState[DATASET.PROVINCES] === []) {
      props.getDataSet(DATASET.PROVINCES);
    }
    if (props.dataSetState[DATASET.CATEGORIES] === undefined || props.dataSetState[DATASET.CATEGORIES] === []) {
      props.getCategories(DATASET.CATEGORIES);
    }
    if (props.authState?.province) {
      props.getWarehousesByProvince(DATASET.WAREHOUSES, props.authState.province);
      var provinceList = props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : [];
      props.setValue("province", getSelectOptionsElementByCode(provinceList, props.authState.province));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const getKitDeliveryApplicationDetails = () => {
    const refNumber = props.getValues("refNumber");
    if (refNumber) props.getKitDeliveryApplicationDetails(refNumber);
    else {
      props.trigger("refNumber");
    }
  };

  return (
    <>
      <Container>
        <Box className={classes.modalHeader} marginBottom="47px!Important">
          Entrega de kit
          <Box marginTop="14px">
            <Box className={classes.subHeader}>
              Imprima o termo a assinar pelo beneficiário através do botão "Gerar Documento". Pesquise a referência
              pretendida para obter a informação a colocar no termo, com as informações referentes à candidatura. Após
              assinturado do beneficiário, carregue o termo no sistema.
            </Box>
          </Box>
        </Box>

        <Box style={{ textAlign: "center", marginBottom: "62px" }}>
          <GenericButton component="a" href="/TERMO DE ENTREGA DE KIT.doc" color={"tertiary"}>
            Gerar Documento
          </GenericButton>
        </Box>
        <Box marginLeft="15px" marginTop="30px">
          <Grid container>
            <Grid item xs={12} md={4}>
              <FormGroup>
                <FormLabel>Nº de Candidatura</FormLabel>
                <Controller
                  name="refNumber"
                  control={control}
                  component={Box}
                  defaultValue=""
                  rules={{
                    required: {
                      value: props.isEditable,
                      message: "O campo é obrigatório",
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      placeholder={"Pesquisar"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            style={{ padding: "0px" }}
                            aria-label="details"
                            onClick={() => {
                              getKitDeliveryApplicationDetails();
                            }}
                          >
                            <Box component={SearchIcon} width="9px!important" height="9px!important" />
                          </IconButton>
                        </InputAdornment>
                      }
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["refNumber"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {props.errors["refNumber"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["refNumber"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormGroup>
                <FormLabel>Nome</FormLabel>
                <Box className={classes.boxFill}>{props.stockState.kitDeliveryDetails?.candidate?.name}</Box>
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormGroup>
                <FormLabel>Nº BI / NIF</FormLabel>
                <Box className={classes.boxFill}>{props.stockState.kitDeliveryDetails?.candidate?.identityCardOrTaxIdNum}</Box>
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormGroup>
                <FormLabel>Nº de kits</FormLabel>
                <Box className={classes.boxFill}>{props.stockState.kitDeliveryDetails?.totalKits}</Box>
              </FormGroup>
            </Grid>
          </Grid>

          <Grid container>
            <TableContainer style={{ boxShadow: "0px 0px 32px rgba(136, 152, 170, 0.15)" }}>
              <Box component={Table} alignItems="center" marginBottom="0!important">
                <TableHead>
                  <TableRow>
                    {tableHead.map((prop, key) => (
                      <TableCell
                        key={key}
                        classes={{
                          root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                        }}
                      >
                        {prop}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableState?.tableBody.map((prop, key) => (
                    <TableRow key={key}>
                      <TableCell classes={{ root: classes.tableCellRoot }}>{prop.category}</TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>{prop.unitValue}</TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>{prop.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Box>
            </TableContainer>
          </Grid>
          <Box marginTop="30px">
            <Grid container>
              <Grid item xs={12} md={8}>
                <FormGroup>
                  <FormLabel>Faça upload do documento assinado</FormLabel>
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box className={classes.fileMaxSizeLabel}>Ficheiro .pdf até 2Mb</Box>
              </Grid>
            </Grid>
            <Box>
              <Box>
                <DropzoneField
                  name={"fileUpload"}
                  multiple={true}
                  label={intl.formatMessage(labels.Label_Generic_Upload_Document)}
                  register={props.register}
                  errors={props.errors}
                  required={true}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  setValue={props.setValue}
                  getValues={props.getValues}
                  trigger={props.trigger}
                  maxNumberOfFiles={1}
                  accept={".pdf"}
                  maxFileSize={2}
                  showFileNameOnPreview={true}
                />
              </Box>
              <Box></Box>
            </Box>
          </Box>
          <Box marginTop="20px">
            <Grid container>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel>Data de Entrega</FormLabel>
                  <Controller
                    name="deliverDate"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "O campo é obrigatório",
                      },
                      validate: {
                        maxDate: (value) => {
                          return value <= moment().startOf("day") || "A data não pode ser superior à data atual";
                        },
                      },
                    }}
                    render={({ field: { onChange, name, value } }) => (
                      <ReactDatetime
                        name={name}
                        value={value}
                        onChange={onChange}
                        locale="pt"
                        dateFormat={"DD/MM/YYYY"}
                        timeFormat={false}
                        isValidDate={(currentDate, _) => {
                          return currentDate <= moment().startOf("day");
                        }}
                        inputProps={{
                          placeholder: "DD/MM/AAAA",
                          className: "",
                        }}
                        renderInput={(dateInputProps, open) => (
                          <OutlinedInput
                            onFocus={open}
                            {...dateInputProps}
                            onChange={(e) => {
                              handleValidDate(e.target.value, dateInputProps.onChange);
                            }}
                            fullWidth
                            startAdornment={
                              <InputAdornment position="start">
                                <Box component={DateRange} marginRight=".2rem" color={theme.palette.primary.main} />
                              </InputAdornment>
                            }
                            classes={{
                              notchedOutline: clsx({
                                [classes.borderWarning]: props.errors["deliverDate"] !== undefined,
                              }),
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  {props.errors["deliverDate"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {props.errors["deliverDate"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel>Província</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="province"
                    selectOptions={props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : []}
                    loadChildrenDataSet={props.getWarehousesByProvince}
                    childrenDataSet={DATASET.WAREHOUSES}
                    childrenSelect="warehouseId"
                    setValue={props.setValue}
                    getValues={props.getValues}
                    required={props.isEditable}
                    requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                    errors={props.errors}
                    classes={classes}
                  />
                  {props.errors["province"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {props.errors["province"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel>Armazém</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="warehouseId"
                    selectOptions={props.dataSetState[DATASET.WAREHOUSES] ? props.dataSetState[DATASET.WAREHOUSES] : []}
                    disabled={!props.getValues("province")}
                    getValues={props.getValues}
                    required={props.isEditable}
                    requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                    errors={props.errors}
                    classes={classes}
                  />
                  {props.errors["warehouseId"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {props.errors["warehouseId"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={12}>
                <FormGroup>
                  <FormLabel>Observações</FormLabel>
                  <Controller
                    name="notes"
                    control={control}
                    component={Box}
                    defaultValue=""
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={onChange}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder={"Escreva aqui as suas observações"}
                        multiline={true}
                        rows={3}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getWarehousesByProvince: (name, province, provinceKeyValue) =>
    dispatch(getWarehousesByProvinceAction(name, province, provinceKeyValue)),
  getCategories: (name) => dispatch(getCategoriesAction(name)),
  getKitDeliveryApplicationDetails: (applicationCode) =>
    dispatch(getKitDeliveryApplicationDetailsAction(applicationCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralData);
