import { login } from "../../../crud/auth.crud";
import { b64ToImage } from "utils/converters";
import { DIALOG } from "utils/actionsConsts";

const loginAction = (email, password) => async (dispatch) => {
  dispatch({
    type: "login_loading",
    payload: true,
  });
  login(email, password)
    .then((res) => {
      dispatch({
        type: "login",
        payload: {
          email: email,
          succeeded: res.data.succeeded,
          token: res.data.jwtToken,
          refreshToken: res.data.refreshToken,
          name: res.data.name,
          lastName: res.data.lastName,
          province: res.data.province,
          photo: b64ToImage(res.data.photo),
          operationsList: res.data.operationsList,
          userPermissionSettings: res.data.userPermissionSettings,
          mustConfirmEmail: res.data.mustConfirmEmail,
          lastLoginDate : res.data.lastLoginDate
        },
      });
      if (res.data.mustConfirmEmail) {
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: false,
              message: "Conta em validação, aguarda confirmação de e-mail.",
            },
          },
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: "login",
        payload: false,
      });
      console.log("error", error);
      dispatch({
        type: "login_loading",
        payload: false,
      });
    });
};

export default loginAction;
