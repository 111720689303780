import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ArrayUtils } from "utils/array_utils";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Pagination from "@material-ui/lab/Pagination";
import getFilteredBackOfficeUsersActions from "redux/actions/usersAction";
// @material-ui/icons components
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// core componentssrc
import componentStylesGeneric from "assets/theme/views/admin/generic";
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const tableHead = ["Nome", "Província", "Município", "Estado", "E-mail", "Função"];

function ResultSectionBo(props) {
  const theme = useTheme();
  const classes = useStylesGeneric();

  const [boUserList, setBoUserList] = useState([]);

  useEffect(() => {
    if (!ArrayUtils.NullOrEmpty(props.usersState.result)) {
      setBoUserList(props.usersState.result.data);
    } else {
      setBoUserList([]);
    }
  }, [props.usersState.result]);

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          title: classes.cardHeader,
          action: classes.cardHeaderAction,
        }}
        title={
          <div>
            <div className={classes.sideBySideDiv}>Lista de Utilizadores Backoffice</div>
            <div className={classes.sideBySideDiv + " " + classes.cardElementsCount}>
              {"("}
              {props.usersState?.total_records}
              {")"}
            </div>
          </div>
        }
      />
      <TableContainer>
        <Box component={Table} alignItems="center" marginBottom="0!important">
          <TableHead>
            <TableRow>
              {tableHead.map((prop, key) => (
                <TableCell
                  key={key}
                  classes={{
                    root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                  }}
                >
                  {prop}
                </TableCell>
              ))}
              <TableCell
                key="detailsCol"
                classes={{
                  root:
                    classes.tableCellRoot + " " + classes.tableCellRootHead + " " + classes.tableCellHeadStickyRight,
                }}
              >
                Detalhes
              </TableCell>
            </TableRow>
          </TableHead>

          {boUserList !== undefined && boUserList.length > 0 && (
            <TableBody>
              {boUserList?.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell
                    classes={{ root: classes.tableRowEllipsis + " " + classes.tableCellRoot }}
                    title={prop.nameOfuser + " " + prop.surname}
                  >
                    {prop.nameOfuser + " " + prop.surname}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.provinceName}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.municipalityName}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.active ? "Activo" : "Inactivo"}</TableCell>
                  <TableCell
                    classes={{ root: classes.tableRowEllipsis + " " + classes.tableCellRoot }}
                    title={prop.email}
                  >
                    {prop.email}
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableRowEllipsis + " " + classes.tableCellRoot }}
                    title={prop.profile ?? ""}
                  >
                    {prop.profile}
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight,
                    }}
                  >
                    <Tooltip title="Detalhes">
                      <IconButton aria-label="details" component={Link} to={`/admin/backofficeusers-edit/${prop.id}`}>
                        <Box
                          component={ArrowForwardIosIcon}
                          width="12px!important"
                          height="12px!important"
                          color={theme.palette.primary.main}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Box>

        {(boUserList == null || boUserList.length === 0) && !props.usersState.backoffice_users_search_loading && (
          <Box className={classes.noResultsInfoInTable}>Não existem resultados disponíveis.</Box>
        )}
      </TableContainer>

      <TableBackdrop open={props.usersState.backoffice_users_search_loading} />

      <Pagination
        className={classes.alignPagination}
        count={props.usersState?.total_pages}
        page={props.page}
        onChange={props.handleChangePage}
        color="primary"
      ></Pagination>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getUsers: (name) => dispatch(getFilteredBackOfficeUsersActions(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultSectionBo);
