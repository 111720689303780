import { React, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
//custom components
import SchedulesSubmit from "./SchedulesSubmit";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import useScrollDirection from "hooks/useScrollDirection";
import { updateSchedulingAction } from "redux/actions/schedulingAction";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function SchedulesDetails(props) {
  const classes = useStylesGeneric();
  // const intl = useIntl();
  const history = useHistory();
  const scrollDirection = useScrollDirection();

  const [scheduleNumber, setScheduleNumber] = useState("");
  const [applicationNumber, setApplicationNumber] = useState("");

  const methods = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    setScheduleNumber(props.match.params.scheduleNumber);
  }, [props.match.params.scheduleNumber]);

  useEffect(() => {
    if (props.schedulingState?.schedule_detail?.applicationNumber)
      setApplicationNumber(props.schedulingState?.schedule_detail?.applicationNumber);
    else setApplicationNumber(props.schedulingState?.schedule_detail?.nameOfUser);
  }, [props.schedulingState.schedule_detail]);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("schedulingNumber", scheduleNumber);
    formData.append("applicationNumber", scheduleNumber);
    formData.append("report", data.scheduleReport);
    if (data.fileUpload !== undefined) {
      formData.append("reportDocument", data.fileUpload[0]);
    }

    props.updateScheduling(formData, history.goBack);
  };

  return (
    <>
      {scrollDirection === "up" && (
        <div className={classes.breadcrumbs}>
          <LocationHeader
            section={"Detalhes do Registo"}
            subsection={"Agendamentos"}
            subsectionLink={"/admin/schedules-management"}
          />
        </div>
      )}

      <GenericBackdrop open={props.schedulingState.schedule_detail_loading} />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Container maxWidth={false} component={Box} classes={{ root: classes.containerRootAfterBreadcrumbs }}>
            <PageTitleHeader title={applicationNumber} />

            <Container maxWidth={false} component={Box}>
              <SchedulesSubmit
                scheduleId={scheduleNumber}
                control={methods.control}
                getValues={methods.getValues}
                errors={methods.formState.errors}
                trigger={methods.trigger}
                isEditable={true}
                loadData={false}
                setValue={methods.setValue}
                register={methods.register}
              ></SchedulesSubmit>
            </Container>
          </Container>
        </form>
      </FormProvider>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  updateScheduling: (updateSchedulingCommand, onSuccess) =>
    dispatch(updateSchedulingAction(updateSchedulingCommand, onSuccess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SchedulesDetails);
