import { OPPORTUNITIES_ACTIONS } from "utils/actionsConsts";

const opportunitiesReducer = (
  state = {
    opportunitySubmitLoading: false,
    searchOpportunities_loading: false,
    get_opportunity_details_loading: false,
    update_opportunity_state_loading: false,
    candidateInfo_loading: false,
    opportunityUsers_loading: false,
    opportunityUpdateLoading: false,
    approveCandidate_loading: false,
    rejectCandidate_loading: false,
    candidateWithdraw_loading: false,
    possibleCandidates_loading: false,
    possibleCandidates_result: [],
    possibleCandidates_total_records: 0,
    possibleCandidates_total_pages: 0,
    addOpportunityCandidatesList_loading: false,
    getAllOpportunityApplications_loading: false,
    isOpportunityApplication: false,
    getAllOpportunityApplicationsFollowUp_loading: false,
    getOpportunityApplicationsFollowUpDetails_loading: false,
    update_opportunity_followup_period_loading: false,
    getFeedbackReportDetails_loading: false,
    validateFeedbackReport_loading: false,
    createAdHocFeedbackReportLoading: false,
    getFilteredAvailableOpportunitiesForUser_loading: false,
    createApplicationsForUserLoading: false,
    total_records: 0,
    total_pages: 0,
  },
  action
) => {
  switch (action.type) {
    case OPPORTUNITIES_ACTIONS.GET_FILTERED_OPPORTUNITIES_ACTIONS:
      return {
        ...state,
        result: action.payload,
        total_records: action.payload.total_records,
        total_pages: action.payload.total_pages,
      };
    case OPPORTUNITIES_ACTIONS.GET_FILTERED_OPPORTUNITIES_ACTIONS_LOADING:
      return { ...state, searchOpportunities_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_DETAILS_ACTIONS_LOADING:
      return { ...state, get_opportunity_details_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.UPDATE_OPPORTUNITY_STATE_ACTIONS_LOADING:
      return { ...state, update_opportunity_state_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_DETAILS_ACTIONS:
      return { ...state, opportunityDetailsData: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_CANDIDATE_INFORMATION:
      return { ...state, candidateInfo: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_CANDIDATE_INFORMATION_LOADING:
      return { ...state, candidateInfo_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_USERS_ACTIONS:
      return {
        ...state,
        result: action.payload,
        total_records: action.payload.total_records,
        total_pages: action.payload.total_pages,
      };
    case OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_USERS_ACTIONS_LOADING:
      return { ...state, opportunityUsers_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.UPDATE:
      return {
        ...state,
        opportunityUpdateSucceeded: action.payload,
      };
    case OPPORTUNITIES_ACTIONS.GET_UPDATE_LOADING:
      return { ...state, opportunityUpdateLoading: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_SUBMIT_LOADING:
      return { ...state, opportunitySubmitLoading: action.payload };
    case OPPORTUNITIES_ACTIONS.APPROVE_CANDIDATE_LOADING:
      return { ...state, approveCandidate_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.REJECT_CANDIDATE_LOADING:
      return { ...state, rejectCandidate_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.CANDIDATE_WITHDRAW_LOADING:
      return { ...state, candidateWithdraw_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_FILTERED_POSSIBLE_OPPORTUNITY_CANDIDATES_LOADING:
      return { ...state, possibleCandidates_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_FILTERED_POSSIBLE_OPPORTUNITY_CANDIDATES:
      return {
        ...state,
        possibleCandidates_result: action.payload,
        possibleCandidates_total_records: action.payload.total_records,
        possibleCandidates_total_pages: action.payload.total_pages,
      };
    case OPPORTUNITIES_ACTIONS.ADD_OPPORTUNITY_CANDIDATES_LIST_LOADING:
      return { ...state, addOpportunityCandidatesList_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_ALL_OPPORTUNITY_APPLICATIONS:
      return {
        ...state,
        opportunityApplications_result: action.payload,
        opportunityApplications_total_records: action.payload.total_records,
        opportunityApplications_total_pages: action.payload.total_pages,
      };
    case OPPORTUNITIES_ACTIONS.GET_ALL_OPPORTUNITY_APPLICATIONS_LOADING:
      return {
        ...state,
        getAllOpportunityApplications_loading: action.payload,
      };
    case OPPORTUNITIES_ACTIONS.GET_IS_OPPORTUNITY_APPLICATION:
      return { ...state, isOpportunityApplication: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_ALL_OPPORTUNITY_APPLICATIONS_FOLLOWUP:
      return {
        ...state,
        opportunityApplicationsFollowUp_result: action.payload,
        opportunityApplicationsFollowUp_total_records:
          action.payload.total_records,
        opportunityApplicationsFollowUp_total_pages: action.payload.total_pages,
      };
    case OPPORTUNITIES_ACTIONS.GET_ALL_OPPORTUNITY_APPLICATIONS_FOLLOWUP_LOADING:
      return {
        ...state,
        getAllOpportunityApplicationsFollowUp_loading: action.payload,
      };
    case OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_DETAILS:
      return {
        ...state,
        opportunityApplicationsFollowUpDetails_result: action.payload,
        opportunityApplicationsFollowUpDetails_total_records:
          action.payload.total_records,
        opportunityApplicationsFollowUpDetails_total_pages:
          action.payload.total_pages,
      };
    case OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_DETAILS_LOADING:
      return {
        ...state,
        getOpportunityApplicationsFollowUpDetails_loading: action.payload,
      };
    case OPPORTUNITIES_ACTIONS.UPDATE_OPPORTUNITY_FOLLOWUP_PERIOD_LOADING:
      return {
        ...state,
        update_opportunity_followup_period_loading: action.payload,
      };
    case OPPORTUNITIES_ACTIONS.GET_FEEDBACK_REPORT_DETAILS:
      return {
        ...state,
        feedbackReportDetails_result: action.payload,
        feedbackReportDetails_total_records: action.payload.total_records,
        feedbackReportDetails_total_pages: action.payload.total_pages,
      };
    case OPPORTUNITIES_ACTIONS.GET_FEEDBACK_REPORT_DETAILS_LOADING:
      return { ...state, getFeedbackReportDetails_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.VALIDATE_FEEDBACK_REPORT_LOADING:
      return { ...state, validateFeedbackReportLoading: action.payload };
    case OPPORTUNITIES_ACTIONS.CREATE_ADHOC_FEEDBACK_REPORT_LOADING:
      return { ...state, createAdHocFeedbackReportLoading: action.payload };
    case OPPORTUNITIES_ACTIONS.CLEAR_VALIDATE_FEEDBACK_REPORT_LOADING:
      return { ...state, validateFeedbackReportLoading: false };
    case OPPORTUNITIES_ACTIONS.GET_FILTERED_AVAILABLE_OPPORTUNITIES_FOR_USER:
      return {
        ...state,
        getFilteredAvailableOpportunitiesForUser_result: action.payload,
      };
    case OPPORTUNITIES_ACTIONS.GET_FILTERED_AVAILABLE_OPPORTUNITIES_FOR_USER_LOADING:
      return {
        ...state,
        getFilteredAvailableOpportunitiesForUser_loading: action.payload,
      };
    case OPPORTUNITIES_ACTIONS.CREATE_OPPORTUNITY_APPLICATIONS_FOR_USER_LOADING:
      return { ...state, createApplicationsForUserLoading: action.payload };
    default:
      return state;
  }
};

export default opportunitiesReducer;
