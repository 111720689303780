import React from "react";
import { connect } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
//@material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
//@material-ui/icons
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
//custom components
import GenericDialog from "components/Dialogs/GenericDialogV2";
import GenericButton from "components/Buttons/GenericButton";
import RejectSection from "views/admin/Loans/Sections/RejectSection";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { rejectLoanAction } from "redux/actions/loansAction";
import { DATASET } from "utils/consts";
//styles
import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function RejectDialog(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = methods;

  //GET MOTIVES
  React.useEffect(() => {
    if (
      props.dataSetState[DATASET.LOAN_REJECT_MOTIVES] === undefined ||
      props.dataSetState[DATASET.LOAN_REJECT_MOTIVES]?.length === 0
    ) {
      props.getLoanRejectMotives();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitReject = (data) => {
    props.rejectLoan(
      {
        id: props.loanNumber,
        rejectMotiveId: data.motive.code,
        rejectMotiveObservations: data.notes,
      },
      function () {
        props.onClose();
      },
      function () {
        props.history.goBack();
      }
    );
  };

  return (
    <GenericDialog maxWidth={props.maxWidth} name={props.name} open={props.open} onClose={props.onClose}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitReject)}>
          <Box className={classes.modalHelpIconBox}>
            <HelpOutlineIcon className={classes.modalHelpIconRoot} />
          </Box>

          <RejectSection
            getValues={getValues}
            isEditable={true}
            errors={errors}
            classes={classes}
            setValue={setValue}
            theme={theme}
            trigger={trigger}
          ></RejectSection>
          <Box textAlign="center" marginBottom="30px" marginTop="30px">
            <GenericButton
              typeSubmit={true}
              color="primary"
              loading={props.loansState.reject_loan_loading}
            >
              Submeter
            </GenericButton>
          </Box>
        </form>
      </FormProvider>
    </GenericDialog>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  rejectLoan: (loan, onSuccess, okAction) => dispatch(rejectLoanAction(loan, onSuccess, okAction)),
  getLoanRejectMotives: () => dispatch(getDataSetByNameAction(DATASET.LOAN_REJECT_MOTIVES)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RejectDialog);
