import { React } from "react";
import { connect } from "react-redux";

// @material-ui/core components
import { Container } from "@material-ui/core";

//import custom components
import FollowUpForms from "../Sections/FollowUpFeedbackReports";

function FollowUpFormsTab(props) {
  return (
    <>
      <Container maxWidth={false}>
        <FollowUpForms
          opportunityApplicationFollowUpDetails={props.opportunityApplicationFollowUpDetails}
          handleRefreshPage={props.handleRefreshPage}
        />
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(FollowUpFormsTab);
