import boxShadows from "assets/theme/box-shadow.js";
import hexToRgb from "assets/theme/hex-to-rgb.js";

const componentStyles = (theme) => ({
  appBarRoot: {
    position: "fixed",
    height: "72px",
    borderBottom:
      "1px solid rgba(" + hexToRgb(theme.palette.white.main) + ", 0.08)",
    backgroundColor: theme.palette.white.main,
    zIndex: "1299",
    boxShadow: "0px -5px 15px black",
  },
  brandTitle: {
    textTransform: "uppercase",
    margin: "0",
    color: theme.palette.white.main,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  searchBoxShow: {
    display: "flex!important",
    marginRight: "0",
    "& div:first-of-type": {
      flex: 1,
    },
  },
  searchBox: {
    borderColor: theme.palette.adminNavbarSearch.main,
    borderRadius: "2rem",
    border: "2px solid",
    backgroundColor: "rgba(" + hexToRgb(theme.palette.white.main) + ",0.9)",
    boxShadow: boxShadows.inputBoxShadow,
    transition: "box-shadow .15s ease",
    [theme.breakpoints.down("xs")]: {
      display: "none",
      width: "100%",
    },
  },
  searchIcon: {
    color: theme.palette.gray[800],
    marginRight: "0.5rem",
    marginLeft: "1rem",
  },
  searchClose: {
    color: theme.palette.gray[800],
    marginRight: "1rem",
    marginLeft: "-.5rem",
  },
  searchInput: {
    color: theme.palette.gray[900],
    width: "270px",
    backgroundColor: "initial",
    border: 0,
    boxShadow: "none",
    padding: "0",
  },
  containerRoot: {
    [theme.breakpoints.up("xl")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  marginLeftAuto: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
    },
  },
  marginLeftNone: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "unset",
    },
  },
  displayNone: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  menuItems: {
    widht: "795px",
    height: "28px",
    borderRadius: "0px",
    display: "flex",
    alignItems: "center",
    padding: "0px",
    position: "absolute",
    left: "23.71%",
    //right: "2.08%",
    top: "25.28px",
    bottom: "95.21%",
    "&. MuiPaper-root": {
      width: "700px",
      height: "500px",
    },
    "& .MuiDialog-paper": {
      width: "700px",
      height: "500px",
    },
    "& .MuiDialog-paperScrollPaper": {
      width: "700px",
      height: "500px",
    },
    "& .MuiDialog-paperWidthSm": {
      width: "700px",
      height: "500px",
    },
  },
  listItemRoot: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "19.07px",
    paddingLeft: "0.5rem",
    paddingRight: "0.25rem",
    paddingTop: ".625rem",
    paddingBottom: ".625rem",
    transition: "all .15s linear",
    color: theme.palette.gray[900],
    margin: "0",
    "& i": {
      marginRight: "0.25rem",
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: "1.5rem",
      paddingLeft: "0",
      paddingRight: "0.5rem",
      paddingTop: "1rem",
      paddingBottom: "1rem",
      justifyContent: "center",
      "&:hover": {
        fontWeight: "900",
        color: theme.palette.primary.greyBlue,
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.greyBlue,
        },
      },
      "&:active": {
        color: theme.palette.primary.darkBlue,
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.darkBlue,
        },
      },
    },
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
      paddingTop: "1rem",
      paddingBottom: "1rem",
      justifyContent: "center",
      "&:hover": {
        fontWeight: "900",
        color: theme.palette.primary.greyBlue,
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.greyBlue,
        },
      },
      "&:active": {
        color: theme.palette.primary.darkBlue,
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.darkBlue,
        },
      },
    },
  },
  loginButton: {
    left: "0%",
    right: "0%",
    top: "0%",
    bottom: "0%",
    width: "142px",
    height: "43px",
    
    fontStyle: " normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    // color: theme.palette.info.main,
    // background: theme.palette.white.main,
    color: theme.palette.white.main,
    background: theme.palette.info.grey,
    boxShadow: "0px 0px 32px rgba(136, 152, 170, 0.15)",
    "&:hover": {
      color: theme.palette.gray[900],
      borderColor: theme.palette.white.main + "!important",
      backgroundColor: theme.palette.white.main,
    },
  },
  line: {
    width: "42px",
    height: "0px",
    left: "1050px",
    top: "50px",
    border: "1px solid rgba(255, 255, 255, 0.5)",
    transform: "rotate(90deg)",
    backgroundColor: theme.palette.primary.mediumBlue,
  },
  dropIcon: {
    minWidth: "0px",
  },
  box: {
    position: "absolute",
    height: "72px",
    width: "180px",
    color: theme.palette.white.main,
    background: theme.palette.info.grey,
    left: "0%",
    top: "0%",
  },
  cardRoot: {
    width: "718px",
    height: "571px",
    boxShadow: boxShadows.boxShadow + "!important",
    border: "0!important",
    backgroundColor: theme.palette.gray[600],
  },
  cardHeader: {
    backgroundColor: "initial",
  },
  cardContent: {
    width: "718px",
    height: "571px",
    [theme.breakpoints.up("md")]: {
      padding: "3rem",
    },
  },
  buttonImg: {
    verticalAlign: "middle",
    borderStyle: "none",
  },
  buttonRoot: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.main,
    boxShadow: boxShadows.buttonBoxShadowNeutral,
    borderColor: theme.palette.white.main + "!important",
    "&:hover": {
      color: theme.palette.gray[900],
      borderColor: theme.palette.white.main + "!important",
      backgroundColor: theme.palette.white.main,
    },
  },
  boxHeader: {
    color: theme.palette.primary.mediumBlue,
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "19px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  recoverButton: {
    width: "200px",
    height: "50px",
    marginTop: "58px",
    marginBottom: "-50px",
    color: theme.palette.white.main,
    background: theme.palette.info.grey,
    boxShadow: "0px 0px 32px rgba(136, 152, 170, 0.15)",
  },
  formControlLabelRoot: {
    position: "relative",
    display: "flex",
    minHeight: "1.5rem",
    WebkitPrintColorAdjust: "exact",
  },
  formControlLabelLabel: {
    cursor: "pointer",
    fontSize: ".875rem",
    position: "relative",
    verticalAlign: "top",
    display: "inline-block",
    color: theme.palette.gray[600],
  },
  footerLinks: {
    color: theme.palette.gray[400],
    textDecoration: "none",
    fontSize: "80%",
  },
  modalSentRecover: {
    maxWidth: "718px",
    width: "718px",
    height: "571px",
    position: "absolute",
    marginLeft: "25.07%",
    marginRight: "25.07%",
    marginBottom: "31.35%",
  },
  modalDeliverKit: {
    width: "80%",
    position: "relative",
    marginLeft: "10.07%",
    marginRight: "10.07%",
    marginTop: "0%",
  },
  modalWarehouse: {
    maxWidth: "718px",
    width: "718px",
    position: "relative",
    marginLeft: "25.07%",
    marginRight: "25.07%",
    marginTop: "0%",
  },
  modalRecoverSentHeader: {
    width: "527px",
    marginTop: "100px",
    marginLeft: "-5%",
    
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "47px",
    letterSpacing: "0em",
    textAlign: "center",
    color: theme.palette.info.grey,
  },
  modalRecoverSentText: {
    marginTop: "25px",
    
    fontSize: "18px",
    fontStyle: "normal",
    lineHeight: "21px",
    letterSpacing: "0em",
    textAlign: "center",
    color: theme.palette.info.grey,
  },
  modalCloseButton: {
    marginLeft: "90%",
  },
  elipse: {
    widht: "90px",
    height: "90px",
    position: "absolute",
    left: "41.41%",
    right: "41.41%",
    top: "0.33%",
    bottom: "50.64%",
    borderRadius: "200px",
    border: "4px solid " + theme.palette.gray[300],
    boxSizing: "border-box",
  },
  logoInfo: {
    position: "absolute",
    right: "49%",
    top: "-25%",
    bottom: "57.64%",
    
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "60px",
    lineHeight: "82px",
    /* identical to box height */
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.gray[300],
  },
  dropMenu: {
    zIndex: "1000"
  },
  dropItems: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "220%",
    color: theme.palette.gray[900],
    "&:hover": {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.primary.greyBlue,
      fontWeight: "900"
    },
    "&:active": {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.primary.darkBlue,
      fontWeight: "900"
    },
  },
  modalHeader: {
    
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "30px",
    lineHeight: "35px%",
    textAlign: "center",
    color: theme.palette.primary.mediumBlue,
  },
  modalTitles: {
    
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    color: theme.palette.primary.mediumBlue,
    display: "flex",
    alignItems: "center",
  },
  modalSubmitButton: {
    marginLeft: "230px",
    marginTop: "100px",
    height: "43px",
    width: "201px",
    backgroundColor: theme.palette.primary.mediumBlue,
    color: theme.palette.white.main,
    boxShadow: boxShadows.buttonBoxShadowNeutral,
    "&:hover": {
      backgroundColor: theme.palette.primary.mediumBlue,
    },
  },
  modalWarehouseSubmitButton: {
    marginLeft: "230px",
    marginBottom: "20px",
    height: "43px",
    width: "201px",
    backgroundColor: theme.palette.primary.mediumBlue,
    color: theme.palette.white.main,
    boxShadow: boxShadows.buttonBoxShadowNeutral,
    "&:hover": {
      backgroundColor: theme.palette.primary.mediumBlue,
    },
  },
  modalResult: {
    position: "absolute",
    top: "0%",
    bottom: "-11.46%",
    backgroundColor: theme.palette.white.main,
    borderRadius: "5px",
    width: "512px",
    height: "350px",
    marginTop: "150px"
  },
  modalResultClose: {
    marginLeft: "90%",
  },
  modalResultHeader: {
    
    fontStyle: "normal",
    lineHeight: "33px",
    fontSize: "24px",
    fontWeight: "600",
    textAlign: "center",
    marginBottom: "1.5rem",
    marginTop: "5rem",
    color: theme.palette.dark.main,
  },
  modalResultIcon: {
    marginRight: "8px",
    color: theme.palette.success.main,
    width: "88px",
    height: "88px",
    /* align-self: center; */
    position: "absolute",
    left: "41.41%",
    right: "41.41%",
    top: "14.33%",
    bottom: "57.64%",
    boxSizing: "border-box",
  },
  cardContentKitCharacterizationResult: {
    [theme.breakpoints.up("md")]: {
      padding: "3rem",
    },
  },
  modalErrorResultIcon: {
    marginRight: "8px",
    color: theme.palette.error.main,
    width: "88px",
    height: "88px",
    /* align-self: center; */
    position: "absolute",
    left: "41.41%",
    right: "41.41%",
    top: "14.33%",
    bottom: "57.64%",
    boxSizing: "border-box",
  },
  modalButton: {
    background:
      "linear-gradient(87deg," +
      theme.palette.info.main +
      "," +
      theme.palette.primary.mediumBlue +
      ")",
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    alignItems: "center",
  },
  modalOkButton: {
    marginLeft: "173px",
    background:
      "linear-gradient(87deg," +
      theme.palette.info.main +
      "," +
      theme.palette.primary.mediumBlue +
      ")",
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    
  },
  logo: {
    width: "120px",
    height: "auto",
    marginRight: "0.25rem",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0.5rem",
    },
  },
  centeredButton: {
    marginRight: "auto", 
    marginLeft: "auto", 
    display: "block",
  },
});

export default componentStyles;
