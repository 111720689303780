import React from "react";
//redux
import { connect } from "react-redux";
import { registerStockInWarehouseAction } from "redux/actions/stockAction";
import { useForm, FormProvider } from "react-hook-form";
//intl
import { useIntl } from "react-intl";
import { labels } from "resources/resources";
// @material-ui/core components
import GenericButton from "components/Buttons/GenericButton";
import Box from "@material-ui/core/Box";
//import custom components
import GeneralData from "./GeneralData";
import registerStockInWarehouseMap from "mappers/registerStockInWarehouseMap";

function RegisterStockInWarehouse(props) {
  const intl = useIntl();

  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
      props.registerStockInWarehouse(registerStockInWarehouseMap(data));
      props.closeModal();
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <GeneralData getValues={getValues} errors={errors} setValue={setValue} isEditable={true} />
          <Box textAlign="center" marginTop="15px" marginBottom="15px">
            <GenericButton typeSubmit={true} color="primary">
              {intl.formatMessage(labels.Label_Generic_Submit_Button)}
            </GenericButton>
          </Box>
        </form>
      </FormProvider>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  registerStockInWarehouse: (formData) => dispatch(registerStockInWarehouseAction(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterStockInWarehouse);
