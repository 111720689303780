export function searchProfessionalKitsMap(formData) {
  if (formData !== null && formData !== undefined)
    return {
      filter: {
        categoryId: formData.categoryId ? formData.categoryId.code : undefined,
        active: formData.statusId ? formData.statusId.code : undefined,
        professionalKitId : formData.professionalKitId ? formData.professionalKitId :undefined
      },
    };
  return formData;
}

export default searchProfessionalKitsMap;
