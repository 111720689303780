import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getDashboardStockInfoAction } from "redux/actions/dashboardAction";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import LinkMaterialUi from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
// @material-ui/lab components
// @material-ui/icons components
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// core components
import componentStyles from "assets/theme/components/cards/dashboard/card-dashboard-generic.js";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TableStateChip from "components/Cards/Tables/TableStateChip";
import { TABLE_STATE_CHIP_COLOR } from "utils/consts";

const useStyles = makeStyles(componentStyles);
const useStylesGeneric = makeStyles(componentStylesGeneric);

const tableHead = ["Armazém", "Especialidade", "Stock", "Estado", ""];

function CardStock(props) {
  const classes = { ...useStylesGeneric(), ...useStyles() };
  const theme = useTheme();

  useEffect(() => {
    props.getDashboardStockInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getColor = (quantity) => {
    switch (quantity) {
      case 1:
      case 2:
        return TABLE_STATE_CHIP_COLOR.DARK_ORANGE;
      case 3:
        return TABLE_STATE_CHIP_COLOR.YELLOW;
      default:
        return TABLE_STATE_CHIP_COLOR.GREEN;
    }
  };

  const getMessage = (quantity) => {
    switch (quantity) {
      case 0:
        return "Esgotado";
      case 1:
      case 2:
        return "A terminar";
      case 3:
        return "Prestes a terminar";
      default:
        return "Em stock";
    }
  };
  
  return (
    <Card classes={{ root: classes.cardRoot }}>
      <div style={{position:"relative"}}>
        <img
            src={"/images/empregoAngolaBackground1.png"} //Designed by 0melapics / Freepik: http://www.freepik.com
            className={classes.backgroundImage}
            alt={""}
        />
      </div>
      <CardHeader
        classes={{
          root: classes.cardHeader + " " + classes.cardHeaderDashboard,
          action: classes.cardHeaderAction,
        }}
        subheader={
          <Grid
            container
            component={Box}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box className={classes.lineHeader}> {""} </Box>
            <Grid item xs={12}>
              <Box
                component={Typography}
                variant="h3"
                marginBottom="0!important"
                classes={{ root: classes.cardTitle }}
              >
                <Box component="span">Stock de Kits</Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                component={Typography}
                variant="h1"
                classes={{ root: classes.cardHeaderCounter }}
                marginBottom="0!important"
              >
                <Box component="span"><br></br></Box>
              </Box>
            </Grid>
          </Grid>
        }
        action={
          <LinkMaterialUi
            component={Link}
            variant="h5"
            to="/admin/kit-management"
            className={classes.link}
          >
            Ver mais
          </LinkMaterialUi>
        }
      ></CardHeader>
      {props.dashboardState?.dashboardStockInfo !== undefined &&
        props.dashboardState?.dashboardStockInfo?.length > 0 && (
          <TableContainer>
            <Box
              component={Table}
              alignItems="center"
              marginBottom="0!important"
            >
              <TableHead>
                <TableRow>
                  {tableHead.map((prop, key) => (
                    <TableCell
                      key={key}
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      {prop}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.dashboardState?.dashboardStockInfo?.map((prop, key) => (
                  <TableRow key={key}>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      {prop.warehouse}
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableRowEllipsis}} style={{maxWidth: "140px"}}>
                      {prop.category}
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      {prop.stock}
                    </TableCell>
                    <TableCell
                      classes={{
                        root: classes.tableCellRoot + " " + classes.actionsCell,
                      }}
                    >
                      <Box paddingTop=".35rem" paddingBottom=".35rem">
                        {prop.stock === 0 ? (
                          <TableStateChip
                            message={getMessage(prop.stock)}
                            showErrorIcon={true}
                          />
                        ) : (
                          <TableStateChip
                            state={getColor(prop.stock)}
                            message={getMessage(prop.stock)}
                          />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      <Tooltip title="Detalhes">
                        <IconButton
                          aria-label="details"
                          component={Link}
                          to={`/admin/stock-detail/${prop.id}`}
                        >
                          <Box
                            component={ArrowForwardIosIcon}
                            width="9px!important"
                            height="9px!important"
                            color={theme.palette.primary.main}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Box>
          </TableContainer>
        )}
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDashboardStockInfo: () => dispatch(getDashboardStockInfoAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardStock);
