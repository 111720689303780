import { logout } from "../../../crud/auth.crud";

const logoutAction = (email) => async (dispatch) => {
  logout(email)
    .then((res) => {
      dispatch({
        type: "logout",
        payload: res.data.succeeded,
      });
    })
    .catch((error) => {
      dispatch({
        type: "logout",
        payload: false,
      });

      console.log("error", error);
    });
};

export default logoutAction;
