import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import clsx from "clsx";
import moment from "moment";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import { Card, CardHeader, CardContent } from "@material-ui/core";
// @material-ui/icons components
import SearchIcon from "@material-ui/icons/Search";
import HelpIcon from "@material-ui/icons/Help";
//import custom components
import GenericButton from "components/Buttons/GenericButton";
import GenericFormSelect from "components/FormFields/GenericFormSelect";
import FormSelect from "components/FormFields/FormSelect";
import { getDataSetByNameAction, getLoanStatesAction, getGovernmentProgramsAction } from "redux/actions/datasetsAction";
import { getFilteredLoansActions } from "redux/actions/loansAction";
import { getReportAction } from "redux/actions/reportsAction";
import { getSelectOptionsElementByCode } from "utils/formSelect";
import { DATASET, PAGINATION, OPERATIONS, REPORT_FILE_NAME, REPORT_CONSTS } from "utils/consts";
import { loansFilterMap, loansExportReportMap } from "mappers/loansMap";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import { checkOperationPermission } from "utils/permission";
import { labels } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function FilterSection(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const [filter, setFilter] = useState({
    nameOfUser: "",
    identityCardOrTaxIdNum: "",
    provinceId: props.authState?.province ?? null,
    numApplication: "",
    governmentProgramId: null,
    loanStateId: null,
    bankId: props.authState?.userPermissionSettings?.managerBankId ?? null,
    loanTypeId: null,
    pageIndex: PAGINATION.FIRST_PAGE,
    pageSize: PAGINATION.PAGE_SIZE,
  });

  const [availableLoanTypes, setAvailableLoanTypes] = useState([]);

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  const cleanFilter = () => {
    setValue("nameOfUser", "");
    setValue("identityCardOrTaxIdNum", "");
    if (!props.authState?.province) setValue("province", undefined);
    setValue("applicationNumber", "");
    setValue("governmentProgramId", undefined);
    setValue("loanState", undefined);
    if (!props.authState?.userPermissionSettings?.managerBankId) setValue("bank", undefined);
    setValue("loanType", undefined);

    setFilter(0);
    setFilterCounter(0);
  };

  const [filterCounter, setFilterCounter] = useState(0);

  useEffect(() => {
    let count = 0;

    Object.keys(filter).map((key) => {
      if (filter[key] && key !== "pageIndex" && key !== "pageSize") {
        count += 1;
      }

      return count;
    });

    setFilterCounter(count);
  }, [filter]);

  useEffect(() => {
    if (
      props.dataSetState[DATASET.LOAN_STATES] === undefined ||
      props.dataSetState[DATASET.LOAN_STATES]?.length === 0
    ) {
      props.getLoanStates(DATASET.LOAN_STATES);
    }

    if (props.dataSetState[DATASET.PROVINCES] === undefined || props.dataSetState[DATASET.PROVINCES]?.length === 0) {
      props.getDataSet(DATASET.PROVINCES);
    }

    if (props.dataSetState[DATASET.BANK] === undefined || props.dataSetState[DATASET.BANK]?.length === 0) {
      props.getDataSet(DATASET.BANK);
    }
    if (props.dataSetState[DATASET.LOAN_TYPE] === undefined || props.dataSetState[DATASET.LOAN_TYPE]?.length === 0) {
      props.getDataSet(DATASET.LOAN_TYPE);
    }

    if (
      props.dataSetState[DATASET.GOVERNMENT_PROGRAMS] === undefined ||
      props.dataSetState[DATASET.GOVERNMENT_PROGRAMS]?.length === 0
    ) {
      props.getGovernmentPrograms(DATASET.GOVERNMENT_PROGRAMS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.authState?.province) {
      var provinceList = props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : [];
      setValue("province", getSelectOptionsElementByCode(provinceList, props.authState.province));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.PROVINCES]]);

  useEffect(() => {
    if (props.authState?.userPermissionSettings?.managerBankId) {
      var banksList = props.dataSetState[DATASET.BANK] ? props.dataSetState[DATASET.BANK] : [];
      setValue("bank", getSelectOptionsElementByCode(banksList, props.authState.userPermissionSettings.managerBankId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.BANK]]);

  useEffect(() => {
    if (props.authState?.userPermissionSettings?.managerLoanTypes) {
      var loanTypesList = props.dataSetState[DATASET.LOAN_TYPE] ? props.dataSetState[DATASET.LOAN_TYPE] : [];
      if (props.authState.userPermissionSettings.managerLoanTypes.length > 0)
        setAvailableLoanTypes(
          loanTypesList.filter((lt) => props.authState.userPermissionSettings.managerLoanTypes.includes(lt.code))
        );
      else setAvailableLoanTypes(loanTypesList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.LOAN_TYPE]]);

  useEffect(() => {
    if (props.loansState?.refresh_results) {
      getResultListData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loansState?.refresh_results]);

  useEffect(() => {
    getResultListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page]);

  //Refresh Results
  const getResultListData = () => {
    let temp_filter = { ...filter, pageIndex: props.page };
    setFilter(temp_filter);
    props.getFilteredLoans(temp_filter);
  };

  const onSubmit = (data) => {
    if (isValid) {
      let filter = loansFilterMap(data);
      setFilter(filter);
      if (props.page === PAGINATION.FIRST_PAGE) props.getFilteredLoans(filter);
      else props.handleChangePage(PAGINATION.FIRST_PAGE);
    }
  };

  const handleSetFilter = (filterKey, value, clearChildKeyList) => {
    const newFilter = { ...filter, [filterKey]: value };

    clearChildKeyList?.forEach((child) => {
      newFilter[child] = null;
    });

    setFilter(newFilter);
  };

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={
          <div>
            <div className={classes.sideBySideDiv}>Pesquisa de Empréstimos</div>
            <div className={classes.sideBySideDiv + " " + classes.cardElementsCount}>
              Filtros {"("}
              {filterCounter}
              {")"}
            </div>
          </div>
        }
      ></CardHeader>
      <CardContent>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container classes={{ root: classes.containerPadding }}>
              <Grid item xs={12} sm={12} md={4}>
                <FormGroup>
                  <FormLabel>{intl.formatMessage(labels.Label_Admin_GeneralDataSection_NameField)}</FormLabel>
                  <Controller
                    name="nameOfUser"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("nameOfUser", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["name"] !== undefined,
                          }),
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_NumBIField)}
                    <Tooltip title="Nº BI se Cidadão, NIF se Empresa">
                      <HelpIcon className={classes.tooltipInfoIcon} />
                    </Tooltip>
                  </FormLabel>
                  <Controller
                    name="identityCardOrTaxIdNum"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("identityCardOrTaxIdNum", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["identityCardOrTaxIdNum"] !== undefined,
                          }),
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>{intl.formatMessage(labels.Label_Admin_GeneralDataSection_ProvinceField)}</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="province"
                    selectOptions={props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : null}
                    disabled={props.authState?.province != null}
                    setValue={setValue}
                    getValues={getValues}
                    required={false}
                    errors={props.errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"provinceId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>{intl.formatMessage(labels.Label_Admin_Loans_ApplicationNumber)}</FormLabel>
                  <Controller
                    name="applicationNumber"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("applicationNumber", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["applicationNumber"] !== undefined,
                          }),
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>{intl.formatMessage(labels.Label_Admin_Subprogram_GovernmentProgramField)}</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="governmentProgramId"
                    selectOptions={
                      props.dataSetState[DATASET.GOVERNMENT_PROGRAMS] !== undefined
                        ? props.dataSetState[DATASET.GOVERNMENT_PROGRAMS]
                        : []
                    }
                    getValues={getValues}
                    setValue={setValue}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"governmentProgramId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>{intl.formatMessage(labels.Label_Admin_Loans_State)}</FormLabel>
                  <GenericFormSelect
                    control={control}
                    fieldName="loanState"
                    selectOptions={
                      props.dataSetState[DATASET.LOAN_STATES] !== undefined
                        ? props.dataSetState[DATASET.LOAN_STATES]
                        : []
                    }
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"loanStateId"}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>{intl.formatMessage(labels.Label_Admin_Loans_BankName)}</FormLabel>
                  <GenericFormSelect
                    control={control}
                    fieldName="bank"
                    selectOptions={
                      props.dataSetState[DATASET.BANK] !== undefined ? props.dataSetState[DATASET.BANK] : []
                    }
                    disabled={props.authState?.userPermissionSettings?.managerBankId != null}
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"bankId"}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>{intl.formatMessage(labels.Label_Admin_Loans_LoanType)}</FormLabel>
                  <GenericFormSelect
                    control={control}
                    fieldName="loanType"
                    selectOptions={availableLoanTypes}
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"loanTypeId"}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} md={12} classes={{ root: classes.alignRight }}>
                <GenericButton
                  variant="contained"
                  color="ghost"
                  onClick={() => {
                    cleanFilter();
                  }}
                >
                  {intl.formatMessage(labels.Label_ClearFilters)}
                </GenericButton>
                {checkOperationPermission(props.authState.operationsList, OPERATIONS.EXPORT_REPORTS) && (
                  <Tooltip
                    title={
                      props.loansState?.total_records > REPORT_CONSTS.MAX_RESULTS ||
                      props.loansState?.total_records === 0
                        ? `Limite mínimo de 1 e máximo de ${REPORT_CONSTS.MAX_RESULTS} resultados para exportar relatório`
                        : ""
                    }
                  >
                    <span>
                      <GenericButton
                        color="secondary"
                        loading={props.reportsState.get_report_loading}
                        disabled={
                          props.loansState?.total_records > REPORT_CONSTS.MAX_RESULTS ||
                          props.loansState?.total_records === 0
                        }
                        style={{ marginRight: "20px" }}
                        onClick={() => {
                          props.getReport(
                            loansExportReportMap(filter),
                            REPORT_FILE_NAME.LOANS_SEARCH_RESULTS +
                              moment().format("YYYYMMDDHHmm") +
                              REPORT_FILE_NAME.EXCEL_EXTENSION
                          );
                        }}
                      >
                        {intl.formatMessage(labels.Label_ExportButton)}
                      </GenericButton>
                    </span>
                  </Tooltip>
                )}
                <GenericButton typeSubmit={true} color="primary" loading={props.loansState.search_loading}>
                  {intl.formatMessage(labels.Label_FilterSection_SearchButton)}
                </GenericButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getLoanStates: (name) => dispatch(getLoanStatesAction(name)),
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getFilteredLoans: (data) => dispatch(getFilteredLoansActions(data)),
  getReport: (data, reportFileName) => dispatch(getReportAction(data, reportFileName)),
  getGovernmentPrograms: () => dispatch(getGovernmentProgramsAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterSection);
