import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import clsx from "clsx";
import { useIntl } from "react-intl";
// MUI Core
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// MUI Icons
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
// custom
import componentStyles from "assets/theme/components/formFields/embeddedVideosField.js";
import { messages } from "resources/resources";

const useStyles = makeStyles(componentStyles);

export const EmbeddedVideosField = ({
  name,
  inputFieldName,
  control,
  setValue,
  getValues,
  trigger,
  errors,
  maxNumberOfVideoUrls,
  showPreviews = false,
  videoUrlList = null,
  handleMediaDelete,
}) => {
  const classes = { ...useStyles() };
  const theme = useTheme();
  const intl = useIntl();
  const [videoUrlListState, setVideoUrlListState] = useState([]);

  const [videosMaxNumberError, setVideosMaxNumberError] = useState(false);
  const [inputFieldEmpty, setInputFieldEmpty] = useState(true);

  useEffect(() => {
    if (videoUrlList !== null && videoUrlList.length > 0) {
      setValue(name, videoUrlList);
      setVideoUrlListState(videoUrlList);
      trigger(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoUrlList]);

  const remove = (i) => {
    const newList = [...videoUrlListState];

    if (newList[i].id !== null) handleMediaDelete(newList[i].id);

    newList.splice(i, 1);

    setValue(name, newList);
    setVideoUrlListState(newList);
    trigger(name);

    setVideosMaxNumberError(false);
  };

  function addUrl() {
    if (errors[inputFieldName] === undefined && !inputFieldEmpty) {
      var url = parseSrcUrl(getValues(inputFieldName));
      var urlObject = { id: null, url: url };
      const newList = [...videoUrlListState, urlObject];

      setValue(name, newList);
      setVideoUrlListState(newList);
      trigger(name);

      setValue(inputFieldName, "");
    }
  }

  function parseSrcUrl(input) {
    if (!input) return "";

    // RegExp to get the url from src="url"
    let rgx = new RegExp(/https?:\/\/[^\s"]*/, "gu");

    let url = input.match(rgx)[0];

    // RegExp to clear &width, &height, ?width, ?height from url
    let clearWidthHeightRgx = new RegExp(/&width=\d+|&height=\d+|\?width=\d+&|\?height=\d+&/, "gu");

    let matchesToClean = url.match(clearWidthHeightRgx);

    matchesToClean?.forEach((match) => {
      // Remove '?' when url has ?width=123&, if it is ?width=123 remove it all since '?' isn't needed
      match = match[0] === "?" && match.at(-1) === "&" ? match.replace("?", "") : match;
      url = url.replace(match, "");
    });

    return url;
  }

  const previews = videoUrlListState.map((videoUrl, i) => (
    <Box key={`key${i}`} className={classes.previewOuterBox}>
      <Box className={classes.previewBox}>
        {showPreviews && (
          <iframe
            id={`infoVideoUrlId${i}`}
            src={videoUrl.url}
            width="200"
            height="112.5"
            loading="lazy"
            className={classes.iframe}
            title={`infoVideoUrl${i}`}
          />
        )}
      </Box>
      <Box>
        <IconButton
          onClick={() => {
            remove(i);
          }}
          classes={{ root: classes.deleteButtonRoot }}
        >
          <ClearIcon classes={{ root: classes.deleteButtonIconRoot }} />
        </IconButton>
      </Box>
    </Box>
  ));

  return (
    <>
      <Grid container component={Box}>
        <Grid item xs={11} md={11}>
          <Controller
            name={inputFieldName}
            control={control}
            component={Box}
            defaultValue=""
            rules={{
              required: {
                value: false,
              },
              pattern: {
                value: /https?:\/\/.+/,
                message: intl.formatMessage(messages.Message_Generic_InvalidUrl),
              },
            }}
            render={({ field: { onChange, name, value } }) => (
              <OutlinedInput
                name={name}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  if (e.target.value?.length > 0) setInputFieldEmpty(false);
                  else setInputFieldEmpty(true);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    addUrl();
                    e.preventDefault();
                  }
                }}
                fullWidth
                autoComplete="off"
                placeholder={'Exemplo: <iframe> src="https://www.youtube.com/embed/_example" </iframe>'}
                multiline={false}
                rows={1}
                type="text"
                classes={{
                  notchedOutline: clsx({
                    [classes.borderWarning]: errors[name] !== undefined,
                  }),
                }}
              />
            )}
          />
          {errors[inputFieldName] !== undefined && (
            <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
              {errors[inputFieldName].message}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={1} md={1} className={classes.addButtonGrid}>
          <IconButton
            classes={{ root: classes.addButtonRoot }}
            disabled={errors[inputFieldName] !== undefined || inputFieldEmpty}
            onClick={addUrl}
          >
            <AddIcon classes={{ root: classes.addButtonIconRoot }} />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container component={Box} marginTop="1rem" className={classes.previewsContainer}>
        {previews}

        {videosMaxNumberError && (
          <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
            {intl.formatMessage(messages.Message_Generic_InvalidUrl)} {maxNumberOfVideoUrls}.
          </FormHelperText>
        )}
      </Grid>

      <Grid container component={Box} marginTop="1rem" className={classes.previewsContainer}>
        {errors[name] !== undefined && (
          <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
            {errors[name].message}
          </FormHelperText>
        )}
      </Grid>
    </>
  );
};

export default EmbeddedVideosField;
