import { React, useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import {
    FormHelperText,
    FormGroup,
    FormLabel,
    Box,
    OutlinedInput,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import InputAdornment from "@material-ui/core/InputAdornment";

import {
    UNITS,
    MONETARYUNITPLACEHOLDER
} from "utils/consts";
//style
import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);


export default function FormMonetaryInput({
    name,
    label,
    control,
    rules,
    errors,
    disabled,
    defaultValue
}) {
    const classes = useStylesGeneric();
    const theme = useTheme();
    const intl = useIntl();
    const [formattedUnitPrice, setFormattedUnitPrice] = useState();

    useEffect(() => {
        if (defaultValue) {
            if (intl.formatNumber(defaultValue) !== "NaN")
                setFormattedUnitPrice(intl.formatNumber(defaultValue))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    const onUnitPriceChange = (value, onChange) => {
        value = value?.replace(/\./g, "");
        if (value && value.charAt(value.length - 1) === ",")
            setFormattedUnitPrice(value);
        else {
            if (value) {
                value = value?.replace(/,/g, ".");
                if (intl.formatNumber(value) !== "NaN")
                    setFormattedUnitPrice(intl.formatNumber(value));
                else
                    setFormattedUnitPrice(value);
            } else {
                setFormattedUnitPrice("");
            }
        }
        value = value?.replace(/\./g, ",");
        onChange(value);
    }

    return (
        <FormGroup>
            <FormLabel>{label}</FormLabel>
            <Controller
                name={name}
                control={control}
                component={Box}
                defaultValue=""
                rules={rules}
                render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                        name={name}
                        value={value ? formattedUnitPrice : ""}
                        onChange={(e) => { onUnitPriceChange(e.target.value, onChange) }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        disabled={disabled}
                        placeholder={MONETARYUNITPLACEHOLDER}
                        endAdornment={
                            <InputAdornment
                                disableTypography={true}
                                position="end"
                            >
                                {UNITS.MONETARY}
                            </InputAdornment>
                        }
                        classes={{
                            notchedOutline: clsx({
                                [classes.borderWarning]: errors[name] !== undefined,
                            }),
                        }}
                    />
                )}
            />
            {errors[name] !== undefined && (
                <FormHelperText
                    component={Box}
                    color={theme.palette.warning.main + "!important"}
                >
                    {errors[name].message}
                </FormHelperText>
            )}
        </FormGroup>
    );
}
