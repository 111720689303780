import { AUTH } from "utils/actionsConsts";

const resetResetPasswordStateAction = () => async (dispatch) => {
  dispatch({
    type: AUTH.RESET_RESET_PASSWORD_STATE,
    payload: false,
  });
};

export default resetResetPasswordStateAction;
