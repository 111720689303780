import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
//import custom components
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import DetailDataTab from "./Tabs/DetailDataTab";
import AssignedKitsTab from "./Tabs/AssignedKitsTab";
import { getSubprogramDetailsActions } from "redux/actions/subprogramsAction";
import { BENEFIT } from "utils/consts";
import useScrollDirection from "hooks/useScrollDirection";
import Tabs from "components/Tabs/Tabs";

import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function SubprogramDetails(props) {
  const classes = useStylesGeneric();
  const scrollDirection = useScrollDirection();

  const TABS = {
    DETAILS_DATA: "detailDataTab",
    ASSIGNED_KITS: "assignedKitsTab",
  };

  const [tabStep, setTabStep] = useState("");
  const [subprogramNumber, setSubprogramNumber] = useState();
  const [subprogramName, setSubprogramName] = useState();

  useEffect(() => {
    if (
      props.applicationsState?.subprogramDetailsData === undefined ||
      props.applicationsState?.subprogramDetailsData?.subprogramNumber !== props.match?.params?.subprogramNumber
    ) {
      props.getSubprogramDetails(props.match?.params?.subprogramNumber);
    }

    setSubprogramNumber(props.match?.params?.subprogramNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match?.params?.subprogramNumber]);

  useEffect(() => {
    if (props.subprogramState?.subprogramDetailsData !== undefined) {
      setTabStep(TABS.DETAILS_DATA);
      setSubprogramName(props.subprogramState?.subprogramDetailsData?.subprogramName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subprogramState?.subprogramDetailsData]);

  useEffect(() => {
    if (props.subprogramState?.refresh_results) {
      props.getSubprogramDetails(props.match?.params?.subprogramNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subprogramState?.refresh_results]);

  const handleTabStep = (event, value) => {
    if (value !== null) {
      setTabStep(value);
    }
  };

  function loadTabContent() {
    switch (tabStep) {
      case TABS.DETAILS_DATA:
        return (
          <DetailDataTab
            subprogramNumber={subprogramNumber}
            subprogramDetailsData={props.subprogramState?.subprogramDetailsData}
          />
        );
      case TABS.ASSIGNED_KITS:
        return <AssignedKitsTab subprogramDetailsData={props.subprogramState?.subprogramDetailsData} />;
      default:
        return <></>;
    }
  }

  return (
    <>
      <GenericBackdrop open={props.subprogramState?.get_subprogram_details_loading} />

      {scrollDirection === "up" && (
        <div className={classes.breadcrumbs}>
          <LocationHeader
            section={"Detalhes Subprograma"}
            subsection={"Subprogramas"}
            subsectionLink={"/admin/subprograms-management"}
          />
        </div>
      )}

      <Container maxWidth={false} component={Box} classes={{ root: classes.containerRootAfterBreadcrumbs }}>
        <PageTitleHeader title={subprogramName} />

        <Box component={Grid} container justifyContent="center">
          <Tabs
            tabs={[
              {
                value: TABS.DETAILS_DATA,
                testId: "subprogram-detail-details-tab",
                text: "Dados do Subprograma",
              },
              {
                value: TABS.ASSIGNED_KITS,
                renderIf:
                  props.subprogramState?.subprogramDetailsData?.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT ||
                  props.subprogramState?.subprogramDetailsData?.benefitKeyValue ===
                    BENEFIT.PROFESSIONAL_KIT_MICROCREDIT,
                testId: "subprogram-detail-assigned-kits-tab",
                text: "Kits Atribuídos",
              },
            ]}
            onChange={handleTabStep}
            step={tabStep}
          />

          <Grid item xs={12} lg={12} md={12}>
            {loadTabContent()}
          </Grid>
        </Box>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getSubprogramDetails: (subprogramNumber) => dispatch(getSubprogramDetailsActions(subprogramNumber)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubprogramDetails);
