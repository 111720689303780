import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import {
  getDataSetByNameAction,
  getWarehousesByProvinceAction,
  getCategoriesAction,
  clearDataSetByNameAction,
} from "redux/actions/datasetsAction";
import { getReportAction } from "redux/actions/reportsAction";
import { searchStocksAction } from "redux/actions/stockAction";
import { searchStocksMap, stocksReportMap } from "mappers/searchStocksMap";
import moment from "moment";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { Card, CardHeader, CardContent } from "@material-ui/core";
//import custom components
import GenericButton from "components/Buttons/GenericButton";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import FormSelect from "components/FormFields/FormSelect";
import { DATASET, PAGINATION, REPORT_CONSTS, REPORT_FILE_NAME, OPERATIONS } from "utils/consts";
import { getSelectOptionsElementByCode } from "utils/formSelect";
import { checkOperationPermission } from "utils/permission";
import { labels, titles } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function Filter(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const [filter, setFilter] = useState({
    CategoryId: null,
    ProvinceId: props.authState?.province ?? null,
    WarehouseId: null,
  });
  const [filterCounter, setFilterCounter] = useState(0);

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    let count = 0;

    Object.keys(filter).map((key) => {
      if (filter[key] && key !== "pageIndex" && key !== "pageSize") {
        count += 1;
      }

      return count;
    });

    setFilterCounter(count);
  }, [filter]);

  const cleanFilter = () => {
    setValue("categoryId", undefined);
    if (!props.authState?.province) setValue("provinceId", undefined);
    setValue("warehouseId", undefined);
    props.clearDataSetByName(DATASET.WAREHOUSES);

    setFilterCounter(0);
    setFilter(0);
  };

  const onSubmit = (data) => {
    if (isValid) {
      let filter = searchStocksMap(data);
      filter["pageIndex"] = 1;
      filter["pageSize"] = PAGINATION.PAGE_SIZE;
      setFilter(filter);
      if (props.page === 1) props.searchStocks(filter);
      else props.handleChangePage(1);
    }
  };

  const handleSetFilter = (filterKey, value, clearChildKeyList) => {
    const newFilter = { ...filter, [filterKey]: value };

    clearChildKeyList?.forEach((child) => {
      newFilter[child] = null;
    });

    setFilter(newFilter);
  };

  useEffect(() => {
    let temp_filter = filter;
    Object.assign(temp_filter, filter);
    temp_filter["pageIndex"] = props.page;
    temp_filter["pageSize"] = PAGINATION.PAGE_SIZE;
    props.searchStocks(temp_filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page]);

  useEffect(() => {
    if (props.dataSetState[DATASET.PROVINCES] === undefined || props.dataSetState[DATASET.PROVINCES] === []) {
      props.getDataSet(DATASET.PROVINCES);
    }
    if (props.dataSetState[DATASET.CATEGORIES] === undefined || props.dataSetState[DATASET.CATEGORIES] === []) {
      props.getCategories(DATASET.CATEGORIES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      props.authState?.province &&
      props.dataSetState[DATASET.PROVINCES] !== undefined &&
      props.dataSetState[DATASET.PROVINCES] !== []
    ) {
      var selectedProvince = getSelectOptionsElementByCode(
        props.dataSetState[DATASET.PROVINCES],
        props.authState.province
      );
      setValue("provinceId", selectedProvince);
      props.getWarehousesByProvince(DATASET.WAREHOUSES, selectedProvince.keyValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.PROVINCES]]);

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={
          <div>
            <div className={classes.sideBySideDiv}>
              {intl.formatMessage(titles.Title_Admin_ProfessionalKits_StocksManagement)}
            </div>
            <div className={classes.sideBySideDiv + " " + classes.cardElementsCount}>
              Filtros {"("}
              {filterCounter}
              {")"}
            </div>
          </div>
        }
      ></CardHeader>
      <CardContent>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container classes={{ root: classes.containerPadding }}>
              <Grid item xs={12} sm={4} md={3}>
                <FormGroup>
                  <FormLabel>Especialidade</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="categoryId"
                    selectOptions={props.dataSetState[DATASET.CATEGORIES] ? props.dataSetState[DATASET.CATEGORIES] : []}
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"categoryId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <FormGroup>
                  <FormLabel>Província</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="provinceId"
                    selectOptions={props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : []}
                    disabled={props.authState?.province != null}
                    loadChildrenDataSet={props.getWarehousesByProvince}
                    childrenDataSet={DATASET.WAREHOUSES}
                    childrenSelect="warehouseId"
                    setValue={setValue}
                    getValues={getValues}
                    required={false}
                    errors={props.errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"provinceId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <FormGroup>
                  <FormLabel>Armazém</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="warehouseId"
                    selectOptions={props.dataSetState[DATASET.WAREHOUSES] ? props.dataSetState[DATASET.WAREHOUSES] : []}
                    disabled={!getValues("provinceId")}
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"warehouseId"}
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={12} classes={{ root: classes.alignRight }}>
                <Box>
                  <GenericButton
                    typeSubmit={false}
                    color="ghost"
                    onClick={() => {
                      cleanFilter();
                    }}
                  >
                    {intl.formatMessage(labels.Label_ClearFilters)}
                  </GenericButton>
                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.EXPORT_REPORTS) && (
                    <Tooltip
                      title={
                        props.stockState?.total_records > REPORT_CONSTS.MAX_RESULTS ||
                        props.stockState?.total_records === 0
                          ? `Limite mínimo de 1 e máximo de ${REPORT_CONSTS.MAX_RESULTS} resultados para exportar relatório`
                          : ""
                      }
                    >
                      <span>
                        <GenericButton
                          typeSubmit={false}
                          color="secondary"
                          variant={"contained"}
                          loading={props.reportsState.get_report_loading}
                          disabled={
                            props.stockState?.total_records > REPORT_CONSTS.MAX_RESULTS ||
                            props.stockState?.total_records === 0
                          }
                          style={{ marginRight: "20px" }}
                          onClick={() => {
                            props.getReport(
                              stocksReportMap(filter),
                              REPORT_FILE_NAME.STOCKS_SEARCH_RESULTS +
                                moment().format("YYYYMMDDHHmm") +
                                REPORT_FILE_NAME.EXCEL_EXTENSION
                            );
                          }}
                        >
                          {intl.formatMessage(labels.Label_ExportButton)}
                        </GenericButton>
                      </span>
                    </Tooltip>
                  )}
                  <GenericButton
                    typeSubmit={true}
                    variant="contained"
                    color="primary"
                    loading={props.subprogramState.searchSubprograms_loading}
                  >
                    {intl.formatMessage(labels.Label_FilterSection_SearchButton)}
                  </GenericButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getWarehousesByProvince: (name, province) => dispatch(getWarehousesByProvinceAction(name, province)),
  getCategories: (name) => dispatch(getCategoriesAction(name)),
  searchStocks: (formData) => dispatch(searchStocksAction(formData)),
  clearDataSetByName: (name) => dispatch(clearDataSetByNameAction(name)),
  getReport: (data, reportFileName) => dispatch(getReportAction(data, reportFileName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
