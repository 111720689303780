import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
// import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import TableStateChip from "components/Cards/Tables/TableStateChip";
import Pagination from "@material-ui/lab/Pagination";
// @material-ui/icons components
import HelpIcon from "@material-ui/icons/Help";
import { ArrayUtils } from "utils/array_utils";
import { TABLE_STATE_CHIP_COLOR, USER_TYPE_KEY } from "utils/consts";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// core componentssrc
import componentStylesGeneric from "assets/theme/views/admin/generic";
import { getNameInitials } from "utils/converters";
import { titles, labels } from "resources/resources";

import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
const useStylesGeneric = makeStyles(componentStylesGeneric);

function ResultSection(props) {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStylesGeneric();

  const [applicationList, setApplicationList] = useState([]);

  const tableHead = [
    intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_NumApplicationField),
    intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_UserCodeField),
    intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_UserNameField),
    intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_NumBIField),
    intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_PhoneNumber),
    intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_CandidateMunicipality),
    intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_EntrepreneurshipTraining),
    intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_GovernmentProgram),
    intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_ProgramField),
    intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_SubprogramField),
    intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_SubmissionDate),
    intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_StatusField),
    "",
  ];

  useEffect(() => {
    if (!ArrayUtils.NullOrEmpty(props.applicationsState.result)) {
      setApplicationList(props.applicationsState.result.data);
    } else {
      setApplicationList([]);
    }
  }, [props.applicationsState.result]);

  const MapApplicationStateToTableChipColors = (applicationState) => {
    const greenChipArray = [14];
    const yellowChipArray = [1, 3, 9];
    const orangeChipArray = [4, 5, 6, 7, 8, 10, 11, 12];
    const redChipArray = [2, 13, 15];

    if (greenChipArray.includes(applicationState)) {
      return TABLE_STATE_CHIP_COLOR.GREEN;
    }
    if (orangeChipArray.includes(applicationState)) {
      return TABLE_STATE_CHIP_COLOR.DARK_ORANGE;
    }
    if (yellowChipArray.includes(applicationState)) {
      return TABLE_STATE_CHIP_COLOR.YELLOW;
    }
    if (redChipArray.includes(applicationState)) {
      return TABLE_STATE_CHIP_COLOR.RED;
    }
  };

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={
          <div>
            <div className={classes.sideBySideDiv}>
              {intl.formatMessage(titles.Title_Admin_ApplicationsManagement_ResultSection)}
            </div>
            <div className={classes.sideBySideDiv + " " + classes.cardElementsCount}>
              {"("}
              {props.applicationsState?.total_records}
              {")"}
            </div>
          </div>
        }
      />
      <TableContainer>
        <Box component={Table} alignItems="center" marginBottom="0!important">
          <TableHead>
            <TableRow>
              {tableHead.map((prop, key) =>
                key === 2 ? (
                  <TableCell
                    key={key}
                    classes={{
                      root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    {prop}
                    <Tooltip title="Nº BI se Cidadão, NIF se Empresa">
                      <HelpIcon className={classes.tooltipInfoIcon} />
                    </Tooltip>
                  </TableCell>
                ) : (
                  <TableCell
                    key={key}
                    classes={{
                      root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    {prop}
                  </TableCell>
                )
              )}
              <TableCell
                key="detailsCol"
                classes={{
                  root:
                    classes.tableCellRoot + " " + classes.tableCellRootHead + " " + classes.tableCellHeadStickyRight,
                }}
              >
                {intl.formatMessage(labels.Label_Details)}
              </TableCell>
            </TableRow>
          </TableHead>

          {applicationList !== undefined && applicationList.length > 0 && (
            <TableBody>
              {applicationList?.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.numApplication}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.userCode}</TableCell>
                  <TableCell
                    classes={{ root: classes.tableRowEllipsis + " " + classes.tableCellRoot }}
                    title={(prop.userTypeKey === USER_TYPE_KEY.INDIVIDUAL ? "Cidadão: " : "Empresa: ") + prop.nameOfUser}
                  >
                    {prop.nameOfUser}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.identityCardOrTaxIdNum}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.phoneNumber}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.candidateMunicipality}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    {prop.hasEntrepreneurshipTraining
                      ? intl.formatMessage(labels.Label_Yes)
                      : intl.formatMessage(labels.Label_No)}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.governmentProgram}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.program}</TableCell>
                  <TableCell
                    classes={{ root: classes.tableRowEllipsis + " " + classes.tableCellRoot }}
                    title={prop.subprogram}
                  >
                    {prop.subprogram}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.submissionDate}</TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot,
                    }}
                  >
                    <TableStateChip
                      state={MapApplicationStateToTableChipColors(prop.stateKeyValue)}
                      message={prop.state}
                    />
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box alignItems="center" display="flex">
                      {prop.userAvatar ? (
                        <Box
                          component={Avatar}
                          marginRight="1rem"
                          alt="..."
                          src={prop.userAvatar}
                          classes={{ root: classes.avatarRoot }}
                        />
                      ) : (
                        <Box component={Avatar} marginRight="1rem" alt="..." classes={{ root: classes.avatarRoot }}>
                          {" "}
                          {getNameInitials(prop.nameOfUser)}
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight }}>
                    <Tooltip title="Detalhes">
                      <IconButton
                        aria-label="details"
                        component={Link}
                        to={`/admin/application-detail/${prop.numApplication}`}
                      >
                        <Box
                          component={ArrowForwardIosIcon}
                          width="12px!important"
                          height="12px!important"
                          color={theme.palette.primary.main}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Box>

        {(applicationList == null || applicationList.length === 0) && !props.applicationsState.loading && (
          <Box className={classes.noResultsInfoInTable}>{intl.formatMessage(labels.Label_NoResultsFound)}</Box>
        )}
      </TableContainer>

      <TableBackdrop open={props.applicationsState.loading} />

      <Pagination
        className={classes.alignPagination}
        count={props.applicationsState?.total_pages}
        page={props.page}
        onChange={props.handleChangePage}
        color="primary"
      ></Pagination>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(ResultSection);
