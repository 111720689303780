import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import clsx from "clsx";
import { useIntl } from "react-intl";
// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
// @material-ui/icons components
import SearchIcon from "@material-ui/icons/Search";
// src components
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import FormSelect from "components/FormFields/FormSelect";
import FormMultipleSelect from "components/FormFields/FormMultipleSelect";
import GenericButton from "components/Buttons/GenericButton";
import {
  getFilteredAvailableOpportunitiesForUserAction,
  createApplicationsForUserAction,
} from "redux/actions/opportunitiesAction";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { getFilteredAvailableOpportunitiesForUserMap } from "mappers/opportunityMap";
import { PAGINATION, DATASET, OPPORTUNITY_TYPE_KEY } from "utils/consts";
import { ArrayUtils } from "utils/array_utils";
import { getSelectOptionsElementByKey } from "utils/formSelect";
import { labels } from "resources/resources";
import limitaString from "utils/string";
import { checkOperationPermission } from "utils/permission";
import { OPERATIONS } from "utils/consts";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
const useStylesGeneric = makeStyles(componentStylesGeneric);

function AddCandidateToOpportunitiesModal(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const methods = useForm({
    mode: "onChange",
  });

  const [opportuniesToApply, setOpportuniesToApply] = useState([]);
  const [possibleOpportunitiesList, setPossibleOpportunitiesList] = useState([]);
  const [page, setPage] = useState(PAGINATION.FIRST_PAGE);

  const [filter, setFilter] = useState({
    candidateId: props.userOverview?.id,
    companyNameOrTaxId: null,
    provinceId: null,
    professionalCategoryId: null,
    opportunityType: null,
    pageIndex: PAGINATION.FIRST_PAGE,
    pageSize: PAGINATION.PAGE_SIZE_MODAL_RESULT_TABLE,
  });

  const tableHead = [
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_OpportunityType),
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_Company),
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_ProfessionalCategory),
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_Candidates),
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_Vacancies),
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_Province),
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_Reference),
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_AcademicLevel),
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_Languages),
  ];

  const clearFilter = () => {
    methods.setValue("companyNameOrTaxId", "");
    methods.setValue(
      "province",
      getSelectOptionsElementByKey(
        props.dataSetState[DATASET.PROVINCES],
        props.userOverview?.userSingularGeneralDataOverview?.provinceKeyValue
      )
    );

    let selectedProfessionalCategoryList = [];
    props.userOverview?.userSingularProfessionalDataOverview?.professionalExperienceKeyValues?.forEach((category) => {
      selectedProfessionalCategoryList.push(
        getSelectOptionsElementByKey(props.dataSetState[DATASET.PROFESSIONAL_CATEGORY], category)
      );
    });
    methods.setValue("professionalCategory", selectedProfessionalCategoryList);

    let selectedLanguagesList = [];

    props.userOverview?.userSingularProfessionalDataOverview?.studiedLanguagesKeyValues?.forEach((language) => {
      selectedLanguagesList.push(getSelectOptionsElementByKey(props.dataSetState[DATASET.LANGUAGES], language));
    });

    methods.setValue("languages", selectedLanguagesList);

    methods.setValue(
      "opportunityType",
      getSelectOptionsElementByKey(
        props.dataSetState[DATASET.OPPORTUNITY_TYPE],
        props.opportunityTypeKey === "internshipTab"
          ? OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL
          : OPPORTUNITY_TYPE_KEY.EMPREGO
      )
    );

    methods.setValue(
      "academicLevel",
      getSelectOptionsElementByKey(
        props.dataSetState[DATASET.ACADEMIC_LEVEL],
        props.userOverview?.userSingularProfessionalDataOverview?.academicLevelKeyValue
      )
    );
  };

  const renderOfferName = (prop) => {
    if (checkOperationPermission(props.authState.operationsList, OPERATIONS.OPPORTUNITIES_MENU)) {
      return (
        <Link href={`/admin/opportunity-details/${prop.id}`} target="_blank">
          {limitaString(prop.title, 60)}
        </Link>
      );
    } else {
      return limitaString(prop.title, 60);
    }
  };

  useEffect(() => {
    //Provinces
    props.getDataSetByName(DATASET.PROVINCES);
    let provinceList = props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : [];
    let selectedProvince = getSelectOptionsElementByKey(
      provinceList,
      props.userOverview?.userSingularGeneralDataOverview?.provinceKeyValue
    );
    methods.setValue("province", selectedProvince);

    //Professional Categories
    props.getDataSetByName(DATASET.PROFESSIONAL_CATEGORY);
    let professionalCategoryList = props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]
      ? props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]
      : [];
    let selectedProfessionalCategoryList = [];

    props.userOverview?.userSingularProfessionalDataOverview?.professionalExperienceKeyValues?.forEach((category) => {
      selectedProfessionalCategoryList.push(getSelectOptionsElementByKey(professionalCategoryList, category));
    });

    methods.setValue("professionalCategory", selectedProfessionalCategoryList);

    //Languages
    props.getDataSetByName(DATASET.LANGUAGES);
    let languagesList = props.dataSetState[DATASET.LANGUAGES] ? props.dataSetState[DATASET.LANGUAGES] : [];
    let selectedLanguagesList = [];

    props.userOverview?.userSingularProfessionalDataOverview?.studiedLanguagesKeyValues?.forEach((language) => {
      selectedLanguagesList.push(getSelectOptionsElementByKey(languagesList, language));
    });

    methods.setValue("languages", selectedLanguagesList);

    //Opportunity Types
    props.getDataSetByName(DATASET.OPPORTUNITY_TYPE);
    let opportunityTypesList = props.dataSetState[DATASET.OPPORTUNITY_TYPE]
      ? props.dataSetState[DATASET.OPPORTUNITY_TYPE]
      : [];
    let selectedOpportunityType = getSelectOptionsElementByKey(
      opportunityTypesList,
      props.opportunityTypeKey === "internshipTab"
        ? OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL
        : OPPORTUNITY_TYPE_KEY.EMPREGO
    );
    methods.setValue("opportunityType", selectedOpportunityType);

    //Academic Level
    props.getDataSetByName(DATASET.ACADEMIC_LEVEL);
    let academicLevelList = props.dataSetState[DATASET.ACADEMIC_LEVEL]
      ? props.dataSetState[DATASET.ACADEMIC_LEVEL]
      : [];
    let selectedAcademicLevel = getSelectOptionsElementByKey(
      academicLevelList,
      props.userOverview?.userSingularProfessionalDataOverview?.academicLevelKeyValue
    );
    methods.setValue("academicLevel", selectedAcademicLevel);

    //Temporary filter
    let selectedProfessionalCategoryKeyValueList = [];
    selectedProfessionalCategoryList?.forEach((category) => {
      selectedProfessionalCategoryKeyValueList?.push(category?.keyValue);
    });

    let selectedLanguagesKeyValueList = [];
    selectedLanguagesList?.forEach((language) => {
      selectedLanguagesKeyValueList?.push(language?.keyValue);
    });

    let temp_filter = {
      ...filter,
      provinceId: selectedProvince?.code,
      opportunityProfessionalCategoryList: selectedProfessionalCategoryKeyValueList,
      languagesList: selectedLanguagesKeyValueList,
      opportunityTypeId: selectedOpportunityType?.code,
      academicLevel: selectedAcademicLevel?.code,
    };

    setFilter(temp_filter);

    props.getFilteredAvailableOpportunitiesForUser(temp_filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!ArrayUtils.NullOrEmpty(props.opportunitiesState?.getFilteredAvailableOpportunitiesForUser_result?.data)) {
      setPossibleOpportunitiesList(props.opportunitiesState?.getFilteredAvailableOpportunitiesForUser_result?.data);
    } else {
      setPossibleOpportunitiesList([]);
    }
  }, [props.opportunitiesState?.getFilteredAvailableOpportunitiesForUser_result]);

  const onSubmitSearch = (data) => {
    data = {
      ...data,
      candidateId: props.userOverview?.id,
    };

    let filter = getFilteredAvailableOpportunitiesForUserMap(data);
    setFilter(filter);
    if (page === PAGINATION.FIRST_PAGE) {
      props.getFilteredAvailableOpportunitiesForUser(filter);
    } else {
      handleChangePage(undefined, PAGINATION.FIRST_PAGE, filter);
    }
  };

  const handleChangePage = (event, value) => {
    if (page !== value) {
      setPage(value);
      let temp_filter = { ...filter, pageIndex: value };
      setFilter(temp_filter);
      props.getFilteredAvailableOpportunitiesForUser(temp_filter);
    }
  };

  const checkIfOpportunityInApplicationsList = (opportunityId) => {
    if (opportuniesToApply.includes(opportunityId)) {
      return true;
    }

    return false;
  };

  const addOpportunitytoApplicationsList = (opportunityId) => {
    setOpportuniesToApply((prevState) => {
      if (checkIfOpportunityInApplicationsList(opportunityId)) {
        return [...prevState];
      }
      return [...prevState, opportunityId];
    });
  };

  const removeOpportunityFromApplicationsList = (opportunityId) => {
    setOpportuniesToApply((prevState) => {
      let elements = prevState.filter((element) => element !== opportunityId);

      return elements;
    });
  };

  let handleNewApplicationsList = (opportunityId, opportunityState) => {
    if (opportunityState) {
      addOpportunitytoApplicationsList(opportunityId);
    } else {
      removeOpportunityFromApplicationsList(opportunityId);
    }
  };

  const checkIfAllOpportunitiesChecked = (opportunitiesList) => {
    const getListOfStates = opportunitiesList?.map((prop) => {
      if (!checkIfOpportunityInApplicationsList(prop.id)) {
        return false;
      }
      return true;
    });

    if (getListOfStates.includes(false)) {
      return false;
    }
    return true;
  };

  const handleApplyToOpportunitiesList = () => {
    const data = {
      userId: props.userOverview?.id,
      opportunitiesId: opportuniesToApply,
    };

    props.createApplicationsForUser(data, () => {
      props.handleClose();
      props.onSuccess();
    });
  };

  return (
    <Container>
      <Box className={classes.modalHeader} style={{ marginBottom: "30px" }}>
        {intl.formatMessage(
          labels.Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_AddCandidatesTitle
        )}
      </Box>
      <form onSubmit={methods.handleSubmit(onSubmitSearch)}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <FormGroup data-testid="filter-companyNameOrTaxId">
              <FormLabel>
                {intl.formatMessage(
                  labels.Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_CompanyNameOrTaxId
                )}
              </FormLabel>
              <Controller
                name="companyNameOrTaxId"
                control={methods.control}
                defaultValue=""
                rules={{ required: { value: false } }}
                render={({ field: { onChange, name, value } }) => (
                  <OutlinedInput
                    name={name}
                    value={value}
                    onChange={onChange}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    placeholder="Pesquisar"
                    data-testid="input-companyNameOrTaxId"
                    endAdornment={
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    classes={{
                      notchedOutline: clsx({
                        [classes.borderWarning]: methods.formState.errors["companyNameOrTaxId"] !== undefined,
                      }),
                    }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormGroup data-testid="filter-province">
              <FormLabel>
                {intl.formatMessage(
                  labels.Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_Province
                )}
              </FormLabel>
              <FormSelect
                control={methods.control}
                fieldName="province"
                selectOptions={props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : []}
                getValues={methods.getValues}
                setValue={methods.setValue}
                errors={methods.formState.errors}
                classes={classes}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormGroup data-testid="filter-opportunityType">
              <FormLabel>
                {intl.formatMessage(
                  labels.Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_OpportunityType
                )}
              </FormLabel>
              <FormSelect
                control={methods.control}
                fieldName="opportunityType"
                selectOptions={
                  props.dataSetState[DATASET.OPPORTUNITY_TYPE] ? props.dataSetState[DATASET.OPPORTUNITY_TYPE] : []
                }
                getValues={methods.getValues}
                setValue={methods.setValue}
                errors={methods.formState.errors}
                classes={classes}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormGroup data-testid="filter-professionalCategory">
              <FormLabel>
                {intl.formatMessage(
                  labels.Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_ProfessionalCategory
                )}
              </FormLabel>
              <FormMultipleSelect
                control={methods.control}
                fieldName="professionalCategory"
                selectOptions={
                  props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]
                    ? props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]
                    : []
                }
                initialLabel={intl.formatMessage(labels.Label_SelectMultipleOptions)}
                getValues={methods.getValues}
                errors={methods.formState.errors}
                classes={classes}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormGroup data-testid="filter-academicLevel">
              <FormLabel>
                {intl.formatMessage(
                  labels.Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_AcademicLevel
                )}
              </FormLabel>
              <FormSelect
                control={methods.control}
                fieldName="academicLevel"
                selectOptions={
                  //TODO: Valor default deve ser o nível académico do candidato. Por também no clear filters.
                  props.dataSetState[DATASET.ACADEMIC_LEVEL] ? props.dataSetState[DATASET.ACADEMIC_LEVEL] : []
                }
                getValues={methods.getValues}
                setValue={methods.setValue}
                errors={methods.formState.errors}
                classes={classes}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormGroup data-testid="filter-languages">
              <FormLabel>
                {intl.formatMessage(
                  labels.Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_Languages
                )}
              </FormLabel>
              <FormMultipleSelect
                control={methods.control}
                fieldName="languages"
                selectOptions={
                  //TODO: Pesquisa disto não está a funcionar
                  props.dataSetState[DATASET.LANGUAGES] ? props.dataSetState[DATASET.LANGUAGES] : []
                }
                initialLabel={intl.formatMessage(labels.Label_SelectMultipleOptions)}
                getValues={methods.getValues}
                errors={methods.formState.errors}
                classes={classes}
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={12} classes={{ root: classes.alignRight }}>
            <GenericButton
              typeSubmit={false}
              color="ghost"
              onClick={() => {
                clearFilter();
              }}
            >
              {intl.formatMessage(labels.Label_ClearFilters)}
            </GenericButton>
            <GenericButton
              typeSubmit={true}
              color="primary"
              size="medium"
              loading={props.opportunitiesState.getFilteredAvailableOpportunitiesForUser_loading}
            >
              {intl.formatMessage(labels.Label_FilterSection_SearchButton)}
            </GenericButton>
          </Grid>
        </Grid>
      </form>
      {
        <Box style={{ marginTop: "2rem" }}>
          <TableContainer>
            <Box component={Table} alignItems="center" marginBottom="0!important">
              <TableHead>
                <TableRow>
                  {tableHead.map((prop, key) => (
                    <TableCell
                      key={key}
                      classes={{
                        root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                      }}
                      data-testid={"table-header-" + prop}
                    >
                      {prop}
                    </TableCell>
                  ))}
                  <TableCell
                    key="selectCol"
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootHead +
                        " " +
                        classes.tableCellHeadStickyRight,
                    }}
                    styles="width: '200px'"
                  >
                    <Grid container>
                      <Grid item xs={12} md={12} classes={{ root: classes.alignRight }}>
                        <FormControlLabel
                          onChange={(e) => {
                            possibleOpportunitiesList?.map((prop) =>
                              handleNewApplicationsList(prop.id, e.target.checked)
                            );
                          }}
                          value="end"
                          control={
                            <Checkbox
                              disabled={possibleOpportunitiesList.length === 0}
                              color="primary"
                              checked={
                                possibleOpportunitiesList.length > 0 &&
                                checkIfAllOpportunitiesChecked(possibleOpportunitiesList)
                              }
                              data-testid="table-header-select-all"
                            />
                          }
                          label={
                            <Box className={classes.formControlLabelCheckboxInTableHeader}>
                              {intl.formatMessage(labels.Label_Select)}
                            </Box>
                          }
                          labelPlacement="start"
                          className={classes.formControlLabelNoMargin}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>

              {possibleOpportunitiesList.length > 0 && (
                <TableBody>
                  {possibleOpportunitiesList?.map((prop, key) => (
                    <TableRow key={key}>
                      <TableCell classes={{ root: classes.tableCellRoot }} title={prop.title ?? ""}>
                        <Box fontWeight={"bold"}>{prop.opportunityTypeDescription}</Box>
                        <Box>{renderOfferName(prop)}</Box>
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }} title={prop.title ?? ""}>
                        <Box fontWeight={"bold"}>{prop.userTaxIdNumber}</Box>
                        <Box>{limitaString(prop.userName, 60)}</Box>
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        {prop.professionalCategoriesDescription}
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>{prop.totalCandidates}</TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        {prop.approvedCandidates}
                        {"/"}
                        {prop.numVacancies}
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>{prop.provinceDescription}</TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }} title={prop.reference ?? ""}>
                        {limitaString(prop.reference, 60)}
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>{prop.academicLevelDescription}</TableCell>
                      <TableCell
                        classes={{ root: classes.tableRowEllipsis + " " + classes.tableCellRoot }}
                        title={prop.languages?.join(", ") ?? ""}
                      >
                        {prop.languages?.join(", ") ?? ""}
                      </TableCell>
                      <TableCell
                        classes={{
                          root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight,
                        }}
                      >
                        <Box alignItems="center" justifyContent="center" display="flex">
                          <FormControlLabel
                            onChange={(e) => {
                              handleNewApplicationsList(prop.id, e.target.checked);
                            }}
                            control={
                              <Checkbox color="primary" checked={checkIfOpportunityInApplicationsList(prop.id)} />
                            }
                            style={{ margin: 0 }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Box>
            {!props.opportunitiesState.getFilteredAvailableOpportunitiesForUser_loading &&
              possibleOpportunitiesList.length === 0 && (
                <Box className={classes.noResultsInfoInTable}>{intl.formatMessage(labels.Label_NoResultsFound)}</Box>
              )}
            <TableBackdrop open={props.opportunitiesState.getFilteredAvailableOpportunitiesForUser_loading} />
          </TableContainer>

          <Pagination
            className={classes.alignPagination}
            count={props.opportunitiesState?.getFilteredAvailableOpportunitiesForUser_result?.totalPages}
            page={page}
            onChange={handleChangePage}
            color="primary"
          ></Pagination>
        </Box>
      }
      <Grid container>
        <Grid item xs={12} lg={6} xl={8}></Grid>
        <Grid item xs={12} lg={3} xl={2}>
          <FormGroup>
            <GenericButton typeSubmit={false} color="secondary" onClick={props.handleClose}>
              {intl.formatMessage(labels.Label_Generic_Cancel)}
            </GenericButton>
          </FormGroup>
        </Grid>
        <Grid item xs={12} lg={3} xl={2}>
          <FormGroup>
            <GenericButton
              typeSubmit={false}
              color="primary"
              onClick={handleApplyToOpportunitiesList}
              disabled={opportuniesToApply.length === 0}
              loading={props.opportunitiesState.createApplicationsForUserLoading}
            >
              {intl.formatMessage(labels.Label_Generic_Add)}
            </GenericButton>
          </FormGroup>
        </Grid>
      </Grid>
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getFilteredAvailableOpportunitiesForUser: (data) => dispatch(getFilteredAvailableOpportunitiesForUserAction(data)),
  createApplicationsForUser: (data, onSuccess) => dispatch(createApplicationsForUserAction(data, onSuccess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCandidateToOpportunitiesModal);
