import { React } from "react";
import { connect } from "react-redux";
import "moment/locale/pt";
import "moment/locale/pt";
import moment from "moment";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import FormLabel from "@material-ui/core/FormLabel";
// @material-ui/icons components
import NotificationsIcon from "@material-ui/icons/Notifications";
//import custom components
import TableStateChip from "components/Cards/Tables/TableStateChip";
import { OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY, TABLE_STATE_CHIP_COLOR } from "utils/consts";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function FollowUpHistory(props) {
  const classes = useStylesGeneric();

  const mapOpportunityFollowUpStateToTableChipColors = (stateKey) => {
    switch (stateKey) {
      case OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY.PORINICIAR:
        return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE;
      case OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY.ADECORRER:
        return TABLE_STATE_CHIP_COLOR.GREEN;
      case OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY.TERMINADO:
        return TABLE_STATE_CHIP_COLOR.RED;
      default:
        return <></>;
    }
  };

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{ title: classes.cardHeader, action: classes.cardHeaderAction }}
        title="Linha do Tempo"
        action={
          <TableStateChip
            state={mapOpportunityFollowUpStateToTableChipColors(
              props.opportunityApplicationFollowUpDetails?.candidateFollowUpStateKey
            )}
            message={props.opportunityApplicationFollowUpDetails?.candidateFollowUpStateDescription}
          />
        }
      ></CardHeader>
      <CardContent>
        {props.opportunityApplicationFollowUpDetails?.followUpHistory?.length > 0 ? (
          <Grid container>
            <Grid item xs={12} md={12}>
              <Timeline
                style={{
                  height: "400px",
                  overflow: "auto",
                }}
              >
                {props.opportunityApplicationFollowUpDetails?.followUpHistory?.map((historyEntry) => {
                  return (
                    <TimelineItem key={historyEntry.id} className={classes.minHeight0PX}>
                      <TimelineOppositeContent style={{ flex: 0 }}></TimelineOppositeContent>
                      <TimelineSeparator>
                        {/* <TimelineDot> */}
                        <Box className={classes.timelineIconsBox}>
                          <NotificationsIcon className={classes.timelineIcons} />
                        </Box>
                        {/* </TimelineDot> */}
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography variant="caption">
                          {historyEntry.createUser} -{" "}
                          {historyEntry.createDatetime
                            ? moment(historyEntry.createDatetime).format("DD/MM/YYYY HH:mm")
                            : ""}
                        </Typography>
                        <Typography variant="h5" component="h1">
                          {historyEntry.actionName}
                        </Typography>
                        <Typography variant="caption">{historyEntry.message}</Typography>
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}
              </Timeline>
            </Grid>
          </Grid>
        ) : (
          <FormLabel className={classes.detailFormLabels}>Não existem dados para apresentar.</FormLabel>
        )}
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(FollowUpHistory);
