import axios from "axios";
import { INTERNAL_API_URL } from "../index";
import { EXTERNAL_API_URL } from "../index";

export const GET_DASHBOARD_STOCK_INFO_URL = "Stock/get-lowest-stocks";
export const GET_DASHBOARD_NEW_COMPANIES_INFO_URL = "User/get-unapproved-users";
export const GET_DASHBOARD_ONGOING_APPLICATIONS_INFO_URL = "Applications/get-pending-applications";
export const GET_DASHBOARD_NOTIFICATIONS_INFO_URL = "UserNotifications/get-notifications";
export const GET_DASHBOARD_UNREAD_NOTIFICATIONS_TOTAL_URL = "UserNotifications/get-number-of-unread-notifications";
export const GET_DASHBOARD_OPPORTUNITIES_INFO_URL = "opportunity/get-pending-opportunities";
export const GET_OPPORTUNITY_APPLICATION_FOLLOWUPS_WITH_UNAPPROVED_REPORTS_URL = "opportunity/get-opportunity-application-followups-with-unapproved-reports";

export function getDashboardStockInfo() {
    return axios.get(INTERNAL_API_URL + GET_DASHBOARD_STOCK_INFO_URL, {
        params: {
            maxResults: 6,
        }
    });
}

export function getDashboardNewCompaniesInfo() {
    return axios.get(INTERNAL_API_URL + GET_DASHBOARD_NEW_COMPANIES_INFO_URL, {
        params: {
            maxResults: 6
        }
    });
}

export function getDashboardOnGoingApplicationsInfo() {
    return axios.get(INTERNAL_API_URL + GET_DASHBOARD_ONGOING_APPLICATIONS_INFO_URL);
}

export function getDashboardNotificationsInfo(formData) {
    return axios.post(EXTERNAL_API_URL + GET_DASHBOARD_NOTIFICATIONS_INFO_URL, formData);
}

export function getDashboardUnreadNotificationsCountInfo() {
    return axios.get(EXTERNAL_API_URL + GET_DASHBOARD_UNREAD_NOTIFICATIONS_TOTAL_URL);
}

export function getDashboardOpportunities() {
    return axios.get(INTERNAL_API_URL + GET_DASHBOARD_OPPORTUNITIES_INFO_URL);
}

export function getOpportunityApplicationFollowUpsWithUnapprovedReports() {
    return axios.get(INTERNAL_API_URL + GET_OPPORTUNITY_APPLICATION_FOLLOWUPS_WITH_UNAPPROVED_REPORTS_URL);
  }


