import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useIntl } from "react-intl";

// @material-ui
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";

// components
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import { PAGINATION } from "utils/consts";
import { getBOUserLoginsHistoryAction } from "redux/actions/usersAction";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import CardTableContainer from "components/Cards/Tables/CardTableContainer";
import { labels } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function LoginsHistory(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const [page, setPage] = useState(1);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const tableHead = [
    intl.formatMessage(labels.Label_BO_UserProfile_LoginHistory_Date),
    intl.formatMessage(labels.Label_BO_UserProfile_LoginHistory_Hour),
  ];

  const getBOUserLoginsHistory = useCallback(() => {
    const filter = {
      pageIndex: page <= 0 ? 1 : page,
      pageSize: PAGINATION.PAGE_SIZE,
      userId: props.userId,
    };
    
    props.getBOUserLoginsHistory(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    getBOUserLoginsHistory();
  }, [getBOUserLoginsHistory]);
  
  return (
    <>
      <CardTableContainer
        title={intl.formatMessage(labels.Label_BO_UserProfile_LoginHistory)}
        count={props.usersState?.getBOUserLoginsHistory_result?.totalCount}
      >
        <TableContainer>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead>
              <TableRow>
                <TableCell
                  classes={{
                    root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                  }}
                />
                {tableHead.map((prop, key) => (
                  <TableCell
                    key={key}
                    classes={{
                      root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    {prop}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.usersState?.getBOUserLoginsHistory_result?.data?.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell classes={{ root: classes.tableCellRoot }} />
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box>{moment(prop?.loginDate).format("DD/MM/YYYY")}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box>{moment(prop?.loginDate).format("HH:mm")}</Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Box>
          <TableBackdrop open={props?.usersState?.getBOUserLoginsHistory_loading} />
        </TableContainer>

        <Pagination
          className={classes.alignPagination}
          count={props.usersState?.getBOUserLoginsHistory_result?.totalPages}
          page={page}
          onChange={handleChangePage}
          color="primary"
        ></Pagination>
      </CardTableContainer>
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getBOUserLoginsHistory: (data) => dispatch(getBOUserLoginsHistoryAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginsHistory);

