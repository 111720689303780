//compile the other user reducer into this one!
import { USERS, REGISTER_BO_USER } from "utils/actionsConsts";

const usersInfoReducer = (
  state = {
    getUserInfo_loading: false,
  },
  action
) => {
  switch (action.type) {
    case USERS.GET_USER_INFO:
      return { ...state, result: action.payload };
    case USERS.GET_USER_INFO_LOADING:
      return { ...state, getUserInfo_loading: action.payload };
    case USERS.UPDATE_INDIVIDUAL_USER_LOADING:
      return { ...state, updateIndividualUser_loading: action.payload };
    case USERS.GET_USER_INFO_BO:
      return { ...state, result: action.payload };
    case USERS.GET_USER_INFO_BO_LOADING:
      return { ...state, getUserInfoBO_loading: action.payload };
    case USERS.UPDATE_Profile_LOADING:
      return { ...state, updateProfile_loading: action.payload };
    case USERS.GET_USER_INFO_ENT:
      return { ...state, result: action.payload };
    case REGISTER_BO_USER.REGISTER_LOADING:
      return { ...state, backOfficeUserRegister_loading: action.payload };
    case USERS.UPDATE_BO_USER_LOADING:
      return { ...state, backOfficeUserUpdate_loading: action.payload };
    case USERS.VALIDATE_COLECTIVE_USER_LOADING:
      return { ...state, validateColectiveUser_loading: action.payload };
    case USERS.GET_FO_USER_COLECTIVE_BASIC_INFO:
      return { ...state, foUserColectiveBasicInfo: action.payload };
    default:
      return state;
  }
};

export default usersInfoReducer;
