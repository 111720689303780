import axios from "axios";
import { EXTERNAL_API_URL, INTERNAL_API_URL } from "../index";
import { ROLES_ORIGIN_APPLICATION } from "utils/consts";

export const GET_DATASET_BY_NAME_URL = "DataSets/GetDataSet";
export const GET_PROGRAMS_URL = "Programs/get-all-programs";
export const GET_SUBPROGRAMS_URL = "SubPrograms/get-all-subprograms";
export const GET_DEPARTMENTS_URL = "Department/get-all-departments";
export const GET_CATEGORIES_URL = "Categories/get-all-categories";
export const GET_ACTIVE_CATEGORIES_URL = "Categories/get-active-categories";
export const GET_NOT_ASSIGNED_ACTIVE_CATEGORIES_URL = "Categories/get-not-assigned-active-categories";
export const GET_INACTIVE_CATEGORIES_URL = "Categories/get-inactive-categories"
export const GET_LOSS_BREAK_MOTIVES_URL = "Stock/get-all-motives";
export const GET_WAREHOUSE_BY_PROVINCE_URL = "Warehouses/get-warehouse";
export const GET_DOCUMENTS_URL = "Documents/getDocuments";
export const GET_CONDITIONS_URL = "Conditions/getConditions";
export const GET_NEXT_APPLICATION_STATES_URL = "Applications/get-next-application-states";
export const GET_SUBPROGRAM_STATES_URL = "Subprograms/get-all-subprogram-states"
export const GET_BACKOFFICE_USERS_URL = "User/get-all-backoffice-users";
export const GET_BACKOFFICE_ROLES_URL = "Roles/getAllRoles";
export const GET_SHAPE_PROGRAMS_URL = "ExternalServices/get-shape-programs"
export const GET_SHAPE_SUBSITES_URL = "ExternalServices/get-shape-subsites"
export const GET_LOAN_STATES_URL = "Loans/get-all-loan-states"
export const GET_SUBPROGRAMS_BY_PROGRAM_URL = "SubPrograms/get-all-subprograms?programId="
export const GET_ALL_PREDEFINED_QUESTIONS ="PredefinedDataSet/get-all-predefined-question"
export const GET_AVAILABLE_INTERNSHIPS_OPPORTUNITY_URL = "opportunity/get-available-internship-opportunities";
export const GET_GOVERNMENT_PROGRAMS_URL = "GovernmentPrograms/get-governmentprograms";

export function getShapePrograms(target, province) {
  return axios.get(EXTERNAL_API_URL + GET_SHAPE_PROGRAMS_URL, {
    params: {
      SearchTarget: target,
      IdProvincia: province
    }
  });
}

export function getShapeSubsites(offer, province) {
  return axios.get(EXTERNAL_API_URL + GET_SHAPE_SUBSITES_URL, {
    params: {
      OfferId: offer,
      ProvinceId: province
    }
  });
}


export function getDataSetByName(name, parentKeyValue) {
  return axios.get(EXTERNAL_API_URL + GET_DATASET_BY_NAME_URL, {
    params: {
      name: name,
      parentKeyValue: parentKeyValue
    },
  });
}

export function getPrograms(governmentProgramId) {
  return axios.get(EXTERNAL_API_URL + GET_PROGRAMS_URL, {
    params: {
      governmentProgramId: governmentProgramId,
    }
  });
}

export function getSubprogramStates() {
  return axios.get(INTERNAL_API_URL + GET_SUBPROGRAM_STATES_URL);
}

export function getDepartments() {
  return axios.get(EXTERNAL_API_URL + GET_DEPARTMENTS_URL);
}

export function getCategories() {
  return axios.get(INTERNAL_API_URL + GET_CATEGORIES_URL);
}

export function getActiveCategories() {
  return axios.get(INTERNAL_API_URL + GET_ACTIVE_CATEGORIES_URL);
}
export function getInactiveCategories() {
  return axios.get(INTERNAL_API_URL + GET_INACTIVE_CATEGORIES_URL);
}
export function getNotAssignedActiveCategories() {
  return axios.get(INTERNAL_API_URL + GET_NOT_ASSIGNED_ACTIVE_CATEGORIES_URL);
}

export function getWarehousesByProvince(province) {
  return axios.get(INTERNAL_API_URL + GET_WAREHOUSE_BY_PROVINCE_URL, {
    params: {
      provinceKeyValue: province
    }
  });
}

export function getDocuments() {
  return axios.get(EXTERNAL_API_URL + GET_DOCUMENTS_URL);
}

export function getConditions() {
  return axios.get(EXTERNAL_API_URL + GET_CONDITIONS_URL);
}

export function getNextApplicationStates(formData) {
  return axios.get(EXTERNAL_API_URL + GET_NEXT_APPLICATION_STATES_URL, {
    params: {
      applicationStateId: formData?.applicationStateId,
      benefitId: formData?.benefitId,
    },
  });
}

export function getSubprograms() {
  return axios.get(INTERNAL_API_URL + GET_SUBPROGRAMS_URL);
}

export function getSubprogramByProgramId(id) {
  return axios.get(INTERNAL_API_URL + GET_SUBPROGRAMS_BY_PROGRAM_URL + id);
}

export function getBackOfficeUsers() {
  return axios.get(INTERNAL_API_URL + GET_BACKOFFICE_USERS_URL);
}

export function getBackOfficeRoles() {
  return axios.get(INTERNAL_API_URL + GET_BACKOFFICE_ROLES_URL, {
    params: {
      originApplication: ROLES_ORIGIN_APPLICATION.BACKOFFICE
    }
  });
}

export function getLoanStates() {
  return axios.get(INTERNAL_API_URL + GET_LOAN_STATES_URL);
}

export function getPredefinedQuestions() {
  return axios.get(INTERNAL_API_URL + GET_ALL_PREDEFINED_QUESTIONS);
}

export function getAvailableInternshipOpportunities(province, professionalCategory, internshipType) {
  return axios.get(INTERNAL_API_URL + GET_AVAILABLE_INTERNSHIPS_OPPORTUNITY_URL, {
    params: {
      provinceId:province,
      professionalCategoryId:professionalCategory,
      internshipTypeKeyValue:internshipType
    }
  });
}

export function getGovernmentPrograms() {
  return axios.get(EXTERNAL_API_URL + GET_GOVERNMENT_PROGRAMS_URL);
}