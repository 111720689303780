import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
//import custom components
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import FollowUpDetailsTab from "./Tabs/FollowUpDetailsTab";
import FollowUpHistoryTab from "./Tabs/FollowUpHistoryTab";
import FollowUpFeedbackReportsTab from "./Tabs/FollowUpFeedbackReportsTab";
import FollowUpEvaluationTab from "./Tabs/FollowUpEvaluationTab";
import { getOpportunityApplicationsFollowUpDetailsAction } from "redux/actions/opportunitiesAction";
import useScrollDirection from "hooks/useScrollDirection";
import Tabs from "components/Tabs/Tabs";

import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function OpportunityApplicationFollowUpDetails(props) {
  const classes = useStylesGeneric();
  const scrollDirection = useScrollDirection();

  const TABS = {
    DETAILS: "detailsTab",
    HISTORY: "historyTab",
    FORMS: "formsTab",
    EVALUATION: "evaluationTab",
  };

  const [tabStep, setTabStep] = useState(TABS.DETAILS);
  const [refreshPage, setRefreshPage] = useState(false);

  useEffect(() => {
    props.getOpportunityApplicationsFollowUpDetails(props.match?.params?.candidateCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabStep = (event, value) => {
    if (value !== null) {
      setTabStep(value);
    }
  };

  const handleRefreshPage = () => {
    setRefreshPage(true);
  };

  useEffect(() => {
    if (refreshPage) {
      refreshResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const refreshResults = () => {
    props.getOpportunityApplicationsFollowUpDetails(props.match?.params?.candidateCode);
    setRefreshPage(false);
  };

  function loadTabContent() {
    switch (tabStep) {
      case TABS.DETAILS:
        return (
          <FollowUpDetailsTab
            opportunityApplicationFollowUpDetails={
              props?.opportunitiesState?.opportunityApplicationsFollowUpDetails_result
            }
            handleRefreshPage={handleRefreshPage}
          />
        );
      case TABS.HISTORY:
        return (
          <FollowUpHistoryTab
            opportunityApplicationFollowUpDetails={
              props?.opportunitiesState?.opportunityApplicationsFollowUpDetails_result
            }
          />
        );
      case TABS.FORMS:
        return (
          <FollowUpFeedbackReportsTab
            opportunityApplicationFollowUpDetails={
              props?.opportunitiesState?.opportunityApplicationsFollowUpDetails_result
            }
            handleRefreshPage={handleRefreshPage}
          />
        );
      case TABS.EVALUATION:
        return (
          <FollowUpEvaluationTab
            opportunityApplicationFollowUpDetails={
              props?.opportunitiesState?.opportunityApplicationsFollowUpDetails_result
            }
          />
        );
      default:
        return (
          <FollowUpDetailsTab
            opportunityApplicationFollowUpDetails={
              props?.opportunitiesState?.opportunityApplicationsFollowUpDetails_result
            }
            handleRefreshPage={handleRefreshPage}
          />
        );
    }
  }

  return (
    <>
      <GenericBackdrop open={props?.opportunitiesState?.getOpportunityApplicationsFollowUpDetails_loading} />

      {scrollDirection === "up" && (
        <div className={classes.breadcrumbs}>
          <LocationHeader
            subsection={"Lista de Estágios"}
            section={"Detalhes do Estágio"}
            subsectionLink={"/admin/opportunity-applications-followup-management"}
          />
        </div>
      )}

      <Container maxWidth={false} component={Box} classes={{ root: classes.containerRootAfterBreadcrumbs }}>
        <PageTitleHeader
          title={props?.opportunitiesState?.opportunityApplicationsFollowUpDetails_result?.opportunityTitle}
          subsectionLink={"/admin/opportunity-applications-followup-management"}
        />

        <Box component={Grid} container justifyContent="center">
          <Tabs
            tabs={[
              {
                value: TABS.DETAILS,
                testId: "opportunity-application-followup-detail-details-tab",
                text: "Dados Gerais",
              },
              {
                value: TABS.HISTORY,
                testId: "opportunity-application-followup-detail-history-tab",
                text: "Histórico",
              },
              {
                value: TABS.FORMS,
                testId: "opportunity-application-followup-detail-forms-tab",
                text: "Formulários",
              },
              {
                value: TABS.EVALUATION,
                testId: "opportunity-application-followup-detail-evaluation-tab",
                text: "Avaliação",
              },
            ]}
            onChange={handleTabStep}
            step={tabStep}
          />

          <Grid item xs={12} lg={12} md={12}>
            {loadTabContent()}
          </Grid>
        </Box>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getOpportunityApplicationsFollowUpDetails: (data) => dispatch(getOpportunityApplicationsFollowUpDetailsAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityApplicationFollowUpDetails);
