import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import rootReducer from "redux/reducers/rootReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "sigpape",
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
function configureStore(
  initialState = {
    authState: {},
  }
) {
  return createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(reduxThunk), window.devToolsExtension ? window.devToolsExtension() : (f) => f)
  );
}

const store = configureStore();
const persistor = persistStore(store);

export { store, persistor };
