import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { Controller } from "react-hook-form";
import "moment/locale/pt";
import ReactDatetime from "react-datetime";
import moment from "moment";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormInput from "components/FormFields/FormInput";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons components
import HelpIcon from "@material-ui/icons/Help";
import DateRange from "@material-ui/icons/DateRange";
//import custom components
import GenericButton from "components/Buttons/GenericButton";
import FormSelect from "components/FormFields/FormSelect";
import FormMultipleSelect from "components/FormFields/FormMultipleSelect";
import { getDataSetByNameAction, clearDataSetByNameAction } from "redux/actions/datasetsAction";
import { DATASET, REGISTRATION_CONSTS, REGEX_PATTERNS } from "utils/consts";
import { getSelectOptionsElementByCode, getMultipleSelectOptionsElementByCode } from "utils/formSelect";
import { errorMessages, labels } from "resources/resources";
import { getDatasetStateByName } from "utils/datasets";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import useTokenHelper from "hooks/useTokenHelper";
const useStylesGeneric = makeStyles(componentStylesGeneric);

function EnterpriseUserData(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const theme = useTheme();
  const [isEditable, setIsEditable] = useState(props.isEditable);

  const methods = useFormContext();

  const tokenHelper = useTokenHelper();

  const handleEditSection = () => {
    setIsEditable(!isEditable);
  };

  useEffect(() => {
    if (props.dataSetState[DATASET.PROVINCES] === undefined || props.dataSetState[DATASET.PROVINCES].length === 0) {
      props.getDataSetByName(DATASET.PROVINCES);
    }
    if (
      props.dataSetState[DATASET.BUSINESS_PURPOSE] === undefined ||
      props.dataSetState[DATASET.BUSINESS_PURPOSE].length === 0
    ) {
      props.getDataSetByName(DATASET.BUSINESS_PURPOSE);
    }

    if (
      //EMPTY
      props.dataSetState[DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION] === undefined ||
      props.dataSetState[DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION].length === 0
    ) {
      props.getDataSetByName(DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION);
    }

    if (
      props.dataSetState[DATASET.EMPLOYMENT_CENTERS] === undefined ||
      props.dataSetState[DATASET.EMPLOYMENT_CENTERS].length === 0
    ) {
      props.getDataSetByName(DATASET.EMPLOYMENT_CENTERS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.usersInfo.result !== undefined && !props.isCreate) {
      setFormValuesOnMount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.usersInfo.result]);

  const setFormValuesOnMount = () => {
    methods.setValue("name", props.usersInfo.result !== undefined ? props.usersInfo.result?.name : null);
    methods.setValue("mobilePhone", props.usersInfo.result !== undefined ? props.usersInfo.result?.mobilePhone : null);
    props.setOldMobilePhone(props.usersInfo?.result?.mobilePhone);
    methods.setValue(
      "phoneNumberConfirmation",
      props.usersInfo.result !== undefined ? props.usersInfo.result?.mobilePhone : null
    );
    methods.setValue(
      "alternativeMobilePhone",
      props.usersInfo.result !== undefined ? props.usersInfo.result?.alternativeMobilePhone : null
    );

    methods.setValue("email", props.usersInfo.result !== undefined ? props.usersInfo.result?.email : null);
    methods.setValue("emailConfirmation", props.usersInfo.result !== undefined ? props.usersInfo.result?.email : null);
    props.setOldEmail(props.usersInfo?.result?.email);

    methods.setValue(
      "contactName",
      props.usersInfo.result !== undefined ? props.usersInfo.result?.userColective?.contactName : null
    );

    methods.setValue(
      "isActive",
      DATASET.YES_OR_NO_DROPDOWN.find((t) => t.code === props.usersInfo.result?.isActive)
    );

    var businessPurposeList = props.dataSetState[DATASET.BUSINESS_PURPOSE]
      ? props.dataSetState[DATASET.BUSINESS_PURPOSE]
      : [];

    var businessPurposes =
      props.usersInfo.result !== undefined ? props.usersInfo.result?.userColective?.businessPurpose : null;

    methods.setValue("businessPurpose", getMultipleSelectOptionsElementByCode(businessPurposeList, businessPurposes));

    methods.setValue(
      "taxIdNumber",
      props.usersInfo.result !== undefined ? props.usersInfo.result?.userColective?.taxIdNumber : null
    );

    methods.setValue(
      "permitCodeValidUntil",
      props.usersInfo.result !== undefined ? moment(props.usersInfo.result?.userColective?.permitCodeValidUntil) : null
    );

    var provinceList = props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : [];

    var provinces =
      props.usersInfo.result !== undefined ? props.usersInfo.result?.userColective?.compositeAddress?.province : null;

    methods.setValue("province", getSelectOptionsElementByCode(provinceList, provinces));

    if (props.getDataSet !== undefined) {
      props.getDataSet(
        DATASET.EMPLOYMENT_CENTERS,
        props.usersInfo.result?.userColective?.compositeAddress?.provinceKey,
        true
      );
    }

    var employmentCentersList = props.dataSetState[DATASET.EMPLOYMENT_CENTERS]
      ? props.dataSetState[DATASET.EMPLOYMENT_CENTERS]
      : [];

    methods.setValue(
      "employmentCenter",
      getSelectOptionsElementByCode(employmentCentersList, props?.usersInfo.result?.employmentCenterId)
    );

    var municipalityList = props.dataSetState[DATASET.MUNICIPALITIES] ? props.dataSetState[DATASET.MUNICIPALITIES] : [];

    var municipalities =
      props.usersInfo.result !== undefined
        ? props.usersInfo.result?.userColective?.compositeAddress?.municipality
        : null;

    methods.setValue("municipality", getSelectOptionsElementByCode(municipalityList, municipalities));

    methods.setValue(
      "district",
      props.usersInfo.result !== undefined ? props.usersInfo.result?.userColective?.compositeAddress?.district : null
    );
    methods.setValue(
      "address",
      props.usersInfo.result !== undefined ? props.usersInfo.result?.userColective?.compositeAddress?.address : null
    );
    methods.setValue("niss", props.usersInfo.result !== undefined ? props.usersInfo.result?.userColective?.NISS : null);

    methods.setValue(
      "permitCode",
      props.usersInfo.result !== undefined ? props.usersInfo.result?.userColective?.permitCode : null
    );

    methods.setValue(
      "companyEmail",
      props.usersInfo.result !== undefined ? props.usersInfo.result?.userColective?.companyEmail : null
    );
    methods.setValue(
      "companyPhone",
      props.usersInfo.result !== undefined ? props.usersInfo.result?.userColective?.companyPhone : null
    );

    methods.setValue(
      "experience",
      props.usersInfo.result !== undefined ? props.usersInfo.result?.userColective?.experience : null
    );

    if (props.dataSetState && props.dataSetState[DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION]) {
      methods.setValue(
        "companyEACId",
        getSelectOptionsElementByCode(
          props.dataSetState[DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION],
          props.usersInfo.result?.userColective?.companyEACId
        )
      );

      methods.setValue(
        "subsidiaryCompanyEACId",
        getSelectOptionsElementByCode(
          props.dataSetState[DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION],
          props.usersInfo.result?.userColective?.subsidiaryCompanyEACId
        )
      );
    }

    methods.setValue("propertyType", props.usersInfo.result?.userColective?.propertyType);
    methods.setValue("subsidiaryCompany", props.usersInfo.result?.userColective?.subsidiaryCompany);
    methods.setValue("subsidiaryCompanyPostalCode", props.usersInfo.result?.userColective?.subsidiaryCompanyPostalCode);
    methods.setValue("subsidiaryCompanyActivity", props.usersInfo.result?.userColective?.subsidiaryCompanyActivity);
    methods.setValue("maleWorkersNumber", props.usersInfo.result?.userColective?.maleWorkersNumber);
    methods.setValue("femaleWorkersNumber", props.usersInfo.result?.userColective?.femaleWorkersNumber);
  };

  const handleValidDate = (date, onChange) => {
    if (date === "" || REGEX_PATTERNS.DATE.test(date)) {
      onChange(date);
    }
  };

  useEffect(() => {
    if (props.dataSetState) {
      var provinceList = props.dataSetState[DATASET.EMPLOYMENT_CENTERS] ?? [];

      if (tokenHelper.getEmploymentCenterId()) {
        methods.setValue(
          "employmentCenter",
          getSelectOptionsElementByCode(provinceList, tokenHelper.getEmploymentCenterId())
        );
      } else {
        methods.setValue(
          "employmentCenter",
          getSelectOptionsElementByCode(provinceList, props.usersInfo.result?.userColective?.compositeAddress?.province)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.EMPLOYMENT_CENTERS]]);

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          title={"Dados do Utilizador"}
          classes={{ title: classes.cardHeader, action: classes.cardHeaderAction }}
          action={
            !(props.isCreate || !props.usersInfo.result?.userColective?.approved) && (
              <GenericButton
                typeSubmit={false}
                color="tertiary"
                size="small"
                onClick={() => {
                  handleEditSection();
                }}
              >
                {intl.formatMessage(labels.Label_Generic_Edit)}
              </GenericButton>
            )
          }
        ></CardHeader>
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={4} xl={3}>
              <FormGroup>
                <FormLabel>Nome do Responsável</FormLabel>
                <Controller
                  name="contactName"
                  control={methods.control}
                  component={Box}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "O campo é obrigatório",
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      disabled={!isEditable}
                      name={name}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: methods.formState.errors["contactName"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {methods.formState.errors["contactName"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["contactName"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>

            {!props.isCreate && (
              <Grid item xs={12} md={4} xl={3}>
                <FormGroup>
                  <FormLabel>Activo</FormLabel>
                  <FormSelect
                    disabled={!isEditable}
                    control={methods.control}
                    fieldName="isActive"
                    selectOptions={DATASET.YES_OR_NO_DROPDOWN}
                    setValue={methods.setValue}
                    getValues={methods.getValues}
                    required={props.isEditable}
                    requiredMessage={"O campo é obrigatório"}
                    errors={methods.formState.errors}
                    classes={classes}
                  />
                  {methods.formState.errors["isActive"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {methods.formState.errors["isActive"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
            )}

            <Grid item xs={12} md={4} xl={3}>
              <FormGroup>
                <FormLabel>Telemóvel</FormLabel>
                <Controller
                  name="mobilePhone"
                  control={methods.control}
                  component={Box}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                    },
                    validate: {
                      validPhoneNumber: (value) => {
                        if (
                          value &&
                          (parseInt(value) < REGISTRATION_CONSTS.MIN_LENGTH_PHONE_NUMBER ||
                            parseInt(value) > REGISTRATION_CONSTS.MAX_LENGTH_PHONE_NUMBER)
                        )
                          return intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                        return true;
                      },
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      disabled={!isEditable}
                      name={name}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        methods.trigger("phoneNumberConfirmation");
                      }}
                      fullWidth
                      autoComplete="off"
                      type="number"
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: methods.formState.errors["mobilePhone"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {methods.formState.errors["mobilePhone"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["mobilePhone"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormGroup>
                <FormLabel>Confirmação de Telemóvel</FormLabel>
                <Controller
                  name="phoneNumberConfirmation"
                  control={methods.control}
                  component={Box}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                    },
                    validate: {
                      validPhoneNumber: (value) => {
                        if (
                          value &&
                          (parseInt(value) < REGISTRATION_CONSTS.MIN_LENGTH_PHONE_NUMBER ||
                            parseInt(value) > REGISTRATION_CONSTS.MAX_LENGTH_PHONE_NUMBER)
                        )
                          return intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                        return true;
                      },
                      phoneNumberMatch: (value) => {
                        return (
                          value === methods.getValues("mobilePhone") ||
                          "O número inserido deve ser igual ao campo 'Telemóvel'"
                        );
                      },
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      disabled={!isEditable}
                      name={name}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        methods.trigger("mobilePhone");
                      }}
                      fullWidth
                      autoComplete="off"
                      type="number"
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: methods.formState.errors["phoneNumberConfirmation"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {methods.formState.errors["phoneNumberConfirmation"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["phoneNumberConfirmation"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormGroup>
                <FormLabel>Telemóvel Alternativo (Opcional)</FormLabel>
                <Controller
                  name="alternativeMobilePhone"
                  control={methods.control}
                  component={Box}
                  defaultValue=""
                  rules={{
                    validate: {
                      validPhoneNumber: (value) => {
                        if (
                          value &&
                          (parseInt(value) < REGISTRATION_CONSTS.MIN_LENGTH_PHONE_NUMBER ||
                            parseInt(value) > REGISTRATION_CONSTS.MAX_LENGTH_PHONE_NUMBER)
                        )
                          return intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                        return true;
                      },
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      disabled={!isEditable}
                      name={name}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      autoComplete="off"
                      type="number"
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: methods.formState.errors["alternativeMobilePhone"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {methods.formState.errors["alternativeMobilePhone"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["alternativeMobilePhone"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormGroup>
                <FormLabel>E-mail (Opcional)</FormLabel>
                <Controller
                  name="email"
                  defaultValue=""
                  control={methods.control}
                  component={Box}
                  rules={{
                    validate: {
                      validEmail: (value) => {
                        if (value && !REGEX_PATTERNS.EMAIL.test(value)) return "Formato inválido";
                        return true;
                      },
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      disabled={!isEditable}
                      name={name}
                      value={value}
                      fullWidth
                      autoComplete="off"
                      type="email"
                      onChange={(e) => {
                        onChange(e);
                        methods.trigger("emailConfirmation");
                      }}
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: methods.formState.errors["email"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {methods.formState.errors["email"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["email"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormGroup>
                <FormLabel>Confirmação de E-mail</FormLabel>
                <Controller
                  name="emailConfirmation"
                  defaultValue=""
                  control={methods.control}
                  component={Box}
                  rules={{
                    validate: {
                      validEmail: (value) => {
                        if (value && !REGEX_PATTERNS.EMAIL.test(value)) return "Formato inválido";
                        return true;
                      },
                      EmailMatch: (value) => {
                        return (
                          value === methods.getValues("email") || "O e-mail inserido deve ser igual ao campo 'E-mail'"
                        );
                      },
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      disabled={!isEditable}
                      name={name}
                      value={value}
                      fullWidth
                      autoComplete="off"
                      type="email"
                      onChange={(e) => {
                        onChange(e);
                        methods.trigger("email");
                      }}
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: methods.formState.errors["emailConfirmation"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {methods.formState.errors["emailConfirmation"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["emailConfirmation"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card>
        <CardHeader title={"Dados da Empresa"} classes={{ title: classes.cardHeader }}></CardHeader>
        <CardContent>
          <Grid container>
            <Grid item xs={12} xl={6}>
              <FormInput
                disabled={!isEditable}
                isRequired
                name="name"
                label="Nome"
                control={methods.control}
                errors={methods.formState.errors}
              />
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormInput
                disabled={!isEditable}
                isRequired
                name="permitCode"
                label="Código do alvará"
                control={methods.control}
                errors={methods.formState.errors}
                rules={{
                  validate: {
                    maxLength: (value) => {
                      return value?.length <= 25 || "O código de alvará tem tamanho máximo de 25 caracteres";
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormGroup>
                <FormLabel>Data de validade do alvará</FormLabel>
                <Controller
                  name="permitCodeValidUntil"
                  control={methods.control}
                  component={Box}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                    },
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <ReactDatetime
                      {...field}
                      locale="pt"
                      dateFormat={REGISTRATION_CONSTS.DATE_TIME_FORMAT}
                      timeFormat={false}
                      inputProps={{
                        placeholder: REGISTRATION_CONSTS.DATE_TIME_PLACEHOLDER,
                        className: "",
                      }}
                      renderInput={(dateInputProps, open) => (
                        <OutlinedInput
                          ref={ref}
                          disabled={!isEditable}
                          onFocus={open}
                          fullWidth
                          {...dateInputProps}
                          onChange={(e) => {
                            handleValidDate(e.target.value, dateInputProps.onChange);
                          }}
                          startAdornment={
                            <InputAdornment position="start">
                              <Box component={DateRange} marginRight=".5rem" />
                            </InputAdornment>
                          }
                          classes={{
                            notchedOutline: clsx({
                              [classes.borderWarning]: methods.formState.errors["permitCodeValidUntil"] !== undefined,
                            }),
                          }}
                        />
                      )}
                    />
                  )}
                />
                {methods.formState.errors["permitCodeValidUntil"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["permitCodeValidUntil"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormInput
                name="taxIdNumber"
                label="NIF"
                isRequired
                control={methods.control}
                errors={methods.formState.errors}
                disabled={!isEditable}
                rules={{
                  validate: {
                    maxLength: (value) => {
                      return value?.length <= 14 || "O NIF deve até ter 14 caracteres";
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} xl={9}>
              <FormGroup>
                <FormLabel>Sector Económico</FormLabel>
                <FormMultipleSelect
                  disabled={!isEditable}
                  control={methods.control}
                  fieldName="businessPurpose"
                  selectOptions={getDatasetStateByName(props, DATASET.BUSINESS_PURPOSE)}
                  getValues={methods.getValues}
                  limitTags={1}
                  errors={methods.formState.errors}
                  classes={classes}
                  required={true}
                  requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                />
                {methods.formState.errors["businessPurpose"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["businessPurpose"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormGroup>
                <FormLabel>Província</FormLabel>
                <FormSelect
                  disabled={!isEditable}
                  control={methods.control}
                  fieldName="province"
                  selectOptions={getDatasetStateByName(props, DATASET.PROVINCES)}
                  loadChildrenDataSet={props.getDataSetByName}
                  childrenDataSet={DATASET.MUNICIPALITIES}
                  childrenSelect="municipality"
                  childrenList={[{ name: "employmentCenter", dataset: DATASET.EMPLOYMENT_CENTERS }]}
                  setValue={methods.setValue}
                  getValues={methods.getValues}
                  required={true}
                  requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                  errors={methods.formState.errors}
                  classes={classes}
                />
                {methods.formState.errors["province"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["province"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormGroup>
                <FormLabel>Município</FormLabel>
                <FormSelect
                  disabled={!isEditable}
                  control={methods.control}
                  fieldName="municipality"
                  selectOptions={getDatasetStateByName(props, DATASET.MUNICIPALITIES)}
                  getValues={methods.getValues}
                  required={true}
                  requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                  errors={methods.formState.errors}
                  classes={classes}
                />
                {methods.formState.errors["municipality"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["municipality"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormGroup>
                <FormLabel>Centro de Emprego</FormLabel>
                <FormSelect
                  disabled={!isEditable}
                  control={methods.control}
                  fieldName="employmentCenter"
                  selectOptions={getDatasetStateByName(props, DATASET.EMPLOYMENT_CENTERS)}
                  getValues={methods.getValues}
                  required={true}
                  requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                  errors={methods.formState.errors}
                  classes={classes}
                />
                {methods.formState.errors["employmentCenter"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["employmentCenter"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormInput
                disabled={!isEditable}
                name="district"
                label="Bairro"
                isRequired
                control={methods.control}
                errors={methods.formState.errors}
              />
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormInput
                disabled={!isEditable}
                isRequired
                name="address"
                label="Endereço"
                control={methods.control}
                errors={methods.formState.errors}
              />
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormInput
                disabled={!isEditable}
                name="niss"
                isRequired
                label={
                  <>
                    NISS
                    <Tooltip title="Número de Identificação da Segurança Social">
                      <HelpIcon className={classes.tooltipInfoIcon} />
                    </Tooltip>
                  </>
                }
                control={methods.control}
                errors={methods.formState.errors}
              />
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormInput
                disabled={!isEditable}
                isRequired
                name="companyEmail"
                label="Email da empresa"
                control={methods.control}
                errors={methods.formState.errors}
                rules={{
                  validate: {
                    validEmail: (value) => {
                      if (value && !REGEX_PATTERNS.EMAIL.test(value))
                        return intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                      return true;
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormInput
                disabled={!isEditable}
                isRequired
                name="companyPhone"
                label="Telefone da empresa"
                control={methods.control}
                errors={methods.formState.errors}
                numberType={true}
                rules={{
                  validate: {
                    validPhoneNumber: (value) => {
                      if (
                        value &&
                        (parseInt(value) < REGISTRATION_CONSTS.MIN_LENGTH_PHONE_NUMBER ||
                          parseInt(value) > REGISTRATION_CONSTS.MAX_LENGTH_PHONE_NUMBER)
                      )
                        return intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                      return true;
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormInput
                disabled={!isEditable}
                isRequired
                name="experience"
                label="Anos de experiência"
                control={methods.control}
                errors={methods.formState.errors}
                numberType={true}
                rules={{
                  validate: {
                    minValue: (value) => {
                      return value >= 0 || intl.formatMessage(errorMessages.ErrorMessage_Invalid_Value);
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormGroup>
                <FormLabel>
                  {intl.formatMessage(labels.Label_UserProfile_UserCollective_CompanyData_CompanyEACId)}
                </FormLabel>
                <FormSelect
                  control={methods.control}
                  disabled={!isEditable}
                  fieldName="companyEACId"
                  selectOptions={getDatasetStateByName(props, DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION)}
                  setValue={methods.setValue}
                  getValues={methods.getValues}
                  required={true}
                  requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                  errors={methods.formState.errors}
                  classes={classes}
                />
                {methods.formState.errors["companyEACId"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {methods.formState.errors["companyEACId"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormInput
                disabled={!isEditable}
                isRequired
                name="propertyType"
                label={intl.formatMessage(labels.Label_UserProfile_UserCollective_CompanyData_PropertyType)}
                control={methods.control}
                errors={methods.formState.errors}
              />
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormInput
                disabled={!isEditable}
                name="subsidiaryCompany"
                label={intl.formatMessage(labels.Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompany)}
                control={methods.control}
                errors={methods.formState.errors}
              />
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormInput
                disabled={!isEditable}
                name="subsidiaryCompanyPostalCode"
                label={intl.formatMessage(
                  labels.Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompanyPostalCode
                )}
                control={methods.control}
                errors={methods.formState.errors}
              />
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormInput
                disabled={!isEditable}
                name="subsidiaryCompanyActivity"
                label={intl.formatMessage(
                  labels.Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompanyActivity
                )}
                control={methods.control}
                errors={methods.formState.errors}
              />
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormGroup>
                <FormLabel>
                  {intl.formatMessage(labels.Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompanyEAC)}
                </FormLabel>
                <FormSelect
                  disabled={!isEditable}
                  control={methods.control}
                  fieldName="subsidiaryCompanyEACId"
                  selectOptions={getDatasetStateByName(props, DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION)}
                  setValue={methods.setValue}
                  getValues={methods.getValues}
                  errors={methods.formState.errors}
                  classes={classes}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormInput
                disabled={!isEditable}
                name="maleWorkersNumber"
                label={intl.formatMessage(
                  labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_MaleWorkersNumber
                )}
                control={methods.control}
                errors={methods.formState.errors}
                numberType={true}
                rules={{
                  validate: {
                    validNumber: (value) => {
                      if (value && parseInt(value) < 0)
                        return intl.formatMessage(errorMessages.ErrorMessage_Invalid_Value);
                      return true;
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} xl={3}>
              <FormInput
                disabled={!isEditable}
                name="femaleWorkersNumber"
                label={intl.formatMessage(
                  labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_FemaleWorkersNumber
                )}
                control={methods.control}
                errors={methods.formState.errors}
                numberType={true}
                rules={{
                  validate: {
                    validNumber: (value) => {
                      if (value && parseInt(value) < 0)
                        return intl.formatMessage(errorMessages.ErrorMessage_Invalid_Value);
                      return true;
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
  clearDataSetByName: (name) => dispatch(clearDataSetByNameAction(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnterpriseUserData);
