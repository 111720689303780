import {
  characterizeProfessionalKit,
  getUnitValueByCategory,
  getBatchValue,
  registerStockInWarehouse,
  searchProfessionalKits,
  registerLossBreak,
  getProfessionalKitDetails,
  updateProfessionalKitState,
  updateProfessionalKit,
  searchStock,
  getStockDetails,
  getKitDeliveryApplicationDetails,
  scheduleKitDelivery,
  getProfessionalKitDeliveryDocument
} from "crud/stock.crud";
import { DIALOG, PROFESSIONAL_KITS, STOCKS } from "utils/actionsConsts";
import { convertByteArrayToBlobUrl } from "utils/converters";

export const scheduleKitDeliveryAction = (kitDelivery) => async (dispatch) => {
  scheduleKitDelivery(kitDelivery)
    .then(() => {
      dispatch({
        type: "showDeliverKitModal",
        payload: {
          toShow: false,
        },
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: true,
            message: "Registo efetuado com sucesso",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: error.response?.data?.errors
              ? error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
              ][0]
              : "Erro a efetuar o seu pedido. Por favor tente mais tarde",
          },
        },
      });
      console.log("error", error);
    });
};

export const characterizeProfessionalKitAction = (formData, _onCloseAction) => async (dispatch) => {
  dispatch({
    type: "characterizeProfessionalKit_loading",
    payload: true,
  });
  characterizeProfessionalKit(formData)
    .then((res) => {
      dispatch({
        type: "characterizeProfessionalKit_loading",
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data,
            buttonLabel: "Ok",
            okAction: res.data ? _onCloseAction : undefined,
            onCloseAction: res.data ? _onCloseAction : undefined,
            message: res.data
              ? "Kit caracterizado com sucesso!"
              : "Erro na caracterização do kit, por favor tente mais tarde.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: "characterizeProfessionalKit_loading",
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
          },
        },
      });
      console.log("error", error);
    });
};

export const updateProfessionalKitAction = (formData, _onCloseAction) => async (dispatch) => {
  dispatch({
    type: "updateProfessionalKit_loading",
    payload: true,
  });
  updateProfessionalKit(formData)
    .then((res) => {
      dispatch({
        type: "updateProfessionalKit_loading",
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data,
            buttonLabel: "Ok",
            okAction: res.data ? _onCloseAction : undefined,
            onCloseAction: res.data ? _onCloseAction : undefined,
            message: res.data
              ? "Detalhes do kit atualizados com sucesso!"
              : "Erro na atualização dos detalhes do kit, por favor tente mais tarde.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: "updateProfessionalKit_loading",
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
          },
        },
      });
      console.log("error", error);
    });
};

export const updateProfessionalKitStateAction =
  (professionalKitId) => async (dispatch) => {
    updateProfessionalKitState(professionalKitId)
      .then(() => {
        dispatch({
          type: "updateProfessionalKitState",
          payload: true,
        });
      })
      .catch((error) => {
        dispatch({
          type: "updateProfessionalKitState",
          payload: false,
        });
        console.log("error", error);
      });
  };
export const getStockDetailsAction = (warehouseStockId) => async (dispatch) => {
  dispatch({
    type: STOCKS.GET_STOCK_DETAILS_LOADING,
    payload: true,
  });
  getStockDetails(warehouseStockId)
    .then((res) => {
      dispatch({
        type: "getStockDetails",
        payload: res.data,
      });
      dispatch({
        type: STOCKS.GET_STOCK_DETAILS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: "getStockDetails",
        payload: [],
      });
      dispatch({
        type: STOCKS.GET_STOCK_DETAILS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};
export const getProfessionalKitDetailsAction =
  (professionalKitId) => async (dispatch) => {
    dispatch({
      type: PROFESSIONAL_KITS.GET_DETAILS_LOADING,
      payload: true,
    });
    getProfessionalKitDetails(professionalKitId)
      .then((res) => {
        dispatch({
          type: "getProfessionalKitDetails",
          payload: res.data,
        });
        dispatch({
          type: PROFESSIONAL_KITS.GET_DETAILS_LOADING,
          payload: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: "getProfessionalKitDetails",
          payload: [],
        });
        dispatch({
          type: PROFESSIONAL_KITS.GET_DETAILS_LOADING,
          payload: false,
        });
        console.log("error", error);
      });
  };

export const searchStocksAction = (formData) => async (dispatch) => {
  dispatch({
    type: STOCKS.SEARCH_LOADING,
    payload: true,
  });

  searchStock(formData)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          id: record.id,
          warehouseId: record.warehouseId,
          warehouseName: record.warehouseName,
          provinceId: record.provinceId,
          provinceName: record.provinceName,
          categoryId: record.categoryId,
          categoryName: record.categoryName,
          noOfBatches: record.noOfBatches,
          kitsAttributed: record.kitsAttributed,
          kitsTotal: record.kitsTotal,
          availableKits: record.availableKits,
          lostKits: record.lostKits,
        };
      });
      dispatch({
        type: "searchStocks",
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: STOCKS.SEARCH_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: "searchStocks",
        payload: [],
      });
      dispatch({
        type: STOCKS.SEARCH_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};
export const searchProfessionalKitsAction = (formData) => async (dispatch) => {
  dispatch({
    type: PROFESSIONAL_KITS.SEARCH_LOADING,
    payload: true,
  });
  searchProfessionalKits(formData)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          id: record.id,
          categoryName: record.categoryName,
          categoryId: record.categoryId,
          noOfBatches: record.noOfBatches,
          kitsAttributed: record.kitsAttributed,
          kitsTotal: record.kitsTotal,
          availableKits: record.availableKits,
          lostKits: record.lostKits,
          active: record.active,
        };
      });
      dispatch({
        type: "searchProfessionalKits",
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: PROFESSIONAL_KITS.SEARCH_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: "searchProfessionalKits",
        payload: [],
      });
      dispatch({
        type: PROFESSIONAL_KITS.SEARCH_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};
export const registerStockInWarehouseAction =
  (formData) => async (dispatch) => {
    registerStockInWarehouse(formData)
      .then(() => {
        dispatch({
          type: "registerStockInWarehouse",
          payload: true,
        });
      })
      .catch((error) => {
        dispatch({
          type: "registerStockInWarehouse",
          payload: false,
        });
        console.log("error", error);
      });
  };
export const registerLossOrBreakAction = (formData, _onCloseAction) => async (dispatch) => {
  registerLossBreak(formData)
    .then(() => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            onCloseAction: _onCloseAction ?? undefined,
            okAction: _onCloseAction ?? undefined,
            isSuccess: true,
            message: "O seu registo foi realizado com sucesso."
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: error.response?.data?.errors
              ? error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
              ][0]
              : "Erro a efetuar o seu pedido. Por favor tente mais tarde",
          },
        },
      });
      console.log("error", error);
    });
};

export const getUnitValueByCategoryAction = (category) => async (dispatch) => {
  getUnitValueByCategory(category)
    .then((res) => {
      dispatch({
        type: "getUnitValueByCategory",
        payload: { categoryId: category, unitValue: res.data },
      });
    })
    .catch((error) => {
      dispatch({
        type: "getUnitValueByCategory",
        payload: { categoryId: category, unitValue: 0 },
      });
      console.log("error", error);
    });
};

export const getBatchValueAction = (category, quantity) => async (dispatch) => {
  getBatchValue(category, quantity)
    .then((res) => {
      dispatch({
        type: "getBatchValue",
        payload: { batchValue: res.data.batchValue },
      });
    })
    .catch((error) => {
      dispatch({
        type: "getBatchValue",
        payload: { batchValue: 0 },
      });
      console.log("error", error);
    });
};

export const getKitDeliveryApplicationDetailsAction =
  (applicationCode) => async (dispatch) => {
    getKitDeliveryApplicationDetails(applicationCode)
      .then((res) => {
        if (res.data)
          dispatch({
            type: "getKitDeliveryApplicationDetails",
            payload: res.data,
          });
        else {
          dispatch({
            type: "getKitDeliveryApplicationDetails",
            payload: {},
          });
          dispatch({
            type: DIALOG.SHOW,
            payload: {
              showGenericDialog: true,
              genericDialogData: {
                type: DIALOG.ONE_BUTTON_TYPE,
                isSuccess: false,
                message:
                  "Não existem agendamentos de entrega de kit para a candidatura indicada",
              },
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: "getKitDeliveryApplicationDetails",
          payload: {},
        });
        console.log("error", error);
      });
  };

export const showRegisterLossBreakModalAction =
  (_toShow, _category, _batchNumber, _province, _warehouse, _onCloseAction) =>
    async (dispatch) => {
      dispatch({
        type: "showRegisterLossBreakModal",
        payload: {
          toShow: _toShow,
          category: _category,
          batchNumber: _batchNumber,
          province: _province,
          warehouse: _warehouse,
          onCloseAction: _onCloseAction
        },
      });
    };

export const showRegisterInWareHouseModalAction =
  (_toShow, _category, _province, _warehouse) => async (dispatch) => {
    dispatch({
      type: "showRegisterInWareHouseModal",
      payload: {
        toShow: _toShow,
        category: _category,
        province: _province,
        warehouse: _warehouse,
      },
    });
  };

export const showDeliverKitModalAction =
  (_toShow, _category) => async (dispatch) => {
    dispatch({
      type: "getKitDeliveryApplicationDetails",
      payload: {},
    });
    dispatch({
      type: "showDeliverKitModal",
      payload: {
        toShow: _toShow,
        category: _category,
      },
    });
  };

export const hideKitCharacterizationResultModalAction =
  () => async (dispatch) => {
    dispatch({ type: "hideKitCharacterizationResultModal", payload: false });
  };

export const hideRegisterStockInWarehouseResultModalAction =
  () => async (dispatch) => {
    dispatch({
      type: "hideRegisterStockInWarehouseResultModal",
      payload: false,
    });
  };

export const hideDeliverKitResultModalAction = () => async (dispatch) => {
  dispatch({ type: "hideDeliverKitResultModalAction", payload: false });
};

export const getProfessionalKitDeliveryDocumentAction = (docId, docName) => async () => {
  getProfessionalKitDeliveryDocument(docId)
    .then((res) => {
      const link = document.createElement("a");
      link.href = convertByteArrayToBlobUrl(res.data);
      link.setAttribute("download", docName);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export default characterizeProfessionalKitAction;
