import React, { useEffect } from "react";
import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";
import ReactDatetime from "react-datetime";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import "moment/locale/pt";
import moment from "moment";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// @material-ui/icons components
import DateRange from "@material-ui/icons/DateRange";
//import custom components
import GenericButton from "components/Buttons/GenericButton";
import GenericFormSelect from "components/FormFields/GenericFormSelect";
import FormSelect from "components/FormFields/FormSelect";
import { getProgramsAction, getDepartmentsAction, getGovernmentProgramsAction } from "redux/actions/datasetsAction";
import { DATASET, REGEX_PATTERNS } from "utils/consts";
import { labels, titles, messages, errorMessages } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function GeneralDataSection(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const intl = useIntl();
  const { control } = useFormContext();

  useEffect(() => {
    if (
      props.dataSetState[DATASET.GOVERNMENT_PROGRAMS] === undefined ||
      props.dataSetState[DATASET.GOVERNMENT_PROGRAMS]?.length === 0
    ) {
      props.getGovernmentPrograms();
    }
    if (
      props.dataSetState[DATASET.DEPARTMENTS] === undefined ||
      props.dataSetState[DATASET.DEPARTMENTS]?.length === 0
    ) {
      props.getDepartments(DATASET.DEPARTMENTS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.subprogramDetailsData !== undefined && props.subprogramDetailsData.subprogramName) {
      handleLoadSubprogramDetailsData(props.subprogramDetailsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subprogramDetailsData]);

  const handleEditSection = () => {
    props.handleEditSection();
  };

  const handleLoadSubprogramDetailsData = (subprogramDetailsData) => {
    if (props.loadData) {
      props.setValue(
        "governmentProgramId",
        props.dataSetState[DATASET.GOVERNMENT_PROGRAMS] !== undefined
          ? props.dataSetState[DATASET.GOVERNMENT_PROGRAMS].find(
              (r) => r.code === subprogramDetailsData.governmentProgramId
            )
          : []
      );

      props.setValue(
        "programId",
        props.dataSetState[DATASET.PROGRAMS] !== undefined
          ? props.dataSetState[DATASET.PROGRAMS].find((r) => r.code === subprogramDetailsData.programId)
          : []
      );

      props.setValue("subprogramName", subprogramDetailsData.subprogramName);

      props.setValue("subprogramValidFrom", moment(subprogramDetailsData.validFrom, "DD/MM/YYYY"));

      props.setValue("subprogramValidTo", moment(subprogramDetailsData.validTo, "DD/MM/YYYY"));

      props.setValue("subprogramDescription", subprogramDetailsData.description);

      props.setValue("subprogramObjectives", subprogramDetailsData.objectives);

      props.setValue("numVacancies", subprogramDetailsData.numVacancies);

      props.setValue(
        "departmentId",
        props.dataSetState[DATASET.DEPARTMENTS] !== undefined
          ? props.dataSetState[DATASET.DEPARTMENTS].find((r) => r.code === subprogramDetailsData.departmentId)
          : []
      );
    }
  };

  const handleInputValidDate = (date, onChange) => {
    if (date === "" || REGEX_PATTERNS.DATE.test(date)) {
      onChange(date);
    }
  };

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          classes={{ title: classes.cardHeader, action: classes.cardHeaderAction }}
          title={intl.formatMessage(titles.Title_Admin_Subprogram_GeneralDataSection)}
          action={
            props.loadData && (
              <GenericButton typeSubmit={false} color="tertiary" size="small" onClick={handleEditSection}>
                {intl.formatMessage(labels.Label_Generic_Edit)}
              </GenericButton>
            )
          }
        ></CardHeader>
        <CardContent>
          <Grid container style={{ alignItems: "flex-end" }}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <FormGroup>
                <FormLabel>{intl.formatMessage(labels.Label_Admin_Subprogram_GovernmentProgramField)}</FormLabel>
                <FormSelect
                  control={control}
                  fieldName="governmentProgramId"
                  selectOptions={
                    props.dataSetState[DATASET.GOVERNMENT_PROGRAMS] !== undefined
                      ? props.dataSetState[DATASET.GOVERNMENT_PROGRAMS]
                      : []
                  }
                  disabled={!props.isEditable}
                  loadChildrenDataSet={() =>
                    props.getPrograms(DATASET.PROGRAMS, props.getValues("governmentProgramId").code)
                  }
                  childrenDataSet={DATASET.PROGRAMS}
                  childrenSelect="programId"
                  setValue={props.setValue}
                  getValues={props.getValues}
                  required={true}
                  requiredMessage={"O campo é obrigatório"}
                  errors={props.errors}
                  classes={classes}
                />
                {props.errors["governmentProgramId"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["governmentProgramId"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <FormGroup>
                <FormLabel>{intl.formatMessage(labels.Label_Admin_Subprogram_ProgramField)}</FormLabel>
                <FormSelect
                  control={control}
                  fieldName="programId"
                  selectOptions={props.dataSetState[DATASET.PROGRAMS] ? props.dataSetState[DATASET.PROGRAMS] : []}
                  setValue={props.setValue}
                  getValues={props.getValues}
                  disabled={!props.isEditable || !props.getValues("governmentProgramId")}
                  required={true}
                  requiredMessage={"O campo é obrigatório"}
                  errors={props.errors}
                  classes={classes}
                />
                {props.errors["programId"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["programId"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <FormGroup>
                <FormLabel>{intl.formatMessage(labels.Label_Admin_Subprogram_SubprogramField)}</FormLabel>
                <Controller
                  name="subprogramName"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: {
                      value: props.isEditable,
                      message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      disabled={!props.isEditable}
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["subprogramName"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {props.errors["subprogramName"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["subprogramName"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2}>
              <FormGroup>
                <FormLabel>{intl.formatMessage(labels.Label_Admin_Subprogram_ValidityDateFieldStart)}</FormLabel>
                <Controller
                  name="subprogramValidFrom"
                  control={control}
                  rules={{
                    required: {
                      value: props.isEditable,
                      message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                    },
                    validate: {
                      isValidDate: (value) => {
                        return (
                          !value ||
                          value._isAMomentObject ||
                          intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat)
                        );
                      },
                      isLessThanEndDate: (value) => {
                        if (!props.getValues("subprogramValidTo") || props.errors["subprogramValidTo"] !== undefined)
                          return true;
                        return (
                          (value !== "" &&
                            value._isAMomentObject &&
                            props.getValues("subprogramValidTo")._isAMomentObject &&
                            value.startOf("day") < props.getValues("subprogramValidTo")?.startOf("day")) ||
                          intl.formatMessage(messages.Message_Generic_DateFromLessThanDateToField)
                        );
                      },
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <ReactDatetime
                      name={name}
                      value={value}
                      open={props.isEditable ? undefined : false}
                      onChange={(e) => {
                        onChange(e);
                        props.trigger("subprogramValidTo");
                      }}
                      locale="pt"
                      dateFormat={"DD/MM/YYYY"}
                      timeFormat={false}
                      closeOnSelect={true}
                      closeOnTab={true}
                      closeOnClickOutside={true}
                      inputProps={{
                        placeholder: "DD/MM/AAAA",
                        className: "",
                        disabled: !props.isEditable,
                      }}
                      renderInput={(dateInputProps, open) => (
                        <OutlinedInput
                          fullWidth
                          onFocus={open}
                          {...dateInputProps}
                          onChange={(e) => {
                            handleInputValidDate(e.target.value, dateInputProps.onChange);
                          }}
                          startAdornment={
                            <InputAdornment position="start">
                              <Box component={DateRange} marginRight=".5rem" color={theme.palette.primary.main} />
                            </InputAdornment>
                          }
                          classes={{
                            notchedOutline: clsx({
                              [classes.borderWarning]: props.errors["subprogramValidFrom"] !== undefined,
                            }),
                          }}
                        />
                      )}
                    />
                  )}
                />
                {props.errors["subprogramValidFrom"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["subprogramValidFrom"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2}>
              <FormGroup>
                <FormLabel>{intl.formatMessage(labels.Label_Admin_Subprogram_ValidityDateFieldEnd)}</FormLabel>
                <Controller
                  name="subprogramValidTo"
                  control={control}
                  rules={{
                    required: {
                      value: props.isEditable,
                      message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                    },
                    validate: {
                      isValidDate: (value) => {
                        return (
                          !value ||
                          value._isAMomentObject ||
                          intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat)
                        );
                      },
                      maxDate: (value) => {
                        return (
                          value >= moment().startOf("day") ||
                          intl.formatMessage(messages.Message_Generic_DateGreaterThanTodayField)
                        );
                      },
                      isGreaterThanStartDate: (value) => {
                        if (
                          !props.getValues("subprogramValidFrom") ||
                          props.errors["subprogramValidFrom"] !== undefined
                        )
                          return true;
                        return (
                          (value !== "" &&
                            value._isAMomentObject &&
                            props.getValues("subprogramValidFrom") &&
                            value.startOf("day") > props.getValues("subprogramValidFrom")?.startOf("day")) ||
                          intl.formatMessage(messages.Message_Generic_DateToGreaterThanDateFromField)
                        );
                      },
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <ReactDatetime
                      name={name}
                      value={value}
                      open={props.isEditable ? undefined : false}
                      onChange={(e) => {
                        onChange(e);
                        props.trigger("subprogramValidFrom");
                      }}
                      locale="pt"
                      dateFormat={"DD/MM/YYYY"}
                      timeFormat={false}
                      isValidDate={(currentDate, _) => {
                        return currentDate >= moment().startOf("day");
                      }}
                      closeOnSelect={true}
                      closeOnTab={true}
                      closeOnClickOutside={true}
                      inputProps={{
                        placeholder: "DD/MM/AAAA",
                        className: "",
                        disabled: !props.isEditable,
                      }}
                      renderInput={(dateInputProps, open) => (
                        <OutlinedInput
                          fullWidth
                          onFocus={open}
                          {...dateInputProps}
                          onChange={(e) => {
                            handleInputValidDate(e.target.value, dateInputProps.onChange);
                          }}
                          startAdornment={
                            <InputAdornment position="start">
                              <Box component={DateRange} marginRight=".5rem" color={theme.palette.primary.main} />
                            </InputAdornment>
                          }
                          classes={{
                            notchedOutline: clsx({
                              [classes.borderWarning]: props.errors["subprogramValidTo"] !== undefined,
                            }),
                          }}
                        />
                      )}
                    />
                  )}
                />
                {props.errors["subprogramValidTo"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["subprogramValidTo"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <FormGroup>
                <FormLabel>{intl.formatMessage(labels.Label_Admin_Subprogram_NumVacanciesField)}</FormLabel>
                <Controller
                  name="numVacancies"
                  control={control}
                  defaultValue=""
                  component={Box}
                  textAlign="right"
                  rules={{
                    required: {
                      value: props.isEditable,
                      message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                    },
                    validate: {
                      minValue: (value) => {
                        return value > 0 || intl.formatMessage(messages.Message_Generic_ValueGreaterThanZeroField);
                      },
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      autoComplete="off"
                      type="number"
                      inputProps={{ style: { textAlign: "right" } }}
                      disabled={!props.isEditable}
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["numVacancies"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {props.errors["numVacancies"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["numVacancies"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <FormGroup>
                <FormLabel>{intl.formatMessage(labels.Label_Admin_Subprogram_DepartmentField)}</FormLabel>
                <GenericFormSelect
                  control={control}
                  fieldName="departmentId"
                  selectOptions={
                    props.dataSetState[DATASET.DEPARTMENTS] !== undefined ? props.dataSetState[DATASET.DEPARTMENTS] : []
                  }
                  getValues={props.getValues}
                  required={true}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  errors={props.errors}
                  classes={classes}
                  disabled={!props.isEditable}
                />
                {props.errors["departmentId"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["departmentId"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={false}></Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormGroup>
                <FormLabel>{intl.formatMessage(labels.Label_Admin_Subprogram_DescriptionField)}</FormLabel>
                <Controller
                  name="subprogramDescription"
                  control={control}
                  defaultValue=""
                  component={Box}
                  rules={{
                    required: {
                      value: props.isEditable,
                      message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      multiline
                      rows="3"
                      autoComplete="off"
                      type="text"
                      disabled={!props.isEditable}
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["subprogramDescription"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {props.errors["subprogramDescription"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["subprogramDescription"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormGroup>
                <FormLabel>{intl.formatMessage(labels.Label_Admin_Subprogram_ObjectivesField)}</FormLabel>
                <Controller
                  name="subprogramObjectives"
                  control={control}
                  defaultValue=""
                  component={Box}
                  rules={{
                    required: {
                      value: props.isEditable,
                      message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      fullWidth={true}
                      multiline
                      rows="3"
                      autoComplete="off"
                      type="text"
                      disabled={!props.isEditable}
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["subprogramObjectives"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {props.errors["subprogramObjectives"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["subprogramObjectives"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getPrograms: (name, governmentProgramId) => dispatch(getProgramsAction(name, governmentProgramId)),
  getDepartments: (name) => dispatch(getDepartmentsAction(name)),
  getGovernmentPrograms: () => dispatch(getGovernmentProgramsAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralDataSection);
