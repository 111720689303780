import React from 'react';
import Chip from "@material-ui/core/Chip";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { TABLE_STATE_CHIP_COLOR } from "utils/consts";

export default function TableStateChip(props) {
    const theme = useTheme();
    
    const useStyles = makeStyles((theme) => ({
        root: {
            display: "flex",
            justifyContent: "normal",
            listStyle: "none",
            padding: theme.spacing(0.5),
            margin: 0,
            width: "100%",
        },
        chip: {
            margin: theme.spacing(0.5),
        },
        label: {
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "10px",
            lineHeight: "14px",
            alignItems: "center",
            color: theme.palette.primary.greyBlue,
        },
        dot: {
            height: "15px",
            width: "15px",
            borderRadius: "50%",
            display: "inline-block",
            margin: "0px 0px 0px -15px",
        },
        outlined: {
            border: "2px solid " + theme.palette.gray[500],
            borderRadius: "20px",
        },
        errorIcon: {
            color: theme.palette.error.main,
            width: "20px",
            height: "20px",
        },
    }));
    const classes = useStyles();

    const generateChip = (label, color, showErrorIcon) => {
        return (
            <Chip
                data-testid="table-state-chip" 
                classes={{
                    root: classes.root,
                    label: classes.label, 
                }}
                icon={
                    showErrorIcon ?
                        <ErrorOutlineIcon 
                            data-testid="table-state-chip-error-icon" 
                            classes={{ root: classes.errorIcon }} 
                        />
                        :
                        <div 
                            data-testid="table-state-chip-success-div" 
                            className={classes.dot} 
                            style={{ backgroundColor: color, width: "10px", height: "10px" }}>
                        </div>
                }
                label={label}
                variant="outlined"
            />

        );
    };

    const renderSwitch = (state, message, showErrorIcon) => {
        if (showErrorIcon)
            return generateChip(message, "", showErrorIcon)
        switch (state) {
            case TABLE_STATE_CHIP_COLOR.GREEN:
            default:
                return generateChip(message, theme.palette.tableStateChip.green);
            case TABLE_STATE_CHIP_COLOR.RED:
                return generateChip(message, theme.palette.tableStateChip.red);
            case TABLE_STATE_CHIP_COLOR.YELLOW:
                return generateChip(message, theme.palette.tableStateChip.yellow);
            case TABLE_STATE_CHIP_COLOR.DARK_ORANGE:
                return generateChip(message, theme.palette.tableStateChip.darkOrange);
            case TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE:
                return generateChip(message, theme.palette.tableStateChip.lightOrange);
            case TABLE_STATE_CHIP_COLOR.BLUE:
                return generateChip(message, theme.palette.tableStateChip.blue);
        }
    }
    return renderSwitch(props.state, props.message, props.showErrorIcon);
};


