import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
//import custom components
import Benefits from "./Sections/BenefitsSection";
import ComplementaryData from "./Sections/ComplementaryDataSection";
import Documents from "./Sections/DocumentsSection";
import GeneralData from "./Sections/GeneralDataSection";
import GenericButton from "components/Buttons/GenericButton";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";

import { subprogramCreationMap } from "mappers/subprogramsMap";
import { createSubprogramAction } from "redux/actions/subprogramsAction";
import { labels } from "resources/resources";
import useScrollDirection from "hooks/useScrollDirection";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function Create(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const history = useHistory();
  const scrollDirection = useScrollDirection();
  const methods = useForm({ mode: "onChange", reValidateMode: "onChange" });
  const [complementaryDataList, setComplementaryDataList] = useState([]);

  const handleCancelOnClickEvent = () => {
    history.goBack();
  };

  const onSubmit = (data) => {
    props.createSubprogram(subprogramCreationMap(data, complementaryDataList));
  };

  return (
    <>
      {scrollDirection === "up" && (
        <div className={classes.breadcrumbs}>
          <LocationHeader
            section={"Caracterizar Subprograma"}
            subsection={"Subprogramas"}
            subsectionLink={"/admin/subprograms-management"}
          />
        </div>
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Container
            maxWidth={false}
            component={Box}
            classes={{ root: classes.containerRootAfterBreadcrumbs }}
          >
            <PageTitleHeader title={"Criar Subprograma"} />

            <Container maxWidth={false} component={Box}>
              <GeneralData
                control={methods.control}
                getValues={methods.getValues}
                setValue={methods.setValue}
                errors={methods.formState.errors}
                trigger={methods.trigger}
                isEditable={true}
                loadData={false}
              />
              <Benefits
                control={methods.control}
                getValues={methods.getValues}
                errors={methods.formState.errors}
                unregister={methods.unregister}
                isEditable={true}
                trigger={methods.trigger}
              />

              <Documents
                control={methods.control}
                getValues={methods.getValues}
                errors={methods.formState.errors}
                isEditable={true}
              />
              <ComplementaryData
                control={methods.control}
                getValues={methods.getValues}
                errors={methods.formState.errors}
                isEditable={true}
                unregister={methods.unregister}
                setValue={methods.setValue}
                trigger={methods.trigger}
                clearErrors={methods.clearErrors}
                complementaryDataList={complementaryDataList}
                setComplementaryDataList={setComplementaryDataList}
              />
              <Grid container>
                <Grid item xs={12} lg={6} xl={8}></Grid>
                <Grid item xs={12} lg={3} xl={2}>
                  <FormGroup>
                    <GenericButton
                      typeSubmit={false}
                      color="secondary"
                      onClick={handleCancelOnClickEvent}
                    >
                      {intl.formatMessage(labels.Label_Generic_Cancel)}
                    </GenericButton>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} lg={3} xl={2}>
                  <FormGroup>
                    <GenericButton
                      typeSubmit={true}
                      color="primary"
                      loading={props.subprogramState.createSubprogram_loading}
                    >
                      {intl.formatMessage(labels.Label_Generic_Submit_Button)}
                    </GenericButton>
                  </FormGroup>
                </Grid>
              </Grid>
            </Container>
          </Container>
        </form>
      </FormProvider>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  createSubprogram: (subprogramData) =>
    dispatch(createSubprogramAction(subprogramData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Create);
