import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { titles, labels } from "resources/resources";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
// import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Pagination from "@material-ui/lab/Pagination";
// @material-ui/icons components
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// core componentssrc
import componentStylesGeneric from "assets/theme/views/admin/generic";
import { ArrayUtils } from "utils/array_utils";

import TableStateChip from "components/Cards/Tables/TableStateChip";
import Tablebackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import { TABLE_STATE_CHIP_COLOR } from "utils/consts";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const tableHead = ["Especialidade", "Nº Lotes", "Qtd.Total", "Qtd.Atribuída", "Stock", "Perdas", "Estado"];

function CardSearchResult(props) {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStylesGeneric();

  const [kitsList, setKitsList] = useState([]);

  useEffect(() => {
    if (!ArrayUtils.NullOrEmpty(props.stockState.result)) {
      setKitsList(props.stockState.result.data);
    } else {
      setKitsList([]);
    }
  }, [props.stockState.result]);

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={
          <div>
            <div className={classes.sideBySideDiv}>
              {intl.formatMessage(titles.Title_Admin_ProfessionalKits_ResultSection)}
            </div>
            <div className={classes.sideBySideDiv + " " + classes.cardElementsCount}>
              {"("}
              {props.stockState?.total_records}
              {")"}
            </div>
          </div>
        }
      />
      <TableContainer>
        <Box component={Table} alignItems="center" marginBottom="0!important">
          <TableHead>
            <TableRow>
              {tableHead.map((prop, key) => (
                <TableCell
                  key={key}
                  classes={{
                    root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                  }}
                >
                  {prop}
                </TableCell>
              ))}
              <TableCell
                key="detailsCol"
                classes={{
                  root:
                    classes.tableCellRoot + " " + classes.tableCellRootHead + " " + classes.tableCellHeadStickyRight,
                }}
              >
                Detalhes
              </TableCell>
            </TableRow>
          </TableHead>

          {kitsList !== undefined && kitsList.length > 0 && (
            <TableBody>
              {kitsList?.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.categoryName}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.noOfBatches}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.kitsTotal}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.kitsAttributed}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.availableKits}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.lostKits}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <TableStateChip
                      state={prop.active ? TABLE_STATE_CHIP_COLOR.GREEN : TABLE_STATE_CHIP_COLOR.RED}
                      message={
                        prop.active
                          ? intl.formatMessage(labels.Label_Generic_Active)
                          : intl.formatMessage(labels.Label_Generic_Inactive)
                      }
                    />
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight }}>
                    <Tooltip title="Detalhes">
                      <IconButton aria-label="details" component={Link} to={`/admin/kit-detail/${prop.id}`}>
                        <Box
                          component={ArrowForwardIosIcon}
                          width="12px!important"
                          height="12px!important"
                          color={theme.palette.primary.main}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Box>

        {(kitsList == null || kitsList.length === 0) && !props.stockState.searchProfessionalKit_loading && (
          <Box className={classes.noResultsInfoInTable}>Não existem resultados disponíveis.</Box>
        )}
      </TableContainer>

      <Tablebackdrop open={props.stockState.searchProfessionalKit_loading} />

      <Pagination
        className={classes.alignPagination}
        count={props.stockState?.total_pages}
        page={props.page}
        onChange={props.handleChangePage}
        color="primary"
      ></Pagination>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(CardSearchResult);
