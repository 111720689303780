import { REPORT_TYPES } from "utils/consts";
import { MONETARY_DECIMAL_SEPARATOR, FORM_FIELDS_PREFIX, PAGINATION } from "utils/consts";
import formatDate from "utils/date_utils";

export function getFilteredOpportunitiesMap(formData) {
  if (formData !== null && formData !== undefined) {
    return {
      searchText: formData.searchText,
      reference: formData.reference,
      provinceId: formData.province?.code,
      municipalityId: formData.municipality?.code,
      employmentCenterId: formData.employmentCenter?.code,
      startDate: formatDate(formData?.startDate),
      endDate: formatDate(formData?.endDate),
      opportunityTypeId: formData.opportunityType?.code,
      opportunityStateId: formData.opportunityState?.code,
      opportunityProfessionalCategoryId: formData.opportunityProfessionalCategory?.code,
      taxIdNumber: formData.taxIdNumber,
      companyName: formData.companyName,
      pageIndex: PAGINATION.FIRST_PAGE,
      pageSize: PAGINATION.PAGE_SIZE,
    };
  }
  return formData;
}

export function createOpportunityMap(formData, questionsData, documentTypes) {
  //Set FormData
  const data = new FormData();
  if (formData !== null && formData !== undefined) {
    const opportunity = {
      userId: formData.userId ?? "",
      numVacancies: formData.numVacancies ?? "",
      provinceId: formData.province?.code ?? "",
      provinceKey: formData.province?.keyValue ?? "",
      municipalityId: formData.county?.code ?? "",
      reference: formData.reference ?? "",
      opportunityTypeId: formData.opportunityType?.code ?? "",
      opportunityTypeKey: formData.opportunityType?.keyValue ?? "",
      description: formData.description ?? "",
      timetableTypeId: formData.timetableType?.code ?? "",
      timetableTypeKey: formData.timetableType?.keyValue ?? "",
      amount: formData.amount?.replace(MONETARY_DECIMAL_SEPARATOR.WEB, MONETARY_DECIMAL_SEPARATOR.API) ?? "",
      contractTypeId: formData.contractType?.code ?? "",
      contractTypeKey: formData.contractType?.keyValue ?? "",
      internshipTypeId: formData.internshipType?.code ?? "",
      internshipTypeKey: formData.internshipType?.keyValue ?? "",
      validFrom: formData.validFrom !== "" && formData.validFrom ? formData.validFrom.format("YYYY-MM-DD") : "",
      validTo: formData.validTo !== "" && formData.validTo ? formData.validTo.format("YYYY-MM-DD") : "",
      mentorName: formData.mentorName ?? "",
      mentorMobilePhone:
        formData.mentorMobilePhone !== "" && formData.mentorMobilePhone ? formData.mentorMobilePhone : "",
      opportunityProfessionalCategories: formData.opportunityProfessionalCategories
        ? [formData.opportunityProfessionalCategories?.code]
        : [],
      reportingRequired: formData.reportingRequired,
      reportingPeriod: formData.reportingPeriod ?? "",
      startDate: formData.startDate !== "" && formData.startDate ? formData.startDate.format("YYYY-MM-DD") : "",
      endDate: formData.endDate !== "" && formData.endDate ? formData.endDate.format("YYYY-MM-DD") : "",
      employmentCenterId: formData.employmentCenter ? formData.employmentCenter?.code : null,
      socialPerks: formData.socialPerks ?? "",
      otherPerks: formData.otherPerks ?? "",
      academicLevelId: formData.academicLevel ? formData.academicLevel?.code : null,
      workplace: formData.workplace ?? "",
      commune: formData.commune ?? "",
      requiredKnowledge: formData.requiredKnowledge ?? "",
      activeMonths: formData.activeMonths ?? "",
      observations: formData.opportunity_observations ?? "",
      acceptWorkersFromOtherRegions: formData.acceptWorkersFromOtherRegions
        ? formData.acceptWorkersFromOtherRegions.code
        : false,
      genderId: formData.gender?.code ?? "",
      minAge: formData.minAge ?? "",
      maxAge: formData.maxAge ?? "",
    };

    //Append data
    for (const key in opportunity) {
      data.append("opportunity." + key, opportunity[key]);
    }

    let countQuestion = 0;
    for (const q in questionsData) {
      data.append("opportunity.OpportunityQuestions[" + countQuestion + "]", formData[questionsData[q].name]);
      countQuestion++;
    }

    //Append documents
    let countDoc = 0;
    documentTypes?.forEach((dt) => {
      if (
        formData[FORM_FIELDS_PREFIX.DOCUMENT + dt.code.toString()] &&
        formData[FORM_FIELDS_PREFIX.DOCUMENT + dt.code.toString()][0]
      ) {
        data.append("opportunity.OpportunityDocuments[" + countDoc + "].documentTypeId", dt.code);
        data.append("opportunity.OpportunityDocuments[" + countDoc + "].documentId", countDoc);
        data.append("OpportunityDocuments", formData[FORM_FIELDS_PREFIX.DOCUMENT + dt.code.toString()][0]);
        countDoc++;
      }
    });

    formData.requiredLanguages?.forEach((element) => {
      data.append("opportunity.RequiredLanguagesIds", element.code);
    });

    return data;
  }
  return formData;
}

export function updateOpportunityMap(formData, questionsData, documentTypes) {
  //Set FormData
  const data = new FormData();
  if (formData !== null && formData !== undefined) {
    const opportunity = {
      alternateKey: formData.alternateKey ?? "",
      numVacancies: formData.numVacancies ?? "",
      provinceId: formData.province?.code ?? "",
      provinceKey: formData.province?.keyValue ?? "",
      municipalityId: formData.county?.code ?? "",
      reference: formData.reference ?? "",
      opportunityTypeId: formData.opportunityTypeId ?? "",
      opportunityTypeKey: formData.opportunityTypeKey ?? "",
      description: formData.description ?? "",
      timetableTypeId: formData.timetableType?.code ?? "",
      timetableTypeKey: formData.timetableType?.keyValue ?? "",
      amount: formData.amount ?? "",
      contractTypeId: formData.contractType?.code ?? "",
      contractTypeKey: formData.contractType?.keyValue ?? "",
      internshipTypeId: formData.internshipType?.code ?? "",
      internshipTypeKey: formData.internshipType?.keyValue ?? "",
      validFrom: formData.validFrom !== "" && formData.validFrom ? formData.validFrom.format("YYYY-MM-DD") : "",
      validTo: formData.validTo !== "" && formData.validTo ? formData.validTo.format("YYYY-MM-DD") : "",
      mentorName: formData.mentorName ?? "",
      mentorMobilePhone:
        formData.mentorMobilePhone !== "" && formData.mentorMobilePhone ? formData.mentorMobilePhone : "",
      opportunityProfessionalCategories: formData.opportunityProfessionalCategories
        ? [formData.opportunityProfessionalCategories?.code]
        : [],
      reportingRequired: formData.reportingRequired,
      reportingPeriod: formData.reportingPeriod ?? "",
      startDate: formData.startDate !== "" && formData.startDate ? formData.startDate.format("YYYY-MM-DD") : "",
      endDate: formData.endDate !== "" && formData.endDate ? formData.endDate.format("YYYY-MM-DD") : "",
      employmentCenterId: formData.employmentCenter ? formData.employmentCenter?.code : null,
      socialPerks: formData.socialPerks ?? "",
      otherPerks: formData.otherPerks ?? "",
      academicLevelId: formData.academicLevel ? formData.academicLevel?.code : null,
      workplace: formData.workplace ?? "",
      commune: formData.commune ?? "",
      requiredKnowledge: formData.requiredKnowledge ?? "",
      activeMonths: formData.activeMonths ?? "",
      observations: formData.opportunity_observations ?? "",
      acceptWorkersFromOtherRegions: formData.acceptWorkersFromOtherRegions
        ? formData.acceptWorkersFromOtherRegions.code
        : false,
      genderId: formData.gender?.code ?? "",
      minAge: formData.minAge ?? "",
      maxAge: formData.maxAge ?? "",
    };

    //Append data
    for (const key in opportunity) {
      data.append("opportunity." + key, opportunity[key]);
    }

    let countQuestion = 0;
    for (const q in questionsData) {
      data.append("opportunity.OpportunityQuestions[" + countQuestion + "]", formData[questionsData[q].name]);
      countQuestion++;
    }

    //Append documents
    let countDoc = 0;
    documentTypes?.forEach((dt) => {
      if (
        formData[FORM_FIELDS_PREFIX.DOCUMENT + dt.code.toString()] &&
        formData[FORM_FIELDS_PREFIX.DOCUMENT + dt.code.toString()][0]
      ) {
        data.append("opportunity.OpportunityDocuments[" + countDoc + "].documentTypeId", dt.code);
        data.append("opportunity.OpportunityDocuments[" + countDoc + "].documentId", countDoc);
        data.append("OpportunityDocuments", formData[FORM_FIELDS_PREFIX.DOCUMENT + dt.code.toString()][0]);
        countDoc++;
      }
    });

    formData.requiredLanguages?.forEach((element) => {
      data.append("opportunity.RequiredLanguagesIds", element.code);
    });

    return data;
  }
  return formData;
}

export function getFilteredPossibleOpportunityCandidatesMap(formData) {
  if (formData !== null && formData !== undefined) {
    return {
      opportunityId: formData.opportunityId,
      userNameOrIdentityCard: formData.userNameOrIdentityCard,
      provinceId: formData.province?.code,
      municipalityId: formData.municipality?.code,
      academicLevelId: formData.academicLevel?.code,
      professionalCategoryId: formData.professionalCategory?.code,
      studiedLanguagesId: formData.studiedLanguages?.code,
      isAvailableForOpportunities: formData.isAvailableForOpportunities,
      pageIndex: PAGINATION.FIRST_PAGE,
      pageSize: PAGINATION.PAGE_SIZE_MODAL_RESULT_TABLE,
      genderId: formData.gender?.code,
      minAge: formData.minAge,
      maxAge: formData.maxAge,
    };
  }
  return formData;
}

export function getAllOpportunityApplicationsMap(formData) {
  if (formData !== null && formData !== undefined) {
    return {
      opportunityTitleOrDescription: formData.searchText,
      opportunityTypeId: formData.opportunityType?.code,
      candidateCode: formData.applicationCode,
      candidateStateId: formData.applicationState?.code,
      opportunityReference: formData.opportunityReference,
      userNameOrIdentityCard: formData.userNameOrIdentityCard,
      companyName: formData.companyName,
      companyTaxIdNumber: formData.companyTaxIdNumber,
      pageIndex: PAGINATION.FIRST_PAGE,
      pageSize: PAGINATION.PAGE_SIZE,
      provinceId: formData.province?.code,
      municipalityId: formData.municipality?.code,
      employmentCenterId: formData.employmentCenter?.code,
      genderId: formData.gender?.code,
      academicLevelId: formData.academicLevel?.code,
      degreeId: formData.academicDegree?.code,
      startDate: formatDate(formData?.startDate),
      endDate: formatDate(formData?.endDate),
      minAge: formData.minAge,
      maxAge: formData.maxAge,
      userCode: formData.userCode,
    };
  }
  return formData;
}

export function getAllOpportunityApplicationsFollowUpMap(formData) {
  if (formData !== null && formData !== undefined) {
    return {
      opportunityTitleOrDescription: formData.searchText,
      opportunityTypeId: formData.opportunityType?.code,
      candidateCode: formData.applicationCode,
      candidateFollowUpStateId: formData.applicationFollowUpState?.code,
      opportunityReference: formData.opportunityReference,
      userNameOrIdentityCard: formData.userNameOrIdentityCard,
      companyName: formData.companyName,
      companyTaxIdNumber: formData.companyTaxIdNumber,
      hasUnapprovedFeedbackReport: formData.hasUnapprovedFeedbackReport?.value,
      userCode: formData.userCode,
      pageIndex: PAGINATION.FIRST_PAGE,
      pageSize: PAGINATION.PAGE_SIZE,
    };
  }
  return formData;
}

export function updateOpportunityFollowupPeriodMap(formData) {
  if (formData !== null && formData !== undefined) {
    return {
      opportunityCandidateId: formData.candidateCode,
      newStartDate:
        formData.followUpStartDate !== "" && formData.followUpStartDate
          ? formData.followUpStartDate.format("YYYY-MM-DD")
          : "",
      newEndDate:
        formData.followUpEndDate !== "" && formData.followUpEndDate
          ? formData.followUpEndDate.format("YYYY-MM-DD")
          : "",
    };
  }
  return formData;
}

export function getFilteredAvailableOpportunitiesForUserMap(formData) {
  if (formData !== null && formData !== undefined) {
    let opportunityProfessionalCategoryList = [];
    let languagesList = [];

    if (formData.professionalCategory) {
      formData.professionalCategory?.forEach((category) => {
        opportunityProfessionalCategoryList.push(category.keyValue);
      });
    }

    if (formData.languages) {
      formData.languages?.forEach((language) => {
        languagesList.push(language.keyValue);
      });
    }

    return {
      candidateId: formData.candidateId,
      companyNameOrTaxId: formData.companyNameOrTaxId,
      provinceId: formData.province?.code,
      academicLevelId: formData.academicLevel?.code,
      opportunityProfessionalCategoryList: opportunityProfessionalCategoryList,
      languagesList: languagesList,
      opportunityTypeId: formData.opportunityType?.code,
      pageIndex: PAGINATION.FIRST_PAGE,
      pageSize: PAGINATION.PAGE_SIZE_MODAL_RESULT_TABLE,
    };
  }
  return formData;
}

export function opportunitiesExportReportMap(filterData) {
  if (filterData !== null && filterData !== undefined) {
    return {
      reportEnumKey: REPORT_TYPES.OPPORTUNITIES_SEARCH_RESULTS,
      parameters: JSON.stringify(filterData),
    };
  }
}

export function opportunitiesApplicationExportReportMap(filterData) {
  if (filterData !== null && filterData !== undefined) {
    return {
      reportEnumKey: REPORT_TYPES.OPPORTUNITY_APPLICATIONS_SEARCH_RESULTS,
      parameters: JSON.stringify(filterData),
    };
  }
}

export default getFilteredOpportunitiesMap;
