import { React } from "react";
import clsx from "clsx";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";

import { messages } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
const useStylesGeneric = makeStyles(componentStylesGeneric);

export default function TextField(props) {
  const intl = useIntl();
  const classes = useStylesGeneric();

  return (
    <FormGroup 
      classes={{
        root: classes.formTextInputInTableCell,
      }}
    >
      <FormLabel>
        <div>
          <div style={{float: "left"}}>
            {props.label}
          </div>
          <div style={{float: "right", fontWeight:"normal", fontSize: ".8rem"}}>
            {props.labelDetails}
          </div>
        </div>
      </FormLabel>
      <Controller
        name={props.name}
        control={props.control}
        setValue={props.setValue}
        component={Box}
        defaultValue=""
        rules={{
          required: {
            value: props.isRequired,
            message: intl.formatMessage(messages.Message_Generic_MandatoryField),
          },
          validate: {
            isGreaterThanZero: (value) => {
              if (props.type === "text" || value > 0) return true;
              return (
                intl.formatMessage(messages.Message_Generic_ValueGreaterThanZeroField)
              );
            },
          },
        }}
        render={({ field: { onChange, name, value } }) => (
          <OutlinedInput
            name={name}
            value={value}
            onChange={onChange}
            fullWidth
            autoComplete="off"
            disabled={props.isDisabled}
            multiline={props.multiline}
            rows={props.rows}
            inputProps={{ maxLength: props.maxLength }}
            type={props.type}
            classes={{
              notchedOutline: clsx({
                [props.classes.borderWarning]:
                  props.errors[name] !== undefined,
              }),
            }}
          />
        )}
      />

      {props.errors[props.name] !== undefined && (
        <FormHelperText
          component={Box}
          color={props.theme.palette.warning.main + "!important"}
        >
          {props.errors[props.name].message}
        </FormHelperText>
      )}
    </FormGroup>
  );
}
