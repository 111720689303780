import { React } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
//@material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
//@material-ui/icons
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
//custom components
import GenericDialog from "components/Dialogs/GenericDialogV2";
import GenericButton from "components/Buttons/GenericButton";
import { labels } from "resources/resources";
//styles
import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ApproveDialog(props) {
  const classes = { ...useStylesGeneric() };
  const intl = useIntl();

  const handleClose = () => {
    props.onClose();
  };

  const onSubmitApprove = () => {
    props.onSubmitApproveDialog();
  };

  return (
    <GenericDialog name={props.name} open={props.open} onClose={props.onClose}>
      <Box className={classes.modalHelpIconBox}>
        {props?.showErrorIcon ? (
          <ErrorOutlineOutlinedIcon className={classes.modalHelpIconRoot} />
        ) : (
          <HelpOutlineIcon className={classes.modalHelpIconRoot} />
        )}
      </Box>
      <Box className={classes.modalHeader}>{props.title}</Box>
      <Grid container>
        <Grid item xs={12} sm={1} md={2}></Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormGroup>
            <GenericButton typeSubmit={false} color="secondary" size="medium" onClick={handleClose}>
              {intl.formatMessage(labels.Label_No)}
            </GenericButton>
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={2} md={2}></Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormGroup>
            <GenericButton
              typeSubmit={false}
              color="primary"
              onClick={() => {
                onSubmitApprove();
              }}
              loading={props.isLoading}
            >
              {intl.formatMessage(labels.Label_Yes)}
            </GenericButton>
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={1} md={2}></Grid>
      </Grid>
    </GenericDialog>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(ApproveDialog);
