import { React, Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Controller, useForm, FormProvider } from "react-hook-form";
import clsx from "clsx";
import { useIntl } from "react-intl";
//material-ui/core
import { IconButton } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { green, red } from "@material-ui/core/colors";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
//material-ui/icons
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
//custom components
import GenericButton from "components/Buttons/GenericButton";
import { usePasswordValidation } from "hooks/usePasswordValidation";
import changePasswordMap from "mappers/changePasswordMap";
import changePasswordAction from "redux/actions/auth/changePasswordAction";
import { messages, labels } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
const useStylesGeneric = makeStyles(componentStylesGeneric);

function PasswordForm(props) {
  const theme = useTheme();
  const classes = useStylesGeneric();
  const intl = useIntl();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const handleClickShowCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
  const handleMouseDownCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const handleClickShowPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);
  const handleMouseDownPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);

  const [password, setPassword] = useState({
    firstPassword: "",
    secondPassword: "",
  });

  const methods = useForm({
    mode: "onChange",
  });

  const [passwordValidation] = usePasswordValidation({
    firstPassword: methods.getValues("newPassword"),
    secondPassword: methods.getValues("newPasswordConfirmation"),
  });

  useEffect(() => {
    methods.clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (passwordValidation?.isValid != null) methods.trigger("newPassword");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordValidation?.isValid]);

  const setFirst = (event) => {
    setPassword({ ...password, firstPassword: event.target.value });
  };

  const setSecond = (event) => {
    setPassword({ ...password, secondPassword: event.target.value });
  };

  function changePw(data) {
    props.changePassword(changePasswordMap(data), () => {
      props.handleCloseModal();
    });
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(changePw)}>
        <Box>
          <Box className={classes.modalHeader} marginBottom="30px!important">
            Alterar palavra-passe
          </Box>
          <Box marginTop="30px">
            <Grid container>
              <Grid item xs={12} md={12}>
                <FormGroup>
                  <FormLabel>Palavra-passe actual</FormLabel>
                  <Controller
                    name="currentPassword"
                    control={methods.control}
                    component={Box}
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                      },
                    }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        fullWidth
                        autoComplete="off"
                        type={showCurrentPassword ? "text" : "password"}
                        onChange={(e) => {
                          onChange(e);
                          methods.getValues("newPassword").length > 0 && methods.trigger("newPassword");
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowCurrentPassword}
                              onMouseDown={handleMouseDownCurrentPassword}
                            >
                              {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: methods.formState.errors["currentPassword"] !== undefined,
                          }),
                        }}
                      />
                    )}
                  />
                  {methods.formState.errors["currentPassword"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {methods.formState.errors["currentPassword"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>

              <Grid item xs={12} md={12}>
                <FormGroup>
                  <FormLabel>Nova palavra-passe</FormLabel>
                  <Controller
                    name="newPassword"
                    control={methods.control}
                    component={Box}
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                      },
                      validate: {
                        validPassword: (value) => {
                          if (methods.getValues("currentPassword") === value)
                            return "Nova palavra-passe deve ser diferente da actual";
                          if (!passwordValidation.isValid) return "Palavra-passe inválida";
                          return true;
                        },
                        //  EmailMatch: value => { return value === methods.getValues("email") || "O e-mail inserido deve ser igual ao campo 'E-mail'"; }
                      },
                    }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        fullWidth
                        autoComplete="off"
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => {
                          onChange(e);
                          setFirst(e);
                          methods.trigger("newPasswordConfirmation");
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: methods.formState.errors["newPassword"] !== undefined,
                          }),
                        }}
                      />
                    )}
                  />
                  {methods.formState.errors["newPassword"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {methods.formState.errors["newPassword"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormGroup>
                  <FormLabel>Confirmação da nova palavra-passe</FormLabel>
                  <Controller
                    name="newPasswordConfirmation"
                    defaultValue=""
                    control={methods.control}
                    component={Box}
                    rules={{
                      required: {
                        value: true,
                        message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                      },
                      validate: {
                        PasswordMatch: (value) => {
                          return (
                            value === methods.getValues("newPassword") ||
                            "O valor inserido deve ser igual ao campo 'Nova palavra-passe'" ||
                            methods.trigger("newPassword")
                          );
                        },
                      },
                    }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        fullWidth
                        autoComplete="off"
                        type={showPasswordConfirm ? "text" : "password"}
                        onChange={(e) => {
                          onChange(e);
                          setSecond(e);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPasswordConfirm}
                              onMouseDown={handleMouseDownPasswordConfirm}
                            >
                              {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: methods.formState.errors["newPasswordConfirmation"] !== undefined,
                          }),
                        }}
                      />
                    )}
                  />
                  {methods.formState.errors["newPasswordConfirmation"] !== undefined &&
                    methods.formState.errors["newPassword"] === undefined && (
                      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                        {methods.formState.errors["newPasswordConfirmation"].message}
                      </FormHelperText>
                    )}
                </FormGroup>
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <FormLabel>{intl.formatMessage(messages.Message_Auth_Password_Rules_1)}</FormLabel>
              <List dense={true}>
                <ListItem>
                  <Fragment>
                    {passwordValidation.validLength ? (
                      <CheckCircleOutlinedIcon style={{ marginRight: 8, color: green[500] }} />
                    ) : (
                      <CancelOutlinedIcon style={{ marginRight: 8, color: red[500] }} />
                    )}
                    {intl.formatMessage(messages.Message_Auth_Password_Rules_2)}
                  </Fragment>
                </ListItem>
                <ListItem>
                  <Fragment>
                    {passwordValidation.upperCase ? (
                      <CheckCircleOutlinedIcon style={{ marginRight: 8, color: green[500] }} />
                    ) : (
                      <CancelOutlinedIcon style={{ marginRight: 8, color: red[500] }} />
                    )}
                    {intl.formatMessage(messages.Message_Auth_Password_Rules_3)}
                  </Fragment>
                </ListItem>
                <ListItem>
                  <Fragment>
                    {passwordValidation.lowerCase ? (
                      <CheckCircleOutlinedIcon style={{ marginRight: 8, color: green[500] }} />
                    ) : (
                      <CancelOutlinedIcon style={{ marginRight: 8, color: red[500] }} />
                    )}
                    {intl.formatMessage(messages.Message_Auth_Password_Rules_4)}
                  </Fragment>
                </ListItem>
                <ListItem>
                  <Fragment>
                    {passwordValidation.hasNumber ? (
                      <CheckCircleOutlinedIcon style={{ marginRight: 8, color: green[500] }} />
                    ) : (
                      <CancelOutlinedIcon style={{ marginRight: 8, color: red[500] }} />
                    )}
                    {intl.formatMessage(messages.Message_Auth_Password_Rules_5)}
                  </Fragment>
                </ListItem>
                <ListItem>
                  <Fragment>
                    {passwordValidation.specialChar ? (
                      <CheckCircleOutlinedIcon style={{ marginRight: 8, color: green[500] }} />
                    ) : (
                      <CancelOutlinedIcon style={{ marginRight: 8, color: red[500] }} />
                    )}
                    {intl.formatMessage(messages.Message_Auth_Password_Rules_6)}
                  </Fragment>
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Box textAlign="center" marginBottom="30px" marginTop="30px">
            <GenericButton typeSubmit={true} color="primary">
              {intl.formatMessage(labels.Label_Generic_Submit_Button)}
            </GenericButton>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  changePassword: (data, onCloseMethod) => dispatch(changePasswordAction(data, onCloseMethod)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForm);
