import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import clsx from "clsx";
import { useIntl } from "react-intl";
// @material-ui/core components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FormLabel from "@material-ui/core/FormLabel";
// @material-ui/icons components
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { green, red } from "@material-ui/core/colors";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { IconButton } from "@material-ui/core";
//import custom components
import GenericButton from "components/Buttons/GenericButton";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import resetPasswordAction from "redux/actions/auth/resetPasswordAction";
import resetResetPasswordStateAction from "redux/actions/auth/resetResetPasswordStateAction";
import { changePasswordMap2 } from "mappers/changePasswordMap";
import { messages } from "resources/resources";
import useWindowSize from "hooks/useWindowSize";
import { usePasswordValidation } from "hooks/usePasswordValidation";
//Styles
import componentStylesLogin from "assets/theme/views/auth/login.js";
import componentStylesGenericDialog from "assets/theme/components/dialogs/generic-dialog";
import componentStylesGeneric from "assets/theme/views/admin/generic";
const useStylesLogin = makeStyles(componentStylesLogin);
const useStylesGenericDialog = makeStyles(componentStylesGenericDialog);
const useStylesGeneric = makeStyles(componentStylesGeneric);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ResetPassword(props) {
  let query = useQuery();
  const history = useHistory();
  const theme = useTheme();
  const classes = { ...useStylesGenericDialog(), ...useStylesLogin(), ...useStylesGeneric() };
  const intl = useIntl();
  const windowSize = useWindowSize();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const handleClickShowPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);
  const handleMouseDownPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);

  const [password, setPassword] = useState({
    firstPassword: "",
    secondPassword: "",
  });

  const [userName, setUserName] = useState("");
  const [token, setToken] = useState("");

  const handleLoginState = () => {
    history.push("/auth/login");
  };

  const {
    handleSubmit,
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const [passwordValidation] = usePasswordValidation({
    firstPassword: getValues("password"),
    secondPassword: getValues("passwordConfirmation"),
  });

  useEffect(() => {
    if (passwordValidation?.isValid != null) trigger("password");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordValidation?.isValid]);

  const setFirst = (event) => {
    setPassword({ ...password, firstPassword: event.target.value });
  };
  const setSecond = (event) => {
    setPassword({ ...password, secondPassword: event.target.value });
  };

  function changePw(data) {
    if (userName && token && changePasswordMap2(data)) {
      props.resetPassword(userName, changePasswordMap2(data).Password, token);
      setShowConfirmModal(true);
    }
  }

  useEffect(() => {
    setUserName(query.get("username"));
    setToken(query.get("token"));
    props.resetResetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {windowSize.width > 900 && (
        <Box style={{ width: "50%", float: "left" }}>
          <img
            src={"/images/empregoAngolaLogin.png"} //Designed by Rawpixel.com / Freepik: http://www.freepik.com
            className={classes.backgroundImage}
            alt={""}
          />
        </Box>
      )}

      <GenericDialog
        name="recover-password-dialog"
        open={true}
        onClose={() => {
          handleLoginState();
        }}
      >
        <Box className={classes.modalHeader}> Alteração de Palavra-passe</Box>
        <form onSubmit={handleSubmit(changePw)}>
          <Box>
            <Box marginTop="50px">
              <Grid container>
                <Grid item xs={12} md={12}>
                  <FormGroup>
                    <FormLabel>Nova palavra-passe</FormLabel>
                    <Controller
                      name="password"
                      control={control}
                      component={Box}
                      defaultValue=""
                      value={getValues("password")}
                      rules={{
                        required: {
                          value: true,
                          message: "O campo é obrigatório",
                        },
                        validate: {
                          validPassword: () => {
                            if (!passwordValidation.isValid) return "Palavra-passe inválida";
                            return true;
                          },
                        },
                      }}
                      render={({ field: { onChange, name, value } }) => (
                        <OutlinedInput
                          name={name}
                          value={value}
                          fullWidth
                          autoComplete="off"
                          type={showPassword ? "text" : "password"}
                          onChange={(e) => {
                            onChange(e);
                            setFirst(e);
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                          classes={{
                            notchedOutline: clsx({
                              [classes.borderWarning]: errors["password"] !== undefined,
                            }),
                          }}
                        />
                      )}
                    />
                    {errors["password"] !== undefined && !passwordValidation.isValid && (
                      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                        {errors["password"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormGroup>
                    <FormLabel>Confirmação da nova palavra-passe</FormLabel>
                    <Controller
                      name="passwordConfirmation"
                      defaultValue=""
                      value={getValues("passwordConfirmation")}
                      control={control}
                      component={Box}
                      rules={{
                        required: {
                          value: true,
                          message: "O campo é obrigatório",
                        },
                        validate: {
                          PasswordMatch: (value) => {
                            return (
                              value === getValues("password") ||
                              "O valor inserido deve ser igual ao campo 'Palavra-passe'"
                            );
                          },
                        },
                      }}
                      render={({ field: { onChange, name, value } }) => (
                        <OutlinedInput
                          name={name}
                          value={value}
                          fullWidth
                          autoComplete="off"
                          type={showPasswordConfirm ? "text" : "password"}
                          onChange={(e) => {
                            onChange(e);
                            setSecond(e);
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPasswordConfirm}
                                onMouseDown={handleMouseDownPasswordConfirm}
                              >
                                {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                          classes={{
                            notchedOutline: clsx({
                              [classes.borderWarning]: errors["passwordConfirmation"] !== undefined,
                            }),
                          }}
                        />
                      )}
                    />
                    {errors["passwordConfirmation"] !== undefined && (
                      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                        {errors["passwordConfirmation"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <FormLabel>{intl.formatMessage(messages.Message_Auth_Password_Rules_1)}</FormLabel>
                <List dense={true}>
                  <ListItem>
                    <Fragment>
                      {passwordValidation.validLength ? (
                        <CheckCircleOutlinedIcon
                          style={{
                            marginRight: 8,
                            color: green[500],
                          }}
                        />
                      ) : (
                        <CancelOutlinedIcon style={{ marginRight: 8, color: red[500] }} />
                      )}
                      {intl.formatMessage(messages.Message_Auth_Password_Rules_2)}
                    </Fragment>
                  </ListItem>
                  <ListItem>
                    <Fragment>
                      {passwordValidation.upperCase ? (
                        <CheckCircleOutlinedIcon
                          style={{
                            marginRight: 8,
                            color: green[500],
                          }}
                        />
                      ) : (
                        <CancelOutlinedIcon style={{ marginRight: 8, color: red[500] }} />
                      )}
                      {intl.formatMessage(messages.Message_Auth_Password_Rules_3)}
                    </Fragment>
                  </ListItem>
                  <ListItem>
                    <Fragment>
                      {passwordValidation.lowerCase ? (
                        <CheckCircleOutlinedIcon
                          style={{
                            marginRight: 8,
                            color: green[500],
                          }}
                        />
                      ) : (
                        <CancelOutlinedIcon style={{ marginRight: 8, color: red[500] }} />
                      )}
                      {intl.formatMessage(messages.Message_Auth_Password_Rules_4)}
                    </Fragment>
                  </ListItem>
                  <ListItem>
                    <Fragment>
                      {passwordValidation.hasNumber ? (
                        <CheckCircleOutlinedIcon
                          style={{
                            marginRight: 8,
                            color: green[500],
                          }}
                        />
                      ) : (
                        <CancelOutlinedIcon style={{ marginRight: 8, color: red[500] }} />
                      )}
                      {intl.formatMessage(messages.Message_Auth_Password_Rules_5)}
                    </Fragment>
                  </ListItem>
                  <ListItem>
                    <Fragment>
                      {passwordValidation.specialChar ? (
                        <CheckCircleOutlinedIcon
                          style={{
                            marginRight: 8,
                            color: green[500],
                          }}
                        />
                      ) : (
                        <CancelOutlinedIcon style={{ marginRight: 8, color: red[500] }} />
                      )}
                      {intl.formatMessage(messages.Message_Auth_Password_Rules_6)}
                    </Fragment>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <Box textAlign="center" marginBottom="30px" marginTop="30px">
              <GenericButton typeSubmit={true} loading={props.authState.reset_password_loading} color="primary">
                Submeter
              </GenericButton>
            </Box>
          </Box>
        </form>

        <GenericDialog name="dialog" open={showConfirmModal}>
          <Box container justifyContent="center">
            <CheckCircleOutlinedIcon classes={{ root: classes.modalResultIcon }} />
            <Box className={classes.modalResultHeader} style={{ marginTop: "8rem" }}>
              Palavra-passe alterada com sucesso.
            </Box>
            <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
              <GenericButton
                typeSubmit={true}
                onClick={() => {
                  handleLoginState();
                }}
                color="primary"
              >
                Continuar
              </GenericButton>
            </Box>
          </Box>
        </GenericDialog>
      </GenericDialog>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (username, password, token) => dispatch(resetPasswordAction(username, password, token)),
  resetResetState: () => dispatch(resetResetPasswordStateAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
