import React from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";

import DisplayField from "components/Fields/DisplayField";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { titles, labels } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ProfessionalDataSection(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();

  return (
    <>
      <Card className={classes.cardRoot}>
        <CardHeader
          classes={{ title: classes.cardHeader }}
          title={intl.formatMessage(titles.Title_UserOverview_ProfessionTab_ProfessionalDataSection)}
          data-testid="user-overview-professional-data-section-title"
        ></CardHeader>
        <CardContent>
          <Grid container>
            <DisplayField
              xs={12}
              sm={6}
              md={3}
              label={intl.formatMessage(labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_AcademicLevel)}
              data={props?.userProfessionalDataOverview?.academicLevelDescription}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={3}
              label={intl.formatMessage(labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_AcademicDegree)}
              data={props?.userProfessionalDataOverview?.academicDegreeDescription}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={3}
              label={intl.formatMessage(
                labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_ProfessionalCategory
              )}
              data={props?.userProfessionalDataOverview?.professionalCategoryDescription}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={3}
              label={intl.formatMessage(
                labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_ProfessionalTraining
              )}
              data={props?.userProfessionalDataOverview?.professionalTrainingDescription}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={3}
              label={intl.formatMessage(
                labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_EntrepreneurshipTraining
              )}
              data={props?.userProfessionalDataOverview?.entrepreneurshipTrainingDescription}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={3}
              label={intl.formatMessage(
                labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_SmallBusinessManagementTraining
              )}
              data={props?.userProfessionalDataOverview?.smallBusinessMgmtTrainingDescription}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={3}
              label={intl.formatMessage(
                labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_IntendedWorkplaceProvince
              )}
              data={props?.userProfessionalDataOverview?.intendedWorkplaceProvinceDescription}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={3}
              label={intl.formatMessage(
                labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_OccupationalCategory
              )}
              data={props?.userProfessionalDataOverview?.occupationalCategoryDescription}
            />
            <DisplayField
              xs={12}
              sm={props?.userProfessionalDataOverview?.professionalExperienceDescriptions?.length > 6 ? 12 : 6}
              md={props?.userProfessionalDataOverview?.professionalExperienceDescriptions?.length > 6 ? 12 : 3}
              label={intl.formatMessage(labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_Profession)}
              data={props?.userProfessionalDataOverview?.professionalExperienceDescriptions?.join(", ")}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={3}
              label={intl.formatMessage(
                labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_MainYearsOfExperience
              )}
              data={props?.userProfessionalDataOverview?.yearsOfExperience}
            />
            <DisplayField
              xs={12}
              sm={props?.userProfessionalDataOverview?.secondaryProfessionalExperienceDescriptions?.length > 6 ? 12 : 6}
              md={props?.userProfessionalDataOverview?.secondaryProfessionalExperienceDescriptions?.length > 6 ? 12 : 3}
              label={intl.formatMessage(
                labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_SecondaryProfessionalExperience
              )}
              data={props?.userProfessionalDataOverview?.secondaryProfessionalExperienceDescriptions?.join(", ")}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={3}
              label={intl.formatMessage(
                labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_SecondaryExperience
              )}
              data={props?.userProfessionalDataOverview?.secondaryExperience}
            />
            <DisplayField
              xs={12}
              sm={props?.userProfessionalDataOverview?.studiedLanguagesDescriptions?.length > 6 ? 12 : 6}
              md={props?.userProfessionalDataOverview?.studiedLanguagesDescriptions?.length > 6 ? 12 : 3}
              label={intl.formatMessage(
                labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_StudiedLanguages
              )}
              data={props?.userProfessionalDataOverview?.studiedLanguagesDescriptions?.join(", ")}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={3}
              label={intl.formatMessage(
                labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_Has_Professional_Wallet
              )}
              data={
                props?.userProfessionalDataOverview?.hasProfessionalWallet
                  ? intl.formatMessage(labels.Label_Yes)
                  : intl.formatMessage(labels.Label_No)
              }
            />
            {props?.userProfessionalDataOverview?.hasProfessionalWallet && (
              <>
                <DisplayField
                  xs={12}
                  sm={6}
                  md={3}
                  label={intl.formatMessage(
                    labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_Professional_Wallet_Number
                  )}
                  data={props?.userProfessionalDataOverview?.professionalWalletNumber}
                />
                <DisplayField
                  xs={12}
                  sm={6}
                  md={3}
                  label={intl.formatMessage(
                    labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_Professional_Wallet_Class
                  )}
                  data={props?.userProfessionalDataOverview?.professionalWalletClass}
                />
                <DisplayField
                  xs={12}
                  sm={6}
                  md={3}
                  label={intl.formatMessage(
                    labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_Professional_Wallet_Specialty
                  )}
                  data={props?.userProfessionalDataOverview?.professionalWalletSpecialty}
                />
              </>
            )}
          </Grid>
          <Grid container>
            <DisplayField
              xs={12}
              sm={6}
              label={intl.formatMessage(
                labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_ProfessionalQualification
              )}
              data={props?.userProfessionalDataOverview?.professionalQualification}
            />
            <DisplayField
              xs={12}
              sm={6}
              label={intl.formatMessage(
                labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_OtherProfessionalExperience
              )}
              data={props?.userProfessionalDataOverview?.otherProfessionalExperience}
            />
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(ProfessionalDataSection);
