export function changePasswordMap(formData) {
  if (formData != null && formData !== undefined)
    return {
      currentPassword: formData.currentPassword ? formData.currentPassword : "",
      newPassword: formData.newPassword ? formData.newPassword : "",
    };
  return formData;
}

export default changePasswordMap;

export function changePasswordMap2(formData) {
  if (formData != null && formData !== undefined)
    return {
      Password: formData.password ? formData.password : "",
    };
  return formData;
}
