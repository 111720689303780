import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactDatetime from "react-datetime";
import "moment/locale/pt";
import moment from "moment";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import { Card, CardHeader, CardContent } from "@material-ui/core";
// @material-ui/icons components
import SearchIcon from "@material-ui/icons/Search";
import DateRange from "@material-ui/icons/DateRange";
import HelpIcon from "@material-ui/icons/Help";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic";
import FormSelect from "components/FormFields/FormSelect";
import GenericButton from "components/Buttons/GenericButton";
import {
  getDataSetByNameAction,
  getProgramsAction,
  getSubprogramsAction,
  getSubprogramByProgramIdAction,
  clearDataSetByNameAction,
  getGovernmentProgramsAction,
} from "redux/actions/datasetsAction";
import { getFilteredApplicationsActions } from "redux/actions/applicationsAction";
import { getReportAction } from "redux/actions/reportsAction";
import { DATASET, PAGINATION, OPERATIONS, REPORT_FILE_NAME, REPORT_CONSTS } from "utils/consts";
import { checkOperationPermission } from "utils/permission";
import { getSelectOptionsElementByCode } from "utils/formSelect";
import { applicationsFilterMap, applicationsFilterExportReportMap } from "mappers/applicationsDetailsMap";
import { labels, messages, errorMessages, titles } from "resources/resources";
import { REGEX_PATTERNS } from "utils/consts";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function FilterSection(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const theme = useTheme();
  //TODO: Validar se os novos campos são contabilizados nos filtros
  const [filter, setFilter] = useState({
    numApplication: "",
    numBI: "",
    userCode: "",
    nameOfUser: "",
    stateId: null,
    governmentProgramId: null,
    programId: null,
    subprogramId: null,
    internshipAreaId: null,
    startDate: null,
    endDate: null,
    provinceId: props.authState?.province ?? null,
    candidateMunicipality: null, //TODO: VER SE O AUTH STATE TAMBÉM TEM MUNICIPALITY
    candidateProvince: null,
    hasEntrepreneurshipTraining: null,
    genderId: null,
    academicLevelId: null,
    degreeId: null,
  });
  const [filterCounter, setFilterCounter] = useState(0);

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const handleInputValidDate = (date, onChange) => {
    if (date === "" || REGEX_PATTERNS.DATE.test(date)) {
      onChange(date);
    }
  };

  useEffect(() => {
    let count = 0;

    Object.keys(filter).map((key) => {
      if (filter[key] && key !== "pageIndex" && key !== "pageSize") {
        count += 1;
      }

      return count;
    });

    setFilterCounter(count);
  }, [filter]);

  const cleanFilter = () => {
    setValue("applicationNumber", "");
    setValue("identityCardOrTaxIdNum", "");
    setValue("userCode", "");
    setValue("nameOfUser", "");
    setValue("phoneNumber", "");
    setValue("statusId", undefined);
    setValue("governmentProgramId", undefined);
    setValue("programId", undefined);
    setValue("subprogramId", undefined);
    setValue("internshipAreaId", undefined);
    setValue("startDate", undefined);
    setValue("endDate", undefined);
    setValue("candidateProvince", undefined);
    setValue("candidateMunicipality", undefined);
    setValue("hasEntrepreneurshipTraining", undefined);
    setValue("genderId", undefined);
    setValue("academicLevelId", undefined);
    setValue("degreeId", undefined);
    if (!props.authState?.province) setValue("province", undefined);
    props.clearDataSetByName(DATASET.SUBPROGRAMS);

    setFilterCounter(0);
    setFilter(0);
  };

  const onSubmit = (data) => {
    let filter = applicationsFilterMap(data);
    filter["pageIndex"] = PAGINATION.FIRST_PAGE;
    filter["pageSize"] = PAGINATION.PAGE_SIZE;
    setFilter(filter);
    if (props.page === PAGINATION.FIRST_PAGE) props.getFilteredApplications(filter);
    else props.handleChangePage(1);
  };

  const handleSetFilter = (filterKey, value, clearChildKeyList) => {
    const newFilter = { ...filter, [filterKey]: value };

    clearChildKeyList?.forEach((child) => {
      newFilter[child] = null;
    });

    setFilter(newFilter);
  };

  useEffect(() => {
    if (
      props.dataSetState[DATASET.GOVERNMENT_PROGRAMS] === undefined ||
      props.dataSetState[DATASET.GOVERNMENT_PROGRAMS]?.length === 0
    ) {
      props.getGovernmentPrograms();
    }

    if (
      props.dataSetState[DATASET.APPLICATION_STATES] === undefined ||
      props.dataSetState[DATASET.APPLICATION_STATES]?.length === 0
    ) {
      props.getDataSet(DATASET.APPLICATION_STATES);
    }

    if (props.dataSetState[DATASET.PROVINCES] === undefined || props.dataSetState[DATASET.PROVINCES]?.length === 0) {
      props.getDataSet(DATASET.PROVINCES);
    }

    if (
      props.dataSetState[DATASET.PROFESSIONAL_CATEGORY] === undefined ||
      props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]?.length === 0
    ) {
      props.getDataSet(DATASET.PROFESSIONAL_CATEGORY);
    }

    if (props.dataSetState[DATASET.GENDER] === undefined || props.dataSetState[DATASET.GENDER]?.length === 0) {
      props.getDataSet(DATASET.GENDER);
    }

    if (
      props.dataSetState[DATASET.ACADEMIC_LEVEL] === undefined ||
      props.dataSetState[DATASET.ACADEMIC_LEVEL]?.length === 0
    ) {
      props.getDataSet(DATASET.ACADEMIC_LEVEL);
    }

    if (
      props.dataSetState[DATASET.ACADEMIC_DEGREE] === undefined ||
      props.dataSetState[DATASET.ACADEMIC_DEGREE]?.length === 0
    ) {
      props.getDataSet(DATASET.ACADEMIC_DEGREE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      props.authState?.province &&
      props.dataSetState[DATASET.PROVINCES] !== undefined &&
      props.dataSetState[DATASET.PROVINCES]?.length !== 0
    ) {
      var selectedProvince = getSelectOptionsElementByCode(
        props.dataSetState[DATASET.PROVINCES],
        props.authState.province
      );
      setValue("province", selectedProvince);
      setValue("candidateProvince", selectedProvince);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.PROVINCES]]);

  useEffect(() => {
    if (props.match?.params?.numBI !== undefined) {
      setValue("identityCardOrTaxIdNum", props.match?.params?.numBI !== undefined ? props.match.params.numBI : "");

      handleSubmit(onSubmit)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match?.params?.numBI]);

  useEffect(() => {
    let temp_filter = filter;
    Object.assign(temp_filter, filter);
    temp_filter["pageIndex"] = props.page;
    temp_filter["pageSize"] = PAGINATION.PAGE_SIZE;
    props.getFilteredApplications(temp_filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page]);

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={
          <div>
            <div className={classes.sideBySideDiv}>{intl.formatMessage(titles.Title_Admin_ApplicationsManagement)}</div>
            <div className={classes.sideBySideDiv + " " + classes.cardElementsCount}>
              Filtros {"("}
              {filterCounter}
              {")"}
            </div>
          </div>
        }
      ></CardHeader>
      <CardContent>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_NumApplicationField)}
                  </FormLabel>
                  <Controller
                    name="applicationNumber"
                    control={control}
                    defaultValue=""
                    rules={{ required: { value: false } }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("numApplication", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder={intl.formatMessage(labels.Label_Search_Filters_Placeholder)}
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["applicationNumber"] !== undefined,
                          }),
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>Código de Utilizador</FormLabel>
                  <Controller
                    name="userCode"
                    control={control}
                    defaultValue=""
                    rules={{ required: { value: false } }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("userCode", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder={intl.formatMessage(labels.Label_Search_Filters_Placeholder)}
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["userCode"] !== undefined,
                          }),
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_NumBIField)}
                    <Tooltip title="Nº BI se Cidadão, NIF se Empresa">
                      <HelpIcon className={classes.tooltipInfoIcon} />
                    </Tooltip>
                  </FormLabel>
                  <Controller
                    name="identityCardOrTaxIdNum"
                    control={control}
                    defaultValue=""
                    rules={{ required: { value: false } }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("identityCardOrTaxIdNum", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder={intl.formatMessage(labels.Label_Search_Filters_Placeholder)}
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["identityCardOrTaxIdNum"] !== undefined,
                          }),
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_UserNameField)}
                  </FormLabel>
                  <Controller
                    name="nameOfUser"
                    control={control}
                    defaultValue=""
                    rules={{ required: { value: false } }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("nameOfUser", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder={intl.formatMessage(labels.Label_Search_Filters_Placeholder)}
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["nameOfUser"] !== undefined,
                          }),
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_PhoneNumber)}
                  </FormLabel>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue=""
                    rules={{ required: { value: false } }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("phoneNumber", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder={intl.formatMessage(labels.Label_Search_Filters_Placeholder)}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_CandidateProvince)}
                  </FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="candidateProvince"
                    selectOptions={props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : []}
                    setValue={setValue}
                    getValues={getValues}
                    loadChildrenDataSet={props.getDataSet}
                    childrenDataSet={DATASET.MUNICIPALITIES}
                    childrenSelect="candidateMunicipality"
                    required={false}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"candidateProvince"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_CandidateMunicipality)}
                  </FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="candidateMunicipality"
                    selectOptions={
                      props.dataSetState[DATASET.MUNICIPALITIES] ? props.dataSetState[DATASET.MUNICIPALITIES] : []
                    }
                    disabled={!getValues("candidateProvince")}
                    getValues={getValues}
                    setValue={setValue}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"candidateMunicipality"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_EntrepreneurshipTraining)}
                  </FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="hasEntrepreneurshipTraining"
                    selectOptions={[
                      { code: true, label: intl.formatMessage(labels.Label_Yes) },
                      { code: false, label: intl.formatMessage(labels.Label_No) },
                    ]}
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"hasEntrepreneurshipTraining"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel classes={{ root: classes.filterLabel }}>
                    {intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_InternshipArea)}
                  </FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="internshipAreaId"
                    selectOptions={
                      props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]
                        ? props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]
                        : []
                    }
                    setValue={setValue}
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"internshipAreaId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_GovernmentProgram)}
                  </FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="governmentProgramId"
                    selectOptions={
                      props.dataSetState[DATASET.GOVERNMENT_PROGRAMS] !== undefined
                        ? props.dataSetState[DATASET.GOVERNMENT_PROGRAMS]
                        : []
                    }
                    getValues={getValues}
                    setValue={setValue}
                    loadChildrenDataSet={props.getPrograms}
                    childrenDataSet={DATASET.PROGRAMS}
                    childrenSelect="programId"
                    grandchildrenSelect="subprogramId"
                    onOptionChange={() => {
                      setValue("subprogramId", undefined);
                    }}
                    required={false}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"governmentProgramId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_ProgramField)}
                  </FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="programId"
                    selectOptions={
                      props.dataSetState[DATASET.PROGRAMS] !== undefined ? props.dataSetState[DATASET.PROGRAMS] : []
                    }
                    disabled={!getValues("governmentProgramId")}
                    getValues={getValues}
                    setValue={setValue}
                    loadChildrenDataSet={props.getSubprogramByProgramId}
                    childrenDataSet={DATASET.SUBPROGRAMS}
                    childrenSelect="subprogramId"
                    required={false}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"programId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_SubprogramField)}
                  </FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="subprogramId"
                    selectOptions={
                      props.dataSetState[DATASET.SUBPROGRAMS] !== undefined
                        ? props.dataSetState[DATASET.SUBPROGRAMS]
                        : []
                    }
                    disabled={!getValues("programId")}
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"subprogramId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_ApplicationProvince)}
                  </FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="province"
                    selectOptions={props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : []}
                    disabled={props.authState?.province !== null}
                    setValue={setValue}
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"province"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_StatusField)}
                  </FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="statusId"
                    selectOptions={
                      props.dataSetState[DATASET.APPLICATION_STATES] !== undefined
                        ? props.dataSetState[DATASET.APPLICATION_STATES]
                        : []
                    }
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"statusId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>{intl.formatMessage(labels.Label_Admin_GeneralDataSection_GenderField)}</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="genderId"
                    selectOptions={
                      props.dataSetState[DATASET.GENDER] !== undefined ? props.dataSetState[DATASET.GENDER] : []
                    }
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"genderId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_AcademicLevel)}
                  </FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="academicLevelId"
                    selectOptions={
                      props.dataSetState[DATASET.ACADEMIC_LEVEL] !== undefined
                        ? props.dataSetState[DATASET.ACADEMIC_LEVEL]
                        : []
                    }
                    loadChildrenDataSet={props.getDataSet}
                    childrenDataSet={DATASET.ACADEMIC_DEGREE}
                    childrenSelect="degreeId"
                    getValues={getValues}
                    setValue={setValue}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"academicLevelId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_AcademicDegree)}
                  </FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="degreeId"
                    selectOptions={
                      props.dataSetState[DATASET.ACADEMIC_DEGREE] !== undefined
                        ? props.dataSetState[DATASET.ACADEMIC_DEGREE]
                        : []
                    }
                    getValues={getValues}
                    required={false}
                    disabled={!getValues("academicLevelId")}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"degreeId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_SubmissionDateFrom)}
                  </FormLabel>
                  <Controller
                    name="startDate"
                    control={control}
                    defaultValue=""
                    rules={{
                      validate: {
                        isValidDate: (value) => {
                          return (
                            !value ||
                            value._isAMomentObject ||
                            intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat)
                          );
                        },
                        maxDate: (value) => {
                          return (
                            !value ||
                            value <= moment().startOf("day") ||
                            intl.formatMessage(messages.Message_Generic_DateLessThanOrEqualToTodayField)
                          );
                        },
                        isLessThanEndDate: (value) => {
                          if (!getValues("endDate") || errors["endDate"] !== undefined) return true;
                          return (
                            !value ||
                            (value !== "" &&
                              value._isAMomentObject &&
                              getValues("endDate")._isAMomentObject &&
                              value <= getValues("endDate")) ||
                            intl.formatMessage(messages.Message_Generic_DateFromLessThanOrEqualToDateToField)
                          );
                        },
                      },
                    }}
                    render={({ field: { onChange, name, value } }) => (
                      <ReactDatetime
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          if (getValues("endDate")) trigger("endDate");
                          trigger("startDate");
                        }}
                        locale="pt"
                        dateFormat={"DD/MM/YYYY"}
                        timeFormat={false}
                        isValidDate={(currentDate, _) => {
                          return (
                            currentDate <= moment().startOf("day") &&
                            (!getValues("endDate") ||
                              (getValues("endDate")._isAMomentObject && currentDate <= getValues("endDate")))
                          );
                        }}
                        closeOnSelect={true}
                        closeOnTab={true}
                        closeOnClickOutside={true}
                        inputProps={{
                          placeholder: "DD/MM/AAAA",
                          className: "",
                          value: value && value._isAMomentObject ? value.format("DD/MM/YYYY") : value ?? "",
                        }}
                        renderInput={(dateInputProps, open) => (
                          <OutlinedInput
                            style={{ display: "flex" }}
                            onFocus={open}
                            {...dateInputProps}
                            onChange={(e) => {
                              handleInputValidDate(e.target.value, dateInputProps.onChange);
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                <Box component={DateRange} marginRight=".5rem" color={theme.palette.primary.main} />
                              </InputAdornment>
                            }
                            classes={{
                              notchedOutline: clsx({
                                [classes.borderWarning]: errors["startDate"] !== undefined,
                              }),
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  {errors["startDate"] !== undefined && (
                    <FormHelperText
                      component={Box}
                      color={theme.palette.warning.badge + "!important"}
                      bgcolor={theme.palette.warning.badgeBg + "!important"}
                      borderRadius="25px"
                      padding={"0px 10px"}
                    >
                      {errors["startDate"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_SubmissionDateTo)}
                  </FormLabel>
                  <Controller
                    name="endDate"
                    control={control}
                    defaultValue=""
                    rules={{
                      validate: {
                        isValidDate: (value) => {
                          return (
                            !value ||
                            value._isAMomentObject ||
                            intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat)
                          );
                        },
                        maxDate: (value) => {
                          return (
                            !value ||
                            value <= moment() ||
                            intl.formatMessage(messages.Message_Generic_DateLessThanOrEqualToTodayField)
                          );
                        },
                        isGreaterThanStartDate: (value) => {
                          if (!getValues("startDate") || errors["startDate"] !== undefined) return true;
                          return (
                            !value ||
                            (value !== "" &&
                              value._isAMomentObject &&
                              getValues("startDate")._isAMomentObject &&
                              value >= getValues("startDate")) ||
                            intl.formatMessage(messages.Message_Generic_DateToGreaterThanOrEqualToDateFromField)
                          );
                        },
                      },
                    }}
                    render={({ field: { onChange, name, value } }) => (
                      <ReactDatetime
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          if (getValues("startDate")) trigger("startDate");
                          trigger("endDate");
                        }}
                        locale="pt"
                        dateFormat={"DD/MM/YYYY"}
                        timeFormat={false}
                        isValidDate={(currentDate, _) => {
                          return (
                            currentDate <= moment() &&
                            (!getValues("startDate") ||
                              (getValues("startDate")._isAMomentObject && currentDate >= getValues("startDate")))
                          );
                        }}
                        closeOnSelect={true}
                        closeOnTab={true}
                        closeOnClickOutside={true}
                        inputProps={{
                          placeholder: "DD/MM/AAAA",
                          className: "",
                          value: value && value._isAMomentObject ? value.format("DD/MM/YYYY") : value ?? "",
                        }}
                        renderInput={(dateInputProps, open) => (
                          <OutlinedInput
                            style={{ display: "flex" }}
                            onFocus={open}
                            {...dateInputProps}
                            onChange={(e) => {
                              handleInputValidDate(e.target.value, dateInputProps.onChange);
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                <Box component={DateRange} marginRight=".5rem" color={theme.palette.primary.main} />
                              </InputAdornment>
                            }
                            classes={{
                              notchedOutline: clsx({
                                [classes.borderWarning]: errors["endDate"] !== undefined,
                              }),
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  {errors["endDate"] !== undefined && (
                    <FormHelperText
                      component={Box}
                      color={theme.palette.warning.badge + "!important"}
                      bgcolor={theme.palette.warning.badgeBg + "!important"}
                      borderRadius="25px"
                      padding={"0px 10px"}
                    >
                      {errors["endDate"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={12} classes={{ root: classes.alignRight }}>
                <GenericButton
                  typeSubmit={false}
                  color="ghost"
                  onClick={() => {
                    cleanFilter();
                  }}
                >
                  {intl.formatMessage(labels.Label_ClearFilters)}
                </GenericButton>
                {checkOperationPermission(props.authState.operationsList, OPERATIONS.EXPORT_REPORTS) && (
                  <Tooltip
                    title={
                      props.applicationsState?.total_records > REPORT_CONSTS.MAX_RESULTS ||
                      props.applicationsState?.total_records === 0
                        ? `Limite mínimo de 1 e máximo de ${REPORT_CONSTS.MAX_RESULTS} resultados para exportar relatório`
                        : ""
                    }
                  >
                    <span>
                      <GenericButton
                        typeSubmit={false}
                        color="secondary"
                        loading={props.reportsState.get_report_loading}
                        disabled={
                          props.applicationsState?.total_records > REPORT_CONSTS.MAX_RESULTS ||
                          props.applicationsState?.total_records === 0
                        }
                        style={{ marginRight: "20px" }}
                        onClick={() => {
                          props.getReport(
                            applicationsFilterExportReportMap(filter),
                            REPORT_FILE_NAME.APPLICATIONS_SEARCH_RESULTS +
                              moment().format("YYYYMMDDHHmm") +
                              REPORT_FILE_NAME.EXCEL_EXTENSION
                          );
                        }}
                      >
                        {intl.formatMessage(labels.Label_ExportButton)}
                      </GenericButton>
                    </span>
                  </Tooltip>
                )}
                <GenericButton typeSubmit={true} color="primary" loading={props.applicationsState.loading}>
                  {intl.formatMessage(labels.Label_FilterSection_SearchButton)}
                </GenericButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getPrograms: (name, governmentProgramId) => dispatch(getProgramsAction(name, governmentProgramId)),
  getSubprograms: (name) => dispatch(getSubprogramsAction(name)),
  getFilteredApplications: (filterData) => dispatch(getFilteredApplicationsActions(filterData)),
  getSubprogramByProgramId: (name, program) => dispatch(getSubprogramByProgramIdAction(name, program)),
  clearDataSetByName: (name) => dispatch(clearDataSetByNameAction(name)),
  getReport: (data, reportFileName) => dispatch(getReportAction(data, reportFileName)),
  getGovernmentPrograms: () => dispatch(getGovernmentProgramsAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FilterSection));
