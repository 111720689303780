import { React, useState } from "react";
import { connect } from "react-redux";
import {
  getWarehousesByProvinceAction,
  getCategoriesAction,
} from "redux/actions/datasetsAction";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import CardSearchResult from "./CardSearchResult";
import Filter from "./Filter";




const useStylesGeneric = makeStyles(componentStylesGeneric);

function ProfessionalKits() {
  const classes = useStylesGeneric();
  const [page, setPage] = useState(1);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <Grid container classes={{ root: classes.containerRoot }} style={{marginTop: "11rem"}}>
      <Grid item xs={12} md={12}>
        <Filter
          page={page}
          handleChangePage={(value) => { handleChangePage(undefined, value) }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <CardSearchResult 
          page={page}
          handleChangePage={handleChangePage}
        />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getWarehousesByProvince: (name, province) =>
    dispatch(getWarehousesByProvinceAction(name, province)),
  getCategories: (name) => dispatch(getCategoriesAction(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalKits);
