import React from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import "moment/locale/pt";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";

import DisplayField from "components/Fields/DisplayField";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { titles } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function TrainingSection(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();

  return (
    <>
      <Card className={classes.cardRoot}>
        <CardHeader
            classes={{ title: classes.cardHeader }}
            title= {intl.formatMessage(titles.Title_UserOverview_ProfessionTab_TrainingSection)}
            data-testid="user-overview-training-section-title"
        ></CardHeader>
        <CardContent>
          <Grid container>
            {props?.userProfessionalDataOverview?.userTrainingCourses?.length > 0 ? (
              props?.userProfessionalDataOverview?.userTrainingCourses?.map((course, key) => (
                  <DisplayField 
                    key={key}
                    xs={12} sm={6} md={3}
                    label={course?.courseName}
                    data={(course?.periodStartDate ? moment(course?.periodStartDate).format("DD/MM/YYYY") : "N/A")
                          + " - " 
                          + (course?.periodEndDate ? moment(course?.periodEndDate).format("DD/MM/YYYY") : "N/A")} 
                  />
              ))
            ): (
                <Grid item xs={12} md={6} lg={4} >
                  Sem dados para apresentar
                </Grid>
              )}
          </Grid>
        </CardContent>
      </Card>
    </> 
  ); 
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(TrainingSection);
