import { React } from "react";
import { connect } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
//import custom components
import GenericButton from "components/Buttons/GenericButton";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import EnterpriseUserData from "./Sections/EnterpriseUserData";
import PasswordCard from "../../Components/PasswordCard";
import { registerAction } from "redux/actions/usersAction";
import frontOfficeEnterpriseUserRegisterMap from "mappers/frontOfficeEnterpriseUserRegisterMap";
import { labels } from "resources/resources";
import useScrollDirection from "hooks/useScrollDirection";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
const useStylesGeneric = makeStyles(componentStylesGeneric);

function CreateEnterpriseUser(props) {
  const history = useHistory();
  const classes = useStylesGeneric();
  const intl = useIntl();
  const scrollDirection = useScrollDirection();

  const methods = useForm({
    mode: "onChange",
  });

  function handleRegister(data) {
    props.register(frontOfficeEnterpriseUserRegisterMap(data));
  }

  const cancelOnClick = () => {
    history.goBack();
  };

  return (
    <>
      <GenericBackdrop open={props.usersInfo.getUserInfo_loading} />

      {scrollDirection === "up" && (
        <div className={classes.breadcrumbs}>
          <LocationHeader
            section={"Criar Utilizador Empresa"}
            subsection={"Gestão de Utilizadores FrontOffice"}
            subsectionLink={"/admin/frontofficeusers-management"}
          />
        </div>
      )}

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleRegister)}>
          <Container maxWidth={false} component={Box} classes={{ root: classes.containerRootAfterBreadcrumbs }}>
            <PageTitleHeader title={"Criar Utilizador Empresa"} />

            <Container maxWidth={false} component={Box}>
              <EnterpriseUserData isEditable={true} isCreate={true} />

              <PasswordCard />

              <Grid container>
                <Grid item xs={12} lg={6} xl={8}></Grid>
                <Grid item xs={12} lg={3} xl={2}>
                  <FormGroup>
                    <GenericButton typeSubmit={false} color="secondary" onClick={() => cancelOnClick()}>
                      {intl.formatMessage(labels.Label_Generic_Cancel)}
                    </GenericButton>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} lg={3} xl={2}>
                  <FormGroup>
                    <GenericButton
                      typeSubmit={true}
                      color="primary"
                      loading={props.usersState.register_fo_user_loading}
                    >
                      {intl.formatMessage(labels.Label_Generic_Submit_Button)}
                    </GenericButton>
                  </FormGroup>
                </Grid>
              </Grid>
            </Container>
          </Container>
        </form>
      </FormProvider>
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  register: (data) => dispatch(registerAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEnterpriseUser);
