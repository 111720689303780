import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
//MaterialUI
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import { Typography } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import Tooltip from "@material-ui/core/Tooltip";
import Pagination from "@material-ui/lab/Pagination";

import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import TableStateChip from "components/Cards/Tables/TableStateChip";
import { 
  TABLE_STATE_CHIP_COLOR, 
  PAGINATION,
  APPLICATION_STATES_CANCELED,
  APPLICATION_STATES_IN_PROGRESS,
  APPLICATION_STATES_APPROVED,
} from "utils/consts";
import { getUserApplicationsAction } from "redux/actions/applicationsAction";
import { titles, labels } from "resources/resources";
import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function GovernmentProgramsTab(props) {
  const theme = useTheme();
  const classes = useStylesGeneric();
  const intl = useIntl();
  const [page, setPage] = useState(1);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const tableHead = [
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_ApplicationsSection_GovernmentProgram),
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_ApplicationsSection_Program),
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_ApplicationsSection_Subprogram),
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_ApplicationsSection_ApplicationCode),
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_ApplicationsSection_ApplicationState),
  ];

  const mapRecentApplicationsDashboardChipColors = (stateKey) => {
    if (APPLICATION_STATES_APPROVED.includes(stateKey)) {
      return TABLE_STATE_CHIP_COLOR.GREEN;
    }
    if (APPLICATION_STATES_CANCELED.includes(stateKey)) {
      return TABLE_STATE_CHIP_COLOR.RED;
    }
    if (APPLICATION_STATES_IN_PROGRESS.includes(stateKey)) {
      return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE;
    }
  };

  useEffect(() => {
    const filter = {
      pageIndex: page <= 0 ? 1 : page, 
      pageSize: PAGINATION.PAGE_SIZE,
      userId: props?.usersState?.getFOUserOverview_result?.id
    }
    
    props.getUserApplications(filter);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          classes={{
            root: classes.cardHeader,
            action: classes.cardHeaderAction,
          }}
          title={
            <Typography style={{ fontWeight: "bold" }}>
              {intl.formatMessage(titles.Title_UserOverview_ApplicationsTab_GovernmentProgramsApplicationsSection)}
            </Typography>
          }
          action={
            <Typography
              variant="h4"
              className={classes.cardHeaderActionTypography}
            >
              {intl.formatMessage(labels.Label_Total)}: {props?.applicationsState?.getUserApplications_result?.totalCount}
            </Typography>
          }
        ></CardHeader>

        <TableContainer>
          <Box
            component={Table}
            alignItems="center"
            marginBottom="0!important"
          >
            <TableHead>
              <TableRow>
                  {tableHead.map((prop, key) => (
                      <TableCell
                          key={key}
                          classes={{
                              root:
                                  classes.tableCellRoot +
                                  " " +
                                  classes.tableCellRootHead,
                          }}
                          data-testid={"user-overview-government-programs-applications-section-table-header-" + key}
                      >
                          {prop}
                      </TableCell>
                  ))}
                  <TableCell
                    key="detailsCol"
                    classes={{
                        root:
                            classes.tableCellRoot + " " + classes.tableCellRootHead + " " + classes.tableCellHeadStickyRight,
                    }}
                    data-testid="user-overview-government-programs-applications-section-table-header-details"
                  >
                    {intl.formatMessage(labels.Label_Details)}
                  </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props?.applicationsState?.getUserApplications_result?.data?.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                      <Box fontWeight={"bold"}>
                          {prop?.governmentProgramName}
                      </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                      <Box>
                          {prop?.programName}
                      </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                      <Box>
                          {prop?.subProgramName}
                      </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                      <Box>
                          {prop?.applicationCode}
                      </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <TableStateChip
                      state={mapRecentApplicationsDashboardChipColors(prop?.state?.key)}
                      message={prop?.state?.description}
                    />
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight }}>
                    <Tooltip title="Detalhes">
                      <IconButton
                        aria-label="details"
                        component={Link}                          
                        to={`/admin/application-detail/${prop.applicationCode}`}
                        data-testid="user-overview-government-programs-applications-section-details-button"
                      >
                        <Box
                          component={ArrowForwardIosIcon}
                          width="12px!important"
                          height="12px!important"
                          color={theme.palette.primary.main}                             
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Box>
          <TableBackdrop
            open={props?.applicationsState?.getUserApplications_loading}
          />
        </TableContainer>

        <Box
          classes={{ root: classes.cardActionsRoot }}
          component={CardActions}
          justifyContent="flex-end"
        ></Box>
      </Card>        

      <Pagination
        className={classes.alignPagination}
        count={props?.applicationsState?.getUserApplications_result?.totalPages}
        page={page}
        onChange={handleChangePage}
        color="primary"
      ></Pagination>
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getUserApplications: (data) => dispatch(getUserApplicationsAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(GovernmentProgramsTab);
