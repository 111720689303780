const themeColors = {
  white: {
    main: "#FFFFFF",
    smoke: "#F5F5F5",
    light: "#F7FAFC"
  },
  black: {
    light: "#12263F",
    main: "#000000",
  },
  transparent: {
    main: "transparent",
  },
  gray: {
    100: "#E9E9E9",
    200: "#DCDCDC",
    300: "#C2C2C2",
    400: "#A9A9A9",
    500: "#8F8F8F",
    600: "#767676",
    700: "#605F5F",
    800: "#4B4A4A",
    900: "#3A3A3A",
  },  
  primary: {
    main: "#002E9E",
    darkBlue: "#022375",
    greyBlue: "#475E98",
    mediumBlue: "#A0B3E2",
    lightBlue: "#CCD5EC",
  },
  secondary: {
    main: "#E0EBF6",
    skyBlue: "#2185D0",
    mediumSkyBlue: "#C9E9FB",
    lightSkyBlue: "#E2F5FF",
  },
  warning: {
    tableLight: "#fed3ca",
    tableLightHover: "#febeb1",
    light: "#ffd600",
    main: "#DB2828",
    badgeBg: "#fee6e0",
    badgeBgHover: "#f93305",
    badge: "#ff3709",
  },
  error: {
    light: "#f3a4b5",
    main: "#f5365c",
    badgeBg: "#fdd1da",
    badgeBgHover: "#e30b36",
    badge: "#f80031",
    dialogNotification: "#f56036",
  },
  info: {
    main: "#FB6340",
    lightgreen:"#87ADBD",
    green: "#2dce89",
    grey: " #9CAFC3",
    badgeBg: "#aaedf9",
    badgeBgHover: "#0c9cb7",
    badge: "#03acca",
  },
  background: {
    default: "#E0EBF6",
  },
  dark: {
    tableBorder: "#1f3a68",
    tableHeadColor: "#4d7bca",
    tableHeadBgColor: "#1c345d",
    main: "#172b4d",
    dark: "#0b1526",
    snackbar: "#3c4d69",
    badgeBgHover: "#09111e",
  },
  success: {
    tableLightHover: "#afecd2",
    tableLight: "#c4f1de",
    main: "#2dce89",
    snackbar: "#4fd69c",
    badgeBg: "#b0eed3",
    badgeBgHover: "#229c68",
    badge: "#1aae6f",
  },
  facebook: {
    main: "#3b5999",
    dark: "#2d4474",
  },
  twitter: {
    main: "#1da1f2",
    dark: "#0c85d0",
  },
  google: {
    main: "#dd4b39",
    dark: "#c23321",
  },
  instagram: {
    main: "#e4405f",
    dark: "#d31e40",
  },
  pinterest: {
    main: "#bd081c",
    dark: "#8c0615",
  },
  youtube: {
    main: "#cd201f",
    dark: "#a11918",
  },
  vimeo: {
    main: "#04A0F0",
    dark: "#037fbe",
  },
  slack: {
    main: "#3aaf85",
    dark: "#2d8968",
  },
  dribbble: {
    main: "#ea4c89",
    dark: "#e51e6b",
  },
  github: {
    main: "#222222",
    dark: "#090909",
  },
  tableStateChip: {
    green: "#21BA45",
    red: "#DB2828",
    yellow: "#FBBD08",
    darkOrange: "#FB6340",
    lightOrange: "#FBB140",
    blue: "#C9E9FB"
  }
};

export default themeColors;
