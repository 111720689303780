import { React, useEffect, useState } from "react";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Controller } from "react-hook-form";
// @material-ui/core components
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";
import { COMPLEMENTARY_DATA_TYPE } from "utils/consts";

export default function DropdownField(props) {
  const [showField] = useState(props.show);
  const fieldVal = (name) => {
    const value = props.getValues(name);
    return value ? value : null;
  };

  useEffect(() => {
    const emptyVal = { code: "", label: "" };
    if (props.selectOptions?.length > 0) {
      let answerOption =
        props.answerValue !== undefined
          ? props.selectOptions.find((x) => x.code.toString() === props.answerValue)
          : emptyVal;

      props.setValue(props.name, answerOption !== null ? answerOption : emptyVal);
      if (props.showHideChildOnChange !== undefined)
        props.showHideChildOnChange(props.childs, props.name, COMPLEMENTARY_DATA_TYPE.DATASET);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectOptions?.length]);

  return (
    <>
      {showField && (
        <Controller
          name={props.name}
          control={props.control}
          rules={{
            required: {
              value: props.isRequired,
              message: "O campo é obrigatório",
            },
          }}
          render={({ field: { onChange, name } }) => (
            <Autocomplete
              name={name}
              disabled={props.isDisabled}
              options={props.selectOptions}
              value={fieldVal(name)}
              getOptionLabel={(option) => (option.label ? option.label : "")}
              getOptionSelected={(option, value) => option.code === value.code}
              classes={{
                inputRoot: clsx({
                  [props.classes.selectBorderWarningInputRoot]:
                    props.errors !== undefined && props.errors[props.name] !== undefined,
                }),
              }}
              ListboxProps={{
                style: {
                  fontWeight: "normal",
                  fontSize: "14px",
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={!props.initialLabel ? "Seleccione opção" : props.initialLabel}
                  variant="outlined"
                />
              )}
              onChange={(_, data) => {
                onChange(data);
                if (props.showHideChildOnChange !== undefined)
                  props.showHideChildOnChange(props.childs, props.name, COMPLEMENTARY_DATA_TYPE.DATASET);
              }}
            />
          )}
        />
      )}
      {showField && props.errors[props.name] !== undefined && (
        <FormHelperText component={Box} color={props.theme.palette.warning.main + "!important"}>
          {props.errors[props.name].message}
        </FormHelperText>
      )}
    </>
  );
}
