import { React } from "react";
import moment from "moment";
import { connect } from "react-redux";
import {
    showRegisterLossBreakModalAction
} from "redux/actions/stockAction"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import GenericButton from "components/Buttons/GenericButton";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// core componentssrc
import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const tableHead = ["Referência do Lote", "Qtd. Total", "Data Entrega", "Observações", ""];


function CardSearchResult(props) {
    const classes = useStylesGeneric();

    const openRegisterLossModal = (category, batchNumber, provinceId, warehouseId) => {
        props.showRegisterLossBreakModal(true, category, batchNumber, provinceId, warehouseId, props.reloadPageAction);
    }
    return (
        <Card classes={{ root: classes.cardRoot }}>
            <CardHeader
                classes={{
                    title: classes.cardHeader,
                    action: classes.cardHeaderAction
                }}
                title={"Stocks de Lotes"}
            ></CardHeader>
            {props.stockState.stockDetails !== undefined &&
                props.stockState.stockDetails?.stockLog?.length > 0 &&
                <TableContainer>
                    <Box component={Table} alignItems="center" marginBottom="0!important">
                        <TableHead>
                            <TableRow>
                                {tableHead.map((prop, key) => (
                                    <TableCell
                                        key={key}
                                        classes={{
                                            root:
                                                classes.tableCellRoot + " " + classes.tableCellRootHead,
                                        }}
                                    >
                                        {prop}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.stockState.stockDetails?.stockLog.map((prop, key) => (
                                <TableRow key={key}>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>
                                        {prop.batchNumber}
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>
                                        {prop.totalKits}
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>
                                        {moment(prop.receivedOn).format("DD/MM/YYYY")}
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>
                                        {prop.notes}
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>
                                        <Box alignItems="center" display="flex">
                                            <Box alignItems="right" marginRight="10px">
                                                <GenericButton
                                                    typeSubmit={false}
                                                    color={"tertiary"}
                                                    size="small"
                                                    onClick={() => { 
                                                        openRegisterLossModal(
                                                            props.stockState.stockDetails?.categoryId, 
                                                            prop.batchNumber, 
                                                            props.stockState.stockDetails?.provinceId, 
                                                            props.stockState.stockDetails?.warehouseId); 
                                                    }}
                                                >
                                                    Registar Perda
                                                </GenericButton>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Box>
                </TableContainer>
            }
        </Card>

    );
};

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
    showRegisterLossBreakModal: (toShow, category, batchNumber, provinceId, categoryId, reloadPageAction) => (dispatch(showRegisterLossBreakModalAction(toShow, category, batchNumber, provinceId, categoryId, reloadPageAction))),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardSearchResult);

