import {
  getFilteredApplications,
  getApplicationDetailsGeneralData,
  updateApplicationDetailGeneralData,
  getApplicationDetailsHistory,
  addApplicationDetailHistoryEntry,
  getApplicationDocument,
  getUserApplications,
} from "crud/applications.crud";
import moment from "moment";
import { APPLICATIONS, DIALOG } from "utils/actionsConsts";
import { convertByteArrayToBlobUrl } from "utils/converters";
import { b64toBlob } from "utils/converters";

export const getFilteredApplicationsActions = (formData) => async (dispatch) => {
  dispatch({
    type: APPLICATIONS.GET_FILTERED_APPLICATIONS_LOADING,
    payload: true,
  });
  getFilteredApplications(formData)
    .then((res) => {
      const mappedList = res.data?.data?.map((record) => {
        return {
          numApplication: record.applicationNumber,
          identityCardOrTaxIdNum: record.identityCardOrTaxIdNum,
          userCode: record.userCode,
          userTypeKey: record.userTypeKey,
          nameOfUser: record.nameOfUser,
          governmentProgram: record.governmentProgram,
          program: record.program,
          subprogram: record.subprogram,
          submissionDate: moment(record.sumissionDate).format("DD/MM/YYYY HH:mm"),
          state: record.state,
          stateKeyValue: record.stateKeyValue,
          userAvatar: record.userPhoto ? b64toBlob(record.userPhoto) : null,
          phoneNumber: record.phoneNumber,
          candidateMunicipality: record.candidateMunicipality,
          hasEntrepreneurshipTraining: record.hasEntrepreneurshipTraining,
        };
      });
      dispatch({
        type: APPLICATIONS.GET_FILTERED_APPLICATIONS,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: APPLICATIONS.GET_FILTERED_APPLICATIONS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: APPLICATIONS.GET_FILTERED_APPLICATIONS,
        result: false,
        payload: [],
      });
      dispatch({
        type: APPLICATIONS.GET_FILTERED_APPLICATIONS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getApplicationDetailsGeneralDataActions = (formData) => async (dispatch) => {
  dispatch({
    type: APPLICATIONS.GET_APPLICATION_DETAILS_GENERAL_DATA_LOADING,
    payload: true,
  });
  getApplicationDetailsGeneralData(formData)
    .then((res) => {
      dispatch({
        type: APPLICATIONS.GET_APPLICATION_DETAILS_GENERAL_DATA,
        result: true,
        payload: {
          applicationNumber: res.data.applicationNumber,
          applicationStateId: res.data.applicationStateId,
          applicationStateHistory: res.data.applicationStateHistory,
          applicationStateGroupKey: parseInt(res.data.state.groupKey),
          departmentName: res.data.departmentName,
          userSingularGeneralDataSection:
            res.data.userSingularGeneralDataSection != null
              ? {
                  userCode: res.data.userSingularGeneralDataSection.userCode,
                  nameOfUser: res.data.userSingularGeneralDataSection.nameOfUser,
                  userId: res.data.userSingularGeneralDataSection.userId,
                  numBI: res.data.userSingularGeneralDataSection.numBI,
                  gender: res.data.userSingularGeneralDataSection.gender,
                  maritalStatus: res.data.userSingularGeneralDataSection.maritalStatus,
                  otherApplicationsOfUser: res.data.userSingularGeneralDataSection.otherApplicationsOfUser,
                  age: res.data.userSingularGeneralDataSection.age,
                  mobilePhone: res.data.userSingularGeneralDataSection.mobilePhone,
                  birthDate: res.data.userSingularGeneralDataSection.birthDate
                    ? moment(res.data.userSingularGeneralDataSection.birthDate).format("DD/MM/YYYY")
                    : "",
                  specialNeeds: res.data.userSingularGeneralDataSection.specialNeeds,
                  specialNeedsPercentage: res.data.userSingularGeneralDataSection.specialNeedsPercentage,
                }
              : null,
          userColectiveGeneralDataSection:
            res.data.userColectiveGeneralDataSection !== null
              ? {
                  userId: res.data.userColectiveGeneralDataSection.userId,
                  userCode: res.data.userColectiveGeneralDataSection.userCode,
                  otherApplicationsOfUser: res.data.userColectiveGeneralDataSection.otherApplicationsOfUser,
                  responsibleName: res.data.userColectiveGeneralDataSection.responsibleName,
                  responsibleMobilePhone: res.data.userColectiveGeneralDataSection.responsibleMobilePhone,
                  responsibleEmail: res.data.userColectiveGeneralDataSection.responsibleEmail,
                  companyName: res.data.userColectiveGeneralDataSection.companyName,
                  companyTaxIdNumber: res.data.userColectiveGeneralDataSection.companyTaxIdNumber,
                  companyPhone: res.data.userColectiveGeneralDataSection.companyPhone,
                  companyEmail: res.data.userColectiveGeneralDataSection.companyEmail,
                }
              : null,
          provinceName: res.data.programSubprogramDataSection.provinceName,
          provinceId: res.data.programSubprogramDataSection.provinceId,

          governmentProgramId: res.data.programSubprogramDataSection.governmentProgramId,
          governmentProgramName: res.data.programSubprogramDataSection.governmentProgramName,
          programId: res.data.programSubprogramDataSection.programId,
          programName: res.data.programSubprogramDataSection.programName
            ? res.data.programSubprogramDataSection.programName
            : "",
          subprogramId: res.data.programSubprogramDataSection.subprogramId,
          subprogramName: res.data.programSubprogramDataSection.subprogramName,
          benefitId: res.data.programSubprogramDataSection.benefitId,
          benefitKeyValue: res.data.programSubprogramDataSection.benefitKeyValue,
          benefitName: res.data.programSubprogramDataSection.benefitName,
          bankPreferenceId: res.data.programSubprogramDataSection.bankPreferenceId,
          microcreditAmount: res.data.programSubprogramDataSection.microcreditAmount,
          concededMicrocreditAmount: res.data.programSubprogramDataSection.concededMicrocreditAmount,
          professionalKitsNumber: res.data.programSubprogramDataSection.professionalKitsNumber,
          concededProfessionalKitsNumber: res.data.programSubprogramDataSection.concededProfessionalKitsNumber,
          intendedCourseCode: res.data.programSubprogramDataSection.intendedCourseCode,
          trainingCenterId: res.data.programSubprogramDataSection.trainingCenterId,
          internshipAreaId: res.data.programSubprogramDataSection.internshipAreaId,
          internshipOpportunityId: res.data.programSubprogramDataSection.internshipOpportunityId,
          kitUnitPrice: res.data.programSubprogramDataSection.kitUnitPrice,
          parentApplicationId: res.data.programSubprogramDataSection.parentApplicationId,
          parentApplicationCode: res.data.programSubprogramDataSection.parentApplicationCode,
          generatedWorkPosts: res.data.programSubprogramDataSection.generatedWorkPosts,

          complementaryData: res.data.complementaryDataSection,
          complementaryDataId: res.data.complementaryDataId,
          documents: res.data.documentsSection,
        },
      });
      dispatch({
        type: APPLICATIONS.GET_APPLICATION_DETAILS_GENERAL_DATA_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: APPLICATIONS.GET_APPLICATION_DETAILS_GENERAL_DATA,
        result: false,
        payload: [],
      });
      dispatch({
        type: APPLICATIONS.GET_APPLICATION_DETAILS_GENERAL_DATA_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const updateApplicationDetailGeneralDataAction = (formData, _onCloseAction) => async (dispatch) => {
  dispatch({
    type: APPLICATIONS.GET_APPLICATION_UPDATE_LOADING,
    payload: true,
  });
  updateApplicationDetailGeneralData(formData)
    .then((res) => {
      dispatch({
        type: APPLICATIONS.UPDATE_APPLICATION_DETAILS_GENERAL_DATA,
        payload: res.data,
      });
      dispatch({
        type: APPLICATIONS.GET_APPLICATION_UPDATE_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            onCloseAction: res.data ? _onCloseAction : undefined,
            okAction: res.data ? _onCloseAction : undefined,
            isSuccess: res.data,
            message: res.data
              ? "Dados Gerais da candidatura editados com sucesso."
              : "Ocorreu um erro ao editar os detalhes da candidatura.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: APPLICATIONS.UPDATE_APPLICATION_DETAILS_GENERAL_DATA,
        payload: false,
      });
      dispatch({
        type: APPLICATIONS.GET_APPLICATION_UPDATE_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getApplicationDetailsHistoryActions = (formData) => async (dispatch) => {
  getApplicationDetailsHistory(formData)
    .then((res) => {
      dispatch({
        type: APPLICATIONS.APPLICATION_DETAILS_HISTORY_REFRESH_RESULTS,
        payload: false,
      });
      dispatch({
        type: APPLICATIONS.GET_APPLICATION_DETAILS_HISTORY,
        result: true,
        payload: res.data.map((record) => {
          return {
            id: record.id,
            actionId: record.actionId,
            actionName: record.actionName,
            message: record.message,
            createDatetime: record.createDatetime,
            createUser: record.createUser,
          };
        }),
      });
    })
    .catch((error) => {
      dispatch({
        type: APPLICATIONS.APPLICATION_DETAILS_HISTORY_REFRESH_RESULTS,
        payload: false,
      });
      dispatch({
        type: APPLICATIONS.GET_APPLICATION_DETAILS_HISTORY,
        result: false,
        payload: [],
      });

      console.log("error", error);
    });
};

export const addApplicationDetailHistoryEntryAction = (formData) => async (dispatch) => {
  addApplicationDetailHistoryEntry(formData)
    .then((res) => {
      dispatch({
        type: APPLICATIONS.CREATE_APPLICATION_DETAILS_HISTORY_ENTRY,
        payload: res.data,
      });

      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            goBack: false,
            isSuccess: res.data,
            message: res.data ? "Comentário adicionado com sucesso" : "Ocorreu a adicionar comentário",
          },
        },
      });
      dispatch({
        type: APPLICATIONS.APPLICATION_DETAILS_HISTORY_REFRESH_RESULTS,
        payload: true,
      });
    })
    .catch((error) => {
      dispatch({
        type: APPLICATIONS.CREATE_APPLICATION_DETAILS_HISTORY_ENTRY,
        payload: false,
      });

      console.log("error", error);
    });
};

export const getApplicationDocumentAction = (docId, docName) => async (dispatch) => {
  getApplicationDocument(docId)
    .then((res) => {
      const link = document.createElement("a");
      link.href = convertByteArrayToBlobUrl(res.data);
      link.setAttribute("download", docName);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      dispatch({
        type: APPLICATIONS.GET_APPLICATION_DOCUMENT_DATA_DOWNLOAD,
        result: false,
        payload: null,
      });

      console.log("error", error);
    });
};

export const getUserApplicationsAction = (data) => async (dispatch) => {
  dispatch({
    type: APPLICATIONS.GET_USER_APPLICATIONS_LOADING,
    payload: true,
  });
  getUserApplications(data)
    .then((res) => {
      dispatch({
        type: APPLICATIONS.GET_USER_APPLICATIONS,
        result: true,
        payload: res.data,
      });
      dispatch({
        type: APPLICATIONS.GET_USER_APPLICATIONS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: APPLICATIONS.GET_USER_APPLICATIONS_LOADING,
        payload: false,
      });
      dispatch({
        type: APPLICATIONS.GET_USER_APPLICATIONS,
        result: false,
        payload: [],
      });

      console.log("error", error);
    });
};

export default getFilteredApplicationsActions;
