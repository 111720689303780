import React from "react";
import { Container, makeStyles } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

export default function Tabs({ tabs, dataTestId, onChange, step }) {
  const classes = useStylesGeneric();

  return (
    <Container maxWidth={false}>
      <ToggleButtonGroup
        value={step}
        exclusive
        onChange={onChange}
        aria-label="tab Step"
        className={classes.changeTabsGroup}
        data-testid={dataTestId}
      >
        {tabs.map(
          ({ renderIf = true, value, testId, text }, key) =>
          renderIf && (
              <ToggleButton
                key={key}
                value={value}
                className={classes.changeTabs}
                data-testid={testId}
              >
                {text}
              </ToggleButton>
            )
        )}
      </ToggleButtonGroup>
    </Container>
  );
}
