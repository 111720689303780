import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Card, CardContent } from "@material-ui/core";
import { CardHeader } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
// @material-ui/icons components

//import custom components
import DropzoneField from "components/FormFields/DropzoneField";
import DisplayField from "components/Fields/DisplayField";
import GenericButton from "components/Buttons/GenericButton";
import { getSchedulingAction, getReportDocumentAction } from "redux/actions/schedulingAction";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { labels, messages, titles } from "resources/resources";
import { getHours, getDate } from "utils/date_utils";
import { SCHEDULE_STATES } from "utils/consts";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function SchedulesSubmit(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const intl = useIntl();
  const { control } = useFormContext();
  const [isEditable, setIsEditable] = useState(false);
  const [data, setData] = useState({});
  const [showApplicationFields, setShowApplicationFields] = useState(true);
  const [fileState, setFile] = useState([]);

  useEffect(() => {
    if (props.scheduleId) {
      props.getScheduling(props.scheduleId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.scheduleId]);

  useEffect(() => {
    if (props.schedulingState.schedule_detail !== undefined && props.schedulingState.schedule_detail !== {}) {
      setData(props.schedulingState.schedule_detail);

      setIsEditable(props.schedulingState.schedule_detail.scheduleState !== SCHEDULE_STATES.SIM);
      if (
        props.schedulingState.schedule_detail.reportDocument !== undefined &&
        props.schedulingState.schedule_detail.reportDocument !== null
      ) {
        setFile(props.schedulingState.schedule_detail.reportDocument);
      } else setFile([]);

      if (props.schedulingState.schedule_detail.report)
        props.setValue("scheduleReport", props.schedulingState.schedule_detail.report);
      else props.setValue("scheduleReport", "");

      if (props.schedulingState.schedule_detail.description)
        props.setValue("scheduleDescription", props.schedulingState.schedule_detail.description);
      else props.setValue("scheduleDescription", "");

      if (!props.schedulingState.schedule_detail.applicationNumber) setShowApplicationFields(false);
      else setShowApplicationFields(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.schedulingState.schedule_detail]);

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          classes={{ title: classes.cardHeader }}
          title={intl.formatMessage(titles.Title_Admin_SchedulesManagement_ScheduleDetails_RegistrationData)}
        ></CardHeader>
        <CardContent>
          <Grid container>
            <DisplayField xs={12} sm={6} md={4} label="Nome" data={data.nameOfUser} />
            <DisplayField xs={12} sm={3} md={2} label="Nº BI" data={data.numBI} />
            {/*New line sm */}
            {showApplicationFields ? (
              <>
                <DisplayField xs={12} sm={3} md={2} label="Programa de Governo" data={data.governmentProgram} />
                <DisplayField xs={12} sm={6} md={2} label="Programa" data={data.program} />
                <DisplayField xs={12} sm={6} md={2} label="Subprograma" data={data.subProgram} />
              </>
            ) : (
              <>
                <DisplayField xs={12} sm={6} md={6} label="Área de mentoria" data={data.mentorMentoringAreas} />
              </>
            )}
            {/*New line md */}
            <DisplayField xs={12} sm={12} md={4} label="Motivo" data={data.motive} />
            {showApplicationFields ? (
              <DisplayField xs={12} sm={3} md={2} label="Numero da Candidatura" data={data.applicationNumber} />
            ) : (
              <></>
            )}

            <DisplayField xs={12} sm={3} md={2} label="Data de Agendamento" data={getDate(data.schedulingDatetime)} />
            <DisplayField xs={12} sm={3} md={2} label="Hora" data={getHours(data.schedulingDatetime)} />
            {/* Relatório */}
            <Grid item xs={6} sm={6} md={6}>
              <FormGroup>
                <FormLabel>Descrição</FormLabel>
                <Controller
                  name="scheduleDescription"
                  control={control}
                  defaultValue=""
                  component={Box}
                  rules={{
                    validate: {
                      requiredIf: (value) => {
                        if (!props.getValues("fileUpload") && !value)
                          return intl.formatMessage(messages.Message_Generic_MandatoryField);
                        return true;
                      },
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      multiline
                      rows="4"
                      autoComplete="off"
                      type="text"
                      disabled={true}
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["scheduleDescription"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {props.errors["scheduleDescription"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["scheduleDescription"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <FormGroup>
                <FormLabel>{intl.formatMessage(labels.Label_Admin_SchedulingTab_ObservationsField)}</FormLabel>
                <Controller
                  name="scheduleReport"
                  control={control}
                  defaultValue=""
                  component={Box}
                  rules={{
                    validate: {
                      requiredIf: (value) => {
                        if (!props.getValues("fileUpload") && !value)
                          return intl.formatMessage(messages.Message_Generic_MandatoryField);
                        return true;
                      },
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      multiline
                      rows="4"
                      autoComplete="off"
                      type="text"
                      disabled={!isEditable}
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["scheduleReport"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {props.errors["scheduleReport"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["scheduleReport"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>

            {fileState.length !== 0 && (
              <Grid item xs={12} md={12}>
                <FormGroup>
                  <FormLabel>Relatório</FormLabel>
                  <Box>
                    <GenericButton
                      typeSubmit={false}
                      color="tertiary"
                      onClick={() => {
                        props.getReportDocument(props.schedulingState.schedule_detail.id, fileState);
                      }}
                    >
                      {intl.formatMessage(labels.Label_Download)}
                    </GenericButton>
                  </Box>
                </FormGroup>
              </Grid>
            )}

            {!isEditable && fileState.length === 0 && (
              <Grid item xs={12} md={12}>
                <FormGroup>
                  <FormLabel>Relatório</FormLabel>
                  Não existem documentos carregados nesta candidatura.
                </FormGroup>
              </Grid>
            )}
            {isEditable && (
              <Grid item xs={12} sm={12} md={12}>
                <FormLabel>{intl.formatMessage(labels.Label_Admin_ScheduleDetails_ReportField)}</FormLabel>
                <DropzoneField
                  name={"fileUpload"}
                  multiple={false}
                  label={intl.formatMessage(labels.Label_Generic_Upload_Document)}
                  register={props.register}
                  errors={props.errors}
                  required={props.getValues("scheduleReport") === "" ? true : false}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  handleRemovedImage={true}
                  setValue={props.setValue}
                  getValues={props.getValues}
                  trigger={props.trigger}
                  maxNumberOfFiles={1}
                  accept={".pdf"}
                  maxFileSize={2}
                  showFileNameOnPreview={true}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      {isEditable ? (
        <Grid container>
          <Grid item xs={12} lg={9} xl={10}></Grid>
          <Grid item xs={12} lg={3} xl={2}>
            <FormGroup>
              <GenericButton
                typeSubmit={true}
                color="primary"
                disabled={props.schedulingState.schedule_update_loading}
                loading={props.schedulingState.schedule_update_loadin}
              >
                {intl.formatMessage(labels.Label_Generic_Conclude)}
              </GenericButton>
            </FormGroup>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getScheduling: (schedulingNum) => dispatch(getSchedulingAction(schedulingNum)),
  getReportDocument: (scheduleId, docName) => dispatch(getReportDocumentAction(scheduleId, docName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SchedulesSubmit);
