import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getDashboardOnGoingApplicationsInfoAction } from "redux/actions/dashboardAction";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LinkMaterialUi from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

// @material-ui/icons components
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// core components
import componentStyles from "assets/theme/components/cards/dashboard/card-dashboard-generic.js";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { getNameInitials } from "utils/converters";
import { titles, labels } from "resources/resources";

const useStyles = makeStyles(componentStyles);
const useStylesGeneric = makeStyles(componentStylesGeneric);

function CardOnGoingApplications(props) {
  const theme = useTheme();
  const intl = useIntl();
  const classes = { ...useStylesGeneric(), ...useStyles() };

  const tableHead = [
    intl.formatMessage(labels.Label_GovernmentProgramField_short),
    intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_NumApplicationField),
    intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_NumBIField),
    intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_UserNameField),
    intl.formatMessage(labels.Label_UserCodeField_short),
    "",
  ];

  useEffect(() => {
    props.getDashboardOnGoingApplicationsInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <div style={{position:"relative"}}>
        <img
            src={"/images/empregoAngolaBackground1.png"} //Designed by 0melapics / Freepik: http://www.freepik.com
            className={classes.backgroundImage}
            alt={""}
        />
      </div>
      <CardHeader
        classes={{
          root: classes.cardHeader + " " + classes.cardHeaderDashboard,
          action: classes.cardHeaderAction,
        }}
        subheader={
          <Grid container component={Box} alignItems="center" justifyContent="space-between">
            <Grid item xs={12}>
              <Box component={Typography} classes={{ root: classes.cardTitle }} variant="h3" marginBottom="0!important">
                <Box component="span">{intl.formatMessage(titles.Title_Admin_Dashboard_CardOnGoingApplications)}</Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                component={Typography}
                variant="h1"
                classes={{ root: classes.cardHeaderCounter }}
                marginBottom="0!important"
              >
                <Box component="span">
                  {props.dashboardState?.dashboardOnGoingApplicationsCount
                    ? props.dashboardState?.dashboardOnGoingApplicationsCount
                    : 0}
                </Box>
              </Box>
            </Grid>
          </Grid>
        }
        action={
          <LinkMaterialUi
            component={Link}
            variant="h5"
            to="/admin/applications-management"
            className={classes.link}
          >
            {intl.formatMessage(labels.Label_SeeMore)}
          </LinkMaterialUi>
        }
      ></CardHeader>
      {props.dashboardState?.dashboardOnGoingApplicationsInfo !== undefined &&
        props.dashboardState?.dashboardOnGoingApplicationsInfo?.length > 0 && (
          <TableContainer>
            <Box component={Table} alignItems="center" marginBottom="0!important">
              <TableHead>
                <TableRow>
                  {tableHead.map((prop, key) => (
                    <TableCell
                      key={key}
                      classes={{
                        root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                      }}
                    >
                      {prop}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.dashboardState?.dashboardOnGoingApplicationsInfo.map((prop, key) => (
                  <TableRow key={key}>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.governmentProgramName}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.applicationNumber}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.identityCard}</TableCell>
                    <TableCell
                      classes={{ root: classes.tableCellRoot + " " + classes.tableRowEllipsis }}
                      style={{ maxWidth: "140px" }}
                    >
                      {prop.name}
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.userCode}</TableCell>
                    <TableCell
                      classes={{
                        root: classes.tableCellRoot + " " + classes.actionsCell,
                      }}
                    >
                      <Box paddingTop=".16rem" paddingBottom=".35rem" alignItems="center" display="flex">
                        {prop.userAvatar ? (
                          <Box
                            component={Avatar}
                            marginRight="1rem"
                            alt="..."
                            src={prop.userAvatar}
                            classes={{ root: classes.avatarRoot }}
                          />
                        ) : (
                          <Box component={Avatar} marginRight="1rem" alt="..." classes={{ root: classes.avatarRoot }}>
                            {" "}
                            {getNameInitials(prop.name)}
                          </Box>
                        )}
                        <Tooltip title={intl.formatMessage(labels.Label_Details)}>
                          <IconButton
                            aria-label="details"
                            component={Link}
                            to={`/admin/application-detail/${prop.applicationNumber}`}
                          >
                            <Box
                              component={ArrowForwardIosIcon}
                              width="9px!important"
                              height="9px!important"
                              color={theme.palette.primary.main}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Box>
          </TableContainer>
        )}
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDashboardOnGoingApplicationsInfo: () => dispatch(getDashboardOnGoingApplicationsInfoAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardOnGoingApplications);
