import { React, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
// @material-ui/core components
import { Container, Grid, FormGroup } from "@material-ui/core";
//import custom components
import GenericButton from "components/Buttons/GenericButton";
import FollowUpDetails from "../Sections/FollowUpDetails";
import { updateOpportunityFollowupPeriodAction } from "redux/actions/opportunitiesAction";
import { updateOpportunityFollowupPeriodMap } from "mappers/opportunityMap";
import { labels } from "resources/resources";

function FollowUpDetailsTab(props) {
  const history = useHistory();
  const intl = useIntl();
  const [isEditable, setIsEditable] = useState(false);

  const methods = useForm({
    mode: "onChange",
  });

  const cancelOnClick = () => {
    history.goBack();
  };

  const handleEditSection = () => {
    setIsEditable(true);
  };

  function onSubmit(data) {
    data["candidateCode"] = props?.opportunityApplicationFollowUpDetails?.candidateCode;

    let updateData = updateOpportunityFollowupPeriodMap(data);

    props.updateOpportunityFollowupPeriod(updateData, () => {
      props.handleRefreshPage();
    });
  }

  return (
    <>
      <Container maxWidth={false}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FollowUpDetails
              opportunityApplicationFollowUpDetails={props?.opportunityApplicationFollowUpDetails}
              isEditable={isEditable}
              handleEditSection={handleEditSection}
            />
            {isEditable && (
              <Grid container>
                <Grid item xs={12} lg={6} xl={8}></Grid>
                <Grid item xs={12} lg={3} xl={2}>
                  <FormGroup>
                    <GenericButton typeSubmit={false} color="secondary" onClick={() => cancelOnClick()}>
                      {intl.formatMessage(labels.Label_Generic_Cancel)}
                    </GenericButton>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} lg={3} xl={2}>
                  <FormGroup>
                    <GenericButton
                      typeSubmit={true}
                      color="primary"
                      loading={props.opportunitiesState?.update_opportunity_followup_period_loading}
                    >
                      {intl.formatMessage(labels.Label_Generic_Save)}
                    </GenericButton>
                  </FormGroup>
                </Grid>
              </Grid>
            )}
          </form>
        </FormProvider>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  updateOpportunityFollowupPeriod: (data, onCloseMethod) =>
    dispatch(updateOpportunityFollowupPeriodAction(data, onCloseMethod)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpDetailsTab);
