import { React, useEffect } from "react";
import { connect } from "react-redux";
import { getStockDetailsAction } from "redux/actions/stockAction";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
//import custom components
import DisplayField from "components/Fields/DisplayField";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import StockLogTable from "./StockLogTable";
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import useScrollDirection from "hooks/useScrollDirection";

import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function StockDetails(props) {
  const scrollDirection = useScrollDirection();
  const classes = useStylesGeneric();
  useEffect(() => {
    if (
      props.stockState.stockDetails === undefined ||
      props.stockState.stockDetails.Id !== props.match.params.warehouseStockId
    ) {
      props.getStockDetails(props.match.params.warehouseStockId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.warehouseStockId]);

  return (
    <>
      <GenericBackdrop open={props.stockState.get_stocks_detail_loading} />

      {scrollDirection === "up" && (
        <div className={classes.breadcrumbs}>
          <LocationHeader
            section={"Detalhes do Stock"}
            subsection={"Gestão de Stock"}
            subsectionLink={"/admin/stocks-management"}
          />
        </div>
      )}
      <Container maxWidth={false} component={Box} classes={{ root: classes.containerRootAfterBreadcrumbs }}>
        <PageTitleHeader title={"Detalhes do Stock"} />

        <Container maxWidth={false} component={Box}>
          <Card classes={{ root: classes.cardRoot }}>
            <CardHeader classes={{ title: classes.cardHeader }} title="Dados Gerais"></CardHeader>
            <CardContent>
              <Grid container>
                <DisplayField
                  xs={12}
                  sm={4}
                  md={3}
                  label="Especialidade"
                  data={props.stockState.stockDetails?.categoryName}
                />
                <DisplayField
                  xs={12}
                  sm={4}
                  md={3}
                  label="Província"
                  data={props.stockState.stockDetails?.provinceName}
                />
                <DisplayField
                  xs={12}
                  sm={4}
                  md={3}
                  label="Armazém"
                  data={props.stockState.stockDetails?.warehouseName}
                />
                <DisplayField
                  xs={12}
                  sm={4}
                  md={3}
                  label="Número de Lotes"
                  data={props.stockState.stockDetails?.noOfBatches}
                />
                <DisplayField
                  xs={12}
                  sm={4}
                  md={3}
                  label="Quantidade Total"
                  data={props.stockState.stockDetails?.kitsTotal}
                />
                <DisplayField
                  xs={12}
                  sm={4}
                  md={3}
                  label="Quantidade Atribuída"
                  data={props.stockState.stockDetails?.kitsAttributed}
                />
                <DisplayField xs={12} sm={4} md={3} label="Stock" data={props.stockState.stockDetails?.availableKits} />
                <DisplayField xs={12} sm={4} md={3} label="Perdas" data={props.stockState.stockDetails?.lostKits} />
              </Grid>
            </CardContent>
          </Card>
          <StockLogTable
            reloadPageAction={() => {
              props.getStockDetails(props.match.params.warehouseStockId);
            }}
          />
        </Container>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getStockDetails: (kitId) => dispatch(getStockDetailsAction(kitId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StockDetails);
