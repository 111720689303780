import { React, useEffect, useState } from "react";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Card, CardHeader, CardContent } from "@material-ui/core";
// @material-ui/icons components
import SearchIcon from "@material-ui/icons/Search";
// components
import GenericFormSelect from "components/FormFields/GenericFormSelect";
import GenericButton from "components/Buttons/GenericButton";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { getFilteredInformationAction } from "redux/actions/informationDisseminationAction";
import { DATASET, PAGINATION } from "utils/consts";
import { informationDisseminationSearchMap } from "mappers/informationDisseminationMap";
import { labels, titles } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function FilterSection(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const history = useHistory();
  const [filter, setFilter] = useState({
    searchText: "",
    informationDisseminationType: null,
    informationDisseminationPublished: null,
    informationDisseminationAccess: null,
    informationDisseminationHighlighted: null,
  });
  const [filterCounter, setFilterCounter] = useState(0);

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    let count = 0;

    Object.keys(filter).map((key) => {
      if (filter[key] && key !== "pageIndex" && key !== "pageSize") {
        count += 1;
      }

      return count;
    });

    setFilterCounter(count);
  }, [filter]);

  const cleanFilter = () => {
    setValue("searchText", "");
    setValue("informationDisseminationType", undefined);
    setValue("informationDisseminationPublished", undefined);
    setValue("informationDisseminationAccess", undefined);
    setValue("informationDisseminationHighlighted", undefined);

    setFilterCounter(0);
    setFilter(0);
  };

  const onSubmit = (data) => {
    if (isValid) {
      let filter = informationDisseminationSearchMap(data);
      filter["pageIndex"] = 1;
      filter["pageSize"] = PAGINATION.PAGE_SIZE_DISSEMINATION;
      setFilter(filter);
      if (props.page === 1) {
        props.getFilterInformation(filter);
      } else {
        props.handleChangePage(1);
      }
    }
  };

  const handleSetFilter = (filterKey, value, clearChildKeyList) => {
    const newFilter = { ...filter, [filterKey]: value };

    clearChildKeyList?.forEach((child) => {
      newFilter[child] = null;
    });

    setFilter(newFilter);
  };

  const redirectToInformationDisseminationCreation = (event) => {
    event.preventDefault();
    history.push("/admin/informationdissemination-create");
  };

  useEffect(() => {
    if (
      props.dataSetState[DATASET.INFORMATION_DISSEMINATION_TYPE] === undefined ||
      props.dataSetState[DATASET.INFORMATION_DISSEMINATION_TYPE]?.length === 0
    ) {
      props.getDataSet(DATASET.INFORMATION_DISSEMINATION_TYPE);
    }
    if (
      props.dataSetState[DATASET.INFORMATION_DISSEMINATION_ACCESS] === undefined ||
      props.dataSetState[DATASET.INFORMATION_DISSEMINATION_ACCESS]?.length === 0
    ) {
      props.getDataSet(DATASET.INFORMATION_DISSEMINATION_ACCESS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Refresh Results
  useEffect(() => {
    let temp_filter = filter;
    Object.assign(temp_filter, filter);
    temp_filter["pageIndex"] = props.page;
    temp_filter["pageSize"] = PAGINATION.PAGE_SIZE_DISSEMINATION;
    props.getFilterInformation(temp_filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page]);

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={
          <div>
            <div className={classes.sideBySideDiv}>Pesquisa de Informação</div>
            <div className={classes.sideBySideDiv + " " + classes.cardElementsCount}>
              Filtros {"("}
              {filterCounter}
              {")"}
            </div>
          </div>
        }
        action={
          <GenericButton typeSubmit={false} color="tertiary" onClick={redirectToInformationDisseminationCreation}>
            {intl.formatMessage(titles.Title_InformationDissemination_CreateInformation)}
          </GenericButton>
        }
      ></CardHeader>
      <CardContent>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} sm={12} md={4}>
                <FormGroup>
                  <FormLabel>Pesquisa (título/descrição)</FormLabel>
                  <Controller
                    name="searchText"
                    control={control}
                    defaultValue=""
                    rules={{ required: { value: false } }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("searchText", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["searchText"] !== undefined,
                          }),
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>Categoria</FormLabel>
                  <GenericFormSelect
                    control={control}
                    fieldName="informationDisseminationType"
                    selectOptions={
                      props.dataSetState[DATASET.INFORMATION_DISSEMINATION_TYPE] !== undefined
                        ? props.dataSetState[DATASET.INFORMATION_DISSEMINATION_TYPE]
                        : []
                    }
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"informationDisseminationType"}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>Acesso</FormLabel>
                  <GenericFormSelect
                    control={control}
                    fieldName="informationDisseminationAccess"
                    selectOptions={
                      props.dataSetState[DATASET.INFORMATION_DISSEMINATION_ACCESS]
                        ? props.dataSetState[DATASET.INFORMATION_DISSEMINATION_ACCESS]
                        : []
                    }
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"informaitonDisseminationAccess"}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>Publicado</FormLabel>
                  <GenericFormSelect
                    control={control}
                    fieldName="informationDisseminationPublished"
                    selectOptions={DATASET.YES_OR_NO_DROPDOWN}
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"informaitonDisseminationPublished"}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>Em Destaque</FormLabel>
                  <GenericFormSelect
                    control={control}
                    fieldName="informationDisseminationHighlighted"
                    selectOptions={DATASET.YES_OR_NO_DROPDOWN}
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"informationDisseminationHighlighted"}
                  />
                </FormGroup>
              </Grid>
              {/* Butões de Pesquisa */}
              <Grid item xs={12} md={12} classes={{ root: classes.alignRight }}>
                <GenericButton
                  color="ghost"
                  onClick={() => {
                    cleanFilter();
                  }}
                >
                  {intl.formatMessage(labels.Label_ClearFilters)}
                </GenericButton>
                <GenericButton
                  typeSubmit={true}
                  color="primary"
                  loading={props.informationDisseminationState.searchInformation_loading}
                >
                  {intl.formatMessage(labels.Label_FilterSection_SearchButton)}
                </GenericButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getFilterInformation: (data) => dispatch(getFilteredInformationAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterSection);
