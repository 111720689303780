
export function registerStockInWarehouseMap(formData) {
    if (formData !== null && formData !== undefined)
        return {
            //Province: formData.province && formData.province.code ? formData.province.code : "",
            WarehouseId: formData.warehouseId && formData.warehouseId.code ? formData.warehouseId.code : "",
            CategoryId: formData.categoryId && formData.categoryId.code ? formData.categoryId.code : "",
            EntryDate: formData.entryDate ? formData.entryDate.format('YYYY-MM-DD') : "",
            Quantity: formData.quantity ? formData.quantity : 0,
            BatchNumber: formData.batchNumber ? formData.batchNumber : "",
            Notes: formData.notes ? formData.notes : "",


        };
    return formData;
}

export default registerStockInWarehouseMap