import { React, useEffect, useState } from "react";
import clsx from "clsx";
import ReactDatetime from "react-datetime";
import { connect } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";
import "moment/locale/pt";
import moment from "moment";
import { useIntl } from "react-intl";
import { messages } from "resources/resources";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// @material-ui/icons components
import DateRange from "@material-ui/icons/DateRange";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import FormSelect from "components/FormFields/FormSelect";
import {
  getDataSetByNameAction,
  getWarehousesByProvinceAction,
  getActiveCategoriesAction,
} from "redux/actions/datasetsAction";
import { getUnitValueByCategoryAction } from "redux/actions/stockAction";
import { DATASET, UNITS } from "utils/consts";
import { getSelectOptionsElementByCode } from "utils/formSelect";
import { REGEX_PATTERNS } from "utils/consts"

const useStylesGeneric = makeStyles(componentStylesGeneric);

function GeneralData(props) {
  const intl = useIntl();
  const classes = useStylesGeneric();
  const theme = useTheme();
  const { control } = useFormContext();
  const [totalValue, setTotalValue] = useState("0");

  useEffect(() => {
    props.getActiveCategories(DATASET.ACTIVE_CATEGORIES);
    if (props.dataSetState[DATASET.PROVINCES] === undefined || props.dataSetState[DATASET.PROVINCES] === []) {
      props.getDataSet(DATASET.PROVINCES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var provinceList = props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : [];
    if (props.authState?.province) {
      const selectedProvince = getSelectOptionsElementByCode(provinceList, props.authState.province);
      props.getWarehousesByProvince(DATASET.WAREHOUSES, selectedProvince.keyValue);
      props.setValue("province", selectedProvince);
    } else {
      if (props.stockState.RegisterInWareHouseModal?.province) {
        const selectedProvince = getSelectOptionsElementByCode(
          provinceList,
          props.stockState.RegisterInWareHouseModal.province
        );
        props.getWarehousesByProvince(DATASET.WAREHOUSES, selectedProvince.keyValue);
        props.setValue("province", selectedProvince);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.PROVINCES]]);

  const handleValidDate = (date, onChange) => {
    if (date === "" || REGEX_PATTERNS.DATE.test(date)) {
      onChange(date);
    }
  };

  useEffect(() => {
    if (props.stockState.RegisterInWareHouseModal?.warehouse) {
      var warehouseList = props.dataSetState[DATASET.WAREHOUSES] ? props.dataSetState[DATASET.WAREHOUSES] : [];
      const warehouse =
        props.stockState.RegisterInWareHouseModal !== undefined
          ? props.stockState.RegisterInWareHouseModal.warehouse
          : null;
      props.setValue("warehouseId", getSelectOptionsElementByCode(warehouseList, warehouse));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.WAREHOUSES]]);

  useEffect(() => {
    if (props.stockState.RegisterInWareHouseModal?.category) {
      var categoryList = props.dataSetState[DATASET.ACTIVE_CATEGORIES]
        ? props.dataSetState[DATASET.ACTIVE_CATEGORIES]
        : [];
      const category =
        props.stockState.RegisterInWareHouseModal !== undefined
          ? props.stockState.RegisterInWareHouseModal.category
          : null;
      props.setValue("categoryId", getSelectOptionsElementByCode(categoryList, category));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.ACTIVE_CATEGORIES]]);

  const getBatchValueField = () => {
    var category = props.getValues("categoryId")?.code;
    var quantity = props.getValues("quantity");
    setTotalValue(
      intl.formatNumber(
        props.stockState &&
          props.stockState.categoriesUnitValue.find((x) => x.id === category)?.unitValue > 0 &&
          quantity > 0
          ? props.stockState.categoriesUnitValue.find((x) => x.id === category)?.unitValue * quantity
          : "0"
      )
    );
  };

  const quantityOnChange = () => {
    var category = props.getValues("categoryId");
    if (category != null && category.code > 0) {
      props.getUnitValueByCategory(category.code);
    }
    getBatchValueField();
  };

  const getCategoryIsDisabled = () => {
    return props.stockState.RegisterInWareHouseModal !== undefined
      ? props.stockState.RegisterInWareHouseModal.category !== undefined
      : false;
  };

  const getProvinceIsDisabled = () => {
    return props.stockState.RegisterInWareHouseModal?.province != null || props.authState?.province != null;
  };

  const getWarehouseIsDisabled = () => {
    return props.stockState.RegisterInWareHouseModal !== undefined
      ? props.stockState.RegisterInWareHouseModal.warehouse !== undefined
      : false;
  };

  return (
    <>
      <Container>
        <Box className={classes.modalHeader}>Registo em Armazém</Box>
        <Box marginLeft="15px">
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormLabel>Província</FormLabel>
                <FormSelect
                  control={control}
                  fieldName="province"
                  selectOptions={props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : []}
                  disabled={getProvinceIsDisabled()}
                  loadChildrenDataSet={props.getWarehousesByProvince}
                  childrenDataSet={DATASET.WAREHOUSES}
                  childrenSelect="warehouseId"
                  setValue={props.setValue}
                  getValues={props.getValues}
                  required={props.isEditable}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  errors={props.errors}
                  classes={classes}
                />
                {props.errors["province"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["province"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormLabel>Armazém</FormLabel>
                <FormSelect
                  control={control}
                  fieldName="warehouseId"
                  disabled={getWarehouseIsDisabled()}
                  selectOptions={props.dataSetState[DATASET.WAREHOUSES] ? props.dataSetState[DATASET.WAREHOUSES] : []}
                  getValues={props.getValues}
                  required={props.isEditable}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  errors={props.errors}
                  classes={classes}
                />
                {props.errors["warehouseId"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["warehouseId"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormLabel>Especialidade</FormLabel>
                <FormSelect
                  control={control}
                  fieldName="categoryId"
                  disabled={getCategoryIsDisabled()}
                  onOptionChange={quantityOnChange}
                  selectOptions={
                    props.dataSetState[DATASET.ACTIVE_CATEGORIES] ? props.dataSetState[DATASET.ACTIVE_CATEGORIES] : []
                  }
                  getValues={props.getValues}
                  required={props.isEditable && !getCategoryIsDisabled()}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  errors={props.errors}
                  classes={classes}
                />
                {props.errors["categoryId"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["categoryId"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormLabel>Referência do Lote</FormLabel>
                <Controller
                  name="batchNumber"
                  control={control}
                  component={Box}
                  defaultValue=""
                  rules={{
                    required: {
                      value: props.isEditable,
                      message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["batchNumber"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {props.errors["batchNumber"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["batchNumber"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormLabel>Data de Entrada</FormLabel>
                <Controller
                  name="entryDate"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                    },
                    validate: {
                      maxDate: (value) => {
                        return (
                          value <= moment().startOf("day") ||
                          intl.formatMessage(messages.Message_Generic_DateLessThanTodayField)
                        );
                      },
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <ReactDatetime
                      name={name}
                      value={value}
                      onChange={onChange}
                      locale="pt"
                      dateFormat={"DD/MM/YYYY"}
                      timeFormat={false}
                      isValidDate={(currentDate, _) => {
                        return currentDate <= moment().startOf("day");
                      }}
                      closeOnSelect={true}
                      closeOnTab={true}
                      closeOnClickOutside={true}
                      inputProps={{
                        placeholder: "DD/MM/AAAA",
                        className: "",
                      }}
                      renderInput={(dateInputProps, open) => (
                        <OutlinedInput
                          fullWidth
                          onFocus={open}
                          {...dateInputProps}
                          onChange={(e) => {
                            handleValidDate(e.target.value, dateInputProps.onChange);
                          }}
                          startAdornment={
                            <InputAdornment position="start">
                              <Box component={DateRange} marginRight=".2rem" color={theme.palette.primary.main} />
                            </InputAdornment>
                          }
                          classes={{
                            notchedOutline: clsx({
                              [classes.borderWarning]: props.errors["entryDate"] !== undefined,
                            }),
                          }}
                        />
                      )}
                    />
                  )}
                />
                {props.errors["entryDate"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["entryDate"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormLabel>Quantidade</FormLabel>
                <Controller
                  name="quantity"
                  control={control}
                  component={Box}
                  defaultValue=""
                  rules={{
                    required: {
                      value: props.isEditable,
                      message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                    },
                    validate: {
                      greaterThanZero: (value) =>
                        value > 0 || intl.formatMessage(messages.Message_Generic_ValueGreaterThanZeroField),
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      onBlur={() => {
                        quantityOnChange();
                      }}
                      fullWidth
                      autoComplete="off"
                      type="number"
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["quantity"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {props.errors["quantity"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["quantity"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormLabel>Valor Total</FormLabel>
                <OutlinedInput
                  disabled={true}
                  readOnly={true}
                  fullWidth
                  value={totalValue}
                  classes={{ input: classes.monetaryFields }}
                  endAdornment={
                    <InputAdornment disableTypography={true} position="end">
                      {UNITS.MONETARY}
                    </InputAdornment>
                  }
                  type="text"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <FormLabel>Notas</FormLabel>
                <Controller
                  name="notes"
                  control={control}
                  component={Box}
                  defaultValue=""
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      placeholder={"Escreva aqui as suas notas"}
                      multiline={true}
                      rows={3}
                    />
                  )}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getWarehousesByProvince: (name, province) => dispatch(getWarehousesByProvinceAction(name, province)),
  getActiveCategories: (name) => dispatch(getActiveCategoriesAction(name)),
  getUnitValueByCategory: (category) => dispatch(getUnitValueByCategoryAction(category)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralData);
