import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  /*
    SECTIONS:
    - Generic
    - Avatar
    - Buttons
    - Breadcrumbs
    - Containers
    - Cards
    - Documents
    - Forms
    - Icons
    - Input Fields
    - Labels
    - Pagination
    - ReactQuill
    - Tables
    - Tabs
    - Tooltips
    - Switches
    - Modals
    */

  //Generic
  paddingRight0px: {
    paddingRight: "0px",
  },
  paddingLeft0px: {
    paddingLeft: "0px",
  },
  minHeight0PX: {
    minHeight: "auto!important",
  },
  alignRight: {
    textAlign: "right",
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  sideBySideDiv: {
    float: "left",
    marginRight: "10px",
  },

  //Avatar
  avatarRoot: {
    width: "35px",
    height: "35px",
    fontWeight: 600,
    fontSize: "12.25px",
    lineHeight: "17px",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
  },
  avatarIconOutlined: {
    padding: "2px",
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.darkBlue,
    borderRadius: "28px",
    border: "1px solid " + theme.palette.primary.darkBlue,
  },

  //Buttons
  containedTertiaryButton: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.darkBlue,
    border: "1px solid " + theme.palette.primary.darkBlue,
    "&:hover": {
      backgroundColor: theme.palette.primary.greyBlue,
      color: theme.palette.white.main,
      borderColor: theme.palette.primary.greyBlue,
    },
    "&:active": {
      backgroundColor: theme.palette.primary.darkBlue,
      color: theme.palette.white.main,
      borderColor: theme.palette.primary.darkBlue,
    },
    "&:focus": {
      color: theme.palette.primary.darkBlue,
      border: "2px solid " + theme.palette.secondary.skyBlue,
    },
    "&.Mui-disabled": {
      backgroundColor: theme.palette.white.main,
      color: theme.palette.primary.mediumBlue,
      borderColor: theme.palette.primary.mediumBlue,
    },
  },
  containedTertiaryRedButton: {
    backgroundColor: theme.palette.white.main,
    color:  theme.palette.warning.main,
    border: "1px solid " +  theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.white.main,
      borderColor: theme.palette.warning.main,
    },
    "&:active": {
      backgroundColor:  theme.palette.warning.main,
      color: theme.palette.white.main,
      borderColor:  theme.palette.warning.main,
    },
    "&:focus": {
      color:  theme.palette.warning.main,
      border: "2px solid " + theme.palette.warning.main,
    },
    "&.Mui-disabled": {
      backgroundColor: theme.palette.white.main,
      color: theme.palette.warning.tableLightHover,
      borderColor: theme.palette.warning.tableLightHover,
    },
  },
  containedGhostButton: {
    textDecorationLine: "none",
    textTransform: "uppercase",
    backgroundColor: theme.palette.transparent.main + "!important",
    color: theme.palette.primary.main,
    borderColor: theme.palette.transparent.main,
    boxShadow: "none !important",
    "&:hover": {
      color: theme.palette.primary.greyBlue,
      textDecorationLine: "none",
      fontWeight: "600",
    },
    "&:active": {
      color: theme.palette.primary.darkBlue,
      fontWeight: "600",
    },
    "&:focus": {
      color: theme.palette.primary.main,
      fontWeight: "600",
      "& .MuiButton-label": {
        textDecorationLine: "underline",
      },
    },
    "&.Mui-disabled": {
      color: theme.palette.primary.mediumBlue,
    },
  },
  indicatorButton: {
    borderRadius: "4px",
    padding: "20px",
    width: "100%",
    textAlign: "left",
    textTransform: "none",
    boxShadow: "none",
    border: "none",
  },
  highlightedIndicatorButton: {
    height: "150px",
    background: "linear-gradient(180deg, rgba(159, 205, 250, 0.216) 0%, rgba(0, 88, 158, 0.464) 100%)",
    color: theme.palette.white.main,
    "& .MuiSvgIcon-root": {
      color: theme.palette.white.main,
    },
    "&:hover": {
      background: theme.palette.primary.greyBlue,
    },
    "&:active": {
      background: theme.palette.primary.darkBlue,
    },
  },
  normalIndicatorButton: {
    height: "115px",
    marginTop: "10px",
    background:
      "linear-gradient(180deg, " +
      theme.palette.white.main +
      " 17.19%, " +
      theme.palette.secondary.lightSkyBlue +
      " 75%, " +
      theme.palette.secondary.mediumSkyBlue +
      " 100%)",
    color: theme.palette.primary.darkBlue,
    fontWeight: "700",
    "&:hover": {
      background: theme.palette.primary.mediumBlue,
    },
    "&:active": {
      background: theme.palette.primary.main,
      color: theme.palette.white.main,
      "& .MuiSvgIcon-root": {
        color: theme.palette.white.main,
      },
    },
  },
  normalIndicator: {
    height: "115px",
    marginTop: "10px",
    background:
      "linear-gradient(180deg, " +
      theme.palette.white.main +
      " 17.19%, " +
      theme.palette.secondary.lightSkyBlue +
      " 75%, " +
      theme.palette.secondary.mediumSkyBlue +
      " 100%)",
    color: theme.palette.primary.darkBlue,
    fontWeight: "700",
  },
  buttonProgress: {
    color: theme.palette.white.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  //Breadcrumbs
  breadcrumbs: {
    marginTop: "150px",
    position: "fixed",
    width: "100%",
    zIndex: 2,
  },

  //Containers
  containerRoot: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "3.5rem",
      paddingRight: "3.5rem",
    },
    marginTop: "10rem",
  },
  containerRootAfterBreadcrumbs: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "3.5rem",
      paddingRight: "3.5rem",
    },
    marginTop: "14rem",
  },

  //Cards
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
  },
  cardHeader: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "25px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  },
  cardHeaderAction: {
    display: "flex",
    marginTop: 0,
    marginRight: 0,
  },
  cardHeaderActionGrid: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  cardHeaderActionTypography: {
    marginBottom: "0px",
    marginTop: "8px",
    color: theme.palette.primary.greyBlue,
  },
  cardActionsRoot: {
    paddingBottom: "1.5rem!important",
    paddingTop: "1.5rem!important",
    borderTop: "0!important",
  },
  cardHeaderActionSwitchGrid: {
    paddingLeft: "5px",
    paddingRight: "5px",
    alignSelf: "center",
  },
  cardHeaderActionSwitchActiveLabel: {
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    paddingRight: "5px",
    paddingLeft: "5px",
  },
  cardHeaderActionSwitchInactiveLabel: {
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.gray[600],
    textTransform: "uppercase",
    paddingRight: "5px",
    paddingLeft: "5px",
  },
  cardElementsCount: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16.8px",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.gray[900],
    marginTop: "6px",
  },
  cardHeaderAvatar: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "17px",
    lineHeight: "23px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  cardHeaderIcon: {
    display: "flex",
    width: "20px",
    height: "auto",
  },
  innerCardHeaderTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.gray[700],
  },
  cardExpand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  cardExpandOpen: {
    transform: "rotate(180deg)",
  },
  cardContentBorderBottom: {
    borderBottom: "1px solid rgba(0, 0, 0,.05)",
  },
  cardTabButton: {
    fontWeight: "bold",
    boxShadow: "0px 0px 0px",
    backgroundColor: theme.palette.grey[200],
    "&.Mui-selected": {
      backgroundColor: theme.palette.white.main + "!important",
      color: theme.palette.primary.main + "!important",
      boxShadow: "0px -2px 1px" + theme.palette.primary.main,
    },
  },

  //Documents
  documentNameRoot: {
    paddingRight: "10px",
  },
  documentBadge: {
    backgroundColor: theme.palette.white.main,
    marginRight: "10px",
    marginBottom: "10px",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    color: theme.palette.primary.main,
    borderRadius: "4px",
    border: "1px solid " + theme.palette.primary.main,
    padding: ".4em .3em .3em .85em",
    alignItems: "center",
    wordBreak: "break-all",
  },
  documentBadgeContainer: {
    paddingLeft: "15px",
    overflow: "auto",
    maxHeight: "150px",
  },
  documentAvatar: {
    width: "100px",
    height: "100px",
  },
  documentDeleteButtonIconRoot: {
    color: theme.palette.primary.main,
    width: "20px",
    height: "20px",
  },
  documentDeleteButtonRoot: {
    padding: 0,
  },
  documentDropzone: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: "1px",
    borderRadius: "1px",
    height: "62px!important",
    borderColor: theme.palette.gray[600],
    borderStyle: "dashed",
    backgroundColor: theme.palette.white.main,
    color: theme.palette.gray[500],
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer",
  },

  // Forms
  formControlCheckboxLabelRoot: {
    position: "relative",
    display: "flex",
    minHeight: "1.5rem",
    WebkitPrintColorAdjust: "exact",
    marginLeft: "17px",
    marginTop: "20px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
    },
  },
  formControlCheckboxLabelLabel: {
    cursor: "pointer",
    fontSize: ".875rem",
    position: "relative",
    verticalAlign: "top",
    display: "inline-block",
    color: theme.palette.gray[600],
  },
  formControlLabelCheckboxInTableHeader: {
    fontSize: ".7rem",
    fontWeight: "650",
    color: theme.palette.gray[900],
  },
  formControlLabelNoMargin: {
    margin: 0,
  },
  formRatingInTableCell: {
    marginBottom: "0px",
  },
  formTextInputInTableCell: {
    marginBottom: "10px",
  },

  //Icons
  iconButtonOutlined: {
    padding: "2px",
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.darkBlue,
    borderRadius: "28px",
    border: "1px solid " + theme.palette.primary.darkBlue,
    "&:hover": {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.primary.greyBlue,
      borderColor: theme.palette.primary.greyBlue,
    },
    "&:active": {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.primary.darkBlue,
      borderColor: theme.palette.primary.darkBlue,
    },
    "&:focus": {
      color: theme.palette.primary.darkBlue,
      borderColor: theme.palette.secondary.skyBlue,
    },
    "&.Mui-disabled": {
      color: theme.palette.primary.mediumBlue,
      borderColor: theme.palette.primary.mediumBlue,
    },
  },
  ratingBox: {
    display: "flex",
    flexDirection: "column",
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
  overallRatingIcon: {
    marginLeft: "-10px",
    marginRight: "-10px",
  },
  timelineIcons: {
    color: theme.palette.white.main,
    width: "25px",
    height: "auto",
    marginTop: "5px",
  },
  timelineIconsBox: {
    background: theme.palette.primary.greyBlue,
    borderRadius: "35px",
    width: "37px",
    height: "37px",
    alignItems: "center",
    textAlign: "center",
  },
  errorIcon: {
    color: theme.palette.error.main,
    width: "20px",
    height: "20px",
  },
  checkIcon: {
    color: theme.palette.info.green,
    width: "20px",
    height: "20px",
  },
  alertIcon: {
    padding: "12px 0",
  },

  //Input Fields
  borderWarning: {
    borderColor: theme.palette.warning.main + "!important",
  },
  selectBorderWarningInputRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.warning.main + "!important",
    },
  },
  monetaryFields: {
    textAlign: "end",
  },

  //Labels
  counterValue: {
    fontStyle: "normal",
    fontSize: "30px",
    lineHeight: "19px",
    alignItems: "center",
    height: "25px",
  },
  counterLabel: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    alignItems: "center",
    marginTop: "10px",
    display: "flex",
  },
  detailFormLabels: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.gray[700],
  },
  detailLabels: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "19px",
    alignItems: "center",
    color: theme.palette.gray[700],
  },

  //Pagination
  alignPagination: {
    "& .MuiPagination-ul": {
      justifyContent: "center",
      marginTop: "20px",
      marginBottom: "20px",
    },
  },

  //ReactQuill
  quillOverflowWrap: {
    "& .ql-container .ql-editor": {
      overflowWrap: "anywhere",
      "& img": {
        maxWidth: "100%",
      },
    },
  },

  //Tables
  tableCellRoot: {
    verticalAlign: "middle",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    borderTop: "0",
    fontSize: ".75rem",
    padding: ".25rem",
  },
  tableCellRootHead: {
    backgroundColor: theme.palette.secondary.lightSkyBlue,
    color: theme.palette.gray[900],
    fontSize: ".7rem !important",
    fontWeight: "650",
    textTransform: "none !important",
  },
  tableCellRootHeadMultiple: {
    backgroundColor: theme.palette.gray[200],
    color: theme.palette.gray[600],
    fontSize: ".7rem !important",
    fontWeight: "650",
    textTransform: "none !important",
  },
  tableCellHeadStickyRight: {
    textAlign: "center",
    position: "sticky",
    right: 0,
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    boxShadow: "inset 2px 0" + theme.palette.gray[200],
  },
  tableCellBodyStickyRight: {
    position: "sticky",
    right: 0,
    backgroundColor: theme.palette.white.main,
    textAlign: "center",
    boxShadow: "inset 2px 0" + theme.palette.gray[200],
  },
  tableRowEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "200px",
  },
  noResultsInfoInTable: {
    margin: "20px",
  },

  //Tabs
  changeTabsGroup: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(min(310px, 100%), 1fr))",
    flex: 1,
    margin: "0 15px",
    marginBottom: "25px",
  },
  changeTabs: {
    width: "100%",
    heigth: "40px",
    backgroundColor: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08);",
    borderRadius: "6px",
    textTransform: "uppercase",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16.8px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.darkBlue,
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.darkBlue,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "17px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      letterSpacing: "0.02em",
      color: theme.palette.white.main,
      "&:hover": {
        background: theme.palette.primary.darkBlue,
      },
    },
    "&:hover": {
      background: theme.palette.primary.greyBlue,
      color: theme.palette.white.main,
    },
  },

  //Tooltips
  tooltipInfoIcon: {
    color: theme.palette.primary.main,
    marginLeft: "5px",
  },
  tooltipDescritionSizeWidth: {
    maxWidth: 500,
  },

  //Switches
  disabledSwitchThumb: {},
  disabledSwitchTrack: {},
  infoSwitchThumb: {},
  infoSwitchTrack: {},
  successSwitchThumb: {},
  successSwitchTrack: {},
  errorSwitchThumb: {},
  errorSwitchTrack: {},
  warningSwitchThumb: {},
  warningSwitchTrack: {},
  whiteSwitchThumb: {},
  whiteSwitchTrack: {},
  checkedSwitch: {
    "& $disabledSwitchThumb": {
      backgroundColor: theme.palette.gray[200] + "!important",
    },
    "& + $disabledSwitchTrack": {
      borderColor: theme.palette.gray[200] + "!important",
    },
    "& $infoSwitchThumb": {
      backgroundColor: theme.palette.info.main + "!important",
    },
    "& + $infoSwitchTrack": {
      borderColor: theme.palette.info.main + "!important",
    },
    "& $successSwitchThumb": {
      backgroundColor: theme.palette.success.main + "!important",
    },
    "& + $successSwitchTrack": {
      borderColor: theme.palette.success.main + "!important",
    },
    "& $errorSwitchThumb": {
      backgroundColor: theme.palette.error.main + "!important",
    },
    "& + $errorSwitchTrack": {
      borderColor: theme.palette.error.main + "!important",
    },
    "& $warningSwitchThumb": {
      backgroundColor: theme.palette.primary.main + "!important",
    },
    "& + $warningSwitchTrack": {
      borderColor: theme.palette.primary.main + "!important",
    },
    "& $whiteSwitchThumb": {
      backgroundColor: theme.palette.white.main + "!important",
    },
    "& + $whiteSwitchTrack": {
      borderColor: theme.palette.white.main + "!important",
    },
  },

  // Modals
  modalHeader: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "33px",
    textAlign: "center",
    color: theme.palette.primary.main,
    marginBottom: "65px",
  },
  subHeader: {
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "14px",
    lineHeight: "19px",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.gray[700],
  },
  modalHelpIconBox: {
    textAlign: "center",
    color: theme.palette.info.lightgreen,
  },
  modalHelpIconRoot: {
    width: "100px",
    height: "auto",
  },
});

export default componentStyles;
