import { LOANS } from "utils/actionsConsts";

const loansReducer = (
  state = {
    search_loading: false,
    reject_loan_loading: false,
    update_loan_loading: false,
    refresh_results: false,
    loan_details_loading: false,
    total_records: 0,
    total_pages: 0,
  },
  action
) => {
  switch (action.type) {
    case LOANS.GET_FILTERED_LOANS:
      return {
        ...state,
        result: action.payload,
        total_records: action.payload.total_records,
        total_pages: action.payload.total_pages,
      };
    case LOANS.GET_LOAN_DETAILS:
      return { ...state, loan_details: action.payload };
    case LOANS.GET_LOAN_DETAILS_LOADING:
      return { ...state, loan_details_loading: action.payload };
    case LOANS.GET_FILTERED_LOANS_LOADING:
      return { ...state, search_loading: action.payload };
    case LOANS.REJECT_LOAN_LOADING:
      return { ...state, reject_loan_loading: action.payload };
    case LOANS.UPDATE_LOAN_LOADING:
      return { ...state, update_loan_loading: action.payload };
    case LOANS.REFRESH_RESULTS:
      return { ...state, refresh_results: action.payload };
    default:
      return state;
  }
};

export default loansReducer;
