import axios from "axios";
import { INTERNAL_API_URL } from "../index";

export const CREATE_SCHEDULING_URL = "Scheduling/create-scheduling";
export const SEARCH_SCHEDULING_URL = "Scheduling/search-scheduling";
export const GET_SCHEDULING_URL = "Scheduling/get-scheduling";
export const UPDATE_SCHEDULING_URL = "Scheduling/update-scheduling"
export const GET_SCHEDULING_DOCUMENT_URL = "Scheduling/get-scheduling-document-by-id?scheduleId="

export function createScheduling(data) {
  return axios.post(INTERNAL_API_URL + CREATE_SCHEDULING_URL, data);
}

export function searchSchedulings(data) {
  return axios.post(INTERNAL_API_URL + SEARCH_SCHEDULING_URL, data)
}

export function getScheduling(scheduleNumber) {
  return axios.get(
    INTERNAL_API_URL + GET_SCHEDULING_URL, { params: { id: scheduleNumber } }
  );
}


export function updateScheduling(data) {
  return axios.post(INTERNAL_API_URL + UPDATE_SCHEDULING_URL, data);
}



export function getReportDocument(data) {
  return axios.get(INTERNAL_API_URL + GET_SCHEDULING_DOCUMENT_URL + data, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  });
}