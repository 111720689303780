import { React, useEffect } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/icons components
import DeleteIcon from "@material-ui/icons/Delete";
//import custom components
import componentStyles from "assets/theme/views/admin/elements.js";
import GenericFormSelect from "components/FormFields/GenericFormSelect";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { DATASET } from "utils/consts";
import { getDatasetStateByName } from "utils/datasets";
import { getSelectOptionsElementByCode } from "utils/formSelect";

const useStyles = makeStyles(componentStyles);

function ConditionSection(props) {
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    if (
      props.dataSetState[DATASET.CONDITION_TYPES] === undefined ||
      props.dataSetState[DATASET.CONDITION_TYPES]?.length === 0
    ) {
      props.getDataSet(DATASET.CONDITION_TYPES);
    }

    props.setValue(
      `${props.keyValue}_conditionType`,
      getSelectOptionsElementByCode(
        props.dataSetState[DATASET.CONDITION_TYPES] !== undefined ? props.dataSetState[DATASET.CONDITION_TYPES] : [],
        props.conditionInfo.conditionType !== undefined ? props.conditionInfo.conditionType.code : null
      )
    );

    props.setValue(
      `${props.keyValue}_conditionalQuestionId`,
      getSelectOptionsElementByCode(
        props.complementaryDataInfoForConditions !== null &&
          props.complementaryDataInfoForConditions !== undefined &&
          props.complementaryDataInfoForConditions.some((item) => item.itemText !== null)
          ? props.complementaryDataInfoForConditions.map((items) => {
              if (items.itemText) {
                return { code: items.itemKey, label: items.itemText };
              }

              return null;
            })
          : [],
        props.conditionInfo.conditionType !== undefined ? props.conditionInfo.conditionalQuestionId.code : null
      )
    );

    props.setValue(
      `${props.keyValue}_conditionalValue`,
      getSelectOptionsElementByCode(
        getDatasetStateByName(props, props.conditionInfo.selectedDatasetKey),
        props.conditionInfo.conditionType !== undefined ? props.conditionInfo.conditionalValue.code : ""
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.complementaryDataInfoForConditions?.forEach((complementaryDataItem) => {
      if (
        props.dataSetState[complementaryDataItem.itemDatasetKey] === undefined ||
        props.dataSetState[complementaryDataItem.itemDatasetKey]?.length === 0
      ) {
        props.getDataSet(complementaryDataItem.itemDatasetKey);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.complementaryDataInfoForConditions]);

  const handleConditionalQuestionIdChangeEvent = (data) => {
    if (data !== undefined) {
      props.handleConditionalQuestionIdChangeEvent(
        props.conditionInfo?.key,
        props.complementaryDataInfoForConditions?.find((r) => r.itemKey === data.code).itemDatasetKey,
        data.code
      );
    }
  };

  const handleGetConditionalDataChangeEvent = (data) => {
    if (data !== undefined) {
      props.handleGetConditionalDataChangeEvent(
        props.conditionInfo?.key,
        props.getValues(`${props.keyValue}_conditionType`),
        props.getValues(`${props.keyValue}_conditionalQuestionId`),
        props.getValues(`${props.keyValue}_conditionalValue`)
      );
    }
  };

  return (
    <>
      <Grid container style={{ alignItems: "center" }}>
        <Grid item xs={12} md={3}>
          <FormGroup>
            <FormLabel>Condição</FormLabel>
            <GenericFormSelect
              control={props.control}
              fieldName={`${props.keyValue}_conditionType`}
              selectOptions={
                props.dataSetState[DATASET.CONDITION_TYPES] !== undefined
                  ? props.dataSetState[DATASET.CONDITION_TYPES]
                  : []
              }
              getValues={props.getValues}
              required={true}
              errors={props.errors}
              classes={classes}
              onOptionChange={handleGetConditionalDataChangeEvent}
            />
            {`${props.keyValue}_conditionType` !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors[`${props.keyValue}_conditionType`]?.message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormGroup>
            <FormLabel>Questão seleccionada</FormLabel>
            <GenericFormSelect
              control={props.control}
              fieldName={`${props.keyValue}_conditionalQuestionId`}
              selectOptions={
                props.complementaryDataInfoForConditions !== null &&
                props.complementaryDataInfoForConditions !== undefined &&
                props.complementaryDataInfoForConditions.some((item) => item.itemText !== null)
                  ? props.complementaryDataInfoForConditions.map((items) => {
                      if (items.itemText) {
                        return { code: items.itemKey, label: items.itemText };
                      }

                      return null;
                    })
                  : []
              }
              getValues={props.getValues}
              required={true}
              errors={props.errors}
              classes={classes}
              onOptionChange={(data) => {
                handleConditionalQuestionIdChangeEvent(data);
                handleGetConditionalDataChangeEvent(data);
              }}
            />
            {`${props.keyValue}_conditionalQuestionId` !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors[`${props.keyValue}_conditionalQuestionId`]?.message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormGroup>
            <FormLabel>Valor</FormLabel>
            <GenericFormSelect
              control={props.control}
              fieldName={`${props.keyValue}_conditionalValue`}
              selectOptions={getDatasetStateByName(props, props.conditionInfo.selectedDatasetKey)}
              getValues={props.getValues}
              required={true}
              errors={props.errors}
              classes={classes}
              onOptionChange={handleGetConditionalDataChangeEvent}
            />
            {`${props.keyValue}_conditionalValue` !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors[`${props.keyValue}_conditionalValue`]?.message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={1} className={classes.gridDeleteIconRoot}>
          <IconButton
            aria-label="delete"
            style={{ marginLeft: "-12px" }}
            onClick={() => {
              props.unregister(`${props.keyValue}_conditionType]`);
              props.unregister(`${props.keyValue}_conditionalQuestionId]`);
              props.unregister(`${props.keyValue}_conditionalValue`);
              props.removeConditionFromListEvent(props.conditionInfo.key);
            }}
          >
            <DeleteIcon style={{ width: "19px", height: "19px" }} />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConditionSection);
