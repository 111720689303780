import { combineReducers } from "redux";
import authReducer from "redux/reducers/authReducer";
import datasetReducer from "redux/reducers/datasetReducer";
import stockReducer from "redux/reducers/stockReducer";
import dashboardReducer from "redux/reducers/dashboardReducer";
import subprogramReducer from "redux/reducers/subprogramReducer";
import dialogReducer from "redux/reducers/dialogsReducer";
import applicationsReducer from "redux/reducers/applicationsReducer";
import schedulingReducer from "redux/reducers/schedulingReducer";
import notificationReducer from "redux/reducers/notificationReducer";
import usersReducer from "redux/reducers/usersReducer";
import usersInfoReducer from "redux/reducers/usersInfoReducer";
import loansReducer from "redux/reducers/loansReducer";
import informationDisseminationReducer from "redux/reducers/informationDisseminationReducer";
import OpportunitiesReducer from "redux/reducers/opportunitiesReducer";
import reportsReducer from "redux/reducers/reportsReducer";

const allReducers = combineReducers({
  authState: authReducer,
  dataSetState: datasetReducer,
  stockState: stockReducer,
  dashboardState: dashboardReducer,
  subprogramState: subprogramReducer,
  dialogState: dialogReducer,
  applicationsState: applicationsReducer,
  schedulingState: schedulingReducer,
  notificationState: notificationReducer,
  usersState: usersReducer,
  usersInfo: usersInfoReducer,
  loansState: loansReducer,
  informationDisseminationState: informationDisseminationReducer,
  opportunitiesState: OpportunitiesReducer,
  reportsState: reportsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "logout") {
    const { authState } = state;
    authState.operationsList = [];
    authState.login_error = false;
    authState.photo = null;
    state = { authState };
    return allReducers(state, action);
  }

  return allReducers(state, action);
};

export default rootReducer;
