import React from "react";
import { connect } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
// @material-ui/icons components
//import custom components
import GenericButton from "components/Buttons/GenericButton";
import BackOfficeUserData from "./Sections/BackOfficeUserData";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import backOfficeUserRegisterMap from "mappers/backOfficeUserRegisterMap";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { registerBoUserAction } from "redux/actions/registerBoUserAction";
import { DATASET } from "utils/consts";
import { getSelectOptionsElementByCode } from "utils/formSelect";
import { labels } from "resources/resources";
import useScrollDirection from "hooks/useScrollDirection";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
const useStylesGeneric = makeStyles(componentStylesGeneric);

function BackOfficeUserCreation(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const scrollDirection = useScrollDirection();

  function handleRegister(data) {
    props.registerBoUser(backOfficeUserRegisterMap(data));
  }

  React.useEffect(() => {
    //Set province, since logged user can only create users from same province
    if (props.authState?.province) {
      var provinceList = props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : [];
      var selectedProvince = getSelectOptionsElementByCode(provinceList, props.authState.province);
      props.getDataSet(DATASET.MUNICIPALITIES, selectedProvince.keyValue);

      setValue("province", selectedProvince);
    }
    //set Active field as true
    setValue(
      "active",
      DATASET.YES_OR_NO_DROPDOWN.find((t) => t.code)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (props.authState?.province) {
      var provinceList = props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : [];
      setValue("province", getSelectOptionsElementByCode(provinceList, props.authState.province));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.PROVINCES]]);

  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    getValues,
    trigger,
    setValue,
    unregister,
    formState: { errors },
  } = methods;

  return (
    <>
      {scrollDirection === "up" && (
        <div className={classes.breadcrumbs}>
          <LocationHeader
            section={"Criar Utilizador BackOffice"}
            subsection={"Gestão de Utilizadores BackOffice"}
            subsectionLink={"/admin/backofficeusers-management"}
          />
        </div>
      )}

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleRegister)}>
          <Container maxWidth={false} component={Box} classes={{ root: classes.containerRootAfterBreadcrumbs }}>
            <PageTitleHeader title={"Criar Utilizador BackOffice"} />

            <Container maxWidth={false} component={Box}>
              <BackOfficeUserData
                isCreate={true}
                getValues={getValues}
                setValue={setValue}
                errors={errors}
                trigger={trigger}
                unregister={unregister}
                isEditable={true}
              />

              <Grid container>
                <Grid item xs={12} lg={3} xl={10}></Grid>
                <Grid item xs={12} lg={3} xl={2}>
                  <FormGroup>
                    <GenericButton
                      typeSubmit={true}
                      color="primary"
                      loading={props.usersInfo.backOfficeUserRegister_loading}
                    >
                      {intl.formatMessage(labels.Label_Generic_Submit_Button)}
                    </GenericButton>
                  </FormGroup>
                </Grid>
              </Grid>
            </Container>
          </Container>
        </form>
      </FormProvider>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  registerBoUser: (data) => dispatch(registerBoUserAction(data)),
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeUserCreation);
