import React, {useEffect} from "react";
import { connect } from "react-redux";
import { Container } from "@material-ui/core";

//import custom components
import ProfessionalDataSection from "views/admin/UserOverview/UserSingular/Sections/ProfessionalDataSection";
import TrainingSection from "views/admin/UserOverview/UserSingular/Sections/TrainingSection";
import DocumentsSection from "views/admin/UserOverview/Components/Sections/DocumentsSection";
import useScrollTo from "hooks/useScrollTo";

function TrainingAndProfessionTab(props) {
  const scrollTo = useScrollTo;

  useEffect(() => {
    if(props.goToTopOfPage){
      scrollTo()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <Container maxWidth={false}>
        <ProfessionalDataSection
          userProfessionalDataOverview={props?.userSingularProfessionalDataOverview}
        />
        <TrainingSection
          userProfessionalDataOverview={props?.userSingularProfessionalDataOverview}
        />
        <DocumentsSection
          userDocuments={props?.userSingularProfessionalDataOverview?.userDocuments}
          userTypeDocuments={props?.userSingularProfessionalDataOverview?.userTypeDocuments}
        />
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(TrainingAndProfessionTab);