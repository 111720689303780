import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
//import custom components
import DisplayField from "components/Fields/DisplayField";
import { getMultipleSelectOptionsElementByCode } from "utils/formSelect";
import { getListAsStringLabels } from "utils/formSelectMultiple";
import { getUserDocumentAction } from "redux/actions/usersAction";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { DATASET } from "utils/consts";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function CandidateProfessionalDetails(props) {
  const classes = useStylesGeneric();
  const [data, setData] = useState();

  useEffect(() => {
    if (props.opportunitiesState?.candidateInfo !== null && props.opportunitiesState?.candidateInfo !== undefined) {
      setData(props.opportunitiesState.candidateInfo);
    } else {
      setData([]);
    }
  }, [props.opportunitiesState?.candidateInfo]);

  //#region DataSets Setup
  useEffect(() => {
    if (
      props.dataSetState[DATASET.ACADEMIC_DEGREE] === undefined ||
      props.dataSetState[DATASET.ACADEMIC_DEGREE] === []
    ) {
      props.getDataSetByName(DATASET.ACADEMIC_DEGREE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.ACADEMIC_DEGREE]]);

  useEffect(() => {
    if (
      props.dataSetState[DATASET.PROFESSIONAL_CATEGORY] === undefined ||
      props.dataSetState[DATASET.PROFESSIONAL_CATEGORY] === []
    ) {
      props.getDataSetByName(DATASET.PROFESSIONAL_CATEGORY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]]);

  useEffect(() => {
    if (
      props.dataSetState[DATASET.PROFESSIONAL_TRAINNING] === undefined ||
      props.dataSetState[DATASET.PROFESSIONAL_TRAINNING] === []
    ) {
      props.getDataSetByName(DATASET.PROFESSIONAL_TRAINNING);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.PROFESSIONAL_TRAINNING]]);

  useEffect(() => {
    if (
      props.dataSetState[DATASET.ENTREPRENEURSHIP_TRAINNING] === undefined ||
      props.dataSetState[DATASET.ENTREPRENEURSHIP_TRAINNING] === []
    ) {
      props.getDataSetByName(DATASET.ENTREPRENEURSHIP_TRAINNING);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.ENTREPRENEURSHIP_TRAINNING]]);

  useEffect(() => {
    if (
      props.dataSetState[DATASET.SMALL_BUSINESS_MGMT_TRAINNING] === undefined ||
      props.dataSetState[DATASET.SMALL_BUSINESS_MGMT_TRAINNING] === []
    ) {
      props.getDataSetByName(DATASET.SMALL_BUSINESS_MGMT_TRAINNING);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.SMALL_BUSINESS_MGMT_TRAINNING]]);

  useEffect(() => {
    if (props.dataSetState[DATASET.ACADEMIC_LEVEL] === undefined || props.dataSetState[DATASET.ACADEMIC_LEVEL] === []) {
      props.getDataSetByName(DATASET.ACADEMIC_LEVEL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.ACADEMIC_LEVEL]]);
  //#endregion

  function getProfessionalExperience() {
    if (data?.userSingular?.professionalExperienceList !== null && data?.userSingular?.professionalExperienceList) {
      let list = getMultipleSelectOptionsElementByCode(
        props.dataSetState[DATASET.PROFESSIONAL_CATEGORY],
        data?.userSingular?.professionalExperienceList
      );
      let result = getListAsStringLabels(list, "; ");
      return result;
    }
  }

  return (
    <>
      <Container maxWidth={false}>
        <Card className={classes.cardRoot}>
          <CardHeader classes={{ title: classes.cardHeader }} title={"Dados Profissionais"} />
          <CardContent>
            <Grid container>
              <DisplayField
                xs={12}
                sm={4}
                md={2}
                label="Nível Académico"
                data={
                  data !== undefined && data !== [] && props.dataSetState[DATASET.ACADEMIC_LEVEL] !== undefined
                    ? props.dataSetState[DATASET.ACADEMIC_LEVEL].find(
                        (r) => r.code === data?.userSingular?.academicLevelId
                      )?.label
                    : ""
                }
              />
              <DisplayField
                xs={12}
                sm={4}
                md={2}
                label="Curso"
                data={
                  data !== undefined && data !== [] && props.dataSetState[DATASET.ACADEMIC_DEGREE] !== undefined
                    ? props.dataSetState[DATASET.ACADEMIC_DEGREE].find((r) => r.code === data?.userSingular?.degreeId)
                        ?.label
                    : ""
                }
              />
              <DisplayField
                xs={12}
                sm={4}
                md={2}
                label="Categoria Profissional"
                data={
                  data !== undefined && data !== [] && props.dataSetState[DATASET.PROFESSIONAL_CATEGORY] !== undefined
                    ? props.dataSetState[DATASET.PROFESSIONAL_CATEGORY].find(
                        (r) => r.code === data?.userSingular?.professionalCategoryId
                      )?.label
                    : ""
                }
              />
              <DisplayField
                xs={12}
                sm={4}
                md={2}
                label="Formação Profissional"
                data={
                  data !== undefined && data !== [] && props.dataSetState[DATASET.PROFESSIONAL_TRAINNING] !== undefined
                    ? props.dataSetState[DATASET.PROFESSIONAL_TRAINNING].find(
                        (r) => r.code === data?.userSingular?.professionalTrainingId
                      )?.label
                    : ""
                }
              />
              <DisplayField
                xs={12}
                sm={4}
                md={3}
                label="Formação Empreendorismo"
                data={
                  data !== undefined &&
                  data !== [] &&
                  props.dataSetState[DATASET.ENTREPRENEURSHIP_TRAINNING] !== undefined
                    ? props.dataSetState[DATASET.ENTREPRENEURSHIP_TRAINNING].find(
                        (r) => r.code === data?.userSingular?.entrepreneurshipTrainingId
                      )?.label
                    : ""
                }
              />

              {/* New Line md */}
              <DisplayField
                xs={12}
                sm={4}
                md={2}
                label="Formação de gestão de pequenos negócios"
                data={
                  data !== undefined &&
                  data !== [] &&
                  props.dataSetState[DATASET.SMALL_BUSINESS_MGMT_TRAINNING] !== undefined
                    ? props.dataSetState[DATASET.SMALL_BUSINESS_MGMT_TRAINNING].find(
                        (r) => r.code === data?.userSingular?.smallBusinessMgmtTrainingId
                      )?.label
                    : ""
                }
              />
              <DisplayField xs={12} sm={4} md={2} label="Profissão" data={getProfessionalExperience()} />
              <DisplayField xs={12} sm={4} md={2} label="Anos de Experiência" data={data?.userSingular?.experience} />
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
  getUserDocument: (docId, docName) => dispatch(getUserDocumentAction(docId, docName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CandidateProfessionalDetails);
