import { USER_TYPE } from "utils/consts";

export function userFilterMap(formData) {
  if (formData !== null && formData !== undefined) {
    formData.email = formData.email ?? "";
    formData.nameOfUser = formData.nameOfUser ?? "";
    formData.genderId = formData.gender ? parseInt(formData.gender.code) : undefined;
    formData.numBI = formData.numBI ?? "";
    formData.provinceId = formData.province ? parseInt(formData.province.code) : undefined;
    formData.municipalityId = formData.municipalityId ? parseInt(formData.municipalityId.code) : undefined;
    formData.employmentCenterId = formData.employmentCenter ? parseInt(formData.employmentCenter.code) : undefined;
    formData.phoneNumber = formData.phoneNumber ? formData.phoneNumber : undefined;
    console.log("formData.typeOfUser", formData.typeOfUser);
    formData.typeOfUser =
      (formData.typeOfUser?.label === "Cidadão"
        ? USER_TYPE.INDIVIDUAL
        : formData.typeOfUser?.label === "Empresa"
        ? USER_TYPE.COLETIVO
        : null) ?? null;
    formData.isActive =
      (formData.isActive?.label === "Activo" ? "true" : formData.isActive?.label === "Inactivo" ? "false" : "") ?? "";
    return formData;
  }
}

export default userFilterMap;
