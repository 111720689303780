import { DATASET_ACTIONS } from "utils/actionsConsts";

const datasetReducer = (state = {}, action) => {
  switch (action.type) {
    case DATASET_ACTIONS.GET_DATASET_BY_NAME:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.CLEAR_DATASET_BY_NAME:
      return { ...state, [action.dataSetName]: [] };
    case DATASET_ACTIONS.GET_DATASET_BY_INDEX:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_DATASET_BY_PROPERTY_ID:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_GEOGRAPHIC_DATA:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_GEOGRAPHIC_DATA_BY_PROVINCE:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_NATIONALITIES:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_PROGRAMS:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_SUBPROGRAM_STATES:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_DEPARTMENTS:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_CATEGORIES:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_ACTIVE_CATEGORIES:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_INACTIVE_CATEGORIES:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_ALL_DATASET_TYPES:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_WAREHOUSE_BY_PROVINCE:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_LOSS_BREACK_MOTIVES:
    case DATASET_ACTIONS.GET_DOCUMENTS:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_CONDITIONS:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_NEXT_APPLICATION_STATES:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_SUBPROGRAMS:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_BO_USERS:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_BO_ROLES:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_SHAPE_PROGRAMS:
    case DATASET_ACTIONS.GET_SHAPE_SUBSITES:
      return { ...state, [action.dataSetName]: action.dataSet };
    case DATASET_ACTIONS.GET_LOAN_STATES:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_LOAN_REJECT_MOTIVES:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_NOT_ASSIGNED_ACTIVE_CATEGORIES:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_SUBPROGRAM_BY_PROGRAM:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_PREDEFINED_QUESTIONS:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_AVAILABLE_INTERNSHIP_OPPORTUNITIES_ACTIONS:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.GET_GOVERNMENT_PROGRAMS:
      return { ...state, [action.dataSetName]: action.payload };
    default:
      return state;
  }
};

export default datasetReducer;