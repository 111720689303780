
export function kitDeliveryMap(kitDeliveryData, kitsDelivered, applicationId) {
    if (kitDeliveryData) {
        const kitDelivery = {
            ApplicationId: applicationId ?? 0,
            DeliveryDate: kitDeliveryData.deliverDate ? kitDeliveryData.deliverDate.format("YYYY-MM-DD") : "",
            WarehouseId: kitDeliveryData.warehouseId?.code ?? 0,
            KitsDelivered: kitsDelivered ?? 0,
            Observations: kitDeliveryData.notes ?? ""

        };
        //Set FormData
        const formData = new FormData();
        //Append fields
        for (const key in kitDelivery) {
            formData.append("KitDelivery." + key, kitDelivery[key])
        }
        //Append files
        kitDeliveryData.fileUpload?.forEach(file => {
            formData.append('DeliveryDocument', file);
        });
        return formData;
    }
    return kitDeliveryData;
}

export default kitDeliveryMap