export const getSelectOptionsElementByCode = (array, uniqueField) => {
  if (!array) return null;
  return array.find((x) => x.code === uniqueField);
};

export const getBoolSelectOptionsElementByCode = (array, uniqueField) => {
  return array.find((x) => x.code === uniqueField);
};

export const getSelectOptionsElementByKey = (array, uniqueField) => {
  if (!array) return null;
  return array.find((x) => x.keyValue === uniqueField);
};

export const getMultipleSelectOptionsElementByLabel = (array, values) => {
  if (!array || !values) return null;
  const toReturn = [];
  values.forEach((t) => {
    let toPush = array.find((x) => x.label === t);
    if (toPush) toReturn.push(toPush);
  });
  return toReturn;
};

export const getMultipleSelectOptionsElementByCode = (array, values) => {
  if (!array || !values) return null;

  const toReturn = array.filter((item) => {
    return values.some((value) => value === item.code);
  });

  return toReturn;
};
