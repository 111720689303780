import { React, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
//import custom components
import GeneralData from "./GeneralDataSection";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import GenericButton from "components/Buttons/GenericButton";
import characterizeProfessionalKitMap from "mappers/characterizeProfessionalKitMap";
import { DATASET } from "utils/consts";
import { labels } from "resources/resources";
import useScrollDirection from "hooks/useScrollDirection";
import { characterizeProfessionalKitAction } from "redux/actions/stockAction";
import { getInactiveCategoriesAction } from "redux/actions/datasetsAction";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function CharacterizeKit(props) {
  const classes = useStylesGeneric();
  const history = useHistory();
  const intl = useIntl();
  const scrollDirection = useScrollDirection();

  const [formattedUnitPrice, setFormattedUnitPrice] = useState();

  const methods = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    props.getInactiveCategories(DATASET.INACTIVE_CATEGORIES);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelOnClick = () => {
    history.goBack();
  };

  const onSubmit = (data) => {
    props.characterizeProfessionalKit(
      characterizeProfessionalKitMap(data), 
      () => {
        history.push("/admin/kit-management");
      }
    );
  };

  return (
    <>
      {scrollDirection === "up" && (
        <div className={classes.breadcrumbs}>
          <LocationHeader
            section={"Caracterizar Kit Profissional"}
            subsection={"Gestão de Kits Profissionais"}
            subsectionLink={"/admin/kit-management"}
          />
        </div>
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Container maxWidth={false} component={Box} classes={{ root: classes.containerRootAfterBreadcrumbs }}>
            <PageTitleHeader title={"Caracterizar Kit Profissional"} />

            <Container maxWidth={false} component={Box}>
              <GeneralData
                getValues={methods.getValues}
                errors={methods.formState.errors}
                isEditable={true}
                isCreate={true}
                formattedUnitPrice={formattedUnitPrice}
                setFormattedUnitPrice={setFormattedUnitPrice}
              />

              <Grid container>
                <Grid item xs={12} lg={6} xl={8}></Grid>
                <Grid item xs={12} lg={3} xl={2}>
                  <FormGroup>
                    <GenericButton typeSubmit={false} color="secondary" onClick={() => cancelOnClick()}>
                      {intl.formatMessage(labels.Label_Generic_Cancel)}
                    </GenericButton>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} lg={3} xl={2}>
                  <FormGroup>
                    <GenericButton
                      typeSubmit={true}
                      color="primary"
                      disabled={props.stockState.characterizeProfessionalKit_loading}
                      loading={props.stockState.characterizeProfessionalKit_loading}
                    >
                      {intl.formatMessage(labels.Label_Generic_Submit_Button)}
                    </GenericButton>
                  </FormGroup>
                </Grid>
              </Grid>
            </Container>
          </Container>
        </form>
      </FormProvider>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  characterizeProfessionalKit: (formData, onCloseAction) => dispatch(characterizeProfessionalKitAction(formData, onCloseAction)),
  getInactiveCategories: (name) => dispatch(getInactiveCategoriesAction(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CharacterizeKit);
