import {
  getDashboardStockInfo,
  getDashboardNewCompaniesInfo,
  getDashboardOnGoingApplicationsInfo,
  getDashboardNotificationsInfo,
  getDashboardUnreadNotificationsCountInfo,
  getDashboardOpportunities,
  getOpportunityApplicationFollowUpsWithUnapprovedReports
} from "crud/dashboard.crud";
import { b64toBlob } from "utils/converters";

import { DASHBOARD_ACTIONS } from "utils/actionsConsts";

export const getDashboardStockInfoAction = () => async (dispatch) => {
  getDashboardStockInfo()
    .then((res) => {
      dispatch({
        type: DASHBOARD_ACTIONS.GET_DASHBOARD_STOCK_INFO,
        payload: res.data.map((child) => {
          return {
            id: child.id,
            warehouse: child.warehouseName,
            category: child.categoryName,
            stock: child.quantity,
          };
        }),
      });
    })
    .catch((error) => {
      dispatch({
        type: DASHBOARD_ACTIONS.GET_DASHBOARD_STOCK_INFO,
        payload: [],
      });
      console.log("error", error);
    });
};
export const getDashboardNotificationsInfoAction =
  (formData) => async (dispatch) => {
    getDashboardNotificationsInfo(formData)
      .then((res) => {
        dispatch({
          type: DASHBOARD_ACTIONS.GET_DASHBOARD_NOTIFICATIONS_INFO,
          payload: res.data?.data.map((child) => {
            return {
              sentBy: child.sentByUserName,
              title: child.subject,
              subtitle: child.motive,
              date: child.notificationDate,
              isRead: child.isRead,
            };
          }),
        });
      })
      .catch((error) => {
        dispatch({
          type: DASHBOARD_ACTIONS.GET_DASHBOARD_NOTIFICATIONS_INFO,
          payload: [],
        });
        console.log("error", error);
      });
  };
export const getDashboardUnreadNotificationsCountInfoAction =
  () => async (dispatch) => {
    getDashboardUnreadNotificationsCountInfo()
      .then((res) => {
        dispatch({
          type: DASHBOARD_ACTIONS.GET_DASHBOARD_UNREAD_NOTIFICATIONS_COUNT_INFO,
          payload: res.data.unreadCount,
        });
      })
      .catch((error) => {
        dispatch({
          type: DASHBOARD_ACTIONS.GET_DASHBOARD_UNREAD_NOTIFICATIONS_COUNT_INFO,
          payload: 0,
        });
        console.log("error", error);
      });
  };

export const getDashboardNewCompaniesInfoAction = () => async (dispatch) => {
  getDashboardNewCompaniesInfo()
    .then((res) => {
      dispatch({
        type: DASHBOARD_ACTIONS.GET_DASHBOARD_NEW_COMPANIES_INFO,
        payload: {
          list: res.data.userList.map((child) => {
            return {
              id: child.id,
              name: child.name,
              permitCode: child.userColective
                ? child.userColective.permitCode
                : "",
              province: child.userColective
                ? child.userColective.compositeAddress.provinceKey
                : "",
              taxIdNumber: child.userColective
                ? child.userColective.taxIdNumber
                : "",
              userAvatar: child.userPhoto ? b64toBlob(child.userPhoto) : null,
            };
          }),
          count: res.data.totalUserCount,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: DASHBOARD_ACTIONS.GET_DASHBOARD_NEW_COMPANIES_INFO,
        payload: { list: [], count: 0 },
      });
      console.log("error", error);
    });
};
export const getDashboardOnGoingApplicationsInfoAction =
  () => async (dispatch) => {
    getDashboardOnGoingApplicationsInfo()
      .then((res) => {
        dispatch({
          type: DASHBOARD_ACTIONS.GET_DASHBOARD_ON_GOING_APPLICATIONS_INFO,
          payload: {
            list: res.data.applications.map((child) => {
              return {
                governmentProgramName: child.governmentProgramName,
                applicationNumber: child.applicationCode,
                identityCard: child.identityCardOrTaxIdNum,
                name: child.name,
                userCode: child.userCode,
                userAvatar: child.userPhoto ? b64toBlob(child.userPhoto) : null,
              };
            }),
            count: res.data.totalCount,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: DASHBOARD_ACTIONS.GET_DASHBOARD_ON_GOING_APPLICATIONS_INFO,
          payload: { list: [], count: 0 },
        });
        console.log("error", error);
      });
  };
export const getDashboardOpportunitiesAction = () => async (dispatch) => {
  getDashboardOpportunities()
    .then((res) => {
      dispatch({
        type: DASHBOARD_ACTIONS.GET_DASHBOARD_OPPORTUNITIES,
        payload: {
          list: res.data.opportunitiesList.map((child) => {
            return {
              id: child.id,
              name: child.userName,
              userAvatar: child.userPhoto ? b64toBlob(child.userPhoto) : null,
              opportunityStateKey: child.opportunityStateKey
                ? child.opportunityStateKey
                : "",
              opportunityStateDescription: child.opportunityStateDescription
                ? child.opportunityStateDescription
                : "",
              opportunityType: child.opportunityTypeDescription
                ? child.opportunityTypeDescription
                : "",
            };
          }),
          count: res.data.totalOpportunityCount,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: DASHBOARD_ACTIONS.GET_DASHBOARD_OPPORTUNITIES,
        payload: { list: [], count: 0 },
      });
      console.log("error", error);
    });
};

export const getOpportunityApplicationFollowUpsWithUnapprovedReportsAction = () => async (dispatch) => {
  dispatch({
    type: DASHBOARD_ACTIONS.GET_OPPORTUNITY_APPLICATION_FOLLOWUPS_WITH_UNAPPROVED_REPORTS_LOADING,
    payload: true,
  });
  getOpportunityApplicationFollowUpsWithUnapprovedReports()
    .then((res) => {
      dispatch({
        type: DASHBOARD_ACTIONS.GET_OPPORTUNITY_APPLICATION_FOLLOWUPS_WITH_UNAPPROVED_REPORTS,
        payload: res.data,
      });
      dispatch({
        type: DASHBOARD_ACTIONS.GET_OPPORTUNITY_APPLICATION_FOLLOWUPS_WITH_UNAPPROVED_REPORTS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: DASHBOARD_ACTIONS.GET_OPPORTUNITY_APPLICATION_FOLLOWUPS_WITH_UNAPPROVED_REPORTS_LOADING,
        payload: false,
      });

      console.log("error", error);
    });
};

export default getDashboardStockInfoAction;
