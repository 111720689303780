import React from "react";
import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { messages } from "resources/resources";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
//import custom components
import { UNITS, REGEX_PATTERNS, MONETARYUNITPLACEHOLDER } from "utils/consts";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function MicrocreditFields(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const { control } = useFormContext();
  const intl = useIntl();

  const onInterestRateChange = (value, onChange) => {
    value = value?.replace(/\./g, ",");
    onChange(value);
  };

  return (
    <>
      <Grid container style={{ alignItems: "flex-end" }}>
        <Grid item xs={12} sm={4} md={3}>
          <FormGroup>
            <FormLabel>Valor mínimo Microcrédito</FormLabel>
            <Controller
              name="minMicrocreditValue"
              control={control}
              defaultValue=""
              component={Box}
              rules={{
                required: {
                  value: true,
                  message: "O campo é obrigatório",
                },
                pattern: {
                  value: REGEX_PATTERNS.MONETARY,
                  message: intl.formatMessage(
                    messages.Message_Generic_InvalidField
                  ),
                },
                validate: {
                  minValue: (value) => {
                    if (value === "0" || value === "0,00" || value === "0,0")
                      return "O valor mínimo do microcédito tem de ser maior que 0.";
                    return true;
                  },
                  isLessThanMaxValue: (value) => {
                    if (!props.getValues("maxMicrocreditValue")) return true;
                    return (
                      (value &&
                        parseInt(value) <
                          parseInt(props.getValues("maxMicrocreditValue"))) ||
                      "O valor mínimo do microcrédito deve ser menor que o valor máximo"
                    );
                  },
                },
              }}
              render={({ field: { onChange, name } }) => (
                <OutlinedInput
                  name={name}
                  value={props.formattedMinValue}
                  onChange={(e) => {
                    props.onMinValChange(e.target.value, onChange);
                    props.trigger("maxMicrocreditValue");
                  }}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  placeholder={MONETARYUNITPLACEHOLDER}
                  inputProps={{ style: { textAlign: "right" } }}
                  endAdornment={
                    <InputAdornment position="end">
                      {UNITS.MONETARY}
                    </InputAdornment>
                  }
                  disabled={!props.isEditable}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]:
                        props.errors["minMicrocreditValue"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {props.errors["minMicrocreditValue"] !== undefined && (
              <FormHelperText
                component={Box}
                color={theme.palette.warning.main + "!important"}
              >
                {props.errors["minMicrocreditValue"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormGroup>
            <FormLabel>Valor máximo Microcrédito</FormLabel>
            <Controller
              name="maxMicrocreditValue"
              control={control}
              defaultValue=""
              component={Box}
              rules={{
                required: {
                  value: true,
                  message: "O campo é obrigatório",
                },
                pattern: {
                  value: REGEX_PATTERNS.MONETARY,
                  message: intl.formatMessage(
                    messages.Message_Generic_InvalidField
                  ),
                },
                validate: {
                  minValue: (value) => {
                    if (value === "0" || value === "0,00" || value === "0,0")
                      return "O valor máximo do microcrédito tem de ser maior que 0.";
                    return true;
                  },
                  isGreaterThanMinValue: (value) => {
                    if (!props.getValues("minMicrocreditValue")) return true;
                    return (
                      (value &&
                        parseInt(value) >
                          parseInt(props.getValues("minMicrocreditValue"))) ||
                      "O valor máximo do microcrédito deve ser maior que o valor mínimo"
                    );
                  },
                },
              }}
              render={({ field: { onChange, name } }) => (
                <OutlinedInput
                  name={name}
                  value={props.formattedMaxValue}
                  onChange={(e) => {
                    props.onMaxValChange(e.target.value, onChange);
                    props.trigger("minMicrocreditValue");
                  }}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  placeholder={MONETARYUNITPLACEHOLDER}
                  inputProps={{ style: { textAlign: "right" } }}
                  endAdornment={
                    <InputAdornment position="end">
                      {UNITS.MONETARY}
                    </InputAdornment>
                  }
                  disabled={!props.isEditable}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]:
                        props.errors["maxMicrocreditValue"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {props.errors["maxMicrocreditValue"] !== undefined && (
              <FormHelperText
                component={Box}
                color={theme.palette.warning.main + "!important"}
              >
                {props.errors["maxMicrocreditValue"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <FormGroup>
            <FormLabel>Taxa de Juro</FormLabel>
            <Controller
              name="interestRate"
              control={control}
              defaultValue=""
              component={Box}
              rules={{
                required: {
                  value: true,
                  message: "O campo é obrigatório",
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  name={name}
                  value={value}
                  onChange={(e) => {
                    onInterestRateChange(e.target.value, onChange);
                  }}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  inputProps={{ style: { textAlign: "right" } }}
                  disabled={!props.isEditable}
                  endAdornment={
                    <InputAdornment disableTypography={true} position="end">
                      {UNITS.INTEREST}
                    </InputAdornment>
                  }
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]:
                        props.errors["interestRate"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {props.errors["interestRate"] !== undefined && (
              <FormHelperText
                component={Box}
                color={theme.palette.warning.main + "!important"}
              >
                {props.errors["interestRate"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <FormGroup>
            <FormLabel>Período de carência</FormLabel>
            <Controller
              name="gracePeriod"
              control={control}
              defaultValue=""
              component={Box}
              rules={{
                required: {
                  value: true,
                  message: "O campo é obrigatório",
                },
                validate: {
                  minValue: (value) => {
                    return (
                      value >= 0 ||
                      intl.formatMessage(
                        messages.Message_Generic_ValueGreaterThanZeroField
                      )
                    );
                  },
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  name={name}
                  value={value}
                  onChange={onChange}
                  fullWidth
                  autoComplete="off"
                  type="number"
                  inputProps={{ style: { textAlign: "right" } }}
                  disabled={!props.isEditable}
                  endAdornment={
                    <InputAdornment disableTypography={true} position="end">
                      {UNITS.MONTHS}
                    </InputAdornment>
                  }
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]:
                        props.errors["gracePeriod"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {props.errors["gracePeriod"] !== undefined && (
              <FormHelperText
                component={Box}
                color={theme.palette.warning.main + "!important"}
              >
                {props.errors["gracePeriod"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <FormGroup>
            <FormLabel>Período de reembolso</FormLabel>
            <Controller
              name="repaymentPeriod"
              control={control}
              defaultValue=""
              component={Box}
              rules={{
                required: {
                  value: true,
                  message: "O campo é obrigatório",
                },
                validate: {
                  minValue: (value) => {
                    return (
                      value >= 0 ||
                      intl.formatMessage(
                        messages.Message_Generic_ValueGreaterThanZeroField
                      )
                    );
                  },
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  name={name}
                  value={value}
                  onChange={onChange}
                  fullWidth
                  autoComplete="off"
                  type="number"
                  inputProps={{ style: { textAlign: "right" } }}
                  disabled={!props.isEditable}
                  endAdornment={
                    <InputAdornment disableTypography={true} position="end">
                      {UNITS.MONTHS}
                    </InputAdornment>
                  }
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]:
                        props.errors["repaymentPeriod"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {props.errors["repaymentPeriod"] !== undefined && (
              <FormHelperText
                component={Box}
                color={theme.palette.warning.main + "!important"}
              >
                {props.errors["repaymentPeriod"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
}

export default MicrocreditFields;
