import { DIALOG } from "utils/actionsConsts";

const dialogsReducer = (state = {}, action) => {
  switch (action.type) {
    case DIALOG.HIDE:
      return { ...state, showGenericDialog: action.payload };
    case DIALOG.SHOW:
      console.log("DIALOG.SHOW", action.payload);
      return {
        ...state,
        showGenericDialog: action.payload.showGenericDialog,
        genericDialogData: action.payload.genericDialogData,
      };
    default:
      return state;
  }
};

export default dialogsReducer;