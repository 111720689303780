import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { labels, titles } from "resources/resources";
import {
  getDataSetByNameAction,
  getWarehousesByProvinceAction,
  getCategoriesAction,
} from "redux/actions/datasetsAction";
import { searchProfessionalKitsAction } from "redux/actions/stockAction";
import { searchProfessionalKitsMap } from "mappers/searchProfessionalKitsMap";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import { Card, CardHeader, CardContent } from "@material-ui/core";

import GenericButton from "components/Buttons/GenericButton";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import GenericFormSelect from "components/FormFields/GenericFormSelect";
import { DATASET, PAGINATION } from "utils/consts";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function Filter(props) {
  const history = useHistory();
  const intl = useIntl();
  const classes = useStylesGeneric();

  const [filter, setFilter] = useState({
    filter: {
      categoryId: null,
      active: null,
      professionalKitId: null,
    },
  });
  const [filterCounter, setFilterCounter] = useState(0);

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  const cleanFilter = () => {
    setValue("categoryId", undefined);
    setValue("statusId", undefined);

    setFilterCounter(0);
    setFilter({
      filter: {
        categoryId: null,
        active: null,
        professionalKitId: null,
      },
    });
  };

  const onSubmit = (data) => {
    if (isValid) {
      let filter = searchProfessionalKitsMap(data);
      filter["pageIndex"] = 1;
      filter["pageSize"] = PAGINATION.PAGE_SIZE;
      console.log(filter);
      setFilter(filter);
      if (props.page === 1) props.searchProfessionalKits(filter);
      else props.handleChangePage(1);
    }
  };

  const handleSetFilter = (filterKey, value, clearChildKeyList) => {
    const newFilter = { ...filter };

    newFilter.filter[filterKey] = value;

    clearChildKeyList?.forEach((child) => {
      newFilter.filter[child] = null;
    });

    setFilter(newFilter);
  };

  const redirectToKitCreation = (event) => {
    event.preventDefault();
    history.push("/admin/characterize-kit");
  };

  useEffect(() => {
    let count = 0;

    Object.keys(filter.filter).map((key) => {
      if (
        filter.filter[key] !== undefined &&
        filter.filter[key] !== null &&
        key !== "pageIndex" &&
        key !== "pageSize"
      ) {
        count += 1;
      }

      return count;
    });

    setFilterCounter(count);
  }, [filter]);

  useEffect(() => {
    let temp_filter = filter;
    Object.assign(temp_filter, filter);
    temp_filter["pageIndex"] = props.page;
    temp_filter["pageSize"] = PAGINATION.PAGE_SIZE;
    props.searchProfessionalKits(temp_filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page]);

  useEffect(() => {
    if (props.dataSetState[DATASET.PROVINCES] === undefined || props.dataSetState[DATASET.PROVINCES] === []) {
      props.getDataSet(DATASET.PROVINCES);
    }
    if (props.dataSetState[DATASET.CATEGORIES] === undefined || props.dataSetState[DATASET.CATEGORIES] === []) {
      props.getCategories(DATASET.CATEGORIES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={
          <div>
            <div className={classes.sideBySideDiv}>
              {intl.formatMessage(titles.Title_Admin_ProfessionalKitsManagement)}
            </div>
            <div className={classes.sideBySideDiv + " " + classes.cardElementsCount}>
              Filtros {"("}
              {filterCounter}
              {")"}
            </div>
          </div>
        }
        action={
          <GenericButton typeSubmit={false} color="tertiary" onClick={redirectToKitCreation}>
            {intl.formatMessage(titles.Title_AdminNavBar_ProfessionalKitsMenu_CharacterizeKitMenuItem)}
          </GenericButton>
        }
      ></CardHeader>
      <CardContent>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container classes={{ root: classes.containerPadding }}>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>Especialidade</FormLabel>
                  <GenericFormSelect
                    control={control}
                    fieldName="categoryId"
                    selectOptions={props.dataSetState[DATASET.CATEGORIES] ? props.dataSetState[DATASET.CATEGORIES] : []}
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"categoryId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <FormGroup>
                  <FormLabel>Estado</FormLabel>
                  <GenericFormSelect
                    control={control}
                    fieldName="statusId"
                    selectOptions={[
                      {
                        code: true,
                        label: intl.formatMessage(labels.Label_Generic_Active),
                      },
                      {
                        code: false,
                        label: intl.formatMessage(labels.Label_Generic_Inactive),
                      },
                    ]}
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"active"}
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={12} classes={{ root: classes.alignRight }}>
                <Box>
                  <GenericButton
                    typeSubmit={false}
                    color={"ghost"}
                    onClick={() => {
                      cleanFilter();
                    }}
                  >
                    {intl.formatMessage(labels.Label_ClearFilters)}
                  </GenericButton>
                  <GenericButton
                    typeSubmit={true}
                    color="primary"
                    loading={props.stockState.searchProfessionalKit_loading}
                  >
                    {intl.formatMessage(labels.Label_FilterSection_SearchButton)}
                  </GenericButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getWarehousesByProvince: (name, province) => dispatch(getWarehousesByProvinceAction(name, province)),
  getCategories: (name) => dispatch(getCategoriesAction(name)),
  searchProfessionalKits: (formData) => dispatch(searchProfessionalKitsAction(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
