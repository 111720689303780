import { REPORT_TYPES } from "utils/consts";

export function searchStocksMap(formData) {
    if (formData !== null && formData !== undefined)
        return {
            CategoryId: formData.categoryId ? formData.categoryId.code : undefined,
            ProvinceId: formData.provinceId ? formData.provinceId.code : undefined,
            WarehouseId: formData.warehouseId ? formData.warehouseId.code : undefined,
        };
    return formData;
}

export function stocksReportMap(filterData) {
    if (filterData !== null && filterData !== undefined) {
      return {
        reportEnumKey: REPORT_TYPES.STOCKS_SEARCH_RESULTS,
        parameters: JSON.stringify(filterData),
      };
    }
  }
  

export default searchStocksMap