import React from "react";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { labels } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function DisplayField(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();

  return (
    <Grid item xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl} style={{wordBreak: 'break-word'}}>
      <FormGroup>
        <FormLabel className={classes.detailFormLabels}>
          {props.label}
        </FormLabel>
        <Box className={classes.detailLabels} data-testid={"display-field-input-" + props.id}>
          {props?.data?.toString() 
            ? props?.data?.toString()
            : intl.formatMessage(labels.Label_Not_Applicable)}
        </Box> 
      </FormGroup>
    </Grid>
  ); 
}

export default DisplayField;
