const componentStyles = (theme) => ({
  sentIcon: {
    color: theme.palette.primary.mediumBlue,
  },
  dividerRoot: {
    color: theme.palette.info.grey,
    width: "2px",
  },
  readNotificationDividerRoot: {
    backgroundColor: theme.palette.primary.main + "!important",
  },
  AccordionRoot: {
    width: "100%",
  },
  cellTextRoot: {
    marginTop: "7px",
    fontStyle: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.palette.primary.main,
  },
  cellTitleTextRoot: {
    fontStyle: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.palette.gray[600],
    fontWeight: "bold",
  },
  typographyOrigin: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    color: theme.palette.gray[600],
  },
  typographySentBy: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    color: theme.palette.gray[600],
  },
  typographySubject: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    color: theme.palette.gray[700],
  },
  typographyMessage: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    color: theme.palette.gray[700],
  },
});

export default componentStyles;
