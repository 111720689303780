import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
//MaterialUI
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import { Typography } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import limitaString from "utils/string";
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import Tooltip from "@material-ui/core/Tooltip";
import Pagination from "@material-ui/lab/Pagination";
import Rating from '@material-ui/lab/Rating';

import TableStateChip from "components/Cards/Tables/TableStateChip";
import {
  OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY,
  FOLLOWUP_STATE_ID_QUERY,
  TABLE_STATE_CHIP_COLOR,
  PAGINATION,
} from "utils/consts";
import { getAllOpportunityApplicationsFollowUpAction } from "redux/actions/opportunitiesAction";
import { titles, labels } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function MyOpportunityApplicationsFollowUpsTab(props) {
  const theme = useTheme();
  const classes =  useStylesGeneric();
  const intl = useIntl();
  const [page, setPage] = useState(1);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const tableHead = [
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_FollowUpsSection_ApplicationCode),
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_FollowUpsSection_Company),
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_FollowUpsSection_OpportunityType),
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_FollowUpsSection_State),
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_FollowUpsSection_OverallEvaluation)
  ];

  const mapOpportunityFollowUpStateToTableChipColors = (stateKey) => {
    switch (stateKey) {
      case OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY.PORINICIAR:
        return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE;
      case OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY.ADECORRER:
        return TABLE_STATE_CHIP_COLOR.GREEN;
      case OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY.TERMINADO:
        return TABLE_STATE_CHIP_COLOR.RED;
      default:
        return <></>;
    }
  };

  useEffect(() => {
    let filterData = {
      candidateFollowUpStateId : props.followUpState,
      pageIndex: page <= 0 ? 1 : page,
      pageSize: PAGINATION.PAGE_SIZE,
      userId: props?.usersState?.getFOUserOverview_result?.id
    };

    props.getAllOpportunityApplicationsFollowUp(filterData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, props.followUpState]);

  useEffect(() => {
    handleChangePage(undefined, PAGINATION.FIRST_PAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.followUpState]);

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          classes={{
            root: classes.cardHeader,
            action: classes.cardHeaderAction,
          }}
          title={
            <Typography style={{ fontWeight: "bold" }}>
              {intl.formatMessage(titles.Title_UserOverview_ApplicationsTab_FollowUpsSection)} {" "}
              {props.followUpState === FOLLOWUP_STATE_ID_QUERY.ONGOING && intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_FollowUpsSection_OnGoing)}{" "}
              {props.followUpState === FOLLOWUP_STATE_ID_QUERY.FINISHED && intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_FollowUpsSection_Finished)}
            </Typography>
          }
          action={
            <Typography
              variant="h4"
              className={classes.cardHeaderActionTypography}
            >
              {intl.formatMessage(labels.Label_Total)}: {props.opportunitiesState?.opportunityApplicationsFollowUp_total_records}
            </Typography>
          }
        ></CardHeader>

        <TableContainer>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead>
              <TableRow>
                {tableHead.map((prop, key) => (
                  <TableCell
                    key={key}
                    classes={{
                      root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                    data-testid={"user-overview-followups-section-table-header-" + key}
                  >
                    {prop}
                  </TableCell>
                ))}
                <TableCell
                  key="detailsCol"
                  classes={{
                    root:
                      classes.tableCellRoot + " " + classes.tableCellRootHead + " " + classes.tableCellHeadStickyRight,
                  }}
                  data-testid="user-overview-followups-section-table-header-details"
                >
                  {intl.formatMessage(labels.Label_Details)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.opportunitiesState.opportunityApplicationsFollowUp_result?.data?.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell classes={{ root: classes.tableCellRoot }}  title={prop.opportunityTitle ?? ""}>
                    <Box>{prop.candidateCode}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box fontWeight={"bold"}>NIF: {prop.companyTaxIdNumber}</Box>
                    <Box>{limitaString(prop.companyName, 60)}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box fontWeight={"bold"}>{prop.opportunityTypeDescription}</Box>
                    <Box>{limitaString(prop.opportunityTitle, 60)}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <TableStateChip
                      state={mapOpportunityFollowUpStateToTableChipColors(prop.candidateFollowUpStateKey)}
                      message={prop.candidateFollowUpStateDescription}
                    />
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box>
                      <Rating 
                        classes={{ icon: classes.overallRatingIcon }} 
                        value={Number(prop.candidateOverallEvaluation)} 
                        precision={0.5} 
                        readOnly
                        data-testid="user-overview-followups-section-evaluation" 
                      />
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight }}>
                    <Tooltip title="Detalhes">
                      <IconButton
                        aria-label="details"
                        component={Link}
                        to={`/admin/opportunity-applications-followup-details/${prop.candidateCode}`}
                        data-testid="user-overview-followups-section-details-button"
                      >
                        <Box
                          component={ArrowForwardIosIcon}
                          width="12px!important"
                          height="12px!important"
                          color={theme.palette.primary.main}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Box>
          <TableBackdrop open={props.opportunitiesState?.getOpportunityApplicationsFollowUpDetails_loading} />
        </TableContainer>

        <Box classes={{ root: classes.cardActionsRoot }} component={CardActions} justifyContent="flex-end"></Box>
      </Card>

      <Pagination
        className={classes.alignPagination}
        count={props.opportunitiesState?.opportunityApplicationsFollowUp_total_pages}
        page={page}
        onChange={handleChangePage}
        color="primary"
      ></Pagination>
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getAllOpportunityApplicationsFollowUp: (data) => dispatch(getAllOpportunityApplicationsFollowUpAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyOpportunityApplicationsFollowUpsTab);
