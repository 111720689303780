export function updateOpportunityStateMap(formData) {
    if (formData !== null && formData !== undefined) {
        return {
            opportunityId: formData.id,
            actionType: formData.actionType,
            opportunityStateMotiveKey: formData.opportunityStateMotiveKey,
            observations: formData.observations
        };
    }
    return formData;
}

export default updateOpportunityStateMap;
