import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import ReactQuill from "react-quill";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Switch from "@material-ui/core/Switch";
// @material-ui/icons components

//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import CarouselMediaPreviewSection from "components/GenericPage/CarouselMediaPreviewSection";
import GenericButton from "components/Buttons/GenericButton";
import "react-quill/dist/quill.snow.css";
import {
  updateInformationPublishAction,
  updateInformationHighlightedAction,
} from "redux/actions/informationDisseminationAction";
import { labels } from "resources/resources";
import { INFORMATION_DISSEMINATION_TYPE_KEYVALUE } from "utils/consts";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function DetailsSection(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const history = useHistory();
  const [statePublished, setStatePublished] = useState(false);
  const [stateHighlighted, setStateHighlighted] = useState(false);
  const [stateDescription, setStateDescription] = useState(null);

  useEffect(() => {
    setStatePublished(
      props.informationDetailsData !== undefined && props.informationDetailsData?.published !== undefined
        ? props.informationDetailsData.published
        : false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.informationDetailsData?.published]);

  useEffect(() => {
    setStateHighlighted(
      props.informationDetailsData !== undefined && props.informationDetailsData?.isHighlighted !== undefined
        ? props.informationDetailsData.isHighlighted
        : false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.informationDetailsData?.isHighlighted]);

  useEffect(() => {
    setStateDescription(
      props.informationDetailsData !== undefined && props.informationDetailsData?.description !== undefined
        ? props.informationDetailsData.description
        : null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.informationDetailsData?.description]);

  const handleChangePublished = (data, event) => {
    setStatePublished(event.target.checked);
    let publishData = { alternateKey: data, published: event.target.checked };

    props.updateInformationPublish(publishData, function () {
      //errorFunction to undo switch pressing
      setStatePublished(!publishData.published);
    });
  };

  const handleChangeHighlighted = (data, event) => {
    setStateHighlighted(event.target.checked);
    let highlightedData = { alternateKey: data, highlighted: event.target.checked };

    props.updateInformationHighlighted(highlightedData, function () {
      //errorFunction to undo switch pressing
      setStateHighlighted(!highlightedData.highlighted);
    });
  };

  return (
    <>
      <Container maxWidth={false}>
        <Card className={classes.cardRoot}>
          <CardHeader
            classes={{ title: classes.cardHeader, action: classes.cardHeaderAction }}
            title={intl.formatMessage(labels.Label_Admin_InformationDissemination_InformationData)}
            action={
              <Grid container>
                <Grid item md={5} xs={10} className={classes.cardHeaderActionGrid}>
                  <Typography variant="body2" style={{ whiteSpace: "nowrap", marginRight: "5px" }}>
                    {stateHighlighted
                      ? intl.formatMessage(labels.Label_Admin_InformationDissemination_Highlighted)
                      : intl.formatMessage(labels.Label_Admin_InformationDissemination_NotHighlighted)}
                  </Typography>
                  <Switch
                    name="infoHighlighted"
                    checked={stateHighlighted}
                    onChange={(e) => {
                      handleChangeHighlighted(props.informationDetailsData.alternateKey, e);
                    }}
                    color="primary"
                  />
                </Grid>
                <Grid item md={4} xs={10} className={classes.cardHeaderActionGrid}>
                  <Typography variant="body2" style={{ whiteSpace: "nowrap", marginRight: "5px" }}>
                    {statePublished
                      ? intl.formatMessage(labels.Label_Admin_InformationDissemination_Published)
                      : intl.formatMessage(labels.Label_Admin_InformationDissemination_NotPublished)}
                  </Typography>
                  <Switch
                    name="infoPublish"
                    checked={statePublished}
                    onChange={(e) => {
                      handleChangePublished(props.informationDetailsData.alternateKey, e);
                    }}
                    color="primary"
                  />
                </Grid>
                <Grid item md={3} xs={7} className={classes.cardHeaderActionGrid}>
                  <GenericButton
                    typeSubmit={false}
                    color="tertiary"
                    size="small"
                    onClick={() => {
                      history.push(
                        `/admin/informationdissemination-edit/${props?.informationDetailsData?.alternateKey}`
                      );
                    }}
                  >
                    {intl.formatMessage(labels.Label_Generic_Edit)}
                  </GenericButton>
                </Grid>
              </Grid>
            }
          ></CardHeader>

          <CardContent style={{ padding: "0 0 24px 0" }}>
            <Grid container className={classes.justifyContentCenter}>
              <Grid item xs={12} md={12}>
                <CarouselMediaPreviewSection
                  mediaList={props.informationDetailsData?.mediaList}
                  mediaItemPhotoName="photo"
                  mediaItemVideoName="url"
                />
              </Grid>
            </Grid>
            <Grid container style={{ padding: "30px" }}>
              <Grid item xs={12}>
                <Box style={{ paddingBottom: "40px" }}>
                  <Typography variant="h3">{props.informationDetailsData?.title}</Typography>
                  <Box>
                    <ReactQuill readOnly={true} style={{ minHeight: "6rem" }} theme="bubble" value={stateDescription} />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel>{intl.formatMessage(labels.Label_Admin_InformationDissemination_CreatedBy)}</FormLabel>
                  <Box>{props.informationDetailsData?.createdBy}</Box>
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel>{intl.formatMessage(labels.Label_Admin_InformationDissemination_PublishedBy)}</FormLabel>
                  <Box>
                    {props.informationDetailsData?.publishedBy ?? intl.formatMessage(labels.Label_Not_Applicable)}
                  </Box>
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel>{intl.formatMessage(labels.Label_Admin_InformationDissemination_PublishedDate)}</FormLabel>
                  <Box>
                    {props.informationDetailsData?.publishedOn ?? intl.formatMessage(labels.Label_Not_Applicable)}
                  </Box>
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_InformationDissemination_InformationCategory)}
                  </FormLabel>
                  <Box>
                    {props.informationDetailsData?.informationDisseminationTypeDescription}
                  </Box>
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_InformationDissemination_Authentication)}
                  </FormLabel>
                  <Box>
                    {props.informationDetailsData?.isPublic
                      ? intl.formatMessage(labels.Label_No)
                      : intl.formatMessage(labels.Label_Yes)}
                  </Box>
                </FormGroup>
              </Grid>
              <Grid item xs={12}></Grid>
              {props.informationDetailsData?.informationDisseminationTypeKeyValue === INFORMATION_DISSEMINATION_TYPE_KEYVALUE.STRUCTURING_PROJECT && (
                (props.informationDetailsData?.socialMediaList?.map((socialMedia, key) => (
                  <Grid item xs={12} md={4} key={key}>
                    <FormGroup>
                      <FormLabel>
                        {intl.formatMessage(labels.Label_Admin_InformationDissemination_SocialMediaLink)} {socialMedia.sociaMediaTypeKeyValue}
                      </FormLabel>
                      <Box>
                        {socialMedia.url}
                      </Box>
                    </FormGroup>
                  </Grid>
                )))
              )}
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  updateInformationPublish: (data, onError) => dispatch(updateInformationPublishAction(data, onError)),
  updateInformationHighlighted: (data, onError) => dispatch(updateInformationHighlightedAction(data, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailsSection);
