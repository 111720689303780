export const checkOperationPermission = (operationList, operationKey) => {
  if (operationList !== undefined && operationList?.length > 0) {
    return operationList.some((item) => item.operationKey === operationKey);
    // console.log(operationList.some((item) => item.operationKey === operationKey));
  }
  return false;
};

// export const getOperationPath = (operationList, operationKey) => {
//   if (operationList !== undefined && operationList?.length > 0) {
//     return operationList.some((item) => item.operationKey === operationKey);
//     // console.log(operationList.some((item) => item.operationKey === operationKey));
//   }
//   return false;
// };
