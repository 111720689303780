const componentStyles = (theme) => ({
  titleGrid: {
    display: "flex"
  },
  container: {
    marginBottom: "37px",
    paddingLeft: "20px",
    paddingRight: "20px",
    height: "44px",
    backgroundColor: theme.palette.primary.darkBlue,
  },
  subsection: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.white.main,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.white.main,
      fontWeight: "600",
      textDecorationLine: "none",
    }
  },
  separator: {
    fontFamily: "FontAwesome",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.white.main,
    marginLeft: "12px",
    marginRight: "12px",
  },
  section: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.mediumBlue,
  }
});

export default componentStyles;
