import { NOTIFICATION } from "utils/actionsConsts";

const notificationReducer = (
  state = {
    notifications_list: [],
    unreadNotifications: [],
    unreadNotificationsCount: 0,
    unreadNotifications_Loading: false,
    notifications_Loading: false,
    total_records: 0,
    total_pages: 0,
  },
  action
) => {
  switch (action.type) {
    case NOTIFICATION.CREATE:
      return { ...state, result: action.payload };
    case NOTIFICATION.CREATE_LOADING:
      return { ...state, create_loading: action.payload };
    case NOTIFICATION.GET_NOTIFICATIONS:
      return {
        ...state,
        notifications_list: action.payload.data,
        total_records: action.payload.total_records,
        total_pages: action.payload.total_pages,
      };            
    case NOTIFICATION.GET_NOTIFICATIONS_LOADING:
      return { ...state, notifications_Loading: action.payload };
    case NOTIFICATION.GET_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        unreadNotifications: action.payload.notifications,
        unreadNotificationsCount: action.payload.count,
      };
    case NOTIFICATION.GET_UNREAD_NOTIFICATIONS_LOADING:
      return { ...state, unreadNotifications_Loading: action.payload };
    case NOTIFICATION.GET_TOTAL_UNREAD_NOTIFICATIONS:
      return { ...state, unreadNotificationsCount: action.payload };
    case NOTIFICATION.UPDATE_NOTIFICATION:
      return {
        ...state,
        notifications_list: state.notifications_list.map((p) =>
          p.id === action.payload?.id
            ? {
                ...p,
                isRead: true,
              }
            : p
        ),
        unreadNotificationsCount: state.unreadNotificationsCount-1,
      };
    default:
      return state;
  }
};

export default notificationReducer;