import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";

import GenericButton from "components/Buttons/GenericButton.js";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import EnterpriseUserData from "./Sections/EnterpriseUserData";
import { showGenericTwoButtonDialogAction } from "redux/actions/dialogsAction";
import {
  updateEnterpriseUserAction,
  getEnterpriseUserInfoAction,
  validateColectiveUserAction,
} from "redux/actions/usersAction";
import frontOfficeEnterpriseUserUpdateMap from "mappers/frontOfficeEnterpriseUserUpdateMap";
import { labels } from "resources/resources";
import useScrollDirection from "hooks/useScrollDirection";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
const useStylesGeneric = makeStyles(componentStylesGeneric);

function EditEnterpriseUser(props) {
  const classes = useStylesGeneric();
  const history = useHistory();
  const intl = useIntl();
  const scrollDirection = useScrollDirection();
  const [oldEmail, setOldEmail] = useState("");
  const [oldMobilePhone, setOldMobilePhone] = useState("");
  const methods = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    props.getEnterpriseUserInfo(props.match.params.userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var emailChanged = "";
  function handleRegister(data) {
    const email = data.email;
    const mobilePhone = data.mobilePhone;

    if (email !== oldEmail || mobilePhone !== oldMobilePhone)
      props.showDialog(
        "Pretende continuar?",
        "Esta ação vai forçar a confirmação do telemóvel/email do utilizador.",
        function () {
          return props.updateEnterpriseUser(
            frontOfficeEnterpriseUserUpdateMap(data, emailChanged, props.match.params.userId)
          );
        }
      );
    else props.updateEnterpriseUser(frontOfficeEnterpriseUserUpdateMap(data, emailChanged, props.match.params.userId));
  }

  const handleValidateColectiveUserEvent = () => {
    props.validateColectiveUser({ userId: props.match.params.userId });
  };

  const cancelOnClick = () => {
    history.goBack();
  };

  return (
    <>
      <GenericBackdrop open={props.usersInfo.getUserInfo_loading} />

      {scrollDirection === "up" && (
        <div className={classes.breadcrumbs}>
          <LocationHeader
            section={"Detalhes Utilizador FrontOffice"}
            subsection={"Gestão de Utilizadores FrontOffice"}
            subsectionLink={"/admin/frontofficeusers-management"}
          />
        </div>
      )}

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleRegister)}>
          <Container maxWidth={false} component={Box} classes={{ root: classes.containerRootAfterBreadcrumbs }}>
            <PageTitleHeader title={"Detalhes Utilizador FrontOffice"} />

            <Container maxWidth={false} component={Box}>
              <EnterpriseUserData
                userId={props.match.params.userId}
                isEditable={false}
                setOldEmail={setOldEmail}
                setOldMobilePhone={setOldMobilePhone}
              />

              <Grid container>
                <Grid item xs={12} lg={6} xl={8}></Grid>
                <Grid item xs={12} lg={3} xl={2}>
                  <FormGroup>
                    <GenericButton GenericButton typeSubmit={false} color="secondary" onClick={() => cancelOnClick()}>
                      {intl.formatMessage(labels.Label_Generic_Cancel)}
                    </GenericButton>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} lg={3} xl={2}>
                  {props.usersInfo.result?.userColective?.approved ? (
                    <FormGroup>
                      <GenericButton
                        typeSubmit={true}
                        color="primary"
                        loading={props.usersInfo.updateIndividualUser_loading}
                      >
                        {intl.formatMessage(labels.Label_Update_Submit)}
                      </GenericButton>
                    </FormGroup>
                  ) : (
                    <FormGroup>
                      <GenericButton
                        typeSubmit={false}
                        color="primary"
                        loading={props.usersInfo.validateColectiveUser_loading}
                        onClick={handleValidateColectiveUserEvent}
                      >
                        Validar Utilizador
                      </GenericButton>
                    </FormGroup>
                  )}
                </Grid>
              </Grid>
            </Container>
          </Container>
        </form>
      </FormProvider>
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  updateEnterpriseUser: (data) => dispatch(updateEnterpriseUserAction(data)),
  getEnterpriseUserInfo: (id) => dispatch(getEnterpriseUserInfoAction(id)),
  validateColectiveUser: (Id) => dispatch(validateColectiveUserAction(Id)),
  showDialog: (message, yesAction, noAction) =>
    dispatch(showGenericTwoButtonDialogAction(message, yesAction, noAction)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditEnterpriseUser);
