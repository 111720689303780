import React from "react";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.white.main,
  },
}));

export default function GenericBackdrop(props) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Backdrop
      className={classes.backdrop}
      open={props.open ?? false}
    >
      <CircularProgress style={{ color: theme.palette.primary.main }} />
    </Backdrop>
  );
}
