import Dashboard from "views/admin/Homepage/Dashboard.js";
import Notifications from "views/admin/Components/Notifications.js";
import Elements from "views/admin/Forms/Elements.js";
import Components from "views/admin/Forms/Components.js";
import Validation from "views/admin/Forms/Validation.js";
import Login from "views/auth/Login.js";
import ResetPassword from "views/auth/ResetPassword";
// @material-ui/icons components
import BubbleChart from "@material-ui/icons/BubbleChart";
import Dns from "@material-ui/icons/Dns";
import FlashOn from "@material-ui/icons/FlashOn";
import House from "@material-ui/icons/House";
import ListAlt from "@material-ui/icons/ListAlt";
import Palette from "@material-ui/icons/Palette";
import PermMedia from "@material-ui/icons/PermMedia";
import Applications from "views/admin/Applications/Applications";
import ApplicationDetails from "views/admin/Applications/ApplicationDetail";
import SubPrograms from "views/admin/Subprogram/Subprograms";
import SubProgramCreate from "views/admin/Subprogram/Create";
import SubProgramDetails from "views/admin/Subprogram/SubprogramDetails";
import CharacterizeKit from "views/admin/ProfessionalKits/CharacterizeKit/CharacterizeKit";
import KitDetails from "views/admin/ProfessionalKits/CharacterizeKit/KitDetails";
import ProfessionalKits from "views/admin/ProfessionalKits/KitsManagement/ProfessionalKits";
import Stocks from "views/admin/ProfessionalKits/Stocks/Stocks";
import StockDetails from "views/admin/ProfessionalKits/Stocks/Details/StockDetails";

import UsersBackOffice from "views/admin/UserManagement/BackOffice/UsersBackOffice";
import UsersFrontOffice from "views/admin/UserManagement/FrontOffice/UsersFrontOffice";
import BackOfficeUserCreation from "views/admin/UserManagement/BackOffice/BackOfficeUserCreation";
import CreateIndividualUser from "views/admin/UserManagement/FrontOffice/IndividualUser/CreateIndividualUser";
import CreateEnterpriseUser from "views/admin/UserManagement/FrontOffice/EnterpriseUser/CreateEnterpriseUser";
import EditIndividualUser from "views/admin/UserManagement/FrontOffice/IndividualUser/EditIndividualUser";
import EditEnterpriseUser from "views/admin/UserManagement/FrontOffice/EnterpriseUser/EditEnterpriseUser";
import EditBackOfficeUser from "views/admin/UserManagement/BackOffice/EditBackOfficeUser";

import UserProfile from "views/admin/UserArea/UserProfile";
import Loans from "views/admin/Loans/Loans";
import LoanDetails from "views/admin/Loans/LoanDetails";

import InformationDissemination from "views/admin/InformationDissemination/InformationDissemination";
import InformationDisseminationCreate from "views/admin/InformationDissemination/Create";
import InformationDisseminationEdit from "views/admin/InformationDissemination/Edit";
import InformationDisseminationDetails from "views/admin/InformationDissemination/Details";

import OpportunitiesList from "views/admin/Opportunities/Opportunities/OpportunitiesList";
import OpportunityApplicationsList from "views/admin/Opportunities/OpportunityApplications/OpportunityApplicationsList";
import OpportunityApplicationsFollowUpList from "views/admin/Opportunities/OpportunityApplicationsFollowUp/OpportunityApplicationsFollowUpList";
import OpportunityApplicationFollowUpDetails from "views/admin/Opportunities/OpportunityApplicationsFollowUp/OpportunityApplicationFollowUpDetails";
import OpportunityCreate from "views/admin/Opportunities/Opportunities/OpportunityCreate";
import OpportunityDetails from "views/admin/Opportunities/Opportunities/OpportunityDetails";
import CandidateDetails from "views/admin/Opportunities/OpportunityCandidates/CandidateDetails";

import UserOverview from "views/admin/UserOverview/UserOverview";

import Schedules from "views/admin/Schedules/Schedules";
import SchedulesDetails from "views/admin/Schedules/SchedulesDetails";
import QuickGuidesPage from "views/admin/Components/QuickGuidesPage";
import NavbarAdvancedSearch from "views/admin/AdvancedSearch/NavbarAdvancedSearch";

var routes = [
  {
    collapse: true,
    name: "Dashboards",
    icon: House,
    iconColor: "Primary",
    state: "dashboardsCollapse",
    views: [
      {
        path: "/dashboard",
        name: "Dashboard",
        miniName: "D",
        component: Dashboard,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "SearchBars",
    icon: House,
    iconColor: "Primary",
    state: "searchCollapse",
    views: [
      {
        path: "/advanced-search",
        name: "NavbarAdvancedSearch",
        miniName: "S",
        component: NavbarAdvancedSearch,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Opportunity",
    icon: House,
    iconColor: "Primary",
    state: "OpportunityCollapse",
    views: [
      {
        path: "/opportunity-management/:opportunityState?",
        name: "Emprego e Estágio Professional",
        miniName: "M",
        component: OpportunitiesList,
        layout: "/admin",
      },
      {
        path: "/opportunity-applications-management",
        name: "Candidaturas a Emprego e Estágio Professional",
        miniName: "M",
        component: OpportunityApplicationsList,
        layout: "/admin",
      },
      {
        path: "/opportunity-applications-followup-management/:hasUnapprovedFeedbackReport?",
        name: "Acompanhamento de candidaturas a Emprego e Estágio Professional",
        miniName: "M",
        component: OpportunityApplicationsFollowUpList,
        layout: "/admin",
      },
      {
        path: "/opportunity-create",
        name: "Criação",
        miniName: "M",
        component: OpportunityCreate,
        layout: "/admin",
      },
      {
        path: "/opportunity-details/:opportunityId",
        name: "Detalhes",
        miniName: "D",
        component: OpportunityDetails,
        layout: "/admin",
      },
      {
        path: "/candidate-details/:alternateKey",
        name: "Detalhes",
        miniName: "D",
        component: CandidateDetails,
        layout: "/admin",
      },
      {
        path: "/opportunity-applications-followup-details/:candidateCode",
        name: "Acompanhamento de candidaturas a Emprego e Estágio Professional",
        miniName: "M",
        component: OpportunityApplicationFollowUpDetails,
        layout: "/admin",
      },      
    ],
  },
  {
    collapse: true,
    name: "Loans",
    icon: House,
    iconColor: "Primary",
    state: "loansCollapse",
    views: [
      {
        path: "/loans-management",
        name: "Empréstimos",
        miniName: "D",
        component: Loans,
        layout: "/admin",
      },
      {
        path: "/loan-detail/:loanId",
        name: "Detalhes do Empréstimo",
        miniName: "L",
        component: LoanDetails,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "InformationDissemination",
    icon: House,
    iconColor: "Primary",
    state: "informationDisseminationCollapse",
    views: [
      {
        path: "/informationdissemination-management",
        name: "Criação Informação",
        miniName: "M",
        component: InformationDissemination,
        layout: "/admin",
      },
      {
        path: "/informationdissemination-create",
        name: "Criar",
        miniName: "C",
        component: InformationDisseminationCreate,
        layout: "/admin",
      },
      {
        path: "/informationdissemination-edit/:infoKey",
        name: "Editar",
        miniName: "E",
        component: InformationDisseminationEdit,
        layout: "/admin",
      },
      {
        path: "/informationdissemination-details/:infoKey",
        name: "Detalhes",
        miniName: "D",
        component: InformationDisseminationDetails,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Agendamentos",
    icon: House,
    iconColor: "Primary",
    state: "agendamentosCollapse",
    views: [
      {
        path: "/schedules-management/:applicationNumber?",
        name: "Agendamentos",
        miniName: "A",
        component: Schedules,
        layout: "/admin",
      },
      {
        path: "/agendamento/:scheduleNumber",
        name: "Detalhes do Agendamento",
        miniName: "L",
        component: SchedulesDetails,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Examples",
    icon: PermMedia,
    iconColor: "Warning",
    state: "examplesCollapse",
    invisible: true,
    views: [
      {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/ResetPassword",
        name: "ResetPassword",
        miniName: "RP",
        component: ResetPassword,
        layout: "/auth",
      },
    ],
  },
  {
    path: "/notifications",
    name: "Notifications",
    miniName: "N",
    component: Notifications,
    layout: "/admin",
  },
  {
    path: "/quick-guides",
    name: "QuickGuides",
    miniName: "QG",
    component: QuickGuidesPage,
    layout: "/admin",
  },
  {
    path: "/myProfile",
    name: "My Profile",
    miniName: "P",
    component: UserProfile,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Forms",
    icon: ListAlt,
    iconColor: "ErrorLight",
    state: "formsCollapse",
    views: [
      {
        path: "/elements",
        name: "Elements",
        miniName: "E",
        component: Elements,
        layout: "/admin",
      },
      {
        path: "/components",
        name: "Components",
        miniName: "C",
        component: Components,
        layout: "/admin",
      },
      {
        path: "/validation",
        name: "Validation",
        miniName: "V",
        component: Validation,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Candidaturas",
    icon: House,
    iconColor: "Primary",
    state: "applicationsCollapse",
    views: [
      {
        path: "/applications-management/:numBI?",
        name: "Gestão de Candidaturas",
        miniName: "L",
        component: Applications,
        layout: "/admin",
      },
      {
        path: "/application-detail/:applicationNumber",
        name: "Detalhes da Candidatura",
        miniName: "D",
        component: ApplicationDetails,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Gestão Utilizadores",
    icon: House,
    iconColor: "Primary",
    state: "userManagement",
    views: [
      {
        path: "/backofficeusers-management",
        name: "Gestão de utilizadores Backoffice",
        miniName: "L",
        component: UsersBackOffice,
        layout: "/admin",
      },
      {
        path: "/frontofficeusers-management/:typeOfUser?",
        name: "Gestão de utilizadores Frontoffice",
        miniName: "C",
        component: UsersFrontOffice,
        layout: "/admin",
      },
      {
        path: "/backofficeusers-create",
        name: "Criar utilizador Backoffice",
        miniName: "L",
        component: BackOfficeUserCreation,
        layout: "/admin",
      },
      {
        path: "/frontOfficeUsers-individual-create",
        name: "Criar utilizador Cidadão",
        miniName: "L",
        component: CreateIndividualUser,
        layout: "/admin",
      },
      {
        path: "/frontOfficeUsers-enterprise-create",
        name: "Criar utilizador Empresa",
        miniName: "L",
        component: CreateEnterpriseUser,
        layout: "/admin",
      },
      {
        path: "/frontOfficeUsers-individual-edit/:userId",
        name: "Edit Utilizador Individual",
        miniName: "E",
        component: EditIndividualUser,
        layout: "/admin",
      },
      {
        path: "/frontOfficeUsers-enterprise-edit/:userId",
        name: "Edit Utilizador Empresa",
        miniName: "E",
        component: EditEnterpriseUser,
        layout: "/admin",
      },
      {
        path: "/backofficeusers-edit/:userId",
        name: "Edit Utilizador ",
        miniName: "E",
        component: EditBackOfficeUser,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Subprogramas",
    icon: House,
    iconColor: "Primary",
    state: "subProgramsCollapse",
    views: [
      {
        path: "/subprograms-management",
        name: "Gestão de Subprogramas",
        miniName: "L",
        component: SubPrograms,
        layout: "/admin",
      },
      {
        path: "/subprograms-create",
        name: "Criar",
        miniName: "C",
        component: SubProgramCreate,
        layout: "/admin",
      },
      {
        path: "/subprograms-detail/:subprogramNumber",
        name: "Detalhes do Subprograma",
        miniName: "D",
        component: SubProgramDetails,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "ProfessionalKits",
    icon: House,
    iconColor: "Primary",
    state: "professionalKitsCollapse",
    views: [
      {
        path: "/characterize-kit",
        name: "Caracterizar Kit",
        miniName: "C",
        component: CharacterizeKit,
        layout: "/admin",
      },
      {
        path: "/kit-detail/:professionalKitId",
        name: "Detalhes do Kit",
        miniName: "D",
        component: KitDetails,
        layout: "/admin",
      },
      {
        path: "/kit-management",
        name: "Gestão de Kits",
        miniName: "L",
        component: ProfessionalKits,
        layout: "/admin",
      },
      {
        path: "/stocks-management",
        name: "Gestão de Stocks",
        miniName: "L",
        component: Stocks,
        layout: "/admin",
      },
      {
        path: "/stock-detail/:warehouseStockId",
        name: "Detalhes do Stock",
        miniName: "L",
        component: StockDetails,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "UserOverview",
    icon: House,
    iconColor: "Primary",
    state: "userOverviewCollapse",
    views: [
      {
        path: "/user-overview/:userId",
        name: "UserOverview",
        miniName: "UO",
        component: UserOverview,
        layout: "/admin",
      },
    ],
  },
  {
    divider: true,
  },
  {
    title: "Documentation",
  },
  {
    href: "https://www.creative-tim.com/learning-lab/material-ui/overview/argon-dashboard?ref=admui-admin-sidebar",
    name: "Getting started",
    icon: FlashOn,
  },
  {
    href: "https://www.creative-tim.com/learning-lab/material-ui/colors/argon-dashboard?ref=admui-admin-sidebar",
    name: "Foundation",
    icon: Palette,
  },
  {
    href: "https://www.creative-tim.com/learning-lab/material-ui/alerts/argon-dashboard?ref=admui-admin-sidebar",
    name: "Components",
    icon: Dns,
  },
  {
    href: "https://www.creative-tim.com/learning-lab/material-ui/google-maps/argon-dashboard?ref=admui-admin-sidebar",
    name: "Plugins",
    icon: BubbleChart,
  },
];
export default routes;
