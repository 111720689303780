import axios from "axios";
import { INTERNAL_API_URL } from "../index";
import { EXTERNAL_API_URL } from "../index";

export const POST_CHARACTERIZE_PROFESSIONAL_KIT_URL = "ProfessionalKits/create-professional-kit";
export const POST_UPDATE_PROFESSIONAL_KIT_URL = "ProfessionalKits/update-professional-kit";
export const POST_REGISTER_STOCK_IN_WAREHOUSE_URL = "Stock/manage-stock";
export const POST_REGISTER_LOSS_BREAK_URL = "Stock/remove-stock"
export const GET_UNIT_VALUE_BY_CATEGORY_URL = "Categories/get-category-unit-price?CategoryId=";
export const GET_BATCH_VALUE__URL = "Categories/get-batch-value?categoryId={cId}&quantity={q}";
export const GET_SEARCH_PROFESSIONAL_KITS__URL = "ProfessionalKits/get-professional-kits";
export const GET_PROFESSIONAL_KIT_DETAILS_URL = "ProfessionalKits/get-professional-kit-details";
export const POST_UPDATE_PROFESSIONAL_KIT_STATE_URL = "ProfessionalKits/update-active-professional-kit";
export const GET_SEARCH_STOCKS__URL = "Stock/get-stock";
export const GET_STOCK_DETAILS_URL = "Stock/get-stock-details";
export const GET_KIT_DELIVERY_APPLICATION_DETAILS_URL = "Stock/get-deliver-kit-application-details";
export const POST_SCHEDULE_KIT_DELIVERY_URL = "Applications/schedule-professional-kit-delivery";
export const GET_PROFESSIONAL_KIT_DELIVERY_DOCUMENT = "Applications/get-professional-kit-delivery-document?professionalKitDeliveryId=";

export function scheduleKitDelivery(kitDelivery) {
  return axios.post(INTERNAL_API_URL + POST_SCHEDULE_KIT_DELIVERY_URL, kitDelivery);
}
export function characterizeProfessionalKit(professionalKit) {
  return axios.post(INTERNAL_API_URL + POST_CHARACTERIZE_PROFESSIONAL_KIT_URL, {
    professionalKit
  });
}
export function updateProfessionalKit(professionalKit) {
  return axios.post(INTERNAL_API_URL + POST_UPDATE_PROFESSIONAL_KIT_URL, {
    professionalKit
  });
}

export function updateProfessionalKitState(professionalKit) {
  return axios.post(INTERNAL_API_URL + POST_UPDATE_PROFESSIONAL_KIT_STATE_URL, {
    professionalKitId: professionalKit
  });
}

export function getProfessionalKitDetails(kitId) {
  return axios.get(INTERNAL_API_URL + GET_PROFESSIONAL_KIT_DETAILS_URL, {
    params: {
      ProfessionalKitId: kitId
    }
  });
}

export function searchProfessionalKits(formData) {
  return axios.post(INTERNAL_API_URL + GET_SEARCH_PROFESSIONAL_KITS__URL, formData)
}

export function searchStock(formData) {
  return axios.post(INTERNAL_API_URL + GET_SEARCH_STOCKS__URL, formData)
}

export function getStockDetails(warehouseStockId) {
  return axios.get(INTERNAL_API_URL + GET_STOCK_DETAILS_URL, {
    params: {
      WarehouseStockId: warehouseStockId
    }
  });
}

export function registerStockInWarehouse(wsem) {
  return axios.post(INTERNAL_API_URL + POST_REGISTER_STOCK_IN_WAREHOUSE_URL, {
    wsem
  });
}

export function registerLossBreak(wsem) {
  return axios.post(INTERNAL_API_URL + POST_REGISTER_LOSS_BREAK_URL, {
    wsem
  });
}

export function getUnitValueByCategory(category) {
  return axios.get(INTERNAL_API_URL + GET_UNIT_VALUE_BY_CATEGORY_URL + category);
}

export function getBatchValue(category, quantity) {
  return axios.get(EXTERNAL_API_URL + GET_BATCH_VALUE__URL.replace("{cId}", category).replace("{q}", quantity));
}

export function getKitDeliveryApplicationDetails(applicationCode) {
  return axios.get(INTERNAL_API_URL + GET_KIT_DELIVERY_APPLICATION_DETAILS_URL, {
    params: {
      ApplicationCode: applicationCode
    }
  });
}

export function getProfessionalKitDeliveryDocument(data) {
  return axios.get(INTERNAL_API_URL + GET_PROFESSIONAL_KIT_DELIVERY_DOCUMENT + data, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  });
}
