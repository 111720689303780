import moment from "moment";

export const getHours = (date) => {
  if (date) return moment(date).format("HH:mm");
};
export const getDate = (date) => {
  if (date) return moment(date).format("DD/MM/YYYY");
};

export default function formatDate(date) {
  if (!date) return null;

  return moment(new Date(date)).format("YYYY-MM-DD");
}
