import { React, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";

//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic";
import componentStylesApplicationDetails from "assets/theme/views/admin/application-details";
import NotificationsTab from "./Tabs/NotificationsTab";
import HistoryTab from "./Tabs/HistoryTab";
import SchedulingTab from "./Tabs/SchedulingTab";
import GeneralDataTab from "./Tabs/GeneralDataTab";
import { getApplicationDetailsGeneralDataActions } from "redux/actions/applicationsAction";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import ApplicationStepper from "./Stepper/ApplicationStepper";
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import { titles } from "resources/resources";
import useScrollDirection from "hooks/useScrollDirection";
import Tabs from "components/Tabs/Tabs";

const useStylesGeneric = makeStyles(componentStylesGeneric);
const useStylesApplicationDetails = makeStyles(componentStylesApplicationDetails);

function ApplicationDetail(props) {
  const classes = { ...useStylesGeneric(), ...useStylesApplicationDetails() };
  const intl = useIntl();
  const scrollDirection = useScrollDirection();

  const TABS = {
    GENERAL_DATA: "generalDataStep",
    SCHEDULING: "schedulingStep",
    NOTIFICATIONS: "notificationsStep",
    HISTORY: "historyStep",
  };

  const [applicationState, setApplicationState] = useState(0);
  const [applicationStateHistory, setApplicationStateHistory] = useState([]);
  const [tabStep, setTabStep] = useState(TABS.GENERAL_DATA);
  const [applicationNumber, setApplicationNumber] = useState();
  const [showAlertMessage, setShowAlertMessage] = useState(true);

  const handleTabStep = (event, value) => {
    if (value !== null) {
      setTabStep(value);

      setShowAlertMessage(value === TABS.GENERAL_DATA);
    }
  };

  function loadTabContent(applicationNumber) {
    switch (tabStep) {
      case TABS.GENERAL_DATA:
        return (
          <GeneralDataTab
            applicationNumber={applicationNumber}
            generalData={props.applicationsState.applicationDetailsGeneralData}
          />
        );
      case TABS.SCHEDULING:
        return (
          <SchedulingTab
            generalData={props.applicationsState.applicationDetailsGeneralData}
            applicationNumber={applicationNumber}
          />
        );
      case TABS.NOTIFICATIONS:
        return (
          <NotificationsTab
            applicationNumber={applicationNumber}
            generalData={props.applicationsState.applicationDetailsGeneralData}
          />
        );
      case TABS.HISTORY:
        return <HistoryTab applicationNumber={applicationNumber} />;
      default:
        return <></>;
    }
  }

  useEffect(() => {
    props.getApplicationDetailsGeneralData(props.match.params.applicationNumber);

    setApplicationNumber(props.match.params.applicationNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.applicationNumber]);

  useEffect(() => {
    if (props.applicationsState.applicationDetailsGeneralData !== undefined) {
      setTabStep(TABS.GENERAL_DATA);
      setApplicationState(props.applicationsState.applicationDetailsGeneralData?.applicationStateId);
      setApplicationStateHistory(props.applicationsState.applicationDetailsGeneralData?.applicationStateHistory);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.applicationsState.applicationDetailsGeneralData]);

  return (
    <>
      <GenericBackdrop open={props.applicationsState.application_details_loading} />

      {scrollDirection === "up" && (
        <div className={classes.breadcrumbs}>
          <LocationHeader
            section={"Detalhes do Processo"}
            subsection={intl.formatMessage(titles.Title_Admin_ApplicationsManagement)}
            subsectionLink={"/admin/applications-management"}
          />
        </div>
      )}

      <Container maxWidth={false} component={Box} classes={{ root: classes.containerRootAfterBreadcrumbs }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <PageTitleHeader title={applicationNumber} />
          </Grid>
          <Grid item xs={12} md={6}>
            {showAlertMessage && (
              <Alert
                severity="info"
                style={{ marginRight: "24px", marginLeft: "24px" }}
                classes={{
                  icon: classes.alertIcon,
                }}
              >
                {"Aviso: ao encargo do pelouro responsável '" +
                  props.applicationsState.applicationDetailsGeneralData?.departmentName +
                  "'"}
              </Alert>
            )}
          </Grid>
        </Grid>

        <Box component={Grid} container justifyContent="center">
          <Tabs
            tabs={[
              {
                value: TABS.GENERAL_DATA,
                testId: "application-detail-general-data-tab",
                text: intl.formatMessage(titles.Title_Admin_ApplicationDetail_GeneralDataSectionButton),
              },
              {
                value: TABS.SCHEDULING,
                testId: "application-detail-scheduling-tab",
                text: intl.formatMessage(titles.Title_Admin_ApplicationDetail_SchedulingSection),
              },
              {
                value: TABS.NOTIFICATIONS,
                testId: "application-detail-notifications-tab",
                text: intl.formatMessage(titles.Title_Admin_ApplicationDetail_NotificationsSection),
              },
              {
                value: TABS.HISTORY,
                testId: "application-detail-history-tab",
                text: intl.formatMessage(titles.Title_Admin_ApplicationDetail_HistorySectionButton),
              },
            ]}
            onChange={handleTabStep}
            step={tabStep}
          />
          <Grid item xs={12} lg={12} md={12}>
            {loadTabContent(applicationNumber)}
          </Grid>
        </Box>
      </Container>

      <Grid>
        <ApplicationStepper applicationState={applicationState} history={applicationStateHistory} />
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getApplicationDetailsGeneralData: (applicationNum) =>
    dispatch(getApplicationDetailsGeneralDataActions(applicationNum)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationDetail);
