import { React, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import "react-quill/dist/quill.snow.css";
//Header
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import DetailsSection from "./Sections/DetailsSection";
import { getInformationDetailsActions } from "redux/actions/informationDisseminationAction";
import useScrollDirection from "hooks/useScrollDirection";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function Details(props) {
  const classes = useStylesGeneric();
  const history = useHistory();
  const scrollDirection = useScrollDirection();

  useEffect(() => {
    if (
      props.informationDisseminationState.informationDetailsData === undefined ||
      props.informationDisseminationState.informationDetailsData.alternateKey !== props.match.params.infoKey ||
      props.informationDisseminationState.refresh_results
    ) {
      props.getInformationDetails(props.match.params.infoKey, history);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [props.match.params.infoKey, props.informationDisseminationState.refresh_results]);

  return (
    <>
      {scrollDirection === 'up' && (
        <div className={classes.breadcrumbs}>
          <LocationHeader
            section={"Detalhes de Informação"}
            subsection={"Gestão de Informação"}
            subsectionLink={"/admin/informationdissemination-management"}
          />
        </div>
      )}

      <GenericBackdrop
        open={props.informationDisseminationState.get_information_details_loading}
      />
      <Container maxWidth={false} classes={{ root: classes.containerRootAfterBreadcrumbs }}>
        <PageTitleHeader title={props.informationDisseminationState.informationDetailsData?.title} />

        <DetailsSection
          informationDetailsData={props.informationDisseminationState.informationDetailsData}
        ></DetailsSection>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
    getInformationDetails: (infoId, history) => dispatch(getInformationDetailsActions(infoId, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Details);
