import React, {useEffect, useState } from "react";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import "moment/locale/pt";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Collapse from "@material-ui/core/Collapse";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import Typography from "@material-ui/core/Typography";
import LinkMaterialUi from "@material-ui/core/Link";
import { FormHelperText } from "@material-ui/core";
// @material-ui/icons components
import NotificationsIcon from "@material-ui/icons/Notifications";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { addFOUserHistoryEntryAction, getFOUserHistoryActions } from "redux/actions/usersAction"
import { HISTORY_ENTRY_TYPES } from "utils/consts";
import { labels } from "resources/resources";
import { messages, titles } from "resources/resources";
import GenericButton from "components/Buttons/GenericButton";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function HistoryTab(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const intl = useIntl();
  const [expanded, setExpanded] = useState(false);

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (
      props.usersState.foUserHistory === undefined ||
      props.usersState.foUserHistory.length === 0 ||
      props.usersState.foUserHistory[0].userId !==
        props.userId
    ) {
      props.getFOUserHistory({ userId: props.userId});
    }

    setValue("userId", props.userId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.usersState.foUserHistory_refresh_results)
      props.getFOUserHistory({ userId: props.userId});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.usersState.foUserHistory_refresh_results]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const onSubmit = (data) => {
    if (isValid) {
      props.addFOUserHistoryEntry({userId: props.userId, message: data.historyComment});
    }
    setValue("historyComment", "")
  };

  function attributeRedirectLink(historyTypeKeyValue, applicationCode){
    switch(historyTypeKeyValue){
      case HISTORY_ENTRY_TYPES.ESTAGIO_FIM :
      case HISTORY_ENTRY_TYPES.ESTAGIO_APROVADO:
      case HISTORY_ENTRY_TYPES.ESTAGIO_INICIO :
        return (
          <LinkMaterialUi
            component={Link}
            variant="h5"
            to={`../opportunity-applications-followup-details/` + applicationCode}
            className={classes.containedGhostButton}
          >
            {intl.formatMessage(labels.Label_SeeDetails)}
          </LinkMaterialUi>
        );
      case HISTORY_ENTRY_TYPES.PROGRAMA_GOVERNO_APROVADO :
        return (
          <LinkMaterialUi
            component={Link}
            variant="h5"
            to={`../application-detail/` + applicationCode}
            className={classes.containedGhostButton}
          >
            {intl.formatMessage(labels.Label_SeeDetails)}
          </LinkMaterialUi>
        );
      case HISTORY_ENTRY_TYPES.EMPREGO_APROVADO:
        return (
          <LinkMaterialUi
            component={Link}
            variant="h5"
            to={`../candidate-details/` + applicationCode}
            className={classes.containedGhostButton}
          >
            {intl.formatMessage(labels.Label_SeeDetails)}
          </LinkMaterialUi>
        );
      default:
        return <div></div>;
    }
  } 

  return (
    <>
      <Container maxWidth={false} component={Box}>
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            classes={{ title: classes.cardHeader }}
            title={intl.formatMessage(
              titles.Title_Admin_ApplicationDetail_HistorySection
            )}
          ></CardHeader>
          <CardContent>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Timeline
                  style={{
                    height: "400px",
                    overflow: "auto",
                  }}
                >
                  {props.usersState.foUserHistory?.map(
                    (userHistory) => {
                      return (
                        <TimelineItem key={userHistory.id} className={classes.minHeight0PX}>
                          <TimelineOppositeContent
                            style={{ flex: 0 }}
                          ></TimelineOppositeContent>
                          <TimelineSeparator>
                            <Box className={classes.timelineIconsBox}>
                              <NotificationsIcon
                                className={classes.timelineIcons}
                              />
                            </Box>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography variant="caption">
                              {userHistory.date}
                            </Typography>
                            <Typography variant="h5" component="h1">
                              {userHistory.title}
                            </Typography>
                            <Typography variant="caption">
                              {userHistory.description}
                            </Typography>
                            <Typography variant="h5" component="h1">
                              {attributeRedirectLink(userHistory.historyEntryTypeKeyValue, userHistory.applicationCode)}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                      );
                    }
                  )}
                </Timeline>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>

      <Container maxWidth={false} component={Box}>
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            classes={{ title: classes.cardHeader, action: classes.cardHeaderAction }}
            title={intl.formatMessage(
              titles.Title_Admin_ApplicationDetail_HistoryNotesSection
            )}
            action={
              <GenericButton
                typeSubmit={false}
                size="small"
                color="tertiary"
                onClick={handleExpandClick}
                aria-expanded={expanded}
              >
                {intl.formatMessage(labels.Label_Generic_Add)}

              </GenericButton>
            }
          ></CardHeader>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <FormGroup>
                      <Controller
                        name="historyComment"
                        control={control}
                        component={Box}
                        rules={{
                          required: {
                            value: true,
                            message: intl.formatMessage(
                              messages.Message_Generic_MandatoryField
                            ),
                          },
                        }}
                        render={({ field: { onChange, name, value } }) => (
                          <OutlinedInput
                            name={name}
                            value={value}
                            onChange={onChange}
                            fullWidth
                            multiline
                            rows="3"
                            autoComplete="off"
                            type="text"
                            classes={{
                              notchedOutline: clsx({
                                [classes.borderWarning]:
                                  errors["historyComment"] !== undefined,
                              }),
                            }}
                          />
                        )}
                      />

                      {errors["historyComment"] !== undefined && (
                        <FormHelperText
                          component={Box}
                          color={theme.palette.warning.main + "!important"}
                        >
                          {errors["historyComment"].message}
                        </FormHelperText>
                      )}
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} lg={6} xl={8}></Grid>
                  <Grid item xs={12} lg={3} xl={2}>
                    <FormGroup>
                      <GenericButton
                        typeSubmit={false}
                        color="secondary"
                        onClick={handleExpandClick}
                      >
                        {intl.formatMessage(labels.Label_Generic_Cancel)}
                      </GenericButton>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} lg={3} xl={2}>
                    <FormGroup>
                      <GenericButton
                        typeSubmit={true}
                        color="primary"
                      >
                        {intl.formatMessage(labels.Label_Generic_Add)}
                      </GenericButton>
                    </FormGroup>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Collapse>
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getFOUserHistory: (userId) =>
    dispatch(getFOUserHistoryActions(userId)),
  addFOUserHistoryEntry: (data) =>
    dispatch(addFOUserHistoryEntryAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryTab);
