import { getFilteredLoans, getLoan, rejectLoan, updateLoan } from "crud/loans.crud";
import moment from "moment";
import { LOANS, DIALOG } from "utils/actionsConsts";

export const getFilteredLoansActions = (formData) => async (dispatch) => {
  dispatch({
    type: LOANS.GET_FILTERED_LOANS_LOADING,
    payload: true,
  });
  dispatch({
    type: LOANS.REFRESH_RESULTS,
    payload: false,
  });
  getFilteredLoans(formData)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          id: record.id,
          userTypeKey: record.userTypeKey,
          nameOfUser: record.nameOfUser,
          identityCardOrTaxIdNum: record.identityCardOrTaxIdNum,
          applicationNumber: record.applicationNumber,
          governmentProgramName: record.governmentProgramName,
          aprovedValue: record.aprovedValue != null ? record.aprovedValue : 0,
          lastInstallment: record.lastInstallment ? moment(record.lastInstallment).format("DD/MM/YYYY HH:mm") : "",
          amountToBePaid: record.amountToBePaid,
          isMissingPayments: record.isMissingPayments ? "Sim" : "Não",
          loanState: record.state,
          loanStateKeyValue: record.stateKeyValue,
          loanTypeDescription: record.loanTypeDescription,
          bankNameInitials: record.bankNameInitials,
        };
      });
      dispatch({
        type: LOANS.GET_FILTERED_LOANS,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: LOANS.GET_FILTERED_LOANS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: LOANS.GET_FILTERED_LOANS,
        result: false,
        payload: [],
      });
      dispatch({
        type: LOANS.GET_FILTERED_LOANS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export default getFilteredLoansActions;

export const getLoanAction = (loanNumber) => async (dispatch) => {
  dispatch({
    type: LOANS.GET_LOAN_DETAILS_LOADING,
    payload: true,
  });
  getLoan(loanNumber)
    .then((res) => {
      dispatch({
        type: LOANS.GET_LOAN_DETAILS,
        result: true,
        payload: res.data,
      });
      dispatch({
        type: LOANS.GET_LOAN_DETAILS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: LOANS.GET_LOAN_DETAILS,
        result: false,
        payload: {},
      });
      dispatch({
        type: LOANS.GET_LOAN_DETAILS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const rejectLoanAction = (loan, successFunction, okFunction) => async (dispatch) => {
  dispatch({
    type: LOANS.REJECT_LOAN_LOADING,
    payload: true,
  });
  rejectLoan(loan)
    .then((res) => {
      dispatch({
        type: LOANS.REJECT_LOAN_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data,
            message: res.data
              ? "Empréstimo rejeitado com sucesso."
              : "Ocorreu um erro rejeitar o empréstimo. Por favor tente novamente.",
            okAction: okFunction,
          },
        },
      });

      if (successFunction) successFunction();

      dispatch({
        type: LOANS.REFRESH_RESULTS,
        payload: true,
      });
    })
    .catch((error) => {
      dispatch({
        type: LOANS.REJECT_LOAN_LOADING,
        payload: false,
      });

      console.log("error", error);
    });
};

export const updateLoanAction = (formData, successFunction, okFunction) => async (dispatch) => {
  const auxOperation = formData.isMissingPayments === null ? "aprovado" : "actualizado";
  dispatch({
    type: LOANS.UPDATE_LOAN_LOADING,
    payload: true,
  });
  updateLoan(formData)
    .then((res) => {
      dispatch({
        type: LOANS.UPDATE_LOAN_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data !== null,
            message:
              res.data !== null
                ? "Empréstimo " + auxOperation + " com sucesso."
                : "Ocorreu um erro a " + auxOperation + " o empréstimo. Por favor tente novamente.",

            okAction: okFunction,
          },
        },
      });
      if (successFunction) successFunction();
      dispatch({
        type: LOANS.REFRESH_RESULTS,
        payload: true,
      });
    })
    .catch(() => {
      dispatch({
        type: LOANS.UPDATE_LOAN_LOADING,
        payload: false,
      });
    });
};
