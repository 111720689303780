import React from "react";

import EmploymentHistorySection from "views/admin/UserManagement/FrontOffice/IndividualUser/Sections/EmploymentHistorySection";
import UnemploymentHistorySection from "views/admin/UserManagement/FrontOffice/IndividualUser/Sections/UnemploymentHistorySection";

function EmploymentAndUnemploymentHistory(props) {
  return (
    <>
      <EmploymentHistorySection {...props} />
      <UnemploymentHistorySection {...props} />
    </>
  );
}

export default EmploymentAndUnemploymentHistory;
