import { React, useState } from "react";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
//import icons
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import GenericButton from "components/Buttons/GenericButton";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ComplementaryDataCard(props) {
  const classes = useStylesGeneric();

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardHeader
        classes={{ title: classes.innerCardHeaderTitle, action: classes.cardHeaderAction }}
        avatar={
          <Box display="flex">
            <Box className={classes.cardHeaderAvatar}>{props.index + 1}</Box>
            <Box marginLeft="20px">
              <FormatAlignLeftIcon className={classes.cardHeaderIcon} />
            </Box>
          </Box>
        }
        title={
          <Box display="flex">
            <Box>{props.questionInfo?.itemTitle}</Box>
            {props.questionInfo?.itemIsRequired && (
              <PriorityHighIcon style={{ alignSelf: "center", marginLeft: "10px" }} />
            )}
          </Box>
        }
        action={
          <IconButton
            className={clsx(classes.cardExpand, {
              [classes.cardExpandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show-more"
          >
            <ExpandMoreIcon />
          </IconButton>
        }
      ></CardHeader>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} style={{ marginBottom: "15px" }}>
              <Typography variant="body2">{props.questionInfo.itemText}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <GenericButton
                typeSubmit={false}
                color="tertiary"
                size="small"
                style={{ display: "flex", width: "100%" }}
                onClick={() => props.removeComplementaryDataListEvent(props.keyValue)}
              >
                Eliminar
              </GenericButton>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <GenericButton
                typeSubmit={false}
                color="tertiary"
                size="small"
                style={{ display: "flex", width: "100%" }}
                onClick={() =>
                  props.addComplementaryDataItemComponentEvent(props.complementaryDataType, props.keyValue)
                }
              >
                Ver mais
              </GenericButton>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
}
export default ComplementaryDataCard;
